import styles from "./JobLocation.module.css";
import StaticMap from "../../Utils/StaticMap/StaticMap";
import { getMapsLink } from "components/Utils/General";

const JobLocation = ({ location, isProject = false, jobType }) => {
	if (
		location.latitude === 0 &&
		location.longitude === 0 &&
		!location.title &&
		!location.locationDTO?.id
	)
		return null;

	return (
		<div className={styles.map_container}>
			{location.latitude !== 0 && location.longitude !== 0 && (
				<StaticMap
					lat={location.latitude}
					lng={location.longitude}
					title={location.title}
					zoom="15"
					customStyle={styles.map}
					onClick={() => window.open(getMapsLink(location), "_blank")}
				/>
			)}

			<div>
				<h3 className={styles.title}>
					{/* {`${
                        isProject
                            ? "Project"
                            : jobType === "Casting_Call"
                            ? ""
                            : "Job"
                    }`}{" "} */}
					{`${isProject ? "Project" : ""} Location`}
				</h3>

				<div className={styles.address}>
					<p>{location.title}</p>
					{location.locationDTO && (
						<p>{`${location.locationDTO.name}, ${location.locationDTO.countryDTO.name}`}</p>
					)}
				</div>
			</div>
		</div>
	);
};

export default JobLocation;
