import jobApi from "api/job";
import useMutate from "./useMutate";
import { useQueryClient } from "@tanstack/react-query";
import { jobsKeys } from "queryKeys/jobs-key-factory";
import { projectKeys } from "queryKeys/projects-key-factory";

const useDeleteJob = (job, callBack) => {
	const queryClient = useQueryClient();
	const { jobType, minifiedCompany: { uuid: companyId } = {}, project } = job || {};

	const mutation = useMutate(jobApi.deleteJob, () => {
		if (project?.uuid) {
			queryClient.invalidateQueries(projectKeys.detail(project.uuid)); //* to invalidate the count of jobs (active / inactive) in the project
		} else {
			queryClient.invalidateQueries(jobsKeys.landing()); //* to invalidate the count of jobs (active / inactive)
		}

		queryClient.invalidateQueries(jobsKeys.jobTypeByCompany(companyId, jobType, project?.uuid));

		callBack && callBack();
	});

	return mutation;
};

export default useDeleteJob;
