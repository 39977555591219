import styles from "./PhoneChange.module.scss";
import { useForm } from "react-hook-form";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import TextInput from "../Utils/SubComs/Inputs/TextInput/TextInput";
import { useEffect, useRef, useState } from "react";
import profileApi from "../../api/profile";
import useMutate from "../../hooks/useMutate";
import { toast } from "react-toastify";
import { components } from "react-select";
import { dialCodeFormatter } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import InfiniteSearchInput from "components/Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import useFetchLocation from "hooks/useFetchLocation";
import { locationKeys } from "queryKeys/location-key-factory";
import { useDispatch, useSelector } from "react-redux";
import { userLoginUpdated } from "store/slices/user";
import GaawkButton from "components/Utils/Button/GaawkButton";
import { InputOtp } from "primereact/inputotp";
import {
	otpUpdated,
	resetOtp,
	// resetTimer,
	startTimer,
	updateNewMobile,
} from "store/slices/otp";
import routes from "components/Routing/routing-keys";
import { useNavigate } from "react-router-dom";

const itemsPerPage = 20;

const PhoneChange = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const schema = Joi.object({
		mobile: Joi.string()
			.pattern(/^[0-9]+$/)
			.required()
			.label("Mobile")
			.messages({
				"string.empty": "Mobile can't be empty",
				"string.pattern.base": "Mobile can only contain numbers",
			}),
		countryCode: Joi.required().messages({
			"any.required": "Dial code is required",
		}),
	});

	const {
		register,
		formState: { errors },
		handleSubmit,
		control,
		watch,
		setError,
	} = useForm({ resolver: joiResolver(schema), mode: "onBlur" });

	const codeWatcher = watch("countryCode");
	const mobileWatcher = watch("mobile");

	const { mobile, mobileCountryCode } = useSelector(
		(state) => state.user.userLogin
	);

	const isSameNumber =
		`+${mobileCountryCode.trim()}${mobile}` ===
		`${codeWatcher?.value}${mobileWatcher}`;

	// const [otpToken, setOtpToken] = useState("");

	const {
		code,
		expireAt,
		// sent: isSent,
		newMobile,
	} = useSelector((state) => state.otp);

	const fullMobile = `${newMobile.countryCode}${newMobile.mobile}`;

	const [remainingSeconds, setRemainingSeconds] = useState(0);
	const [hasError, setHasError] = useState(false);
	const [otp, setOtp] = useState("");
	const [canResend, setCanResend] = useState(false);
	const intervalId = useRef(null);
	const hasTimer = useRef(false);

	// Calculate and update remaining time
	useEffect(() => {
		// Clear any existing timer first
		if (intervalId.current) {
			clearInterval(intervalId.current);
			intervalId.current = null;
		}

		// Check if we have a valid expiry time
		if (expireAt) {
			// Initial calculation of remaining time
			const calculateRemainingTime = () => {
				const now = new Date().getTime();
				const remaining = Math.max(
					0,
					Math.ceil((expireAt - now) / 1000)
				);
				setRemainingSeconds(remaining);
				setCanResend(remaining <= 0);

				// Update our ref to track timer status
				hasTimer.current = remaining > 0;

				// Clear timer when it reaches zero
				if (remaining <= 0 && intervalId.current) {
					clearInterval(intervalId.current);
					intervalId.current = null;
				}
			};

			// Calculate once immediately
			calculateRemainingTime();

			// Then set up interval
			intervalId.current = setInterval(calculateRemainingTime, 1000);
		} else {
			// No expiry time set, enable resend
			setRemainingSeconds(0);
			setCanResend(true);
			hasTimer.current = false;
		}

		// Cleanup on unmount or when dependencies change
		return () => {
			if (intervalId.current) {
				clearInterval(intervalId.current);
				intervalId.current = null;
			}
		};
	}, [expireAt]);

	// Handle component unmount or code change (leaving OTP step)
	useEffect(() => {
		// This effect specifically tracks when code goes from truthy to falsy (leaving OTP step)
		// or when component unmounts completely
		return () => {
			// If we're unmounting or leaving OTP step and there's no time remaining, reset the timer
			if (!hasTimer.current && expireAt) {
				dispatch(resetOtp());
			}
			// If there is time remaining, we keep the timer state so user can come back
		};
	}, [code, dispatch, expireAt]);

	const {
		action: { mutate: sendOtp },
	} = useMutate(profileApi.sendOtp, (response) => {
		// setOtpToken(response.data.otp)
		dispatch(otpUpdated(response.data));
		dispatch(
			updateNewMobile({
				countryCode: codeWatcher?.value,
				mobile: mobileWatcher,
			})
		);
	});

	const handleSendOtp = (data) => {
		const {
			mobile,
			countryCode: { value: countryCode },
		} = data;

		if (isSameNumber) {
			setError("mobile", {
				type: "manual",
				message: "You cannot update to the same phone number",
			});
			return;
		}

		sendOtp({
			email: undefined,
			mobile: `${countryCode}${mobile}`,
		});

		// Start the timer through Redux
		dispatch(startTimer(new Date().getTime()));
		setCanResend(false);
	};

	// const handleUpdateSuccess = (response) => {
	// setOtpToken("");
	// toast.success("Phone number updated!");
	// dispatch(userLoginUpdated(response.data));
	// };

	const fetchCountries = useFetchLocation(false, itemsPerPage);

	const handleOTPChange = (value) => {
		setOtp(value);
		setHasError(false);
	};

	const resendOTP = () => {
		if (!canResend) return;

		sendOtp({ email: undefined, mobile: fullMobile });

		// Start the timer through Redux
		dispatch(startTimer(new Date().getTime()));
		setCanResend(false);
	};

	const renderResendButton = () => {
		const text = !canResend
			? `Send code again (wait 0:${remainingSeconds
					.toString()
					.padStart(2, "0")})`
			: "Resend code";

		return (
			<GaawkButton
				severity={"tertiary"}
				disabled={!canResend}
				onClick={resendOTP}
				text={text}
			/>
		);
	};

	const {
		action: { mutate: updatePhone },
	} = useMutate(profileApi.updatePhone, (response) => {
		dispatch(resetOtp());
		toast.success("Phone number updated!");
		dispatch(userLoginUpdated(response.data));
		navigate(routes.settingsAccount);
	});

	const checkOtp = () => {
		if (otp !== code) {
			setHasError(true);
		} else {
			setHasError(false);
			updatePhone({
				countryCode: newMobile.countryCode,
				mobile: newMobile.mobile,
			});
		}
	};

	return (
		<div className={styles.container}>
			{code ? (
				<div className={styles.step}>
					<h3>Confirm your new phone number</h3>
					<div className={styles.info}>
						{`We have sent an SMS message with a code to ${newMobile.countryCode}${newMobile.mobile}. Please enter the 6 digit code from the SMS below`}
					</div>

					<div className={styles.otp_container}>
						<InputOtp
							value={otp}
							onChange={(e) => handleOTPChange(e.value)}
							length={6}
						/>
					</div>

					{hasError && (
						<p className={styles.otp_error}>
							Please check your SMS for a code and try entering it
							again.
						</p>
					)}

					<div className={styles.button_container}>
						{renderResendButton()}
						<GaawkButton text="Confirm" onClick={checkOtp} />
					</div>
				</div>
			) : (
				<form onSubmit={handleSubmit((data) => handleSendOtp(data))}>
					<p className={styles.info}>
						Enter a mobile number which will be linked to your
						account. This number will not be published on your
						profile unless you choose to make it visible.
					</p>

					<div className={styles.form_input_wrapper}>
						<label>
							Mobile Number<span className="required">*</span>
						</label>

						<div className={styles.phone_input_wrapper}>
							<InfiniteSearchInput
								queryName={locationKeys.countries}
								queryFn={fetchCountries}
								itemsPerPage={itemsPerPage}
								formatter={dialCodeFormatter}
								error={errors?.countryCode}
								control={control}
								name="countryCode"
								customStyle={styles.dialCode_input}
								errorStyle={styles.hide}
								openMenuOnClick={true}
								option={{
									ValueContainer: ({
										children,
										...props
									}) => {
										const { value } = props.selectProps;

										return (
											<components.ValueContainer
												{...props}
											>
												{value?.value || children}
											</components.ValueContainer>
										);
									},
								}}
							/>

							<TextInput
								{...register("mobile")}
								placeholder="Enter your mobile number"
								error={!!errors.mobile}
							/>
						</div>

						{errors?.countryCode && (
							<p
								className={`${styles.error_message} ${styles.countryCode}`}
							>
								{errors?.countryCode?.message}
							</p>
						)}
						{errors?.mobile && (
							<p
								className={`${styles.error_message} ${styles.mobile}`}
							>
								{errors?.mobile?.message}
							</p>
						)}
					</div>

					<div className={styles.button_container}>
						<GaawkButton
							type={"submit"}
							text={"Send OTP"}
							disabled={errors.countryCode || errors.mobile}
						/>
					</div>
				</form>
			)}
		</div>
	);
};

export default PhoneChange;
