import JobApplicants from "components/Jobs/Applicants/JobApplicants";
import EmployerLanding from "components/Jobs/EmployerLanding/EmployerLanding";
import OtherUserJobs from "components/Jobs/JobsComponents/OtherUserJobs/OtherUserJobs";
import JobsLanding from "components/Jobs/Landing/JobsLanding";
import ProjectAll from "components/Jobs/ProjectAll/ProjectAll";
import NavBar from "components/Utils/SubComs/NavBar/NavBar";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { resetJobsFilters } from "store/slices/filters";
import JobEntry from "../../components/Jobs/JobEntry/JobEntry";
import ProjectEntry from "../../components/Jobs/ProjectEntry/ProjectEntry";
import BackButton from "../../components/Utils/Button/BackButton";
import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import useCompanyId from "../../hooks/useCompanyId";
import styles from "./JobsView.module.scss";
import JobsCompany from "components/Jobs/JobsCompany/JobsCompany";

const JobsView = () => {
	const navigate = useNavigate();
	const { pathname, state } = useLocation();

	const isCompany = useCompanyId();
	const dispatch = useDispatch();

	useEffect(() => {
		return () => {
			//CLEARS JOBS SEARCH FILTERS WHEN COMPONENT UNMOUNTS
			dispatch(resetJobsFilters());
		};
	}, []);

	const { jobId, projectId, companyUrl } = useParams();

	let component;
	let headerBtn;
	let headerTitle;
	let directRender = false; //* this allows to render the component directly instead of using the <DefaultPage /> arch component
	let defaultBgColor = "#FFF";

	switch (pathname) {
		case "/jobs/applied":
			directRender = true;
			component = <OtherUserJobs jobCategory={"applied"} />;
			break;

		case "/jobs/saved":
			directRender = true;
			component = <OtherUserJobs jobCategory={"saved"} />;
			break;

		case "/jobs/create":
			headerTitle = "Create Job";
			component = (
				<JobEntry jobType={state?.jobType} project={state?.project} />
			);
			break;

		case `/jobs/${jobId}/edit`:
			component = <JobEntry />;
			break;

		case `/jobs/${jobId}/applicants`:
			directRender = true;
			component = <JobApplicants />;
			break;

		case `/jobs/project/${projectId}/edit`:
			headerTitle = "Update Project";
			component = <ProjectEntry />;
			break;

		case `/jobs/${companyUrl}`:
			directRender = true;
			component = <JobsCompany />;
			break;

		case "/jobs":
			// dispatch(resetJobsFilters()); //TODO >> this causes error in the console
			// headerTitle = "Jobs";
			if (isCompany) {
				directRender = true;
				component = <EmployerLanding />;
			} else {
				directRender = true;
				component = <JobsLanding />;
			}
			break;

		case "/jobs/project/add":
			headerTitle = "Create Project";
			component = <ProjectEntry />;
			break;

		case "/jobs/projects":
			// headerTitle = "Manage Projects";
			directRender = true;
			component = <ProjectAll />;

			break;

		default:
			break;
	}

	return (
		<>
			{directRender ? (
				component
			) : (
				<DefaultPage
					headerRightContent={
						<>
							<div className={styles.header}>
								<BackButton onBack={() => navigate(-1)} />
								{headerTitle}
							</div>
							{headerBtn}
						</>
					}
					leftSideChildren={<NavBar />}
					rightSideChildren={component}
					rightSideContainerColor={defaultBgColor}
				/>
			)}
		</>
	);
};

export default JobsView;
