import { cssValue } from "components/Utils/General";
import { forwardRef } from "react";
import Switch from "react-switch";

const Switcher = forwardRef(({ value, onChange }, ref) => {
	return (
		<Switch
			ref={ref}
			checked={value}
			onColor="#000000"
			offColor={cssValue("--gray70")}
			handleDiameter={18}
			width={39}
			height={24}
			checkedIcon={false}
			uncheckedIcon={false}
			activeBoxShadow="0px 0px 1px 8px rgb(166, 177, 188, 0.3)"
			onChange={onChange}
		/>
	);
});

export default Switcher;
