export const eventsDictionary = {
	HOME: "home_landing",
	// ABOUT: "", //TODO
	// FAQ: "", //TODO

	NOTIFICATION: {
		LANDING: "notification_landing",
		MARK_ALL_READ: "notification_landing_read_all",
		DELETE_ALL: "notification_landing_delete_all",
	},

	SETTINGS: {
		LANDING: "setting_landing",
		DELETE_USER: "delete_user_account",
		DELETE_COMPANY: "delete_company",
	},

	REPORT: {
		REPORT: "report_landing",
		REPORT_DONE: "report_done",
	},

	BOARD: {
		DETAIL: "board_detail",
		DETAIL_FOLLOW: "board_detail_follow",
		DETAIL_UN_FOLLOW: "board_detail_un_follow",
		LIST: "board_list",
		CREATE: "create_board",
		CREATE_DONE: "create_board_done",
		UPDATE_DONE: "create_board_updated",
		SUGGEST: "suggest_board",
		SUGGEST_DONE: "suggest_board_suggested",
		MANAGE: "manage_board",
		MANAGE_MODERATOR: "manage_board_moderator",
	},

	AGENCY: {
		BECOME_INITIATE: "become_an_agency_initiate",
		BECOME_DONE: "become_an_agency_done",
		DELETE_INITIATE: "delete_agency_initiate",
		DELETE_DONE: "delete_agency_done",
	},

	COMPANY: {
		CREATE: "create_company",
		CREATE_DONE: "create_company_done",
		DEGRADE: "degrade_to_company",
		MANAGE: "manage_company",
		MANAGE_DELETE_INITIATE: "manage_company_delete_initiate",
		MANAGE_DELETE_DONE: "manage_company_delete_done",
		SELF_LIST: "my_companies",
		TRANSFER_OWNERSHIP_INITIATE: "transfer_ownership_initiate",
		TRANSFER_OWNERSHIP_DONE: "transfer_ownership_done",
		MY_COMPANIES: "my_companies",
		CREATE_COMPANY: "create_company",
		CREATE_COMPANY_DONE: "create_company_done",
	},

	CONNECTION: {
		LIST: "connection_list",
		LIST_FRIENDS: "connection_view_all_friends",
		LIST_SENT_REQUEST: "connection_view_all_sent_friend_request",
		LIST_PENDING_REQUEST: "connection_view_all_pending_friend_request",
		LIST_YOUR_WORK_CIRCLE: "connection_view_all_your_work_circle",
		LIST_THEIR_WORK_CIRCLE: "connection_view_all_their_work_circle",
		LIST_YOU_FOLLOW: "connection_view_all_you_follow",
		LIST_YOUR_FOLLOWER: "connection_view_all_your_follower",
		LIST_YOUR_FAVORITE: "connection_view_favorite",
		LIST_BLOCKED: "connection_view_all_blocked",
	},

	PROFILE: {
		ADD_ADDRESS: "add_address",
		ADD_ADDRESS_DONE: "add_address_done",
		ADDRESS_LIST: "address_list",
		DELETE_ADDRESS: "delete_address",

		ADD_EMAIL: "add_email",
		ADD_EMAIL_DONE: "add_email_done",
		EMAIL_LIST: "email_list",
		DELETE_EMAIL: "delete_email",

		ADD_PHONE: "add_phone",
		ADD_PHONE_DONE: "add_phone_done",
		PHONE_LIST: "phone_list",
		DELETE_PHONE: "delete_phone",

		ADD_SOCIAL_MEDIA: "add_social_media",
		ADD_SOCIAL_MEDIA_DONE: "add_social_media_done",
		SOCIAL_MEDIA_LIST: "social_media_list",
		DELETE_SOCIAL_MEDIA: "delete_social_media",

		ADD_OTHERS: "add_others",
		ADD_OTHERS_DONE: "add_others_done",
		OTHERS_LIST: "others_list",
		DELETE_OTHERS: "delete_others",

		ADD_BIO: "add_bio",
		ADD_BIO_DONE: "add_bio_done",
		DELETE_BIO: "delete_bio",

		ADD_CERTIFICATE: "add_certificate",
		ADD_CERTIFICATE_DONE: "add_certificate_done",
		CERTIFICATE_LIST: "certificate_list",
		DELETE_CERTIFICATE: "delete_certificate",

		ADD_EDUCATION: "add_education",
		ADD_EDUCATION_DONE: "add_education_done",
		EDUCATION_LIST: "education_list",
		DELETE_EDUCATION: "delete_education",

		ADD_EXPERIENCE: "add_experience",
		ADD_EXPERIENCE_DONE: "add_experience_done",
		EXPERIENCE_LIST: "experience_list",
		DELETE_EXPERIENCE: "delete_experience",

		ADD_LANGUAGE: "add_language",
		ADD_LANGUAGE_DONE: "add_language_done",
		LANGUAGE_LIST: "language_list",
		DELETE_LANGUAGE: "delete_language",

		ADD_KEY_PEOPLE: "add_key_people",
		ADD_KEY_PEOPLE_DONE: "add_key_people_done",
		KEY_PEOPLE_LIST: "key_people_list",
		DELETE_KEY_PEOPLE: "delete_key_people",

		ADD_ROLES: "add_roles",
		ADD_ROLES_DONE: "add_roles_done",

		ADD_SKILLS: "add_skills",
		ADD_SKILLS_DONE: "add_skills_done",

		ADD_SOFTWARE: "add_software",
		ADD_SOFTWARE_DONE: "add_software_done",
		SOFTWARE_LIST: "software_list",
		DELETE_SOFTWARE: "delete_software",

		ADD_CALENDAR_EVENT: "add_calendar_event",
		ADD_CALENDAR_EVENT_DONE: "add_calendar_event_done",
		DELETE_CALENDAR_EVENT: "delete_calendar_event",
		CALENDAR_EVENT_DETAILS: "calendar_event_details",
		CALENDAR_DETAILS: "calendar_details",
		UPDATE_WORK_STATUS: "update_work_status",
		UPDATE_WORK_STATUS_DONE: "update_work_status_done",

		EDIT_COVER: "edit_cover",
		EDIT_PROFILE: "edit_profile",
		EDIT_HEADER: "edit_header",
		EDIT_HEADER_DONE: "edit_header_done",
		DETAIL: "profile_detail",
		PROFILE_TAB: "profile_tab",
		CONTACTS_TAB: "contacts_tab",
		SERVICES_TAB: "services_tab",
		PRODUCTS_TAB: "products_tab",
		POSTS_TAB: "posts_tab",
		GALLERY_TAB: "gallery_tab",
		CONNECTIONS_TAB: "connections_tab",
		WORK_STATUS_TAB: "work_status_tab",

		TALENT_TAB: "talent_tab",
		TALENT_PENDING: "pending_talent_list",

		SWITCH_PANEL: "switch_panel",
		SWITCH_PROFILE: "profile_switch_main_done",
		CONNECTION: "profile_connection_landing",
		EMPLOYEES_LIST: "employees_list",
		EMPLOYEES_LIST_ALL: "employees_list_all",
		EMPLOYEES_LIST_PENDING: "employees_list_pending",
	},

	SPECIAL_PROFILE: {
		LIST: "special_profile_list",
		ADD: "add_special_profile",
		EDIT: "edit_special_profile",
		EDIT_GENERAL: "edit_special_profile_general",
		EDIT_PHYSICAL: "edit_special_profile_physical",
		EDIT_VOCAL: "edit_special_profile_vocal",
		EDIT_ADDITIONAL: "edit_special_profile_additional",
		EDIT_MEDIA: "edit_special_profile_media",
		DETAIL: "special_profile_detail",
	},

	POST: {
		CREATE: "create_post",
		CREATE_DONE: "create_post_done",
		EDIT: "edit_post",
		EDIT_DONE: "edit_post_done",
		POST_MEDIA_DETAILS: "post_media_details",
		FULL_POST_DETAILS: "full_post_details",
		COMMENT_LIST: "comment_list",
		REACTION_LIST: "reaction_list",
	},

	SERVICE: {
		ADD_SERVICE_1: "add_service_1",
		ADD_SERVICE_2: "add_service_2",
		ADD_SERVICE_3: "add_service_3",
		ADD_SERVICE_DONE: "add_service_done",
		EDIT_SERVICE_2: "edit_service_2",
		EDIT_SERVICE_3: "edit_service_3",
		EDIT_SERVICE_DONE: "edit_service_DONE",
		DELETE_SERVICE: "delete_service",
		SUGGEST_SERVICE: "suggest_service",
		DETAILS: "service_details",
		ENQUIRE: "service_enquiry",
	},

	PRODUCT: {
		ADD_PRODUCT: "add_product",
		ADD_PRODUCT_DONE: "add_product_done",
		EDIT_PRODUCT: "edit_product",
		EDIT_PRODUCT_DONE: "edit_product_done",
		DELETE_PRODUCT: "delete_product",
		PRODUCT_LIST: "product_list",
		DETAILS: "product_details",
		ENQUIRE: "product_enquiry",
	},

	CHAT: {
		LANDING: "chat_landing",
		CREATE_GROUP: "create_group",
		SWITCH_PROFILE_CHAT: "profile_switch_from_chat",
		CONVERSATION_DETAILS: "conversation_details",
		GROUP_DETAILS: "group_details",
		MESSAGE_INFO: "message_info_details",
		START_CONVERSATION: "start_conversation",
	},

	SEARCH: {
		LANDING: "search_landing",
		ALL: "search_landing_all",
		PEOPLE: "search_landing_people",
		COMPANIES: "search_landing_company",
		PRODUCTS: "search_landing_products",
		SERVICES: "search_landing_services",
		JOBS: "search_landing_jobs",
		POSTS: "search_landing_posts",
	},

	MORE: {
		LANDING: "more_landing",
		PEOPLE: "more_landing_people",
		COMPANIES: "more_landing_company",
		PRODUCTS: "more_landing_products",
		SERVICES: "more_landing_services",
	},

	JOB: {
		APPLICANTS: "job_applicants",
		APPLY: "apply_job",
		APPLY_REVIEW: "apply_job_review",
		APPLY_DONE: "apply_job_done",
		REVIEW_APPLICATION: "review_job_application",
		SAVED: "saved_jobs",
		APPLIED: "applied_jobs",
		CREATE: "create_job",
		CREATE_PROJECT: "create_project",
		DETAIL: "job_detail",
		SEARCH_LANDING: "job_search_landing",
		LANDING: "job_landing",
		PROJECT_DETAIL: "project_detail",
		FIND_AGENCY: "agency_list_landing",
	},

	AUTH: {
		LOGIN: "login",
		LOGOUT: "logout",
		LOGIN_DONE: "login_done",
		// ONBOARDING: "onboarding",
		OTP_VERIFICATION: "onboarding_verification",
		OTP_VERIFICATION_DONE: "onboarding_verification_done",
		SIGN_UP_1: "sign_up_1",
		SIGN_UP_2: "sign_up_2",
		SIGN_UP_3: "sign_up_3",
		SIGN_UP_DONE: "sign_up_done",
	},

	BUNDLE_KEYS: {
		INSTANCE_ID: "instance_id",
		USER_ID: "user_id",
		PROFILE_ID: "profile_id",
		NAME: "name",
		PROFILE_NAME: "profile_name",
		BOARD_ID: "board_id",
		BOARD_NAME: "board_name",
		SUGGESTED_BOARD_NAME: "suggested_board_name",
		COMPANY_NAME: "company_name",
		COMPANY_ID: "company_id",
		INSIDE_PROJECT: "inside_project",
		EMAIL: "email",
		GENDER: "gender",
		DOB: "dob",
	},
};
