import TextInput from "../Utils/SubComs/Inputs/TextInput/TextInput";
import styles from "./PasswordChange.module.scss";
import { passwordRegex } from "../Utils/General";
import { useForm } from "react-hook-form";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import md5 from "md5";
import useMutate from "../../hooks/useMutate";
import profileApi from "../../api/profile";
import { toast } from "react-toastify";
import GaawkButton from "components/Utils/Button/GaawkButton";
import PasswordValidationItem from "components/Registeration/Components/PasswordForm/PasswordValidationItem";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import useLogout from "hooks/useLogout";

const PasswordChange = () => {
	const schema = Joi.object({
		oldPassword: Joi.string()
			.pattern(passwordRegex)
			.required()
			.label("Current Password")
			.messages({
				"string.pattern.base": "Invalid password",
				"string.empty": "Current password should not be empty",
			}),
		password: Joi.string()
			.required()
			.messages({
				"string.empty": "Password is required",
			})
			.regex(/.{8,}/)
			.message("length")
			.regex(/[A-Z]/)
			.message("upper")
			.regex(/[a-z]/)
			.message("lower")
			.regex(/\d/)
			.message("digit")
			.regex(/[@$!%*?&]/)
			.message("special")
			.custom((value, helpers) => {
				const oldPassword = helpers.state.ancestors[0].oldPassword;
				if (value === oldPassword) {
					return helpers.error("password.same");
				}
				return value;
			})
			.messages({
				"password.same":
					"New password cannot be the same as the old password",
			}),
		confirmPassword: Joi.string()
			.required()
			.valid(Joi.ref("password"))
			.label("Confirm new password")
			.messages({
				"any.only": "Does not match the password",
			}),
	});

	const { handleLogout } = useLogout();

	const {
		register,
		formState: { errors, isDirty, isValid },
		handleSubmit,
		setError,
		reset,
	} = useForm({
		resolver: joiResolver(schema),
		mode: "onChange",
		criteriaMode: "all",
	});
	console.log("🚀 ~ errors >>", errors);

	const handleSuccess = () => {
		toast.success("Successfully updated password");
		reset();
		handleLogout();
	};

	const {
		action: { mutate: updatePassword },
	} = useMutate(profileApi.updatePassword, handleSuccess, (error) =>
		setError("oldPassword", {
			message: error.message,
		})
	);

	const handleUpdate = (data) => {
		const { oldPassword, password } = data;
		updatePassword({
			oldPassword: md5(oldPassword),
			newPassword: md5(password),
		});
	};

	return (
		<form
			className={styles.form}
			onSubmit={handleSubmit((data) => handleUpdate(data))}
		>
			<InputWrapper
				label="Current Password"
				required={true}
				error={errors?.oldPassword}
				component={
					<TextInput
						{...register("oldPassword")}
						placeholder="Enter current password"
						cursor="text"
						isPassword={true}
					/>
				}
			/>

			<p className={styles.info}>
				Choose a password with at least 8 characters, it should include
				at least 1 uppercase letter, 1 number and 1 special character.
			</p>

			<PasswordValidationItem
				validate="length"
				label="8 characters"
				errors={errors}
				isDirty={isDirty}
			/>

			<PasswordValidationItem
				validate="upper"
				label="1 uppercase letter"
				errors={errors}
				isDirty={isDirty}
			/>

			<PasswordValidationItem
				validate="lower"
				label="1 lowercase letter"
				errors={errors}
				isDirty={isDirty}
			/>

			<PasswordValidationItem
				validate="digit"
				label="1 number"
				errors={errors}
				isDirty={isDirty}
			/>

			<PasswordValidationItem
				validate="special"
				label="1 special character"
				errors={errors}
				isDirty={isDirty}
			/>

			<InputWrapper
				label="New Password"
				required={true}
				error={errors?.password}
				customError={
					(errors?.password?.type === "string.empty" ||
						errors?.password?.type === "password.same") &&
					errors?.password?.message
				}
				className={styles.custom_padding}
				component={
					<TextInput
						{...register("password")}
						placeholder="Enter new password"
						cursor="text"
						isPassword={true}
					/>
				}
			/>
			<InputWrapper
				label="Confirm New Password"
				required={true}
				error={errors?.confirmPassword}
				className={styles.custom_padding}
				component={
					<TextInput
						{...register("confirmPassword")}
						placeholder="Enter new password again"
						cursor="text"
						isPassword={true}
					/>
				}
			/>

			<div className={styles.button_container}>
				<GaawkButton
					type={"submit"}
					text={"Change Password"}
					disabled={!isValid}
				/>
			</div>
		</form>
	);
};

export default PasswordChange;
