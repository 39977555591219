import { useState, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import styles from "./ProfileHeader.module.css";
import viewIcon from "images/view-icon-20-x-20-black.svg";
import editIcon from "images/edit-icon-20-x-20-black.svg";
import Popover from "../../Utils/Popover/Popover";
import ProfilePic from "../../Home/ProfilePic";
import LazyImage from "../../Utils/LazyImage/LazyImage";
import FullScreenModal from "../../Utils/GaawkModal/FullScreenModal";
import routes from "components/Routing/routing-keys";
import ConnectionActions from "./ConnectionActions";
import { classNames } from "primereact/utils";
import Tag from "components/Utils/SubComs/Tags/Tag";
import FileViewer from "components/Utils/FileViewer/FileViewer";
import BackButton from "components/Utils/Button/BackButton";
import { ReactComponent as FullscreenIcon } from "images/fullscreen-icon-white.svg";
import { ReactComponent as CoverPlaceholder } from "images/cover-placeholder.svg";
import TabMenu from "components/Utils/SubComs/TabMenu/TabMenu";
import { useSelector } from "react-redux";

const Index = ({
	userInfo: profileInfo,
	canEdit,
	onClickEditCoverPic,
	onClickEditProfilePic,
	onClickEdit,
	onItemClicked,
}) => {
	const userInfo = profileInfo;
	const fileviewerRef = useRef();

	const profileActiveTab = useSelector((state) => state.activeTab.profileTab);

	const [activeImage, setActiveImage] = useState(undefined);

	const { userUrl } = useParams();
	const { pathname } = useLocation();

	const [showViewer, setShowViewer] = useState(false);

	const {
		firstName = "",
		lastName = "",
		name: companyName = "",
		tagLine = "",
		prime,
		type,
		connection: { blocked, blocking } = {},
		deleted,
		agency,
		hasServices,
		hasProducts,
	} = userInfo;

	const thumbnail = userInfo?.profileImage?.framedImageURL
		? userInfo.profileImage.framedImageURL
		: userInfo?.profileImage?.originalImageURL
		? userInfo.profileImage.originalImageURL
		: undefined;

	const profileCoverImg = userInfo.profileCover
		? userInfo.profileCover.originalImageURL
		: undefined;

	const { name: country } = userInfo.countryDTO || "";
	const { name: city } = userInfo.cityDTO || "";

	const location = city && country ? `${city},  ${country}` : "";
	const fullName = `${firstName} ${lastName}`;

	const popoverRef = useRef(null);

	const handleClosePictureModal = () => {
		setShowViewer(false);
		handleUpdateUrl();
	};

	const handleUpdateUrl = () => {
		window.history.pushState(
			null,
			"",
			pathname === "/chat"
				? "/chat"
				: `/profile${userUrl ? `/${userUrl}` : ""}`
		);
	};

	return (
		<>
			<div className={styles.container}>
				<div className={styles.cover_container}>
					<Popover
						ref={popoverRef}
						allow={["bottom-start"]}
						render={
							(profileCoverImg || canEdit) &&
							!blocked &&
							!blocking && (
								<div className="popover_container">
									{profileCoverImg && (
										<button
											className="popover_item"
											onClick={() => {
												setShowViewer(true);
												setActiveImage({
													mediaType: "IMAGE",
													type: "Cover",
													url: userInfo?.profileCover
														?.originalImageURL,
												});

												popoverRef.current.closePopover();
											}}
											tabIndex={0}
										>
											<span>
												<img
													src={viewIcon}
													alt="view"
												/>
											</span>
											<span>View cover</span>
										</button>
									)}

									{canEdit && (
										<button
											className="popover_item"
											onClick={onClickEditCoverPic}
											tabIndex={0}
										>
											<span>
												<img
													src={editIcon}
													alt="edit"
												/>
											</span>
											<span>Edit cover picture</span>
										</button>
									)}
								</div>
							)
						}
					>
						<div className={styles.cover_wrapper}>
							{profileCoverImg ? (
								<LazyImage
									image={
										<img
											src={profileCoverImg}
											alt="cover"
										/>
									}
									customWrapper={classNames({
										[styles.disabled]: !profileCoverImg,
									})}
									// placeholder={<LoadingSpinner visible={true} />} //!Not working as per git
									// onAfterLoad={() =>
									//     console.log("FINISHED LOADING")
									// } //! possible solution conditionally hiding spinner onAfterLoad
								/>
							) : (
								<CoverPlaceholder />
							)}
						</div>
					</Popover>

					<Popover
						ref={popoverRef}
						render={
							(thumbnail || canEdit) &&
							!blocked &&
							!blocking && (
								<div className="popover_container">
									{thumbnail && (
										<button
											className="popover_item"
											onClick={() => {
												setShowViewer(true);
												setActiveImage({
													mediaType: "IMAGE",
													type: "Profile",
													url: userInfo?.profileImage
														?.framedImageURL,
												});

												popoverRef.current.closePopover();
											}}
											tabIndex={0}
										>
											<span>
												<img
													src={viewIcon}
													alt="view"
												/>
											</span>
											<span>View photo</span>
										</button>
									)}
									{canEdit && (
										<button
											className="popover_item"
											onClick={onClickEditProfilePic}
											tabIndex={0}
										>
											<span>
												<img
													src={editIcon}
													alt="edit"
												/>
											</span>
											<span>Edit profile picture</span>
										</button>
									)}
								</div>
							)
						}
					>
						<div
							className={`${styles.profile_pic_wrapper} ${
								type === "COMPANY" ? styles.company : undefined
							}`}
						>
							<ProfilePic
								thumbnail={thumbnail}
								w={130}
								h={130}
								type={type}
								noMarginRight={true}
								isPrime={prime}
								name={
									type === "COMPANY" ? companyName : fullName
								}
								enableName={false}
							/>
						</div>
					</Popover>
				</div>

				<div className={styles.info_container}>
					<div className={styles.info_wrapper}>
						<div className={styles.name_wrapper}>
							<p className={styles.name}>
								{type === "COMPANY" ? companyName : fullName}
							</p>
							{/* {agency && <img src={agencyBadge} alt="" />} */}
							{agency && (
								<Tag
									itemName={"Agency"}
									customStyle={styles.tag}
								/>
							)}
						</div>
						{!blocked && !blocking && (
							<>
								{tagLine && (
									<p className={styles.position}>{tagLine}</p>
								)}
								{location && (
									<p className={styles.location}>
										{location}
									</p>
								)}
							</>
						)}
					</div>

					<ConnectionActions
						canEdit={canEdit}
						onClickEdit={onClickEdit}
						userInfo={userInfo}
						pathname={pathname}
					/>
				</div>

				{!deleted && !blocked && !blocking && (
					<TabMenu
						menuItems={{
							...(pathname === routes.chat && { info: "Info" }),
							profile: "Profile",
							contacts: "Contacts",
							// ...(userInfo.agency && { talent: "Talent" }),
							...(type === "COMPANY" && { jobs: "Jobs" }),
							...((hasServices || canEdit) && {
								services: "Services",
							}),
							...((hasProducts || canEdit) && {
								products: "Products",
							}),
							posts: "Posts",
							// gallery: "Gallery",
							// connections: "Connections",
							...(userInfo.type === "USER" && {
								work_status: "Work Status",
							}),
						}}
						selectedTab={profileActiveTab}
						onSelectedTabChanged={onItemClicked}
					/>
				)}
			</div>

			{deleted && (
				<div className={styles.deleted_container}>
					<h3>OOPS!</h3>
					<p>This profile has been deleted</p>
				</div>
			)}

			<FullScreenModal
				show={showViewer}
				onClose={handleClosePictureModal}
				fullWidth={true}
				header={
					<div className={styles.fileviewer_header}>
						<div>
							<BackButton />
							<p>{activeImage?.type} Picture</p>
						</div>
						<button
							onClick={() =>
								fileviewerRef.current.enterFullscreen()
							}
						>
							<FullscreenIcon width={20} />
						</button>
					</div>
				}
				children={
					<FileViewer ref={fileviewerRef} files={[activeImage]} />
				}
			/>
		</>
	);
};

export default Index;
