import { Skeleton } from "primereact/skeleton";
import styles from "./ServiceCardLoader.module.scss";
import { classNames } from "primereact/utils";
const ServiceCardLoader = ({ className }) => {
	return (
		<div className={classNames(styles.service_card_skeleton, className)}>
			<Skeleton
				size={64}
				borderRadius={8}
				pt={{
					root: {
						style: {
							flexShrink: "0",
						},
					},
				}}
			/>
			<div className={styles.service_card_skeleton_content}>
				<div className={styles.top}>
					<Skeleton width={"50%"}></Skeleton>
					<Skeleton width={"80%"}></Skeleton>
				</div>
				<div className={styles.bottom}>
					<Skeleton shape="circle" size={25}></Skeleton>
					<Skeleton width={"30%"}></Skeleton>
				</div>
			</div>
		</div>
	);
};

export default ServiceCardLoader;
