import StorageCard from "components/Storage/StorageComponents/StorageCard";
import {
	formatFiltersValues,
	getStorageWarningText,
	showStorageWarning,
} from "components/Utils/General";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import useVaultStorage from "hooks/useVaultStorage";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setModalProduct } from "store/slices/products";
import SearchInput from "../../Utils/SubComs/Inputs/SearchInput/SearchInput";

import {
	// useGetUncategoirzedProducts, //! DELETE
	// useProductsSearch, //! DELETE
	useProductsStats,
} from "./hooks";
import ProductEntry from "./ProductEntry/ProductEntry";
// import { useGetServicesProduct } from "./hooks/useGetServicesProduct"; //! DELETE
// import { AllProductsComponent } from "./ProductsComponents/AllProducts"; //! DELETE
// import { ProductItems } from "./ProductsComponents/ProductItems"; //! DELETE
// import { UncategorisedProductsComponent } from "./ProductsComponents/UncategorizedProducts"; //! DELETE
import styles from "./ProductsTab.module.scss";
import { Fragment, useEffect, useMemo, useState } from "react";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";
import RoundButton from "components/Utils/Button/RoundButton";
import ResourceSearchView from "components/Resources/ResourceSearchView/ResourceSearchView";
import useApi from "hooks/useApi";
import servicesApi from "api/services";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { servicesKeys } from "queryKeys/services-key-factory";
import { ReactComponent as BackIcon } from "images/back.svg";
import ScrollContainer from "react-indiana-drag-scroll";
import NewProductItem from "./ProductsComponents/NewProductItem";
import { Skeleton } from "primereact/skeleton";
import { searchKeys } from "queryKeys/search-key-factory";
import searchApi from "api/search";
import routes from "components/Routing/routing-keys";

const PAGE_SIZE = 20;
const PRODUCTS_COUNT = 11;

const ProductsTab = ({ userInfo, canEdit }) => {
	const dispatch = useDispatch();

	const { data, usedSpaceInPercentage } = useVaultStorage();

	const {
		usedProductsCount,
		currentPlan: { products: productsAdCount } = {},
	} = data;

	const [searchInput, setSearchInput] = useState("");

	// const isMobile = useSelector((state) => state.ui.isMobile);

	const {
		uuid: userId,
		hasProducts,
		firstName,
		lastName,
		name: companyName,
		type,
		url,
	} = userInfo;

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.PRODUCTS_TAB);
	}, []);

	const name = type === "USER" ? `${firstName} ${lastName}` : companyName;

	const navigate = useNavigate();

	const { stats } = useProductsStats(userId, hasProducts);

	const hasCategorizedProducts = stats?.total - stats?.uncategorizedCount > 0;

	const hasUncategorizedProducts = stats?.uncategorizedCount > 0;

	// ! =========== SERVICES THAT HAVE PRODUCTS ============

	//TODO >> to delete custom hook
	// const { productsServicesLevel1, isFetchingLevel1, viewRefLevel1 } =
	// 	useGetServicesProduct(userId, stats?.total > 0 ? true : false);

	const getServicesApi = useApi(
		servicesApi.getServicesWithProducts,
		true,
		true
	);

	const fetchServicesWithProducts = async ({ pageParam = 0, queryKey }) => {
		const [_, __, level, userId, parentId, ___] = queryKey;

		const response = await getServicesApi.request(
			userId,
			pageParam,
			PAGE_SIZE,
			PRODUCTS_COUNT,
			parentId,
			level
		);
		return response.data;
	};

	const {
		items: servicesWithProducts,
		viewRef,
		isFetchingNextPage,
		fetchStatus,
		isLoading,
	} = useInfiniteScroll({
		queryKey: servicesKeys.serviceLvl(1, userId, "", true),
		queryFn: fetchServicesWithProducts,
		pageSize: PAGE_SIZE,
		enabled: hasCategorizedProducts,
	});

	const handleServiceClick = (service) => {
		navigate(routes.serviceProducts(service.tag));
	};

	const handleUncategorisedClick = () => {
		navigate(routes.uncategorisedProducts(url));
	};

	const ServiceItemLoader = () => {
		return (
			<div className={styles.service_item}>
				<div className={styles.service_header}>
					<Skeleton width={200}></Skeleton>
				</div>
				<ScrollContainer className={styles.product_scroll_container}>
					<Skeleton
						className={styles.product_item_skeleton}
						size={"180px"}
					></Skeleton>
					<Skeleton
						className={styles.product_item_skeleton}
						size={"180px"}
					></Skeleton>
					<Skeleton
						className={styles.product_item_skeleton}
						size={"180px"}
					></Skeleton>
					<Skeleton
						className={styles.product_item_skeleton}
						size={"180px"}
					></Skeleton>
					<Skeleton
						className={styles.product_item_skeleton}
						size={"180px"}
					></Skeleton>
				</ScrollContainer>
			</div>
		);
	};

	const servicesWithProductsList = useMemo(
		() =>
			servicesWithProducts.map((service, index) => {
				const isLastItem = index === servicesWithProducts.length - 1;
				return (
					<Fragment key={service.uuid}>
						<div
							className={styles.service_item}
							ref={isLastItem ? viewRef : null}
						>
							<button
								type={"button"}
								onClick={() => handleServiceClick(service)}
								className={styles.service_header}
							>
								<h3>{service.name}</h3>

								<BackIcon
									style={{ transform: "rotate(180deg)" }}
								/>
							</button>
							<ScrollContainer
								className={styles.product_scroll_container}
							>
								{service.products.map((product) => (
									<NewProductItem
										key={product.uuid}
										product={product}
										showOwnerThumbnail={false}
										className={styles.product_item}
									/>
								))}

								{service.products.length > 10 && (
									<button
										type={"button"}
										onClick={() =>
											handleServiceClick(service)
										}
										className={styles.more_thumbnail}
									>
										Show {service.productsCount - 10} more
									</button>
								)}
							</ScrollContainer>
						</div>

						{isLastItem && isFetchingNextPage && (
							<ServiceItemLoader />
						)}
					</Fragment>
				);
			}),
		[servicesWithProducts, isFetchingNextPage, viewRef]
	);

	// ! ============= UNCATEGORIZED PRODUCTS ===============

	const productsFiltersObject = useSelector(
		(state) => state.filters.productsFilters
	);

	const storedProductsFilters = {
		productFilters: {
			...productsFiltersObject.productFilters,
			onlyUncategorizedProducts: true,
		},
		ownerIds: [`${userId}`],
	};

	const searchProductsApi = useApi(searchApi.searchProducts, true, true);

	const fetchProducts = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, filters] = queryKey;

		const response = await searchProductsApi.request(
			pageParam,
			PRODUCTS_COUNT,
			formatFiltersValues(filters, ["ownerIds"])
		);
		return response.data;
	};

	const {
		items: searchProducts,
		isLoading: isLoadingProducts,
		fetchStatus: productsFetchStatus,
	} = useInfiniteScroll({
		queryKey: searchKeys.products(storedProductsFilters),
		queryFn: fetchProducts,
		pageSize: PRODUCTS_COUNT,
		enabled: hasUncategorizedProducts,
		queryOptions: {
			getNextPageParam: (lastPage, pages) => {
				const nextPage =
					lastPage.list?.length === PAGE_SIZE
						? pages.length
						: undefined;
				return nextPage;
			},
		},
	});

	const uncategorizedProductsCount = searchProducts?.[0]?.count;
	const searchProductsData = searchProducts?.flatMap((page) => page.list);

	const uncategorizedProductsList = useMemo(
		() =>
			searchProductsData.map((product, index) => {
				return (
					<NewProductItem
						key={product.uuid}
						product={product}
						showOwnerThumbnail={false}
						className={styles.product_item}
					/>
				);
			}),
		[searchProductsData]
	);

	// ! ====================================================

	return (
		<>
			<div className={styles.product_tab_container}>
				<div className={styles.product_title_wrapper}>
					<h3>Products Categories</h3>
					{canEdit && (
						<RoundButton
							icon={"add"}
							onClick={() => dispatch(setModalProduct(true))}
							disabled={usedProductsCount >= productsAdCount}
						/>
					)}
				</div>

				{canEdit && showStorageWarning(usedSpaceInPercentage, data) && (
					<div className={styles.warning_wrapper}>
						<StorageCard
							text={getStorageWarningText(
								"productsAndServices",
								usedSpaceInPercentage,
								data
							)}
						/>
					</div>
				)}

				{hasProducts && (
					<SearchInput
						onChange={({ target }) => {
							const query = target.value;
							setSearchInput(query);
						}}
						value={searchInput}
						showIcons={true}
						onClearClicked={() => setSearchInput("")}
						border={false}
						placeholder={`Search resources by ${name}`}
					/>
				)}

				{!searchInput && (
					<>
						{isLoading && fetchStatus !== "idle" && (
							<>
								<ServiceItemLoader />
								<ServiceItemLoader />
								<ServiceItemLoader />
							</>
						)}

						{hasCategorizedProducts && servicesWithProductsList}

						{isLoadingProducts &&
							productsFetchStatus !== "idle" && (
								<ServiceItemLoader />
							)}

						{hasUncategorizedProducts && (
							<div className={styles.service_item}>
								<button
									type={"button"}
									onClick={handleUncategorisedClick}
									className={styles.service_header}
								>
									<h3>Un-categorised</h3>

									<BackIcon
										style={{ transform: "rotate(180deg)" }}
									/>
								</button>

								<ScrollContainer
									className={styles.product_scroll_container}
								>
									{uncategorizedProductsList}

									{uncategorizedProductsCount > 10 && (
										<button
											type={"button"}
											onClick={handleUncategorisedClick}
											className={styles.more_thumbnail}
										>
											Show{" "}
											{uncategorizedProductsCount - 10}{" "}
											more
										</button>
									)}
								</ScrollContainer>
							</div>
						)}
					</>
				)}

				{/* {hasProducts &&
    				!isSearching &&
    				productsServicesLevel1?.pages[0].length > 0 && (
    					<div className={styles.products_container}>
    						<ProductItems
    							ref={viewRefLevel1}
    							productsServicesLevel1={productsServicesLevel1}
    						/>
    						<LoadingSpinner visible={isFetchingLevel1} />
    					</div>
    				)}
    			{hasProducts && stats?.uncategorizedCount > 0 && !searchInput && (
    				<div className={styles.products_container}>
    					<UncategorisedProductsComponent
    						userUrl={userInfo?.url}
    						UncategorizedProducts={uncategorizedProducts}
    						visible={searchInput.length === 0}
    					/>
    					<LoadingSpinner visible={isFetchingUncategorized} />
    				</div>
    			)} */}

				<NoResults
					visible={!hasProducts}
					title={"Nothing Here Yet!"}
					text="There are no products here"
					button={
						canEdit && (
							<GaawkButton
								severity={"secondary"}
								text={"Add Product"}
								onClick={() => dispatch(setModalProduct(true))}
							/>
						)
					}
				/>

				<ResourceSearchView
					searchInput={searchInput}
					requestedProfileId={userId}
					displayOptions={{ services: false, products: true }}
					productsTitle={""}
					isProfilePage
				/>

				{/* {hasProducts && !isSearching && (
    				<div className={styles.button_wrapper}>
    					<SeeMore
    						text={`View All ${stats?.total} ${pluralize(
    							stats?.total,
    							"Product"
    						)}`}
    						onClick={() => {
    							navigate(`/profile/${userInfo?.url}/products`, {
    								state: {
    									filters: {
    										onlyUncategorizedProducts: false,
    									},
    								},
    							});
    						}}
    					/>
    				</div>
    			)} */}
			</div>
			<ProductEntry />
		</>
	);
};

export default ProductsTab;
