import styles from "./ResourcePanel.module.scss";
import NewProductDetails from "components/Profile/ProductsTab/ProductDetails/NewProductDetails";

const ResourcePanel = ({ resource, isOpen, onClose }) => {
	return (
		<div className={`${styles.panel} ${isOpen ? styles.open : ""}`}>
			{resource ? (
				<NewProductDetails
					isPanel
					product={resource}
					onClosePanel={onClose}
				/>
			) : null}
		</div>
	);
};

export default ResourcePanel;
