import styles from "./MessageInfoUser.module.css";
import ProfilePic from "../Home/ProfilePic";
import { format } from "date-fns";

const MessageInfoUser = ({ thumbnail, name, type, time = 0 }) => {
	return (
		<div className={styles.user_item}>
			<ProfilePic
				thumbnail={thumbnail}
				w={24}
				h={24}
				type={type}
				createMode={true}
				enableName={false}
				name={name}
			/>
			<div className={styles.user_details}>
				<div className={styles.user_name}>{name}</div>
				{time !== 0 && (
					<div className={styles.messageInfo_status_time}>
						{format(new Date(time), "do MMMM yyyy, h:mm aaa")}
					</div>
				)}
			</div>
		</div>
	);
};

export default MessageInfoUser;
