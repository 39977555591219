import { jobsKeys } from "queryKeys/jobs-key-factory";
import { projectKeys } from "queryKeys/projects-key-factory";
import useMutate from "./useMutate";
import { useQueryClient } from "@tanstack/react-query";
import jobApi from "api/job";

const useUpdateJobStatus = (job) => {
	const queryClient = useQueryClient();
	const { uuid: jobId, jobType, minifiedCompany: { uuid: companyId } = {}, project } = job || {};

	const {
		action: { mutate: toggleStatus },
	} = useMutate(jobApi.updateJobStatus, () => {
		//* optimistic update for job status
		queryClient.setQueryData(jobsKeys.detail(jobId), (oldData) => {
			return {
				...oldData,
				closed: !oldData.closed,
			};
		});

		if (project?.uuid) {
			//* to invalidate the count of jobs (active / inactive) in the project
			queryClient.invalidateQueries(projectKeys.detail(project.uuid));
		} else {
			//* to invalidate the count of jobs (active / inactive)
			queryClient.invalidateQueries(jobsKeys.landing());
		}

		queryClient.invalidateQueries(jobsKeys.jobTypeByCompany(companyId, jobType, project?.uuid));
	});

	return toggleStatus;
};

export default useUpdateJobStatus;
