import client from "./client";

const endpoint = "/profile";

const fileContentType = {
	"Content-Type": "multipart/form-data",
};

//! USER ENDPOINTS ===========================

const profilesWithServicesOrProducts = (
	page = 0,
	size = 20,
	lat = 0,
	lng = 0
) =>
	client.get(
		endpoint +
			`/withServicesOrProducts?page=${page}&size=${size}&latitude=${lat}&longitude=${lng}`
	);

const getVaultInfo = () => client.get(endpoint + `/storage`);

const deleteGalleryImageStorage = () =>
	client.delete(endpoint + `/storage/gallery/images`);

const deleteGalleryPdfStorage = () =>
	client.delete(endpoint + `/storage/gallery/pdfs`);

const deleteGalleryVideoStorage = () =>
	client.delete(endpoint + `/storage/gallery/video`);

const deleteVaultFileStorage = () =>
	client.delete(endpoint + `/storage/vault/files`);

const deleteVaultImageStorage = () =>
	client.delete(endpoint + `/storage/vault/images`);

const deleteVaultVideoStorage = () =>
	client.delete(endpoint + `/storage/vault/video`);

const search = (page = 0, size = 20, q) =>
	client.get(endpoint + `/search?page=${page}&size=${size}&q=${q}`);

const searchTags = (page = 0, size = 20, q = "") =>
	client.get(endpoint + `/search/tags?page=${page}&size=${size}&q=${q}`);

const searchGroupProfile = (page = 0, size = 20, groupId, q) =>
	client.get(
		endpoint +
			`/search/groups?page=${page}&size=${size}&groupId=${groupId}&q=${q}`
	);

const searchUser = (page = 0, size = 20, q) =>
	client.get(endpoint + `/user/search?page=${page}&size=${size}&q=${q}`);

const getUserProfile = (userId) =>
	client.get(endpoint + `/user/get?requestedUserId=${userId}`);

const getUserByUrl = (url) => client.get(endpoint + `/url?url=${url}`);

const urlCheck = (url) => client.get(endpoint + `/checkURL?url=${url}`);

const updateUserInfo = (data) => client.put(endpoint + "/user", data);
const getProfileLocations = () => client.get(endpoint + "/locations");
const updateProfileLocations = (locationIds) => {
	const url = endpoint + "/locations";
	const params = locationIds.map((id) => `locationIds=${id}`).join("&");
	const fullUrl = `${url}?${params}`;
	return client.put(fullUrl);
};

const block = (profileId) =>
	client.patch(endpoint + `/block?blockedProfileId=${profileId}`);

const unblock = (profileId) =>
	client.patch(endpoint + `/unblock?blockedProfileId=${profileId}`);

const passwordLink = (email, link) =>
	client.post(
		endpoint + `/user/forgotPassword/sendLink?email=${email}&link=${link}`
	);

const checkEmail = (email) =>
	client.get(endpoint + `/checkEmail?email=${email}`);

const resetPassword = (email, newPassword, key) =>
	client.post(
		endpoint +
			`/user/resetPassword?email=${email}&newPassword=${newPassword}&key=${key}`
	);

const updatePassword = (oldPassword, newPassword) =>
	client.put(
		endpoint +
			`/user/updatePassword?oldPassword=${oldPassword}&newPassword=${newPassword}`
	);

const getConnectionsCount = (requestedProfileId) =>
	client.get(
		endpoint + `/connections/count?requestedProfileId=${requestedProfileId}`
	);

const getCommonConnections = (page, size, requestedProfileId) =>
	client.get(
		endpoint +
			`/connections/common?requestedProfileId=${requestedProfileId}&page=${page}&size=${size}`
	);

const getRecentConnections = (page, size) =>
	client.get(endpoint + `/connections/recent?page=${page}&size=${size}`);

const getFavorites = (page, size, query = "") =>
	client.get(
		endpoint +
			`/favorites?page=${page}&size=${size}${query && `&q=${query}`}`
	);

const addFavorite = (targetProfileId) =>
	client.post(endpoint + `/favorites?targetProfileId=${targetProfileId}`);

const removeFavorite = (targetProfileId) =>
	client.delete(endpoint + `/favorites?targetProfileId=${targetProfileId}`);

const addCertificate = (body) =>
	client.post(endpoint + "/user/certificate", body);
const updateCertificate = (body) =>
	client.put(endpoint + "/user/certificate", body);
const deleteCertificate = (certificateId) =>
	client.delete(
		endpoint + `/user/certificate?certificationId=${certificateId}`
	);

const addCertificateFiles = (formData) =>
	client.post(endpoint + "/user/certificate/file", formData, {
		headers: fileContentType,
	});

const deleteCertificateFile = (certificationId, fileId) =>
	client.delete(
		endpoint +
			`/user/certificate/file?certificationId=${certificationId}&fileId=${fileId}`
	);

const addEducation = (body) => client.post(endpoint + "/user/education", body);

const updateEducation = (body) =>
	client.put(endpoint + "/user/education", body);

const deleteEducation = (educationId) =>
	client.delete(endpoint + `/user/education?educationId=${educationId}`);

const addEducationFiles = (formData) =>
	client.post(endpoint + "/user/education/file", formData);

const deleteEducationFile = (educationId, fileId) =>
	client.delete(
		endpoint +
			`/user/education/file?educationId=${educationId}&fileId=${fileId}`
	);

const getNearbyCompanies = (page, size = 5, lat = 0, lng = 0) =>
	client.get(
		endpoint +
			`/user/landing/company/nearby?page=${page}&size=${size}&lat=${lat}&lng=${lng}`
	);

const getHiringCompanies = (page, size = 5, lat = 0, lng = 0) =>
	client.get(
		endpoint +
			`/user/landing/users/company/hiring?page=${page}&size=${size}&lat=${lat}&lng=${lng}`
	);

const getNetworkCompanies = (page, size = 5) =>
	client.get(
		endpoint + `/user/landing/network/company?page=${page}&size=${size}`
	);

const getPeopleInterests = (page, size = 5, lat = 0, lng = 0) =>
	client.get(
		endpoint +
			`/user/landing/users/skills?page=${page}&size=${size}&lat=${lat}&lng=${lng}`
	);

const getPeopleNearby = (page, size = 5, lat = 0, lng = 0) =>
	client.get(
		endpoint +
			`/user/landing/users/nearby?page=${page}&size=${size}&lat=${lat}&lng=${lng}`
	);

const getPeopleFromMyCompany = (page, size = 5) =>
	client.get(
		endpoint + `/user/landing/users/myCompany?page=${page}&size=${size}`
	);

const getNetworkPeople = (page, size = 5) =>
	client.get(
		endpoint + `/user/landing/network/users?page=${page}&size=${size}`
	);

const sendOtp = (email = "", mobile = "", code = "") =>
	client.get(
		endpoint + `/user/sendOTP?email=${email}&mobile=${mobile}&code=${code}`
	);

const updatePhone = (countryCode, mobile) =>
	client.put(
		endpoint +
			`/user/login/mobile?countryCode=${countryCode}&mobile=${mobile}`
	);

const deleteAccount = (password) =>
	client.delete(endpoint + `/user?password=${password}`);

const verifyUserEmail = () => client.get(endpoint + `/user/login/email/verify`);

const updateLoginEmail = (email) =>
	client.put(endpoint + `/user/login/email?email=${email}`);

const login = (login, password) =>
	client.post(endpoint + `/user/login?login=${login}&password=${password}`);

const logout = (fcmToken) =>
	client.patch(endpoint + `/user/logout?fcmToken=${fcmToken}`);

const registerToken = (fcmToken, agent) =>
	client.post(
		endpoint + `/user/registerToken?fcmToken=${fcmToken}&agent=${agent}`
	);

const unregisterToken = (fcmToken) =>
	client.delete(endpoint + `/user/registerToken?fcmToken=${fcmToken}`);

const getCurrentPositions = () =>
	client.get(endpoint + `/user/experience/current`);

const addExperience = (data) =>
	client.post(endpoint + `/user/experience`, data);

const updateExperience = (data) =>
	client.put(endpoint + `/user/experience`, data);

const deleteExperience = (experienceId, roleId) =>
	client.delete(
		endpoint +
			`/user/experience?experienceId=${experienceId}&roleId=${roleId}`
	);

const addAddress = (body) => client.post(endpoint + `/user/address`, body);

const updateAddress = (body) => client.put(endpoint + `/user/address`, body);

const deleteAddress = (addressId) =>
	client.delete(endpoint + `/user/address?addressId=${addressId}`);

const addSite = (body) => client.post(endpoint + `/user/site`, body);

const updateSite = (body) => client.put(endpoint + `/user/site`, body);

const deleteSite = (siteId) =>
	client.delete(endpoint + `/user/site?siteId=${siteId}`);

const addContact = (body) => client.post(endpoint + `/user/contact`, body);

const updateContact = (body) => client.put(endpoint + `/user/contact`, body);

const deleteContact = (contactId) =>
	client.delete(endpoint + `/user/contact?contactId=${contactId}`);

const addOther = (body) => client.post(endpoint + `/user/other`, body);

const updateOther = (body) => client.put(endpoint + `/user/other`, body);

const deleteOther = (otherUuid) =>
	client.delete(endpoint + `/user/other?otherUuid=${otherUuid}`);

const addSocial = (body) => client.post(endpoint + `/user/social`, body);

const updateSocial = (body) => client.put(endpoint + `/user/social`, body);

const deleteSocial = (userSocialId) =>
	client.delete(endpoint + `/user/social?userSocialId=${userSocialId}`);

const addLanguage = (body) => client.post(endpoint + `/user/languages`, body);

const updateLanguage = (body) => client.put(endpoint + `/user/languages`, body);

const deleteLanguage = (userLanguageId) =>
	client.delete(
		endpoint + `/user/languages?userLanguageId=${userLanguageId}`
	);

const addSoftware = (body) => client.post(endpoint + `/user/software`, body);

const updateSoftware = (body) => client.put(endpoint + `/user/software`, body);

const deleteSoftware = (softwareId) =>
	client.delete(endpoint + `/user/software?softwareId=${softwareId}`);

const makeProfilePicPermanent = () =>
	client.patch(endpoint + "/user/profileImage/permTemp");

const removeTempImg = () =>
	client.delete(endpoint + "/user/profileImage/removeTemp");

//! COMPANY ENDPOINTS ===========================

const getCompanyProfile = (companyId) =>
	client.get(
		endpoint + `/company/get?companyId=${companyId}`,
		{}
		// { headers: { useUserId: true } }
	);
const getUserCompany = () => client.get(endpoint + "/company/getUserCompany");

const createCompany = (formData) =>
	client.post(endpoint + "/company", formData);

const updateCompany = (formData) => client.put(endpoint + "/company", formData);

const deleteCompany = (companyId) =>
	client.delete(endpoint + `/company?companyId=${companyId}`);

const transferCompany = (companyId, toProfileId) =>
	client.put(
		endpoint +
			`/company/users/transferOwner?companyId=${companyId}&toProfileId=${toProfileId}`
	);

const getCompanyUsers = (companyId, userType) =>
	client.get(
		endpoint + `/company/users?companyId=${companyId}&type=${userType}`
	);

const addCompanyUser = (companyId, profileId, userType) =>
	client.post(
		endpoint +
			`/company/users?companyId=${companyId}&profileId=${profileId}&type=${userType}`
	);

const deleteCompanyUser = (companyId, profileId, userType) =>
	client.delete(
		endpoint +
			`/company/users?companyId=${companyId}&profileId=${profileId}&type=${userType}`
	);

const addKeyPeople = (companyId, profileId, role) =>
	client.post(
		endpoint +
			`/company/users/key?companyId=${companyId}&profileId=${profileId}&role=${role}`
	);

const deleteKeyPeople = (companyId, profileId) =>
	client.delete(
		endpoint +
			`/company/users/key?companyId=${companyId}&profileId=${profileId}`
	);

const searchCompany = (q = "", page = 0, size = 10, agenciesOnly = false) =>
	client.get(
		endpoint +
			`/company/search?q=${q}&page=${page}&size=${size}&agenciesOnly=${agenciesOnly}`
	);

const becomeAgency = () => client.put(endpoint + "/company/agency/add");

const deleteAgency = () => client.delete(endpoint + "/company/agency/delete");

const sendAgencyRequest = (agencyId, specialProfileId) =>
	client.post(
		endpoint +
			`/company/agency/request/send?agencyId=${agencyId}&specialProfileId=${specialProfileId}`
	);

const unlinkAgency = (agencyId, specialProfileId) =>
	client.delete(
		endpoint +
			`/company/agency/request/delete?agencyId=${agencyId}&specialProfileId=${specialProfileId}`
	);

const getPendingTalent = (requestedProfileId) =>
	client.get(
		endpoint +
			`/company/agency/pending/count?requestedProfileId=${requestedProfileId}`
	);

const acceptTalentRequest = (agencyId, specialProfileId, alias) =>
	client.put(
		endpoint +
			`/company/agency/request/confirm?agencyId=${agencyId}&specialProfileId=${specialProfileId}&alias=${alias}`
	);

const declineTalentRequest = (agencyId, specialProfileId) =>
	client.delete(
		endpoint +
			`/company/agency/request/cancel?agencyId=${agencyId}&specialProfileId=${specialProfileId}`
	);

const getAgencyFilters = (body, lat = 0, lng = 0) =>
	client.post(
		endpoint +
			`/company/agency/search/filter?latitude=${lat}&longitude=${lng}`,
		body
	);

const searchAgency = (page, size = 10, body, lat = 0, lng = 0) =>
	client.post(
		endpoint +
			`/company/agency/search?page=${page}&size=${size}&latitude=${lat}&longitude=${lng}`,
		body
	);

const getPendingEmployees = (page, size = 10) =>
	client.get(
		endpoint + `/company/experience/role/pending?page=${page}&size=${size}`
	);

const getVerifiedEmployees = (page, size = 10, requestedCompanyId, q = "") =>
	client.get(
		endpoint +
			`/company/experience/role/verified?page=${page}&size=${size}&requestedCompanyId=${requestedCompanyId}&q=${q}`
	);

const verifyRole = (targetProfileId, roleId) =>
	client.put(
		endpoint +
			`/company/experience/role/verify?targetProfileId=${targetProfileId}&roleId=${roleId}`
	);

const rejectRole = (targetProfileId, roleId) =>
	client.put(
		endpoint +
			`/company/experience/role/reject?targetProfileId=${targetProfileId}&roleId=${roleId}`
	);

const deleteEmployee = (employeeId) =>
	client.delete(
		endpoint + `/company/experience/employee?employeeId=${employeeId}`
	);

const registerUser = (data) => client.post(endpoint + "/user/register", data);

const updateRoles = (roleIds) => client.put(endpoint + "/user/role", roleIds);

const updateSkills = (skillsIds) =>
	client.put(endpoint + "/user/skills", skillsIds);

const updateBio = (data) => client.put(endpoint + "/user/bio", data);

const deleteBio = () => client.delete(endpoint + "/user/bio");

const updateCompanyBio = (data, companyId) =>
	client.put(endpoint + `/company/bio?companyId=${companyId}`, data);

const deleteCompanyBio = (companyId) =>
	client.delete(endpoint + `/company/bio?companyId=${companyId}`);

const updatePicture = (formData, isCompany) => {
	return client.put(
		endpoint + `/${isCompany ? "company" : "user"}/profileImage`,
		formData,
		{
			headers: fileContentType,
		}
	);
};

const makeCompanyProfilePicPermanent = (companyId) =>
	client.patch(
		endpoint + `/company/profileImage/permTemp?companyId=${companyId}`
	);

const removeCompanyTempImg = (companyId) =>
	client.delete(
		endpoint + `/company/profileImage/removeTemp?companyId=${companyId}`
	);

const addCompanyCertificate = (body, companyId) =>
	client.post(endpoint + `/company/certificate?companyId=${companyId}`, body);

const updateCompanyCertificate = (body, companyId) =>
	client.put(endpoint + `/company/certificate?companyId=${companyId}`, body);

const deleteCompanyCertificate = (certificateId, companyId) =>
	client.delete(
		endpoint +
			`/company/certificate?certificationId=${certificateId}&companyId=${companyId}`
	);

const addCompanyCertificateFiles = (formData) =>
	client.post(endpoint + "/company/certificate/file", formData, {
		headers: fileContentType,
	});

const deleteCompanyCertificateFile = (certificationId, fileId, companyId) =>
	client.delete(
		endpoint +
			`/company/certificate/file?certificationId=${certificationId}&fileId=${fileId}&companyId=${companyId}`
	);

// const updatePicture = (formData, imgData, companyId = undefined) => {
// 	const array = [];
// 	for (const [key, value] of Object.entries(imgData)) {
// 		array.push(`${key}=${value}`);
// 	}

// 	return client.put(
// 		endpoint +
// 			`/${companyId ? "company" : "user"}/profileImage?${array.join(
// 				"&"
// 			)}${companyId ? `&companyId=${companyId}` : ""}`,
// 		formData,
// 		{ headers: fileContentType }
// 	);
// };

const deleteProfilePic = (companyId = undefined) =>
	client.delete(
		endpoint +
			`/${companyId ? "company" : "user"}/profileImage${
				companyId ? `?companyId=${companyId}` : ""
			}`
	);

const updateCoverPicture = (formData, isCompany) => {
	return client.put(
		endpoint + `/${isCompany ? "company" : "user"}/coverImage`,
		formData,
		{
			headers: fileContentType,
		}
	);
};
// const updateCoverPicture = (formData, imgData, companyId = undefined) => {
// 	const array = [];
// 	for (const [key, value] of Object.entries(imgData)) {
// 		array.push(`${key}=${value}`);
// 	}

// 	return client.put(
// 		endpoint +
// 			`/${companyId ? "company" : "user"}/coverImage?${array.join("&")}${
// 				companyId ? `&companyId=${companyId}` : ""
// 			}`,
// 		formData,
// 		{ headers: fileContentType }
// 	);
// };

const deleteCoverPic = (companyId = undefined) =>
	client.delete(
		endpoint +
			`/${companyId ? "company" : "user"}/coverImage${
				companyId ? `?companyId=${companyId}` : ""
			}`
	);

const addCompanyAddress = (companyId, body) =>
	client.post(endpoint + `/company/address?companyId=${companyId}`, body);

const updateCompanyAddress = (companyId, body) =>
	client.put(endpoint + `/company/address?companyId=${companyId}`, body);

const deleteCompanyAddress = (companyId, addressId) =>
	client.delete(
		endpoint +
			`/company/address?addressId=${addressId}&companyId=${companyId}`
	);

const addCompanySite = (companyId, body) =>
	client.post(endpoint + `/company/site?companyId=${companyId}`, body);

const updateCompanySite = (companyId, body) =>
	client.put(endpoint + `/company/site?companyId=${companyId}`, body);

const deleteCompanySite = (companyId, siteId) =>
	client.delete(
		endpoint + `/company/site?companyId=${companyId}&siteId=${siteId}`
	);

const addCompanyContact = (companyId, body) =>
	client.post(endpoint + `/company/contact?companyId=${companyId}`, body);

const updateCompanyContact = (companyId, body) =>
	client.put(endpoint + `/company/contact?companyId=${companyId}`, body);

const deleteCompanyContact = (companyId, contactId) =>
	client.delete(
		endpoint +
			`/company/contact?companyId=${companyId}&contactId=${contactId}`
	);

const addCompanyOther = (companyId, body) =>
	client.post(endpoint + `/company/other?companyId=${companyId}`, body);

const updateCompanyOther = (companyId, body) =>
	client.put(endpoint + `/company/other?companyId=${companyId}`, body);

const deleteCompanyOther = (companyId, otherUuid) =>
	client.delete(
		endpoint +
			`/company/other?companyId=${companyId}&otherUuid=${otherUuid}`
	);

const addCompanySocial = (companyId, body) =>
	client.post(endpoint + `/company/social?companyId=${companyId}`, body);

const updateCompanySocial = (companyId, body) =>
	client.put(endpoint + `/company/social?companyId=${companyId}`, body);

const deleteCompanySocial = (companyId, userSocialId) =>
	client.delete(
		endpoint +
			`/company/social?companyId=${companyId}&userSocialId=${userSocialId}`
	);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getVaultInfo,
	search,
	searchTags,
	searchGroupProfile,
	searchUser,
	searchCompany,
	getUserProfile,
	getUserByUrl,
	updateUserInfo,
	urlCheck,
	block,
	unblock,
	passwordLink,
	resetPassword,
	updatePassword,
	getConnectionsCount,
	getCommonConnections,
	getRecentConnections,
	getFavorites,
	addFavorite,
	removeFavorite,
	checkEmail,
	getCompanyProfile,
	getUserCompany,
	createCompany,
	deleteCompany,
	transferCompany,
	addEducation,
	updateEducation,
	deleteEducation,
	addEducationFiles,
	deleteEducationFile,
	addCertificateFiles,
	addCompanyCertificateFiles,
	addKeyPeople,
	deleteKeyPeople,
	updateCompany,
	becomeAgency,
	deleteAgency,
	sendAgencyRequest,
	unlinkAgency,
	getPendingTalent,
	acceptTalentRequest,
	declineTalentRequest,
	getAgencyFilters,
	getProfileLocations,
	updateProfileLocations,
	searchAgency,
	getVerifiedEmployees,
	getPendingEmployees,
	verifyRole,
	rejectRole,
	deleteEmployee,
	getNearbyCompanies,
	getHiringCompanies,
	getNetworkCompanies,
	getPeopleInterests,
	getPeopleNearby,
	getPeopleFromMyCompany,
	getNetworkPeople,
	sendOtp,
	updatePhone,
	deleteAccount,
	registerUser,
	login,
	logout,
	registerToken,
	unregisterToken,
	deleteCertificateFile,
	deleteCompanyCertificateFile,
	updateRoles,
	updateSkills,
	updateBio,
	deleteBio,
	updateCompanyBio,
	deleteCompanyBio,
	updatePicture,
	updateCoverPicture,
	deleteProfilePic,
	deleteCoverPic,
	deleteGalleryImageStorage,
	deleteGalleryPdfStorage,
	deleteGalleryVideoStorage,
	deleteVaultFileStorage,
	deleteVaultImageStorage,
	deleteVaultVideoStorage,
	getCompanyUsers,
	deleteCompanyUser,
	addCompanyUser,
	getCurrentPositions,
	addCertificate,
	updateCertificate,
	deleteCertificate,
	addCompanyCertificate,
	updateCompanyCertificate,
	deleteCompanyCertificate,
	addAddress,
	updateAddress,
	deleteAddress,
	addCompanyAddress,
	updateCompanyAddress,
	deleteCompanyAddress,
	addSite,
	updateSite,
	deleteSite,
	addCompanySite,
	updateCompanySite,
	deleteCompanySite,
	addContact,
	updateContact,
	deleteContact,
	addCompanyContact,
	updateCompanyContact,
	deleteCompanyContact,
	addOther,
	updateOther,
	deleteOther,
	addCompanyOther,
	updateCompanyOther,
	deleteCompanyOther,
	addSocial,
	updateSocial,
	deleteSocial,
	addCompanySocial,
	updateCompanySocial,
	deleteCompanySocial,
	addExperience,
	updateExperience,
	deleteExperience,
	addLanguage,
	updateLanguage,
	deleteLanguage,
	addSoftware,
	updateSoftware,
	deleteSoftware,
	verifyUserEmail,
	makeProfilePicPermanent,
	makeCompanyProfilePicPermanent,
	removeTempImg,
	removeCompanyTempImg,
	profilesWithServicesOrProducts,
	updateLoginEmail,
};
