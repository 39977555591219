import routes from "components/Routing/routing-keys";
import WarningModal from "components/Utils/GaawkModal/WarningModal";
import Popover from "components/Utils/Popover/Popover";
import ReportModal from "components/Utils/ReportModal/ReportModal";
import useCurrentUser from "hooks/useCurrentUser";
import useDeleteJob from "hooks/useDeleteJob";
import useUpdateJobStatus from "hooks/useUpdateJobStatus";
import archiveIcon from "images/archive.svg";
import deleteIcon from "images/delete.svg";
import { ReactComponent as DotsIcon } from "images/dots.svg";
import editIcon from "images/edit.svg";
import reportIcon from "images/report-black-icon.svg";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./JobActions.module.scss";

const JobActions = ({ job, onDeleteJob }) => {
	const { uuid, closed, jobType, minifiedCompany: { uuid: companyId } = {} } = job;

	const navigate = useNavigate();
	const popoverRef = useRef(null);
	const { uuid: profileId } = useCurrentUser();

	const isJobOwner = profileId === companyId;

	const reportRef = useRef();

	const handleReport = () => reportRef.current.showReportModal(uuid);

	//! Delete job ==

	const { handleRequestMutate, handleConfirmMutate, setWarningModal, warningModal } =
		useDeleteJob(job, onDeleteJob);

	//! toggle status ==

	const toggleStatus = useUpdateJobStatus(job);

	const jobLabel = jobType === "Casting_Call" ? "Casting Call" : "Job";

	return (
		<>
			<Popover
				ref={popoverRef}
				render={
					<div className="popover_container">
						{isJobOwner ? (
							<>
								<button
									className="popover_item"
									onClick={(e) => {
										e.stopPropagation();
										navigate(routes.jobEdit(uuid));
										popoverRef.current.closePopover();
									}}
								>
									<span>
										<img src={editIcon} alt="edit" />
									</span>
									<span>Edit {jobLabel}</span>
								</button>

								<button
									className="popover_item"
									onClick={(e) => {
										e.stopPropagation();

										toggleStatus({
											uuid,
											status: closed,
										});
										popoverRef.current.closePopover();
									}}
								>
									<span>
										<img
											src={archiveIcon}
											alt={`mark as ${closed ? "open" : "closed"}`}
										/>
									</span>
									<span>
										Mark {jobLabel} as {closed ? "open" : "closed"}
									</span>
								</button>

								<button
									className="popover_item"
									onClick={(e) => {
										e.stopPropagation();
										// setDeleteModal(true);
										handleRequestMutate();
										popoverRef.current.closePopover();
									}}
								>
									<span>
										<img src={deleteIcon} alt="delete" />
									</span>
									<span>Delete {jobLabel}</span>
								</button>
							</>
						) : (
							<button
								className="popover_item"
								onClick={(e) => {
									e.stopPropagation();
									handleReport();
									popoverRef.current.closePopover();
								}}
							>
								<span>
									<img src={reportIcon} alt="report" />
								</span>
								<span>Report</span>
							</button>
						)}
					</div>
				}
			>
				<button onClick={(e) => e.stopPropagation()} className={styles.option_btn}>
					<DotsIcon />
				</button>
			</Popover>

			<WarningModal
				show={warningModal}
				headerText={`Are you sure you want to delete this ${jobLabel}?`}
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={(e) => {
					e.stopPropagation();
					setWarningModal(false);
				}}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={(e) => {
					e.stopPropagation();
					handleConfirmMutate(uuid);
				}}
			/>

			<ReportModal ref={reportRef} category="job" />
		</>
	);
};

export default JobActions;
