import { useRef } from "react";
import Popover from "components/Utils/Popover/Popover";
import reportIcon from "images/report.svg";
import deleteIcon from "images/delete.svg";
import editIcon from "images/edit.svg";
import useCurrentUser from "hooks/useCurrentUser";
import { ReactComponent as DotsIcon } from "images/dots.svg";

const ProductPopover = ({ product, onEdit, onDelete, onReport }) => {
	const popoverRef = useRef(null);
	const { uuid: userId } = useCurrentUser();

	const isProductOwner = userId === product?.owner?.uuid;

	return (
		<Popover
			ref={popoverRef}
			allow={["bottom"]}
			crossAxis={-40}
			render={
				<div className="popover_container">
					{!isProductOwner ? (
						<button
							className="popover_item"
							tabIndex={0}
							onClick={(e) => {
								onReport();
								e.stopPropagation();
								popoverRef.current.closePopover();
							}}
						>
							<span>
								<img src={reportIcon} alt={"report"} />
							</span>
							<span>Report Product</span>
						</button>
					) : (
						<>
							<button
								onClick={(e) => {
									onEdit();
									e.stopPropagation();
									popoverRef.current.closePopover();
								}}
								className="popover_item"
							>
								<span>
									<img src={editIcon} alt="edit" />
								</span>
								<span>Edit Product</span>
							</button>

							<button
								className="popover_item"
								onClick={(e) => {
									onDelete();
									e.stopPropagation();
									popoverRef.current.closePopover();
								}}
							>
								<span>
									<img src={deleteIcon} alt="delete" />
								</span>
								<span>Delete Product</span>
							</button>
						</>
					)}
				</div>
			}
		>
			<button
				onClick={(e) => {
					e.stopPropagation();
					e.preventDefault();
				}}
			>
				<DotsIcon />
			</button>
		</Popover>
	);
};

export default ProductPopover;
