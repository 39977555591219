import { useState } from "react";
import styles from "./CurrentPositionItem.module.css";
import { Checkbox } from "primereact/checkbox";
import LazyImage from "components/Utils/LazyImage/LazyImage";

const CurrentPositionItem = ({ item, onSelect }) => {
	const [isSelected, setIsSelected] = useState(false);

	const handleCheckBox = () => {
		setIsSelected((prevState) => !prevState);
		onSelect(item.uuid);
	};

	return (
		<div key={item.uuid} className={styles.item_container}>
			<div className={styles.container}>
				<LazyImage
					inlineStyle={styles.company_icon}
					image={<img src={item.company?.profileImage?.image} alt="" />}
					width={"unset"}
					height={"unset"}
				/>
				<div className={styles.details_container}>
					<span className={styles.position}>
						{item.role ? item.role.name : item.title}
					</span>
					<span className={styles.company_name}>
						{item.company ? item.company.name : item.companyName}
					</span>

					<span className={styles.position_location}>
						{`${item?.locationDTO?.name}, ${item?.locationDTO?.countryDTO?.name}`}
					</span>
				</div>
			</div>
			<div className={styles.checkbox_container}>
				<Checkbox
					checked={isSelected}
					onChange={handleCheckBox}
					name={`position-${item.uuid}`}
					inputId={`position-${item.uuid}`}
				/>
				<label htmlFor={`position-${item.uuid}`}>End this position</label>
			</div>
		</div>
	);
};

export default CurrentPositionItem;
