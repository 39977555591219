import styles from "./CollapsibleProjectSection.module.scss";
import CollapsibleContainer from "components/Utils/CollapsibleContainer/CollapsibleContainer";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import useApi from "hooks/useApi";
import projectApi from "api/project";
import useCurrentUser from "hooks/useCurrentUser";
import { useMemo } from "react";
import { projectKeys } from "queryKeys/projects-key-factory";
import ProjectCard from "components/Jobs/JobsComponents/ProjectCard/ProjectCard";
import JobItemLoader from "components/Utils/SubComs/CustomLoader/JobItemLoader";
import useInfiniteScroll from "hooks/useInfiniteScroll";

const PAGE_SIZE = 20;

const CollapsibleProjectSection = ({ open, count, onAdd, onClick, activeProjectId }) => {
	const { uuid: companyId } = useCurrentUser();

	const getProjectsApi = useApi(projectApi.getAllProjects, true, true);

	const fetchProjects = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, ___, status] = queryKey;

		const response = await getProjectsApi.request(pageParam, PAGE_SIZE, status);
		return response.data;
	};

	const { items, viewRef, isFetchingNextPage, isLoading } = useInfiniteScroll({
		queryKey: projectKeys.filteredProjectsByCompany(companyId, open),
		queryFn: fetchProjects,
		pageSize: PAGE_SIZE,
	});

	const projectsList = useMemo(
		() =>
			items?.map((project, index) => {
				const isLastItem = index === items.length - 1;
				return (
					<div
						key={project.uuid}
						ref={isLastItem ? viewRef : null}
						className={styles.project_wrapper}
					>
						<ProjectCard
							isMenu
							project={project}
							isActive={activeProjectId === project.uuid}
							onClick={() => onClick(project)}
						/>
						{isLastItem && isFetchingNextPage && (
							<div className={styles.project_loader}>
								<JobItemLoader />
							</div>
						)}
					</div>
				);
			}),
		[items, viewRef, activeProjectId]
	);

	return (
		<CollapsibleContainer
			title={`${open ? "Open" : "Closed"} Projects (${count})`}
			onAdd={onAdd}
		>
			<div className={styles.projects_wrapper}>{projectsList}</div>

			{/* //*IMPROVEMENT >> make the number of loaders dynamic based on the `count` */}
			{isLoading && (
				<>
					<JobItemLoader />
					<JobItemLoader />
				</>
			)}

			<NoResults
				text={`No ${open ? "open" : "closed"} Projects to show here.`}
				visible={count === 0}
			/>
		</CollapsibleContainer>
	);
};

export default CollapsibleProjectSection;
