import styles from "./MediaLoader.module.scss";
import { Skeleton } from "primereact/skeleton";

const MediaLoader = () => {
	return (
		<div className={styles.media_card_skeleton}>
			<Skeleton width={"100%"} height={"100%"} />
		</div>
	);
};

export default MediaLoader;
