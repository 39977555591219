import { Controller, useForm } from "react-hook-form";
import styles from "./GenderForm.module.scss";
import { joiResolver } from "@hookform/resolvers/joi";
import genderSchema from "components/Registeration/Schema/gender-schema";
import CustomSelect from "components/Utils/SubComs/CustomSelect/CustomSelect";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import { customGenderValues } from "components/Utils/General";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import { useDispatch, useSelector } from "react-redux";
import { updateStep2 } from "store/slices/registration";
import { useEffect } from "react";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";
import { RadioButton } from "primereact/radiobutton";

const GenderForm = ({ onBack, onNext }) => {
	const dispatch = useDispatch();
	const { step2 } = useSelector((state) => state.registration);

	const {
		register,
		watch,
		formState: { errors },
		handleSubmit,
		control,
	} = useForm({
		resolver: joiResolver(genderSchema),
		mode: "all",
		defaultValues: step2,
	});

	const watchAll = watch();

	const handleNext = (data) => {
		const { gender, customGender, optionalGender } = data;

		onNext({
			gender: gender.charAt(),
			genderPronoun:
				gender === "male" ? "he" : gender === "female" ? "she" : data.customGender.value,
			customGender: gender === "custom" && data.optionalGender ? data.optionalGender : "",
		});

		dispatch(
			updateStep2({
				gender,
				customGender,
				optionalGender,
			})
		);
	};

	useEffect(() => {
		trackEvent(eventsDictionary.AUTH.SIGN_UP_2);
	}, []);

	return (
		<form className={styles.form} onSubmit={handleSubmit((data) => handleNext(data))}>
			<button className={styles.arrow_btn} type="button" onClick={onBack}>
				<img src={require("images/back-arrow-black.svg").default} alt="back" />
			</button>

			<h2>What's your gender?</h2>

			<p className={styles.description}>
				You can decide who can see your gender on your profile by applying visibility
				settings.
			</p>

			<div className={styles.radio_container}>
				<Controller
					name={"gender"}
					control={control}
					render={({ field: { onChange, value } }) => (
						<>
							<div className={styles.radio_wrapper}>
								<RadioButton
									checked={value === "female"}
									onChange={onChange}
									inputId={"female"}
									value={"female"}
								/>
								<label htmlFor={"female"}>Female</label>
							</div>

							<div className={styles.radio_wrapper}>
								<RadioButton
									checked={value === "male"}
									onChange={onChange}
									inputId={"male"}
									value={"male"}
								/>
								<label htmlFor={"male"}>Male</label>
							</div>

							<div className={styles.radio_wrapper}>
								<RadioButton
									checked={value === "custom"}
									onChange={onChange}
									inputId={"custom"}
									value={"custom"}
								/>
								<label htmlFor={"custom"}>Custom</label>
							</div>
						</>
					)}
				/>
			</div>

			<InputWrapper
				className={watchAll.gender === "custom" ? undefined : styles.hide}
				label="Select your pronoun"
				required={true}
				error={errors?.customGender}
				customError={"Please select an option"}
				component={
					<CustomSelect
						control={control}
						name="customGender"
						options={customGenderValues}
					/>
				}
			/>

			<InputWrapper
				className={watchAll.gender === "custom" ? undefined : styles.hide}
				label="Enter your gender"
				component={
					<TextInput {...register("optionalGender")} placeholder="Type your gender" />
				}
			/>

			{errors?.gender?.message && (
				<p className={styles.error_message}>Please select your gender</p>
			)}

			<GaawkButton type={"submit"} text="Next" className={styles.next_btn} />
		</form>
	);
};

export default GenderForm;
