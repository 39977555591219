import { useQuery } from "@tanstack/react-query";
import { formatFiltersValues } from "components/Utils/General";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import useApi from "hooks/useApi";
import useGetQueryParam from "hooks/useGetQueryParam";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { filterKeys } from "queryKeys/filters-key-factory";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPeopleFilters, updatePeopleFilters } from "store/slices/filters";
import searchApi from "../../../api/search";
import {
	defaultFormatter,
	languageFormatter,
} from "../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import ConnectionFilter from "./ConnectionFilter";
import styles from "./PeopleFilter.module.scss";
import useFilterAndSort from "./useFilterAndSort";
import GaawkButton from "components/Utils/Button/GaawkButton";
import NewMultiCheckbox from "components/Utils/NewMultiCheckBox/NewMultiCheckBox";

const FILTERS_PAGE_SIZE = 100;

const PeopleFilter = forwardRef(({ userCoordinates, companyId }, ref) => {
	const { paramValue: inputValue } = useGetQueryParam("q");

	const dispatch = useDispatch();

	const peopleFiltersObject = useSelector((state) => state.filters.peopleFilters);

	const storedPeopleFilters = {
		...peopleFiltersObject,
		q: inputValue,
		locationIdForSort: userCoordinates.id,
	};

	useEffect(() => {
		return () => {
			//CLEARS PEOPLE SEARCH FILTERS WHEN COMPONENT UNMOUNTS
			dispatch(resetPeopleFilters());
		};
	}, []);

	// ! ========= FETCHING OF DEFAULT PEOPLE FILTERS ============

	const peopleFiltersApi = useApi(searchApi.usersFilters, true, true);

	const fetchPeopleFilters = async ({ queryKey }) => {
		const [_, __, filters] = queryKey;
		const response = await peopleFiltersApi.request(formatFiltersValues(filters));
		return response.data;
	};

	const {
		data: peopleFilters,
		// isLoading, //TODO >> loading spinner for 1st render
		isFetching,
		// isError,
	} = useQuery({
		queryKey: filterKeys.people(storedPeopleFilters),
		queryFn: fetchPeopleFilters,
	});

	// ! =====================================================

	const getUsersDynamicFilterApi = useApi(searchApi.usersDynamicFilters, true, true);

	const fetchDynamicFilters = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, type, filters] = queryKey;
		const response = await getUsersDynamicFilterApi.request(
			type,
			pageParam,
			FILTERS_PAGE_SIZE,
			formatFiltersValues(filters)
		);
		return response.data;
	};

	const {
		items: dynamicSkills,
		hasNextPage: hasNextPageSkills,
		refetch: refetchSkills,
		loadMore: loadMoreSkills,
	} = useInfiniteScroll({
		queryKey: filterKeys.dynamicPeopleFilter("skills", storedPeopleFilters),
		queryFn: fetchDynamicFilters,
		pageSize: FILTERS_PAGE_SIZE,
		enabled: false,
	});

	const {
		items: dynamicRoles,
		hasNextPage: hasNextPageRoles,
		refetch: refetchRoles,
		loadMore: loadMoreRoles,
	} = useInfiniteScroll({
		queryKey: filterKeys.dynamicPeopleFilter("roles", storedPeopleFilters),
		queryFn: fetchDynamicFilters,
		pageSize: FILTERS_PAGE_SIZE,
		enabled: false,
	});

	const {
		items: dynamicLanguages,
		hasNextPage: hasNextPageLanguages,
		refetch: refetchLanguages,
		loadMore: loadMoreLanguages,
	} = useInfiniteScroll({
		queryKey: filterKeys.dynamicPeopleFilter("languages", storedPeopleFilters),
		queryFn: fetchDynamicFilters,
		pageSize: FILTERS_PAGE_SIZE,
		enabled: false,
	});

	const {
		items: dynamicSoftware,
		hasNextPage: hasNextPageSoftware,
		refetch: refetchSoftware,
		loadMore: loadMoreSoftware,
	} = useInfiniteScroll({
		queryKey: filterKeys.dynamicPeopleFilter("software", storedPeopleFilters),
		queryFn: fetchDynamicFilters,
		pageSize: FILTERS_PAGE_SIZE,
		enabled: false,
	});

	const {
		roles: rolesList = [],
		software: softwareList = [],
		languages: languagesList = [],
		skills: skillsList = [],
	} = peopleFilters || {};

	const roleRef = useRef();
	const softwareRef = useRef();
	const languageRef = useRef();
	const skillRef = useRef();

	const {
		roles = [],
		software = [],
		languages = [],
		skills = [],
		friends = false,
		inMyCircle = false,
		inTheirCircle = false,
	} = storedPeopleFilters || {};

	const filteredLanguages = useFilterAndSort(
		languages,
		dynamicLanguages,
		languagesList,
		dynamicLanguages.length > 0,
		"code"
	);

	const filteredRoles = useFilterAndSort(roles, dynamicRoles, rolesList, dynamicRoles.length > 0);

	const filteredSkills = useFilterAndSort(
		skills,
		dynamicSkills,
		skillsList,
		dynamicSkills.length > 0
	);

	const filteredSoftware = useFilterAndSort(
		software,
		dynamicSoftware,
		softwareList,
		dynamicSoftware.length > 0
	);

	useImperativeHandle(ref, () => ({
		updateDynamicFilters(key, tag) {
			switch (key) {
				case "roles":
					roleRef.current.removeItem(tag);
					break;
				case "software":
					softwareRef.current.removeItem(tag);
					break;
				case "languages":
					languageRef.current.removeItem(tag);
					break;
				case "skills":
					skillRef.current.removeItem(tag);
					break;
				default:
					break;
			}
		},
	}));

	if (isFetching) return <LoadingSpinner customStyle={styles.loading_spinner} />;

	return (
		<div className={styles.container}>
			{rolesList?.length > 0 && (
				<div className={styles.field_wrapper}>
					<NewMultiCheckbox
						ref={roleRef}
						title={"Roles"}
						options={filteredRoles.map((item) => defaultFormatter(item))}
						onSelect={(roles) => {
							dispatch(updatePeopleFilters({ roles }));
						}}
						selected={roles}
						perRow="2, 150px"
						// customStyle={styles.multicheckbox_container}
					/>

					{((dynamicRoles?.length === 0 && rolesList.length === 4) ||
						(dynamicRoles?.length > 0 && hasNextPageRoles)) && (
						<SeeMore
							onClick={dynamicRoles?.length === 0 ? refetchRoles : loadMoreRoles}
							className={styles.margin_top}
						/>
					)}
				</div>
			)}

			{softwareList?.length > 0 && (
				<div className={styles.field_wrapper}>
					<NewMultiCheckbox
						ref={softwareRef}
						title={"Software"}
						options={filteredSoftware.map((item) => defaultFormatter(item))}
						onSelect={(software) => {
							dispatch(updatePeopleFilters({ software }));
						}}
						selected={software}
						perRow="2, 150px"
						// customStyle={styles.multicheckbox_container}
					/>

					{((dynamicSoftware?.length === 0 && softwareList.length === 4) ||
						(dynamicSoftware?.length > 0 && hasNextPageSoftware)) && (
						<SeeMore
							onClick={
								dynamicSoftware?.length === 0 ? refetchSoftware : loadMoreSoftware
							}
							className={styles.margin_top}
						/>
					)}
				</div>
			)}

			{languagesList?.length > 0 && (
				<div className={styles.field_wrapper}>
					<NewMultiCheckbox
						ref={languageRef}
						title={"Languages"}
						options={filteredLanguages.map((item) => languageFormatter(item))}
						onSelect={(languages) => {
							dispatch(updatePeopleFilters({ languages }));
						}}
						selected={languages}
						perRow="2, 150px"
						// customStyle={styles.multicheckbox_container}
					/>

					{((dynamicLanguages?.length === 0 && languagesList.length === 4) ||
						(dynamicLanguages?.length > 0 && hasNextPageLanguages)) && (
						<SeeMore
							onClick={
								dynamicLanguages?.length === 0
									? refetchLanguages
									: loadMoreLanguages
							}
							className={styles.margin_top}
						/>
					)}
				</div>
			)}

			{skillsList?.length > 0 && (
				<div className={styles.field_wrapper}>
					<NewMultiCheckbox
						ref={skillRef}
						title={"Skills"}
						options={filteredSkills.map((item) => defaultFormatter(item))}
						onSelect={(skills) => {
							dispatch(updatePeopleFilters({ skills }));
						}}
						selected={skills}
						perRow="2, 150px"
						// customStyle={styles.multicheckbox_container}
					/>

					{((dynamicSkills?.length === 0 && skillsList.length === 4) ||
						(dynamicSkills?.length > 0 && hasNextPageSkills)) && (
						<SeeMore
							onClick={dynamicSkills?.length === 0 ? refetchSkills : loadMoreSkills}
							className={styles.margin_top}
						/>
					)}
				</div>
			)}

			<ConnectionFilter
				friends={friends}
				inMyCircle={inMyCircle}
				inTheirCircle={inTheirCircle}
				onFriendChange={
					!companyId && ((value) => dispatch(updatePeopleFilters({ friends: value })))
				}
				onMyWorkChange={(value) => dispatch(updatePeopleFilters({ inMyCircle: value }))}
				onTheirWorkChange={(value) =>
					dispatch(updatePeopleFilters({ inTheirCircle: value }))
				}
			/>

			<div className={styles.button_container}>
				<GaawkButton
					severity={"tertiary"}
					text={"Clear Filters"}
					className={styles.btn}
					onClick={() => dispatch(resetPeopleFilters())}
				/>
			</div>
		</div>
	);
});

export default PeopleFilter;
