import LoadingPage from "components/Utils/SubComs/CustomLoader/LoadingPage";
import TabMenu from "components/Utils/SubComs/TabMenu/TabMenu";
import { useNavigate } from "react-router-dom";
import CollapsibleJobSection from "./CollapsibleSection/CollapsibleJobSection";
import useJobsLanding from "./hooks/useJobsLanding";
import useProject from "./hooks/useProject";
import styles from "./EmployerLanding.module.scss";
import ProjectSwitcher from "./ProjectSwitcher/ProjectSwitcher";
import routes from "components/Routing/routing-keys";
import useCurrentUser from "hooks/useCurrentUser";
import useUrlParams from "hooks/useUrlParams";
import { useEffect, useState } from "react";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import { useSelector } from "react-redux";
import JobDetails from "../JobDetails/JobDetails";
import { ReactComponent as GaawkJobsLogo } from "images/gaawk-logos/gaawk-jobs.svg";
import { classNames } from "primereact/utils";
import BackButton from "components/Utils/Button/BackButton";
import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
import useJob from "hooks/useJob";
import JobActions from "../JobsComponents/JobActions/JobActions";
import ProjectDetails from "../ProjectDetails/ProjectDetails";
import ProjectActions from "../JobsComponents/ProjectActions/ProjectActions";

const EmployerLanding = () => {
	const navigate = useNavigate();
	// const location = useLocation();
	const { uuid: companyId } = useCurrentUser();

	//* IMPROVEMENT >> take first job (open first, if none, closed) and add set it as the active jobId to load by default (should also do the same when deleting a job while the job in opened in the right side)

	const isMobile = useSelector((state) => state.ui.isMobile);

	useEffect(() => {
		trackEvent(eventsDictionary.JOB.LANDING);
	}, []);

	const [openDetails, setOpenDetails] = useState(false);

	// !---- url params handlers -----

	const paramConfigs = {
		tab: {
			validator: (tab) => ["long_term", "freelance", "casting"].includes(tab),
			defaultValue: "long_term",
		},
		projectTab: {
			validator: (tab) => ["description", "long_term", "freelance", "casting"].includes(tab),
			defaultValue: "",
		},
		projectId: {
			validator: (id) => typeof id === "string",
			defaultValue: "",
		},
		jobId: {
			validator: (id) => typeof id === "string",
			defaultValue: "",
		},
	};

	const { params, setParams } = useUrlParams(paramConfigs);

	const { tab: activeTab, projectId, jobId, projectTab } = params;

	// !------------------------------

	const { data: job, isFetching, isSuccess } = useJob(jobId);

	useEffect(() => {
		if (isSuccess) {
			trackEvent(eventsDictionary.JOB.DETAIL, { job });
		}
	}, [isSuccess, job]);

	const {
		data: projectData,
		isFetching: isFetchingProject,
		isLoading: isLoadingProject,
		fetchStatus: projectFetchStatus,
	} = useProject(projectId);

	const { data: jobsLanding, isLoading, fetchStatus } = useJobsLanding(projectId);

	const {
		activeLongTermJobs,
		pastLongTermJobs,
		activeFreelanceJobs,
		pastFreelanceJobs,
		activeCastingCallJobs,
		pastCastingCallJobs,
	} = jobsLanding || {};

	const {
		activeJobsCount,
		passedJobsCount,
		activeFreelanceCount,
		passedFreelanceCount,
		activeCastingCallsCount,
		passedCastingCallsCount,
	} = projectData || {};

	const jobCounts = {
		long_term: {
			open: projectData ? activeJobsCount : activeLongTermJobs,
			closed: projectData ? passedJobsCount : pastLongTermJobs,
		},
		freelance: {
			open: projectData ? activeFreelanceCount : activeFreelanceJobs,
			closed: projectData ? passedFreelanceCount : pastFreelanceJobs,
		},
		casting: {
			open: projectData ? activeCastingCallsCount : activeCastingCallJobs,
			closed: projectData ? passedCastingCallsCount : pastCastingCallJobs,
		},
	};

	const handleTabChange = (tab) => {
		setParams({ tab, jobId: "", projectTab: "" });
		setOpenDetails(false);
	};
	// const handleTabChange = (tab) => {
	// 	const validTab = validateTab(tab);
	// 	const searchParams = new URLSearchParams(location.search);
	// 	searchParams.set("tab", validTab);
	// 	navigate(`${location.pathname}?${searchParams.toString()}`, {
	// 		replace: true,
	// 	});
	// 	setActiveTab(validTab);
	// };

	const handleAddJob = () => {
		const jobType =
			activeTab === "long_term"
				? "Long_Term"
				: activeTab === "freelance"
				? "Freelance"
				: "Casting_Call";

		navigate(routes.jobCreate, {
			state: { jobType, project: projectData },
		});
	};

	const handleJobClick = (job) => {
		setParams({ jobId: job.uuid, projectTab: "" });
		setOpenDetails(false);
	};

	const handleBackButton = () => {
		if (openDetails) {
			setOpenDetails(false);
		} else if (isMobile) {
			setParams({ jobId: "" });
		} else {
			navigate(-1);
		}
	};

	if (
		(isLoading && fetchStatus !== "idle") ||
		(isLoadingProject && projectFetchStatus !== "idle")
	)
		return <LoadingPage fullPage />;

	return (
		<DefaultPage
			headerRightContent={
				<>
					<div className={styles.header_bar}>
						<BackButton onBack={handleBackButton} />
						{openDetails ? "Project" : "Jobs"}
					</div>
					{openDetails && projectData ? (
						<ProjectActions
							project={projectData}
							onDeleteProject={() => navigate(routes.jobs)}
						/>
					) : (
						!isFetching &&
						job && <JobActions job={job} onDeleteJob={() => setParams({ jobId: "" })} />
					)}
				</>
			}
			hideLeftSide={isMobile && jobId}
			hideRightSide={isMobile && !jobId}
			enableCollapsedNavBar
			hybridNavigation
			// disableScrollbar
			leftSideChildren={
				<div className={styles.left_side}>
					<div className={styles.header}>
						<ProjectSwitcher
							activeProjectTitle={projectData?.title}
							paramConfigs={paramConfigs}
						/>
					</div>

					<div className={styles.menu}>
						<TabMenu
							menuItems={{
								long_term: "Job Openings",
								freelance: "Freelancing",
								casting: "Casting Calls",
							}}
							selectedTab={activeTab}
							onSelectedTabChanged={handleTabChange}
							customStyle={styles.menu_button}
						/>
					</div>

					<div className={styles.jobs_container}>
						<CollapsibleJobSection
							activeTab={activeTab}
							onAdd={handleAddJob}
							open
							count={jobCounts[activeTab].open}
							projectId={projectId}
							companyId={companyId}
							onClick={handleJobClick}
							activeJobId={jobId}
						/>

						<CollapsibleJobSection
							activeTab={activeTab}
							open={false}
							count={jobCounts[activeTab].closed}
							projectId={projectId}
							companyId={companyId}
							onClick={handleJobClick}
							activeJobId={jobId}
						/>
					</div>
				</div>
			}
			rightSideChildren={
				<div className={styles.container}>
					<div
						className={classNames(styles.right_side, {
							[styles.align_middle]: !jobId,
						})}
					>
						{jobId ? (
							openDetails ? (
								<ProjectDetails
									project={projectData}
									isLoading={isFetchingProject}
									activeTab={projectTab || "description"}
									onTabChange={(projectTab) => setParams({ projectTab })}
									onJobClick={handleJobClick}
								/>
							) : (
								<JobDetails
									job={job}
									isLoading={isFetching}
									onProjectClick={() => setOpenDetails(true)}
								/>
							)
						) : (
							<GaawkJobsLogo />
						)}
					</div>
				</div>
			}
		/>
	);
};

export default EmployerLanding;
