import GaawkLink from "components/Utils/SubComs/GaawkLink/GaawkLink";
import styles from "./NewProductItem.module.scss";
import { forwardRef } from "react";
import LazyImage from "components/Utils/LazyImage/LazyImage";
import routes from "components/Routing/routing-keys";
import ProfilePic from "components/Home/ProfilePic";

const NewProductItem = forwardRef(
	({ product, showOwnerThumbnail = true, className, onClick }, ref) => {
		const handleItemClick = (e) => {
			if (onClick) {
				e.preventDefault();
				onClick(e);
			}
		};

		return (
			<GaawkLink
				ref={ref}
				className={`${styles.product_card} ${className}`}
				to={routes.productDetails(product.uuid)}
				onClick={handleItemClick}
			>
				<div className={styles.product_image_container}>
					<LazyImage
						image={
							<img
								src={product?.mainImage?.file?.url}
								alt={product?.name}
							/>
						}
						alt={product.name}
					/>
					{showOwnerThumbnail && (
						<ProfilePic
							thumbnail={product?.owner?.profileImage?.image}
							name={product?.owner?.name}
							enableName={false}
							type={product?.owner?.type}
							w={"100%"}
							h={"100%"}
							noMarginRight={true}
							className={styles.owner_thumbnail}
						/>
					)}
				</div>
				<p>{product?.name}</p>
			</GaawkLink>
		);
	}
);

export default NewProductItem;
