import client from "./client";

const endpoint = "/gaawk/category";

const getGaawkCategories = (
	l1ServiceId,
	l2ServiceId,
	q = "",
	withServicesOrProducts = false
) =>
	client.get(
		endpoint +
			`?l1ServiceId=${l1ServiceId}&l2ServiceId=${l2ServiceId}&q=${q}&withServicesOrProducts=${withServicesOrProducts}`
	);

const searchGaawkService = (page, size, name = "") =>
	client.get(endpoint + `/search?page=${page}&size=${size}&name=${name}`);

const addGaawkServiceSuggestion = (name, description, parentId = "") =>
	client.post(
		endpoint +
			`/suggested?name=${name}&description=${description}&parentId=${parentId}`
	);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getGaawkCategories,
	searchGaawkService,
	addGaawkServiceSuggestion,
};
