export const searchKeys = {
	all: ["search"],
	searchAll: (searchQuery, locationId) => [
		...searchKeys.all,
		"all",
		searchQuery,
		locationId,
	],
	agency: (filters) => [...searchKeys.all, "agency", filters],
	talent: (agencyId, filters, agenciesOnly = false) => [
		...searchKeys.all,
		"talent",
		agencyId,
		filters,
		agenciesOnly,
	],
	people: (filters) => [...searchKeys.all, "people", filters],
	companies: (filters) => [...searchKeys.all, "companies", filters],
	posts: (filters) => [...searchKeys.all, "posts", filters],
	jobs: (filters) => [...searchKeys.all, "jobs", filters],
	products: (filters) => [...searchKeys.all, "products", filters],
	services: (filters) => [...searchKeys.all, "services", filters],
	resources: (filters) => [...searchKeys.all, "resources", filters],
	jobTitles: (q) => [...searchKeys.all, "job-titles", q],
	resourcesTitles: (q) => [...searchKeys.all, "resource-titles", q],
};
