import Select, { components } from "react-select";
import styles from "./SelectSearchInput.module.scss";
import { cssValue } from "components/Utils/General";
import { ReactComponent as MagnifierIcon } from "images/magnifier.svg";

const SelectSearchInput = ({
	options,
	onChange,
	value,
	onInputChange,
	isLoading,
	onMenuScrollToBottom,
}) => {
	const customStyles = {
		option: (baseStyles, { isFocused, isSelected, selectProps }) => ({
			...baseStyles,
			"&:active": {
				backgroundColor: cssValue("--light-gaawk"),
			},
			backgroundColor: isSelected
				? cssValue("--gaawk")
				: isFocused
				? cssValue("--light-gaawk")
				: "white",
			cursor: "pointer",
			color: isSelected ? "#FFF" : "#000000",
			fontWeight: isSelected ? "bold" : "regular",
		}),

		control: (provided, { isFocused, isSelected, selectProps }) => ({
			...provided,
			borderRadius: "18px",
			backgroundColor: cssValue("--gray20"),
			outline: isFocused || isSelected ? "1px solid var(--gray70) !important" : "none",
			outlineOffset: "0px",
			borderWidth: "0px",
			transition: "box-shadow 0.3s ease",
			boxShadow: isFocused || isSelected ? "0 0 0 5px var(--gray20)" : "0 0 0 0 transparent",
			cursor: selectProps.cursor ? selectProps.cursor : "pointer",
			height: "35px",
			minHeight: selectProps.height,
		}),
		singleValue: (provided, { selectProps }) => ({
			...provided,
			color: "black",
			fontSize: selectProps.fontSize,
		}),

		valueContainer: (provided, { selectProps }) => ({
			...provided,
			fontSize: selectProps.fontSize,
			height: "35px",
			padding: "7px 7px 7px 36px",
			alignContent: "center",
			alignItems: "center",
		}),

		menu: (provided) => ({
			...provided,
			zIndex: "3",
			borderRadius: "18px",
			overflow: "hidden",
		}),

		menuList: (baseStyles) => ({
			...baseStyles,
			borderRadius: "18px",
		}),
		placeholder: (provided) => ({
			...provided,
			color: cssValue("--gray70"),
		}),
	};

	return (
		<div className={styles.search_wrapper}>
			<MagnifierIcon className={styles.searchIcon} />
			<Select
				options={options}
				components={{
					DropdownIndicator: () => null,
					IndicatorSeparator: () => null,
					Option: (props) => {
						const searchItem = props.data;
						return (
							<components.Option {...props}>
								<div className={styles.search_item}>{searchItem.label}</div>
							</components.Option>
						);
					},
				}}
				isSearchable={true}
				styles={customStyles}
				placeholder={"Search"}
				onChange={onChange}
				value={value}
				onInputChange={onInputChange}
				isClearable={true}
				isLoading={isLoading}
				onMenuScrollToBottom={onMenuScrollToBottom}
				noOptionsMessage={() => "No results found"}
			/>
		</div>
	);
};

export default SelectSearchInput;
