import projectApi from "api/project";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import useApi from "hooks/useApi";
import useCurrentUser from "hooks/useCurrentUser";
import usePaginatedResource from "hooks/usePaginatedResource";
import { ReactComponent as ForwardIcon } from "images/arrow-icon.svg";
import { classNames } from "primereact/utils";
import { projectKeys } from "queryKeys/projects-key-factory";
import { useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./ProjectSwitcher.module.scss";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import routes from "components/Routing/routing-keys";
import useUrlParams from "hooks/useUrlParams";
import GaawkButton from "components/Utils/Button/GaawkButton";
import { RadioButton } from "primereact/radiobutton";
import NewGaawkModal from "components/Utils/NewGaawkModal/NewGaawkModal";

const itemsPerPage = 20;

const ProjectSwitcher = ({ activeProjectTitle = "All Jobs", paramConfigs }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const [switchModal, setSwitchModal] = useState(false);
	const { uuid: companyId } = useCurrentUser();
	const openStatusProject = true;

	const searchParams = new URLSearchParams(location.search);
	const activeProjectId = searchParams.get("projectId") || "";

	// * ==== FETCH PROJECTS PAGINATED ===

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	const getAllProjectsApi = useApi(projectApi.getAllProjects, true, true);

	const fetchProjects = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, ___, status] = queryKey;

		const response = await getAllProjectsApi.request(pageParam, itemsPerPage, status);
		return response.data;
	};

	const { data, isLoading, isFetching, hasNextPage, fetchNextPage } = usePaginatedResource(
		projectKeys.filteredProjectsByCompany(companyId, openStatusProject),
		fetchProjects,
		itemsPerPage,
		switchModal
	);

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetching]);

	const { setParams } = useUrlParams(paramConfigs);

	const handleProjectClick = (projectId = "") => {
		setParams({ projectId, jobId: "" });
		setSwitchModal(false);
	};

	const pendingList = useMemo(
		() =>
			data?.pages?.map((page) =>
				page.map((project, i) => (
					<button
						ref={page.length === i + 1 ? viewRef : null}
						className={classNames(styles.project_item, {
							[styles.active]: project.uuid === activeProjectId,
						})}
						key={project.uuid}
						onClick={() => handleProjectClick(project.uuid)}
					>
						<p>{project.title}</p>
						<RadioButton readOnly checked={project.uuid === activeProjectId} />
					</button>
				))
			),
		[activeProjectId, data, handleProjectClick]
	);

	return (
		<>
			<button className={styles.switcher_wrapper} onClick={() => setSwitchModal(true)}>
				<p>{activeProjectTitle}</p>
				<ForwardIcon className={styles.forward_icon} />
			</button>

			<NewGaawkModal
				visible={switchModal}
				onHide={() => setSwitchModal(false)}
				title={"Active Projects"}
				children={
					<>
						<p className={styles.info}>
							You can create or select the projects from here & can use them as
							container for your jobs.
						</p>

						<div className={styles.project_container}>
							{isLoading ? (
								<LoadingSpinner />
							) : (
								<>
									<button
										className={classNames(styles.project_item, {
											[styles.active]: activeProjectId === "",
										})}
										onClick={() => handleProjectClick()}
									>
										<p>All Jobs</p>
										<RadioButton readOnly checked={activeProjectId === ""} />
									</button>

									{pendingList}

									{isFetching && <LoadingSpinner />}
								</>
							)}
						</div>

						<div className={styles.button_container}>
							<GaawkButton
								severity={"secondary"}
								text={"Add New Project"}
								className={styles.addProjectBtn}
								onClick={() => navigate(routes.addProject)}
							/>

							<SeeMore
								text={"Manage Projects"}
								className={styles.manage_projects_btn}
								color={"#000"}
								onClick={() => navigate(routes.allProjects)}
							/>
						</div>
					</>
				}
			/>
		</>
	);
};

export default ProjectSwitcher;
