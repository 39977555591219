import { classNames } from "primereact/utils";
import styles from "./SeeMore.module.css";
import { ReactComponent as ForwardIcon } from "images/arrow-icon.svg";

const SeeMore = ({
	text = "More",
	onClick,
	className,
	color,
	style,
	horizontal = true,
}) => {
	return (
		<button
			className={`${styles.see_more} ${className}`}
			style={style}
			onClick={onClick}
			type={"button"}
		>
			<label style={{ color }}>{text}</label>
			<ForwardIcon
				fill={color ? color : "#686e76"}
				className={classNames(styles.forward_icon, {
					[styles.vertical]: !horizontal,
				})}
			/>
		</button>
	);
};

export default SeeMore;
