import React from "react";
import ContentLoader from "react-content-loader";

const JobLoader = (props) => (
	<ContentLoader
		speed={2}
		width={"100%"}
		height={50}
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		{...props}
	>
		<rect x="57" y="2" rx="3" ry="3" width="410" height="6" />
		<rect x="57" y="16" rx="3" ry="3" width="380" height="6" />
		<rect x="57" y="31" rx="3" ry="3" width="178" height="6" />
		<rect x="0" y="0" rx="8" ry="8" width="40" height="40" />
	</ContentLoader>
);

export default JobLoader;
