import GroupMember from "components/Chat/GroupMember";
import ContactLoader from "components/Utils/SubComs/CustomLoader/ContactLoader";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { Fragment, useMemo } from "react";
import styles from "./ConnectionSection.module.scss";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";

const ConnectionSection = ({
	queryFn,
	queryKey,
	pageSize,
	isWaitingForDebounce,
}) => {
	const { items, viewRef, isFetchingNextPage, isFetching, isLoading } =
		useInfiniteScroll({
			queryKey,
			queryFn,
			pageSize,
		});

	const itemsList = useMemo(
		() =>
			items.map((item, index) => {
				const isLastItem = index === items.length - 1;
				return (
					<Fragment key={item.uuid}>
						<GroupMember
							ref={isLastItem ? viewRef : null}
							data={item}
							redirect={true}
						/>
						{isLastItem && isFetchingNextPage && <ContactLoader />}
					</Fragment>
				);
			}),
		[isFetchingNextPage, items, viewRef]
	);

	return (
		<div className={styles.container}>
			{!isFetching && !isWaitingForDebounce && itemsList}
			{(isLoading || isWaitingForDebounce) && <ContactLoader />}
			<NoResults
				visible={
					items.length === 0 && !isFetching && !isWaitingForDebounce
				}
				text={"No results"}
			/>
		</div>
	);
};

export default ConnectionSection;
