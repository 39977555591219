import ContactLoader from "components/Utils/SubComs/CustomLoader/ContactLoader";
import SearchInput from "components/Utils/SubComs/Inputs/SearchInput/SearchInput";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import { useCustomQuery } from "hooks/useCustomQuery";
import useDebounce from "hooks/useDebounce";
import removeIcon from "images/cancel-icon-graywhite.svg";
import { emotionsKeys } from "queryKeys/emotions-key-factory";
import { useState } from "react";
import emotionApi from "../../api/emotion";
import useApi from "../../hooks/useApi";
import styles from "./EmotionModal.module.css";
import NewGaawkModal from "components/Utils/NewGaawkModal/NewGaawkModal";

const EmotionModal = ({ show, onClose, onSelect, emotionId, onRemoveEmotion }) => {
	// ! ============ fetch emotion emotion ============
	const getEmotionsApi = useApi(emotionApi.getEmotions, true, true);

	const fetchEmotions = async () => {
		const response = await getEmotionsApi.request();
		return response.data;
	};

	const { data, isFetching } = useCustomQuery({
		queryKey: emotionsKeys.all,
		queryFn: fetchEmotions,
		enabled: show,
	});

	// ! ============ search emotion ============
	const searchEmotionsApi = useApi(emotionApi.searchEmotions, true, true);

	const [emotionSearchText, setEmotionSearchText] = useState("");
	const debouncedQuery = useDebounce(emotionSearchText);

	const searchEmotions = async ({ queryKey }) => {
		const [_, __, query] = queryKey;
		const response = await searchEmotionsApi.request(query);
		return response.data;
	};

	const { data: searchData, isFetching: isFetchingSearch } = useCustomQuery({
		queryKey: emotionsKeys.search(debouncedQuery),
		queryFn: searchEmotions,
		enabled: show && !!debouncedQuery,
	});

	const handleEmotionSearchChange = ({ target }) => {
		const query = target.value;
		setEmotionSearchText(query);
	};

	const handleSelect = (emotion) => {
		onSelect(emotion);
		handleClose();
	};

	const handleClose = () => {
		setEmotionSearchText("");
		onClose();
	};

	const emotionList = (emotionSearchText ? searchData : data)?.map((emotion) => {
		return (
			<div
				className={`${styles.emotion_item} ${
					emotion.uuid === emotionId ? styles.active : undefined
				}`}
				key={emotion.uuid}
				onClick={() => handleSelect(emotion)}
			>
				<div>
					<img src={emotion.icon} alt={emotion.name} />
					<label>{emotion.name}</label>
				</div>
				{emotion.uuid === emotionId && (
					<img
						src={removeIcon}
						className={styles.remove_icon}
						onClick={onRemoveEmotion}
						alt="remove"
					/>
				)}
			</div>
		);
	});

	return (
		<NewGaawkModal
			visible={show}
			onHide={handleClose}
			title={"How are you feeling today?"}
			children={
				<>
					<SearchInput
						value={emotionSearchText}
						onChange={handleEmotionSearchChange}
						border={false}
						onClearClicked={() => setEmotionSearchText("")}
						showIcons={true}
					/>

					<div className={styles.emotion_list_wrapper}>
						{(isFetchingSearch || isFetching) && <ContactLoader />}
						{emotionList}
						<NoResults
							text={"No Results"}
							visible={
								(searchData?.length === 0 &&
									!isFetchingSearch &&
									emotionSearchText) ||
								(data?.length === 0 && !isFetching)
							}
						/>
					</div>
				</>
			}
		/>
	);
};

export default EmotionModal;
