import client from "./client";

const endpoint = "/settings";

const getSettings = () => client.get(endpoint);

const updateAccountPrivacy = (q) =>
	client.put(endpoint + `/privacy?visibility=${q}`);

const updateMessagesSettings = (q) =>
	client.put(endpoint + `/messages?allowFrom=${q}`);

const updateGroupSettings = (q) =>
	client.put(endpoint + `/groups?allowFrom=${q}`);

const updateTagsAndMentionsSettings = (q) =>
	client.put(endpoint + `/tagsAndMentions?allowFrom=${q}`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getSettings,
	updateAccountPrivacy,
	updateMessagesSettings,
	updateGroupSettings,
	updateTagsAndMentionsSettings,
};
