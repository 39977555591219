import LeftSide from "components/Utils/SubComs/Left-Side/LeftSide";
import styles from "./ThreeColumnPage.module.scss";
import RightSideColumn from "./RightSideColumn";
import HeaderBar from "components/Utils/SubComs/HeaderBar/HeaderBar";
import CenterContainer from "components/Utils/SubComs/CenterContainer/CenterContainer";
import BottomNavBar from "components/Utils/SubComs/bottomNavBar/BottomNavBar";

const ThreeColumnPage = ({
	leftSideChildren,
	centerChildren,
	rightSideChildren,
	headerRightContent,
	bgColor = undefined,
}) => {
	return (
		<>
			<HeaderBar headerRightContent={headerRightContent} />

			<div className={styles.main}>
				<div className={styles.container}>
					<LeftSide children={leftSideChildren} />

					<CenterContainer
						children={centerChildren}
						bgColor={bgColor}
					/>

					{/* //TODO >> removing right side column as it's crashing with new invitation response for now */}

					{/* <RightSideColumn children={rightSideChildren} bgColor={bgColor} /> */}
				</div>

				{/* Show only in mobile mode (below 748px) because bottomBar in left pannel will be hidden */}
				<div className={styles.bottomNavBar}>
					<BottomNavBar />
				</div>
			</div>
		</>
	);
};

export default ThreeColumnPage;
