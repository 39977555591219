import styles from "./BoardPendingRequests.module.scss";
import { useInView } from "react-intersection-observer";
import useApi from "hooks/useApi";
import boardsApi from "api/boards";
import usePaginatedResource from "hooks/usePaginatedResource";
import { boardsKeys } from "queryKeys/boards-key-factory";
import { useEffect, useMemo } from "react";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import GroupMember from "components/Chat/GroupMember";
import acceptIcon from "images/blue-tick-35-x-35.svg";
import cancelIcon from "images/cancel-btn-red.svg";
import useMutate from "hooks/useMutate";
import { useQueryClient } from "@tanstack/react-query";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import NewGaawkModal from "components/Utils/NewGaawkModal/NewGaawkModal";

const itemsPerPage = 20;

const BoardPendingRequests = ({ show, onClose, boardId }) => {
	const queryClient = useQueryClient();

	// !==================== GET BOARD PENDING FOLLOWERS =========================

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	const getPendingFollowersApi = useApi(boardsApi.getPendingFollowers, true, true);

	const fetchPendingFollowers = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, boardId] = queryKey;

		const response = await getPendingFollowersApi.request(boardId, pageParam, itemsPerPage);
		return response.data;
	};

	const { data, isFetching, hasNextPage, fetchNextPage } = usePaginatedResource(
		boardsKeys.pendingFollowers(boardId),
		fetchPendingFollowers,
		itemsPerPage,
		show
	);

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetching]);

	const onSuccess = () => {
		queryClient.invalidateQueries(boardsKeys.pendingFollowers(boardId));
		queryClient.invalidateQueries(boardsKeys.detail(boardId));
	};

	const {
		action: { mutate: acceptRequest },
	} = useMutate(boardsApi.acceptBoardFollower, onSuccess);
	const {
		action: { mutate: declineRequest },
	} = useMutate(boardsApi.deleteFollower, onSuccess);

	const pendingList = useMemo(
		() =>
			data?.pages?.map((page) =>
				page.map((item, i) => (
					<GroupMember
						// ref={viewRef}
						ref={page.length === i + 1 ? viewRef : null}
						customStyle={styles.contact_item}
						key={item.uuid}
						data={item}
						redirect={true}
						disableDecoration={true}
						rightSideAction={
							<>
								<button
									onClick={() =>
										declineRequest({
											boardId,
											requestedProfileId: item.uuid,
										})
									}
								>
									<img src={cancelIcon} alt="" />
								</button>
								<button
									onClick={() =>
										acceptRequest({
											boardId,
											requestedProfileId: item.uuid,
										})
									}
								>
									<img src={acceptIcon} alt="" />
								</button>
							</>
						}
					/>
				))
			),
		[data]
	);

	return (
		<NewGaawkModal
			visible={show}
			onHide={onClose}
			title={"Pending Followers"}
			children={
				<div className={styles.container}>
					<div className={styles.items_wrapper}>{pendingList}</div>

					<LoadingSpinner visible={isFetching} />

					<NoResults
						title={"Nothing Yet!"}
						text={"No pending requests"}
						visible={data?.pages?.[0]?.length === 0}
					/>
				</div>
			}
		/>
	);
};

export default BoardPendingRequests;
