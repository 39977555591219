import styles from "./Message.module.css";
import SystemMessage from "../Chat/SystemMessage";
import MessageBubble from "./MessageBubble";
import { Checkbox } from "primereact/checkbox";

const Message = ({
	item,
	profileId,
	conversation,
	onShowInfo,
	onReply,
	onForward,
	onDelete,
	onSelect,
	isInfoModal = false,
	selectActive,
	isSelected,
	onShowSlider,
}) => {
	const { message, systemMessage, sendBy } = item;

	const type = !systemMessage && sendBy && profileId === sendBy.uuid ? "SENDER" : "RECEIVER";
	const isGroup = conversation.conversationType === "Group";

	// * ============ select message handlers ============

	const handleSelectedMessage = () => {
		if (selectActive && !systemMessage) onSelect(item);
	};

	return (
		<div
			className={`${styles.container} ${
				selectActive && !systemMessage ? styles.selectActive : undefined
			} ${type === "SENDER" ? styles.sender : styles.receiver} ${
				isInfoModal ? styles.isInfoModal : undefined
			}`}
			onClick={handleSelectedMessage}
		>
			{selectActive && !systemMessage && (
				<Checkbox checked={isSelected} onChange={handleSelectedMessage} />
			)}
			{systemMessage && <SystemMessage message={message} />}
			{!systemMessage && (
				<MessageBubble
					item={item}
					type={type}
					isGroup={isGroup}
					onShowInfo={onShowInfo}
					onShowSlider={onShowSlider}
					isInfoModal={isInfoModal}
					onReply={onReply}
					onForward={onForward}
					onDelete={onDelete}
				/>
			)}
		</div>
	);
};

export default Message;
