import ProfilePic from "components/Home/ProfilePic";
import styles from "./CarouselItem.module.scss";

const CarouselItem = ({ data, isActive, onClick }) => {
	return (
		<button
			className={`${styles.profile_button} ${
				data.type === "USER" ? styles.user : undefined
			} ${isActive ? styles.active : undefined}`}
			onClick={onClick}
		>
			<ProfilePic
				w={"100%"}
				h={"100%"}
				type={data.type}
				thumbnail={data?.profileImage?.image || data?.profileImage?.originalImageURL}
				noMarginRight={true}
				name={data.firstName || data.name}
				enableName={false}
			/>
			{data.messageCount > 0 && (
				<div className={styles.notification_counter}>
					<p>{data.messageCount > 100 ? "+100" : data.messageCount}</p>
				</div>
			)}
			<div className={styles.indicator}></div>
		</button>
	);
};

export default CarouselItem;
