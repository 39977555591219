import useApi from "hooks/useApi";
import styles from "./Visibility.module.scss";
import settingsApi from "api/settings";
import { settingsKeys } from "queryKeys/settings-key-factory";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useMutate from "hooks/useMutate";
import { privacyOptions, accountPrivacy } from "components/Utils/General";
import LoadingPage from "components/Utils/SubComs/CustomLoader/LoadingPage";
import NewMultiCheckbox from "components/Utils/NewMultiCheckBox/NewMultiCheckBox";
import NewGaawkModal from "components/Utils/NewGaawkModal/NewGaawkModal";
import GaawkButton from "components/Utils/Button/GaawkButton";

const Visibility = () => {
	const queryClient = useQueryClient();

	const getSettingsApi = useApi(settingsApi.getSettings, true, true);

	const fetchSettings = async () => {
		const response = await getSettingsApi.request();
		return response.data;
	};

	const { isLoading, data: settings } = useQuery({
		queryKey: settingsKeys.all,
		queryFn: fetchSettings,
	});

	const { messages, tagsAndMentions, groups, profileVisibility } =
		settings || {};

	const handleSuccess = (response) => {
		queryClient.setQueryData(settingsKeys.all, () => {
			return response.data;
		});
	};

	//! ====== ACCOUNT PRIVACY ==========

	const {
		handleRequestMutate,
		handleConfirmMutate,
		setWarningModal,
		warningModal,
		activeItem,
	} = useMutate(settingsApi.updateAccountPrivacy, handleSuccess, undefined, {
		meta: {
			successMessage: "Account privacy settings saved!",
		},
	});

	//! ====== MESSAGES ======

	const {
		action: { mutate: mutateMessages },
	} = useMutate(
		settingsApi.updateMessagesSettings,
		handleSuccess,
		undefined,
		{
			meta: {
				successMessage: "Messages settings saved!",
			},
		}
	);

	//! ====== GROUPS ========

	const {
		action: { mutate: mutateGroups },
	} = useMutate(settingsApi.updateGroupSettings, handleSuccess, undefined, {
		meta: {
			successMessage: "Groups settings saved!",
		},
	});

	//! ====== TAGS ==========

	const {
		action: { mutate: mutateTagsAndMentions },
	} = useMutate(
		settingsApi.updateTagsAndMentionsSettings,
		handleSuccess,
		undefined,
		{
			meta: {
				successMessage: "Tags and mentions settings saved!",
			},
		}
	);

	if (isLoading) return <LoadingPage />;

	return (
		<div className={styles.container}>
			<h3>Account Privacy</h3>
			<NewMultiCheckbox
				options={accountPrivacy}
				onSelect={(option) => handleRequestMutate(option?.[0]?.value)}
				selected={[
					accountPrivacy.find(
						(item) => item.value === profileVisibility
					),
				]}
				type={"radio"}
				perRow="1, auto"
				customStyle={styles.privacy_item}
				name={"accountPrivacy"}
			/>

			<h3>General Privacy</h3>

			<NewMultiCheckbox
				title={"Who can send you messages"}
				options={privacyOptions}
				onSelect={(option) => mutateMessages(option?.[0]?.value)}
				selected={[
					privacyOptions.find((item) => item.value === messages),
				]}
				type={"radio"}
				perRow="1, auto"
				customStyle={styles.privacy_item}
				name={"messages"}
			/>
			<NewMultiCheckbox
				title={"Who can add you to the group"}
				options={privacyOptions}
				onSelect={(option) => mutateGroups(option?.[0]?.value)}
				selected={[
					privacyOptions.find((item) => item.value === groups),
				]}
				type={"radio"}
				perRow="1, auto"
				customStyle={styles.privacy_item}
				name={"groups"}
			/>

			<NewMultiCheckbox
				title={"Who can tag or mention you in posts"}
				options={privacyOptions}
				onSelect={(option) => mutateTagsAndMentions(option?.[0]?.value)}
				selected={[
					privacyOptions.find(
						(item) => item.value === tagsAndMentions
					),
				]}
				type={"radio"}
				perRow="1, auto"
				customStyle={styles.privacy_item}
				name={"tagsAndMentions"}
			/>

			<NewGaawkModal
				visible={warningModal}
				onHide={() => setWarningModal(false)}
				title={`Switch to ${
					profileVisibility === "PUBLIC" ? "Private" : "Public"
				} Account`}
			>
				<div className={styles.confirmation_content}>
					<p>
						{profileVisibility === "PUBLIC"
							? "Are you sure you want to make your account private? Only approved followers will be able to see your content."
							: "Anyone will be able to view your content and follow you without approval. All pending requests will be accepted, and those users will automatically follow you after you make your profile public."}
					</p>
					<div className={styles.confirmation_buttons}>
						<GaawkButton
							text="Cancel"
							onClick={() => setWarningModal(false)}
							severity="tertiary"
						/>
						<GaawkButton
							text={`Yes, Make ${
								profileVisibility === "PUBLIC"
									? "Private"
									: "Public"
							}`}
							onClick={() => handleConfirmMutate(activeItem)}
							loading={isLoading}
						/>
					</div>
				</div>
			</NewGaawkModal>
		</div>
	);
};

export default Visibility;
