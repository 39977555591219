import jobApi from "api/job";
import CompanyJobItem from "components/Jobs/JobsComponents/CompanyJobItem/CompanyJobItem";
import CollapsibleContainer from "components/Utils/CollapsibleContainer/CollapsibleContainer";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import useApi from "hooks/useApi";
import { jobsKeys } from "queryKeys/jobs-key-factory";
import { useMemo } from "react";
import styles from "./CollapsibleJobSection.module.scss";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import JobItemLoader from "components/Utils/SubComs/CustomLoader/JobItemLoader";

const PAGE_SIZE = 15;

const sectionHeaders = {
	long_term: {
		title: "Jobs",
		jobType: "Long_Term",
	},
	freelance: {
		title: "Freelance Jobs",
		jobType: "Freelance",
	},
	casting: {
		title: "Casting Calls",
		jobType: "Casting_Call",
	},
};

const CollapsibleJobSection = ({
	activeTab,
	open,
	count,
	projectId,
	companyId,
	onAdd,
	onClick,
	activeJobId,
	disableActions = false,
}) => {
	const getJobsApi = useApi(jobApi.getJobByCompanyId, true, true);

	const fetchJobs = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, companyId, jobType, projectId, closed, excludeId] = queryKey;

		const response = await getJobsApi.request(
			companyId,
			pageParam,
			PAGE_SIZE,
			jobType,
			closed,
			excludeId,
			projectId
		);
		return response.data;
	};

	const { items, viewRef, isFetchingNextPage, isLoading } = useInfiniteScroll({
		queryKey: jobsKeys.jobsByCompany(
			companyId,
			sectionHeaders[activeTab].jobType,
			!open,
			undefined,
			projectId
		),
		queryFn: fetchJobs,
		pageSize: PAGE_SIZE,
	});

	const jobsList = useMemo(
		() =>
			items?.map((job, index) => {
				const isLastItem = index === items.length - 1;
				const isActive = activeJobId === job.uuid;
				return (
					<div
						key={job.uuid}
						ref={(el) => {
							if (isLastItem) viewRef(el);
							if (isActive) {
								setTimeout(() => {
									el?.scrollIntoView({ behavior: "smooth", block: "center" });
								}, 100);
							}
						}}
					>
						<CompanyJobItem
							isActive={isActive}
							onClick={() => onClick(job)}
							job={job}
							disableActions={disableActions}
						/>
						{isLastItem && isFetchingNextPage && (
							<div className={styles.job_loader}>
								<JobItemLoader />
							</div>
						)}
					</div>
				);
			}),
		[items, viewRef, activeJobId]
	);

	return (
		<CollapsibleContainer
			title={`${open ? "Open" : "Closed"} ${sectionHeaders[activeTab].title} (${count})`}
			onAdd={onAdd}
		>
			{!isLoading && <div className={styles.jobs_wrapper}>{jobsList}</div>}

			{/* //*IMPROVEMENT >> make the number of loaders dynamic based on the `count` */}
			{isLoading && (
				<>
					<JobItemLoader />
					<JobItemLoader />
				</>
			)}

			<NoResults
				text={`No ${open ? "open" : "closed"} ${
					sectionHeaders[activeTab].title
				} to show here.`}
				title={"Nothing Yet!"}
				visible={count === 0}
			/>
		</CollapsibleContainer>
	);
};

export default CollapsibleJobSection;
