import { Outlet, useNavigate } from "react-router-dom";
// import useCustomPageViewTracking from "hooks/usePageViewTracking";
import { ToastContainer, toast } from "react-toastify";
import ProfileSwitchModal from "components/Utils/Notifications/ProfileSwitchModal";
import { useRef, useState } from "react";
import getNotificationDetails from "components/Utils/Notifications/notification-details";
import NotificationBubble from "components/Utils/Notifications/NotificationBubble";
import useSpecialAction from "components/Utils/Notifications/useSpecialAction";
import useProfileSwitch from "hooks/useProfileSwitch";
import { specialNotificationActions } from "components/Utils/Notifications/special-notification-actions";
import useCurrentUser from "hooks/useCurrentUser";
import { getMessaging, onMessage } from "firebase/messaging";
import usePageLeave from "hooks/usePageLeave";
import { logout } from "components/Utils/General";

const RootLayout = () => {
	const navigate = useNavigate();
	// useCustomPageViewTracking(); //TODO >> removed for now to test the enrichPageUrlPlugin

	const keepMeLogged = !(localStorage.getItem("keepLogged")?.toLowerCase() === "false");

	usePageLeave(() => {
		if (!keepMeLogged) {
			logout();
		}
	});

	const handleSpecialAction = useSpecialAction();

	const { uuid: profileId } = useCurrentUser();

	const { switchToCompany, switchToProfile } = useProfileSwitch(false, () => {
		setSwitchModal(false);
		handleNotification(notification);
	});

	const toastId = useRef(null);
	const dismiss = () => toast.dismiss(toastId.current);

	const [notification, setNotification] = useState(undefined);
	const [switchModal, setSwitchModal] = useState(false);

	const handleSwitchModal = (profileData) => {
		setNotification(profileData);
		setSwitchModal(true);
	};

	const handleNotification = (notification) => {
		specialNotificationActions.includes(notification.action)
			? handleSpecialAction(notification)
			: navigate(notification.url);
	};

	// const messaging = getMessaging();  //* firebase commented

	//* firebase commented
	// onMessage(messaging, (notification) => {
	// 	const parsedNotification = JSON.parse(notification.data.payload);

	// 	const { body, title, url } = getNotificationDetails(parsedNotification);

	// 	toastId.current = toast(
	// 		<div>
	// 			<NotificationBubble
	// 				notification={parsedNotification}
	// 				body={body}
	// 				title={title}
	// 				onClick={() => {
	// 					if (profileId !== parsedNotification.to.uuid) {
	// 						handleSwitchModal({
	// 							...parsedNotification,
	// 							url,
	// 						});
	// 					} else {
	// 						handleNotification({
	// 							...parsedNotification,
	// 							url,
	// 						});
	// 					}

	// 					dismiss();
	// 				}}
	// 			/>
	// 		</div>,
	// 		{
	// 			position: "top-right",
	// 			autoClose: 5000,
	// 			hideProgressBar: true,
	// 			closeOnClick: false,
	// 			pauseOnHover: true,
	// 			draggable: true,
	// 			progress: undefined,
	// 			theme: "light",
	// 		}
	// 	);
	// });

	return (
		<>
			<ToastContainer className="toast_container" />
			<Outlet />
			<ProfileSwitchModal
				show={switchModal}
				onClose={() => setSwitchModal(false)}
				data={notification?.to}
				onConfirm={() => {
					notification?.to.type === "COMPANY"
						? switchToCompany(notification?.to.uuid)
						: switchToProfile(notification?.to.uuid);
				}}
			/>
		</>
	);
};

export default RootLayout;
