import Joi from "joi";

const serviceSuggestionSchema = Joi.object({
	name: Joi.string().required().max(30).label("Name").messages({
		"string.empty": "Service Name can't be empty",
		"string.max": "Service Name can't be more than 30 characters",
	}),
	description: Joi.string().required().label("Description").messages({
		"string.empty": "Description can't be empty",
		"any.required": "Description can't be empty",
	}),
	// category: Joi.string().valid("NEW", "SUB").label("Category"),
	// gaawkLvl1: Joi.when("category", {
	// 	is: "SUB",
	// 	then: Joi.object({
	// 		label: Joi.string(),
	// 		value: Joi.string(),
	// 	})
	// 		.required()
	// 		.messages({
	// 			"any.required": "Level One Services can't be empty",
	// 			"object.base": "Level One Services can't be empty",
	// 		})
	// 		.label("Level One Services"),
	// }),
	// gaawkLvl2: Joi.object({
	// 	label: Joi.string(),
	// 	value: Joi.string(),
	// })
	// 	.allow(null)
	// 	.label("Level Two Services"),
});

export default serviceSuggestionSchema;
