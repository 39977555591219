import useApi from "hooks/useApi";
import productApi from "api/product";
import { productsKeys } from "queryKeys/products-key-factory";
import { useCustomQuery } from "./useCustomQuery";

export const useProductMedia = (productId, enabled, itemsPerPage = 10) => {
	const getProductMediaApi = useApi(productApi.getProductMedia, true, true);

	const fetchMedia = async ({ queryKey }) => {
		const [, , , productId] = queryKey;
		const response = await getProductMediaApi.request(
			0,
			itemsPerPage,
			productId
		);
		return response.data;
	};

	const query = useCustomQuery({
		queryKey: productsKeys.productMedia(productId),
		queryFn: fetchMedia,
		enabled,
	});

	return query;
};
