import styles from "./ServiceAccordionItem.module.scss";
import arrowIcon from "images/dropdown_arrow_white_14_x_10.svg";
import arrowIconGray from "images/dropdown-arrow-gray.svg";
import { useEffect, useRef, useState } from "react";
import { RadioButton } from "primereact/radiobutton";

const ServiceAccordionItem = ({
	content,
	isOpen,
	onToggle,
	itemKey,
	className,
	// showArrow = true,
	level = undefined,
	radioMode,
	onRadioSelect,
	selectedItem,
	// button = undefined,
	// showServicesWithProducts,
	isActiveService,
	productsCount,
	// product_number,
	LinkComponent,
	productCountStyle,
	// name,
	// idToOpen,
}) => {
	const accordionRef = useRef(null);
	// const radioRef = useRef(null);

	const isSelected = selectedItem === itemKey;
	// console.log("🚀 ~ selectedItem >>", selectedItem);
	// console.log("🚀 ~ isSelected >>", isSelected);

	const [open, setOpen] = useState(false);

	// console.log("🚀 ~ isOpen prop >>", name, ">>", isOpen);
	// console.log("open state >> ", open);
	// console.log("🚀 ~ service name >>", name);
	// console.log("=====");

	// useEffect(() => {
	// 	if (isOpen && selectedItem) {
	// 		setOpen(true);
	// 	}
	// }, [selectedItem, isOpen]);

	useEffect(() => {
		if (isOpen) {
			setOpen(true);
		}
	}, [isOpen]);

	//* removed...
	// ${
	//     isSelected ? styles.active : undefined
	// }
	return (
		<>
			<div
				role="button"
				ref={accordionRef}
				className={`${styles.header} ${className}`} //* ...from here
				// onClick={() => onToggle(itemKey)}
				onClick={(e) => {
					if (e.target.closest(".p-radiobutton")) {
						return;
					}
					if (level !== 3) {
						setOpen((prevState) => !prevState);
						!open && onToggle && onToggle();
					}
				}}
				onKeyDown={(e) => {
					if (e.key === "Enter" || e.key === " ") {
						accordionRef.current.click();
					}
				}}
				tabIndex={0}
			>
				{LinkComponent}

				<div className={styles.right_side}>
					{radioMode && level !== 3 && (
						<RadioButton checked={isSelected} onChange={() => onRadioSelect(itemKey)} />
					)}

					{productsCount && <span className={productCountStyle}>{productsCount}</span>}
					{/* {showServicesWithProducts && (
						<span className={productCountStyle}>
							{product_number}
						</span>
					)} */}

					{(level === 1 || level === 2) && (
						<img
							src={
								isActiveService
									? arrowIcon
									: level === 2
									? arrowIconGray
									: arrowIcon
							}
							className={`${styles.arrow} ${open ? styles.rotate : undefined}`}
							alt=""
						/>
					)}
					{/* {button && button} */}
				</div>
			</div>
			{open && <div>{content}</div>}
		</>
	);
};

export default ServiceAccordionItem;
