import Tag from "components/Utils/SubComs/Tags/Tag";
import styles from "./NewProductSpecs.module.scss";

const NewProductSpecs = ({ product }) => {
	const {
		brand,
		modelNumber,
		length,
		lengthUnit,
		width,
		widthUnit,
		height,
		heightUnit,
		weight,
		weightUnit,
		materials,
		productColors,
	} = product || {};

	return (
		<div className={styles.specsGrid}>
			{(brand || modelNumber) && (
				<div className={styles.specGroup}>
					<h4>Brand Details</h4>

					<div className={styles.specContent}>
						{brand && (
							<div className={styles.specItem}>
								<span className={styles.label}>Brand:</span>
								<span className={styles.value}>{brand}</span>
							</div>
						)}
						{modelNumber && (
							<div className={styles.specItem}>
								<span className={styles.label}>
									Model Number:
								</span>
								<span className={styles.value}>
									{modelNumber}
								</span>
							</div>
						)}
					</div>
				</div>
			)}

			{(length > 0 || width > 0 || height > 0 || weight > 0) && (
				<div className={styles.specGroup}>
					<h4>
						{length === 0 &&
							width === 0 &&
							height === 0 &&
							weight > 0 &&
							"Weight"}
						{(length > 0 || width > 0 || height > 0) &&
							weight === 0 &&
							"Dimensions"}
						{(length > 0 || width > 0 || height > 0) &&
							weight > 0 &&
							"Dimensions & Weight"}
					</h4>

					<div className={styles.specContent}>
						{length > 0 && (
							<div className={styles.specItem}>
								<span className={styles.label}>Length:</span>
								<span
									className={styles.value}
								>{`${length} ${lengthUnit.toLowerCase()}`}</span>
							</div>
						)}
						{width > 0 && (
							<div className={styles.specItem}>
								<span className={styles.label}>Width:</span>
								<span
									className={styles.value}
								>{`${width} ${widthUnit.toLowerCase()}`}</span>
							</div>
						)}
						{height > 0 && (
							<div className={styles.specItem}>
								<span className={styles.label}>Height:</span>
								<span
									className={styles.value}
								>{`${height} ${heightUnit.toLowerCase()}`}</span>
							</div>
						)}

						{weight > 0 && (
							<div className={styles.specItem}>
								<span className={styles.label}>Weight:</span>
								<span
									className={styles.value}
								>{`${weight} ${weightUnit.toLowerCase()}`}</span>
							</div>
						)}
					</div>
				</div>
			)}

			{materials?.length > 0 && (
				<div className={styles.specGroup}>
					<h4>Materials</h4>

					<div className={styles.specContent}>
						<div className={styles.tagList}>
							{materials?.map((material) => (
								<Tag
									customStyle={styles.tag}
									key={material.uuid}
									itemName={material.name}
								/>
							))}
						</div>
					</div>
				</div>
			)}

			{productColors?.length > 0 && (
				<div className={styles.specGroup}>
					<h4>Available Colors</h4>

					<div className={styles.specContent}>
						<div className={styles.tagList}>
							{productColors?.map((color) => (
								<Tag
									key={color.uuid}
									customStyle={styles.tag}
									itemName={color.name}
								/>
							))}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default NewProductSpecs;
