import {
	useEffect,
	useMemo,
	// useState
} from "react";
import styles from "./SelectAdminModal.module.scss";
import LoadingSpinner from "../../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
// import ContactSearch from "../ContactSearch";
// import useDebounce from "../../../hooks/useDebounce";
import { useInfiniteQuery } from "@tanstack/react-query";
import useApi from "../../../../hooks/useApi";
import { useInView } from "react-intersection-observer";
import chatApi from "../../../../api/chat";
import GroupMember from "../../GroupMember";
import NewGaawkModal from "components/Utils/NewGaawkModal/NewGaawkModal";

const itemsPerPage = 50;

const SelectAdminModal = ({ show, onClose, groupId, onClick }) => {
	// const [searchQuery, setSearchQuery] = useState("");

	// const handleContactSearch = ({ target }) => {
	//     const query = target.value;
	//     setSearchQuery(query);
	// };

	// const debouncedSearch = useDebounce(searchQuery);

	const getParticipants = useApi(chatApi.getParticipants, true, true);

	const { ref, inView } = useInView({ triggerOnce: true });

	const fetchSearch = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, groupId] = queryKey;

		const response = await getParticipants.request(
			groupId,
			pageParam,
			itemsPerPage,
			"MEMBER"
			// searchInput
		);
		return response.data;
	};

	const { data, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery({
		queryKey: ["searchGroupMembers", groupId], //TODO >> need to be added to the query keys files
		queryFn: fetchSearch,
		getNextPageParam: (lastPage, pages) => {
			const nextPage = lastPage.length === itemsPerPage ? pages.length : undefined;
			return nextPage;
		},
		enabled: show,
	});

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage]);

	const contactList = useMemo(
		() =>
			data?.pages?.map((page) =>
				page.map((profile, i) => (
					<div
						key={profile.uuid}
						ref={page.length === i + 1 ? ref : null}
						className={styles.contact_item}
						onClick={() => onClick(profile)}
					>
						<GroupMember
							data={profile}
							isChatLeftSide={true}
							disableDecoration={true}
						/>
					</div>
				))
			),
		[data]
	);

	return (
		<NewGaawkModal
			visible={show}
			onHide={onClose}
			title={"Assign new admin"}
			children={
				<>
					{/* //TODO >> LATER NEED TO ADD THE SEARCH WHEN ADDED TO THE ENDPOINT BY WALEED */}
					{/* <div className={styles.group_search_wrapper}>
                <ContactSearch
                    searchInput={searchQuery}
                    onSearchInputChange={handleContactSearch}
                    onClear={() => setSearchQuery("")}
                />
            </div> */}
					<div className={styles.add_group_container}>
						<div className={styles.contact_list_wrapper}>
							{contactList}
							<LoadingSpinner visible={isFetching} />
						</div>
					</div>
				</>
			}
		/>
	);
};

export default SelectAdminModal;
