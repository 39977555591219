import ProfilePic from "components/Home/ProfilePic";
import routes from "components/Routing/routing-keys";
import useCurrentUser from "hooks/useCurrentUser";
import { ReactComponent as Boards } from "images/board.svg";
import { ReactComponent as Jobs } from "images/job.svg";
import { ReactComponent as Add } from "images/navbar-icons/add.svg";
import { ReactComponent as Bell } from "images/navbar-icons/bell.svg";
import { ReactComponent as Chat } from "images/navbar-icons/chat.svg";
import { ReactComponent as Home } from "images/navbar-icons/homepage.svg";
import { ReactComponent as Logout } from "images/navbar-icons/logout.svg";
import { ReactComponent as Search } from "images/navbar-icons/search.svg";
import { ReactComponent as Settings } from "images/navbar-icons/settings.svg";
import { ReactComponent as SwitchIcon } from "images/navbar-icons/switch.svg";
import { ReactComponent as Services } from "images/services.svg";
import { useRef, useState } from "react";
import styles from "./NavBar.module.scss";
import { Tooltip } from "primereact/tooltip";
import useLogout from "hooks/useLogout";
import { useLocation } from "react-router";
import Popover from "components/Utils/Popover/Popover";
import ProfileSwitch from "../BurgerMenu/ProfileSwitch";
import NavItem from "./NavItem";

const NavBar = ({ collapsed = false }) => {
	//TODO >> add a useImperativeHandle to collapse or expand from parent component ?
	const { handleLogout } = useLogout();
	const { pathname } = useLocation();

	const popoverRef = useRef(null);
	const [menuOpen, setMenuOpen] = useState(false);

	const [isCollapsed, setIsCollapsed] = useState(collapsed);

	const currentUser = useCurrentUser();
	const thumbnail = currentUser?.profileImage?.framedImageURL
		? currentUser.profileImage.framedImageURL
		: currentUser?.profileImage?.originalImageURL
		? currentUser.profileImage.originalImageURL
		: undefined;

	const menuItems = [
		{
			icon: <Home />,
			label: "Home",
			path: routes.root,
			isActive: pathname === routes.root,
		},
		{
			icon: <Search />,
			label: "Search",
			path: routes.search,
			isActive: pathname === routes.search,
		},
		{
			icon: <Add />,
			label: "Create",
			path: "/", //TODO >> should be an action instead of a path
			// isActive: pathname === routes.root //TODO
		},
		{
			icon: <Chat />,
			label: "Messages",
			path: routes.chat,
			isActive: pathname === routes.chat,
		},
		{
			icon: <Bell />,
			label: "Notifications",
			path: routes.notifications,
			isActive: pathname === routes.notifications,
		},
		{
			icon: <Services />,
			label: "Resources",
			path: routes.resources,
			isActive: pathname === routes.resources,
		},
		{
			icon: <Jobs />,
			label: "Jobs",
			path: routes.jobs,
			isActive: pathname === routes.jobs,
		},
		{
			icon: <Boards />,
			label: "Boards",
			path: routes.boards,
			isActive: pathname === routes.boards,
		},
		{
			icon: <Settings />,
			label: "Settings",
			path: routes.settings,
			isActive: pathname === routes.settings,
		},
	];

	return (
		<div
			className={`${styles.navBar} ${
				isCollapsed ? styles.collapsed : undefined
			}`}
		>
			{isCollapsed && (
				<Tooltip target="#target" mouseTrack mouseTrackLeft={10} />
			)}

			<Popover
				allow={[isCollapsed ? "right" : "bottom"]}
				onOpen={setMenuOpen}
				crossAxis={isCollapsed ? 360 : 0}
				ref={popoverRef}
				render={<ProfileSwitch isOpen={menuOpen} />}
			>
				<button
					className={`${styles.header} ${
						isCollapsed ? styles.collapsed : undefined
					}`}
				>
					<ProfilePic
						w={24}
						h={24}
						type={currentUser.type}
						noMarginRight={isCollapsed}
						thumbnail={thumbnail}
						name={
							currentUser.type === "USER"
								? `${currentUser.firstName} ${currentUser.lastName}`
								: currentUser.name
						}
						enableName={!isCollapsed}
					/>
				</button>
			</Popover>

			<nav className={styles.navigation}>
				{menuItems.map((item, index) => (
					<NavItem
						key={index}
						label={item.label}
						icon={item.icon}
						path={item.path}
						//TODO >> add action={} for the "create" button (doesn't have a route, but should show the create post modal)
						isCollapsed={isCollapsed}
						isActive={item.isActive}
					/>
				))}
			</nav>

			<div className={styles.footer}>
				<NavItem
					label={"Switch Account"}
					icon={<SwitchIcon />}
					action={() => alert("TODO")} //TODO
					isCollapsed={isCollapsed}
				/>

				<NavItem
					label={"Logout"}
					icon={<Logout />}
					action={handleLogout}
					isCollapsed={isCollapsed}
				/>
			</div>
		</div>
	);
};

export default NavBar;
