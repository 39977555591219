import { useEffect, useMemo, Fragment, useRef } from "react";
import styles from "./JobApplicants.module.scss";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import useApi from "hooks/useApi";
import jobApi from "api/job";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { jobsKeys } from "queryKeys/jobs-key-factory";
import useUrlParams from "hooks/useUrlParams";
import TabMenu from "components/Utils/SubComs/TabMenu/TabMenu";
import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
import useJob from "hooks/useJob";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import BackButton from "components/Utils/Button/BackButton";
import { ReactComponent as GaawkJobsLogo } from "images/gaawk-logos/gaawk-jobs.svg";
import { classNames } from "primereact/utils";
import ApplicantDetails from "./ApplicantDetails";
import { useCustomQuery } from "hooks/useCustomQuery";
import { cssValue, pluralize } from "components/Utils/General";
import GaawkButton from "components/Utils/Button/GaawkButton";
import { format } from "date-fns";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import GroupMember from "components/Chat/GroupMember";
import ContactLoader from "components/Utils/SubComs/CustomLoader/ContactLoader";
import { ReactComponent as RemoveIcon } from "images/remove.svg";
import { useQueryClient } from "@tanstack/react-query";
import useMutate from "hooks/useMutate";
import { Skeleton } from "primereact/skeleton";

const PAGE_SIZE = 20;

const STATUS_TYPE = {
	new: 1,
	shortlisted: 2,
	turned_down: 3,
};

const JobApplicants = () => {
	const queryClient = useQueryClient();

	const navigate = useNavigate();

	const isMobile = useSelector((state) => state.ui.isMobile);

	const applicationRef = useRef(null);

	// ! ======= Fetch Job ==========
	const { jobId } = useParams();

	const { data: job, isLoading: isLoadingJob, isSuccess: isSuccessJob } = useJob(jobId);

	useEffect(() => {
		if (isSuccessJob) trackEvent(eventsDictionary.JOB.APPLICANTS, { jobId: job.uuid });
	}, [isSuccessJob]);

	// ! ======= Fetch applications count ==========

	const getApplicantsCountApi = useApi(jobApi.getApplicantsCount, true, true);

	const fetchApplicationsCount = async ({ queryKey }) => {
		const [_, __, jobId] = queryKey;
		const response = await getApplicantsCountApi.request(jobId);
		return response.data;
	};

	const {
		data: applicationsCount,
		isLoading: isLoadingCount,
		isFetching: isFetchingApplicationsCount,
		isSuccess: isSuccessCount,
		// isError,
	} = useCustomQuery({
		queryKey: jobsKeys.applicationsCount(job?.uuid),
		queryFn: fetchApplicationsCount,
		enabled: isSuccessJob,
	});

	const { total, newCount, shortListedCount, turnedDownCount } = applicationsCount || {};

	// ! ======= TABS URL LOGIC ==========

	const paramConfigs = {
		statusTab: {
			validator: (tab) => ["new", "shortlisted", "turned_down"].includes(tab),
			defaultValue: "new",
		},
		applicationId: {
			validator: (id) => typeof id === "string",
			defaultValue: "",
		},
	};

	const { params, setParams } = useUrlParams(paramConfigs);
	const { statusTab, applicationId } = params;

	// ! ======= FETCH APPLICANTS ========

	const getApplicantsApi = useApi(jobApi.getApplicants, true, true);

	const fetchApplicants = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, jobId, statusId] = queryKey;

		const response = await getApplicantsApi.request(jobId, pageParam, PAGE_SIZE, statusId);
		return response.data;
	};

	const {
		items: applicants,
		viewRef,
		isFetchingNextPage,
		fetchStatus,
		isLoading,
	} = useInfiniteScroll({
		queryKey: jobsKeys.jobApplicants(job?.uuid, STATUS_TYPE[statusTab]),
		queryFn: fetchApplicants,
		pageSize: PAGE_SIZE,
		enabled: isSuccessJob,
	});

	const applicantsList = useMemo(() => {
		return applicants.map((application, index) => {
			const isLastItem = index === applicants.length - 1;
			const isActive = applicationId === application.uuid;

			return (
				<Fragment key={application.uuid}>
					<GroupMember
						ref={isLastItem ? viewRef : null}
						isActive={isActive}
						redirect={false}
						data={application.minifiedProfile}
						onClick={() => setParams({ applicationId: application.uuid })}
						customStyle={styles.applicant_item}
						showMutualConnections={false}
						showConnection={false}
						rightSideAction={
							application.status === "NEW" || application.status === "SHORTLISTED" ? (
								<button onClick={() => applicationRef.current.turnDownApplicant()}>
									<RemoveIcon fill={cssValue("--error200")} />
								</button>
							) : undefined
						}
					/>

					{isLastItem && isFetchingNextPage && <ContactLoader />}
				</Fragment>
			);
		});
	}, [applicants, viewRef, applicationId]);

	// ! ======= FETCH SINGLE APPLICANT ========

	const getApplicantApi = useApi(jobApi.getApplicant, true, true);

	const fetchApplicant = async ({ queryKey }) => {
		const [_, __, applicationId] = queryKey;
		const response = await getApplicantApi.request(applicationId);
		return response.data;
	};

	const {
		data: applicant,
		isLoading: isLoadingApplicant,
		isSuccess: isSuccessApplicant,
	} = useCustomQuery({
		queryKey: jobsKeys.applicant(applicationId),
		queryFn: fetchApplicant,
		enabled: !!applicationId,
	});

	useEffect(() => {
		if (isSuccessApplicant) {
			trackEvent(eventsDictionary.JOB.REVIEW_APPLICATION, {
				jobId: job?.uuid,
				applicationId,
			});
		}
	}, [isSuccessApplicant]);

	// !============= TURN DOWN ALL APPLICANTS ==================

	const handleInvalidate = () => {
		queryClient.invalidateQueries(jobsKeys.applicationsCount(job?.uuid));
		queryClient.invalidateQueries(jobsKeys.jobApplicants(job?.uuid, STATUS_TYPE[statusTab]));
	};

	const {
		action: { mutate: turnAllDown },
	} = useMutate(jobApi.updateAllApplicantsStatus, handleInvalidate);

	// !=========================================================

	const handleTabChange = (statusTab) => {
		setParams({ statusTab });
	};

	const handleBackButton = () => {
		if (isMobile && applicationId) {
			setParams({ applicationId: "" });
		} else {
			navigate(-1);
		}
	};

	return (
		<DefaultPage
			headerRightContent={
				<div className={styles.header_bar}>
					<BackButton onBack={handleBackButton} />
					{applicationId ? "Review Applicant" : "Applicants"}
				</div>
			}
			hideLeftSide={isMobile && applicationId}
			hideRightSide={isMobile && !applicationId}
			enableCollapsedNavBar
			hybridNavigation
			// disableScrollbar
			leftSideChildren={
				<div className={styles.left_side}>
					<div className={styles.menu}>
						<TabMenu
							menuItems={{
								new: "New Applicants",
								shortlisted: "Shortlisted",
								turned_down: "Turn Down",
							}}
							selectedTab={statusTab}
							onSelectedTabChanged={handleTabChange}
							customStyle={styles.menu_button}
						/>
					</div>

					<div className={styles.title_wrapper}>
						{isLoadingCount && (
							<>
								<Skeleton height={"2rem"}></Skeleton>
								<Skeleton width={"150px"} height={"2rem"}></Skeleton>
							</>
						)}

						{!isLoadingCount && isSuccessCount && (
							<>
								<h3>
									{`${
										statusTab === "new"
											? newCount
											: statusTab === "shortlisted"
											? shortListedCount
											: turnedDownCount
									} ${pluralize(
										statusTab === "new"
											? newCount
											: statusTab === "shortlisted"
											? shortListedCount
											: turnedDownCount,
										"Applicant"
									)}`}
								</h3>

								{statusTab === "new" && (
									<GaawkButton
										severity={"tertiary"}
										size={"small"}
										text="Turn Down All"
										disabled={newCount === 0}
										onClick={() => turnAllDown(job?.uuid)}
									/>
								)}
							</>
						)}
					</div>

					<div className={styles.applicants_container}>
						{fetchStatus !== "idle" && isLoading && (
							<>
								<ContactLoader />
								<ContactLoader />
							</>
						)}

						{applicants.length > 0 && !isLoading && applicantsList}
						<NoResults
							text={"Nothing to show here yet!"}
							visible={
								!isFetchingApplicationsCount &&
								((statusTab === "new" && newCount === 0) ||
									(statusTab === "shortlisted" && shortListedCount === 0) ||
									(statusTab === "turned_down" && turnedDownCount === 0))
							}
						/>
					</div>
				</div>
			}
			rightSideChildren={
				<div className={styles.container}>
					<div
						className={classNames(styles.right_side, {
							[styles.align_middle]: !applicationId,
						})}
					>
						{isLoadingJob && (
							<div className={styles.job_container}>
								<Skeleton width={"100%"} height={"2rem"}></Skeleton>
								<Skeleton width={"50%"} height={"2rem"}></Skeleton>
							</div>
						)}

						{job && (
							<div className={styles.job_container}>
								<div className={styles.job_name_wrapper}>
									<h3>{job?.name}</h3>
									{job?.project && <p>{`Project: ${job?.project?.title}`}</p>}
								</div>

								<div className={styles.job_date}>
									<p>
										Posted on:{" "}
										{format(new Date(job?.insertionTime), "dd/MM/yyyy")}
										{applicationsCount &&
											` | ${total} ${pluralize(total, "Applicant")}`}
									</p>
								</div>
							</div>
						)}

						<div
							className={classNames(styles.applicant_details, {
								[styles.logo_container]: !applicationId,
							})}
						>
							{applicationId ? (
								<ApplicantDetails
									ref={applicationRef}
									applicant={applicant}
									isLoading={isLoadingApplicant}
									onInvalidate={handleInvalidate}
								/>
							) : (
								<GaawkJobsLogo />
							)}
						</div>
					</div>
				</div>
			}
		/>
	);
};

export default JobApplicants;
