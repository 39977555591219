import { useState } from "react";
import LazyImage from "components/Utils/LazyImage/LazyImage";
import styles from "./LandingPageHeader.module.scss";
import GaawkLink from "components/Utils/SubComs/GaawkLink/GaawkLink";

const LandingPageHeader = () => {
	// states
	const [menu, setMenu] = useState(false);

	// JSX
	return (
		<div className={styles.container}>
			<LazyImage
				inlineStyle={styles.backgroundImage}
				image={
					<img
						src={
							"https://gigshack.s3.ap-southeast-1.amazonaws.com/system/gaawk_feature_1.jpg"
						}
						alt={"headerImage"}
					/>
				}
			/>

			<div className={styles.wrapper}>
				<div className={styles.topSection}>
					<img
						className={styles.gaawkIcon}
						src={require("images/gaawk-logo-79-x-25.svg").default}
						alt={"icon"}
					/>
					<div className={styles.buttonView}>
						<GaawkLink
							disableDecoration={true}
							to={"/login"}
							customStyle={styles.loginText}
							tabIndex={1}
						>
							SIGN UP / SIGN IN
						</GaawkLink>
						<GaawkLink
							disableDecoration={true}
							tabIndex={1}
							customStyle={styles.button}
						>
							<span className={styles.buttonText}>DOWNLOAD APP</span>
						</GaawkLink>
						<img
							onClick={() => setMenu(true)}
							className={styles.menuImg}
							src={require("images/landing_menu.svg").default}
							alt={"menu"}
						/>
					</div>
				</div>
				<div className={styles.bottomSection}>
					<img
						className={styles.bottomSectionTitle}
						src={require("images/forCreators.svg").default}
						alt={"for creators"}
					/>
					<span className={styles.bottomSectionSubTitle}>
						Connecting the world’s thinkers, planners, builders, and creators.
					</span>
					<img
						className={styles.bottomSectionIcon}
						src={require("images/mouse.svg").default}
						alt={"scroll"}
					/>
				</div>
			</div>
			{menu && (
				<div className={styles.menuContainer}>
					<div className={styles.menuWrapper}>
						<div className={styles.menuIcons}>
							<img
								className={styles.menuIconsGaawk}
								src={require("images/gaawk-logo-79-x-25-black-text.svg").default}
								alt={"gaawk"}
							/>
							<img
								onClick={() => setMenu(false)}
								className={styles.menuIconsClose}
								src={require("images/close.svg").default}
								alt={"close"}
							/>
						</div>
						<div className={styles.menuItem}>
							<GaawkLink
								disableDecoration={true}
								to={"/login"}
								customStyle={styles.menuItemText}
								tabIndex={1}
							>
								<span className={styles.menuItemText}>Sign Up / Sign in</span>
							</GaawkLink>
						</div>
						<div className={styles.menuItem}>
							<GaawkLink
								disableDecoration={true}
								to={"#"}
								customStyle={styles.menuItemText}
								tabIndex={2}
							>
								<span className={styles.menuItemText}>Download app - Android</span>
							</GaawkLink>
						</div>
						<div className={styles.menuItem} style={{ borderBottom: "none" }}>
							<GaawkLink
								disableDecoration={true}
								to={"#"}
								customStyle={styles.menuItemText}
								tabIndex={3}
							>
								<span className={styles.menuItemText}>Download app - IOS</span>
							</GaawkLink>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default LandingPageHeader;
