// import { useQueryClient } from "@tanstack/react-query";
// import projectApi from "api/project";
import routes from "components/Routing/routing-keys";
import WarningModal from "components/Utils/GaawkModal/WarningModal";
import Popover from "components/Utils/Popover/Popover";
// import useCurrentUser from "hooks/useCurrentUser";
// import useMutate from "hooks/useMutate";
import { ReactComponent as AddIcon } from "images/add-icon.svg";
import archiveIcon from "images/archive.svg";
import deleteIcon from "images/delete.svg";
import editIcon from "images/edit.svg";
import { ReactComponent as DotsIcon } from "images/dots.svg";
// import { classNames } from "primereact/utils";
// import { jobsKeys } from "queryKeys/jobs-key-factory";
// import { projectKeys } from "queryKeys/projects-key-factory";
import {
	useRef,
	//  useState
} from "react";
import {
	// useLocation,
	useNavigate,
} from "react-router-dom";
import styles from "./ProjectActions.module.scss";
import useUpdateProjectStatus from "hooks/useUpdateProjectStatus";
import useDeleteProject from "hooks/useDeleteProject";

const ProjectActions = ({ project, onDeleteProject }) => {
	const { uuid, active } = project;

	const navigate = useNavigate();
	const popoverRef = useRef(null);

	const handleAddProjectJob = (jobType) => {
		navigate(routes.jobCreate, {
			state: { jobType, project },
		});
	};

	// * toggle project status =========

	const toggleProjectStatus = useUpdateProjectStatus(project);

	// * Delete project ================

	const { handleRequestMutate, handleConfirmMutate, setWarningModal, warningModal } =
		useDeleteProject(project, onDeleteProject);

	return (
		<>
			<Popover
				ref={popoverRef}
				render={
					<div className="popover_container">
						<button
							className="popover_item"
							onClick={(e) => {
								e.stopPropagation();
								navigate(routes.projectEdit(uuid));
								popoverRef.current.closePopover();
							}}
						>
							<span>
								<img src={editIcon} alt="edit" />
							</span>
							<span>Edit Project</span>
						</button>

						<button
							className="popover_item"
							onClick={(e) => {
								e.stopPropagation();
								toggleProjectStatus({
									uuid,
									status: !active,
								});
								popoverRef.current.closePopover();
							}}
						>
							<span>
								<img
									src={archiveIcon}
									alt={`mark as ${active ? "Past" : "Active"}`}
								/>
							</span>
							<span>Mark Project as {active ? `"Past"` : `"Active"`}</span>
						</button>

						<button
							className="popover_item"
							onClick={(e) => {
								e.stopPropagation();
								handleAddProjectJob("Long_Term");
								popoverRef.current.closePopover();
							}}
						>
							<span>
								<AddIcon width={14} height={14} fill={"#000"} />
							</span>
							<span>Add Job to Project</span>
						</button>

						<button
							className="popover_item"
							onClick={(e) => {
								e.stopPropagation();
								handleAddProjectJob("Casting_Call");
								popoverRef.current.closePopover();
							}}
						>
							<span>
								<AddIcon width={14} height={14} fill={"#000"} />
							</span>
							<span>Add Casting Call to Project</span>
						</button>

						<button
							className="popover_item"
							onClick={(e) => {
								e.stopPropagation();
								handleAddProjectJob("Freelance");
								popoverRef.current.closePopover();
							}}
						>
							<span>
								<AddIcon width={14} height={14} fill={"#000"} />
							</span>
							<span>Add Freelance Job to Project</span>
						</button>

						<button
							className="popover_item"
							onClick={(e) => {
								e.stopPropagation();
								handleRequestMutate();
								popoverRef.current.closePopover();
							}}
						>
							<span>
								<img src={deleteIcon} alt="delete" />
							</span>
							<span>Delete Project</span>
						</button>
					</div>
				}
			>
				<button onClick={(e) => e.stopPropagation()} className={styles.option_btn}>
					<DotsIcon />
				</button>
			</Popover>

			<WarningModal
				show={warningModal}
				headerText="Are you sure you want to delete this project? All associated roles and casting calls will be deleted as well. This action can NOT be undone!"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={(e) => {
					e.stopPropagation();
					setWarningModal(false);
				}}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={(e) => {
					e.stopPropagation();
					handleConfirmMutate(uuid);
				}}
			/>
		</>
	);
};

export default ProjectActions;
