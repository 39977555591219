import styles from "./DeleteItemModal.module.css";
import GaawkButton from "../Button/GaawkButton";
import NewGaawkModal from "../NewGaawkModal/NewGaawkModal";

const DeleteItemModal = ({ show, onClose, text, title, onConfirm, isLoading }) => {
	return (
		<NewGaawkModal
			visible={show}
			onHide={onClose}
			title={title}
			children={
				<>
					<div className={styles.warning_container}>{text}</div>

					<div className={styles.button_container}>
						<GaawkButton
							severity={"tertiary"}
							text={"Cancel"}
							type="button"
							onClick={onClose}
						/>
						<GaawkButton
							severity={"danger"}
							text={"Remove"}
							type="button"
							onClick={onConfirm}
							isLoading={isLoading}
						/>
					</div>
				</>
			}
		/>
	);
};

export default DeleteItemModal;
