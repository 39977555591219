import ReactDOM from "react-dom/client";
import "./index.scss";
import "./bootstrap-style.css"; //* cf <NotificationPrompt /> component
import App from "./App";
// import { BrowserRouter } from "react-router-dom";
import createAppStore from "./store/configureStore";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { queryClient } from "./react-query-config";
// import "./firebase"; //* commented
import { initAmplitude } from "analytics/amplitude-config";

const store = createAppStore();
export const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));

initAmplitude();

root.render(
	<QueryClientProvider client={queryClient}>
		{/* <BrowserRouter> */}
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
				{/* <ReactQueryDevtools initialIsOpen={false} /> */}
			</PersistGate>
		</Provider>
		{/* </BrowserRouter> */}
	</QueryClientProvider>
);
