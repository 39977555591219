import useDebounce from "hooks/useDebounce";
import styles from "./ResourceSearchView.module.scss";
import useApi from "hooks/useApi";
import servicesApi from "api/services";
import { servicesKeys } from "queryKeys/services-key-factory";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import {
	forwardRef,
	Fragment,
	useEffect,
	useImperativeHandle,
	useMemo,
} from "react";
import ServiceCard from "../ServiceCard/ServiceCard";
import routes from "components/Routing/routing-keys";
import { useNavigate } from "react-router-dom";
import ServiceCardLoader from "components/Utils/SubComs/CustomLoader/ServiceCardLoader";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import { useDispatch, useSelector } from "react-redux";
import { resetProductsFilters } from "store/slices/filters";
import { searchKeys } from "queryKeys/search-key-factory";
import { formatFiltersValues } from "components/Utils/General";
import searchApi from "api/search";
import ProductLoader from "components/Utils/SubComs/CustomLoader/ProductLoader";
import NewProductItem from "components/Profile/ProductsTab/ProductsComponents/NewProductItem";
import { useQueryClient } from "@tanstack/react-query";

const PAGE_SIZE = 20;

//* note: if the service is provided in props, we will search for services with products ortherwise it will search for all services

const ResourceSearchView = forwardRef(
	(
		{
			searchInput,
			requestedProfileId = "",
			displayOptions = { services: true, products: true },
			servicesTitle = "Sub-Categories",
			productsTitle = "Products",
			isProfilePage = false,
			service,
			isUncategorised = false,
		},
		ref
	) => {
		const dispatch = useDispatch();
		const debouncedSearch = useDebounce(searchInput);
		const navigate = useNavigate();
		const queryClient = useQueryClient();

		// Show artificial loading when user is typing but debounce hasn't triggered yet
		const isWaitingForDebounce =
			searchInput && searchInput !== debouncedSearch;

		useImperativeHandle(ref, () => ({
			invalidate() {
				queryClient.invalidateQueries(
					servicesKeys.search(requestedProfileId, debouncedSearch)
				);
				queryClient.invalidateQueries(
					searchKeys.products(storedProductsFilters)
				);
			},
		}));

		// ! ======= SERVICES WITH PRODUCTS SEARCH ======
		const getServicesApi = useApi(
			servicesApi.getServicesWithProducts,
			true,
			true
		);

		const fetchServicesWithProducts = async ({
			pageParam = 0,
			queryKey,
		}) => {
			const [_, __, level, userId, parentId, ___, q] = queryKey;

			const response = await getServicesApi.request(
				userId,
				pageParam,
				PAGE_SIZE,
				undefined, //* numberOfProducts param not needed
				parentId,
				level,
				q
			);
			return response.data;
		};

		const {
			items: servicesWithProducts,
			isLoading: isLoadingServicesWithProducts,
			fetchStatus: servicesWithProductsFetchStatus,
			hasNextPage: servicesWithProductsHasNextPage,
			loadMore: loadMoreServicesWithProducts,
			isFetchingNextPage: servicesWithProductsIsFetchingNextPage,
		} = useInfiniteScroll({
			queryKey: servicesKeys.serviceLvl(
				service?.level + 1,
				requestedProfileId,
				service?.uuid,
				true,
				debouncedSearch
			),
			queryFn: fetchServicesWithProducts,
			pageSize: PAGE_SIZE,
			enabled: !!service && service?.level < 3, //* only show services with products if the service is level 1 or 2
		});

		const isFetchingServicesWithProducts =
			servicesWithProductsFetchStatus === "fetching" ||
			(servicesWithProductsFetchStatus !== "idle" &&
				isLoadingServicesWithProducts);

		const shouldShowServicesWithProductsResults =
			!isFetchingServicesWithProducts && !isWaitingForDebounce;

		const showNoResultsServicesWithProducts =
			debouncedSearch &&
			servicesWithProducts.length === 0 &&
			shouldShowServicesWithProductsResults &&
			displayOptions.services;

		const servicesWithProductsList = useMemo(() => {
			return servicesWithProducts?.map((service) => (
				<ServiceCard
					key={service.uuid}
					data={service}
					onClick={() =>
						navigate(
							`${routes.serviceTag(service.tag)}${
								isProfilePage
									? "?from=profile"
									: "?from=resources"
							}`
						)
					}
					className={styles.custom_card}
				/>
			));
		}, [servicesWithProducts, isProfilePage]);

		// ! ======= ALL SERVICES SEARCH ======

		const searchServicesApi = useApi(
			servicesApi.searchServices,
			true,
			true
		);

		const searchServices = async ({ pageParam = 0, signal, queryKey }) => {
			const [, , , userId, query] = queryKey;
			const response = await searchServicesApi.request(
				pageParam,
				PAGE_SIZE,
				query,
				userId
			);
			return response.data;
		};

		const {
			items: searchedServices,
			isLoading,
			fetchStatus,
			hasNextPage,
			loadMore,
			isFetchingNextPage,
		} = useInfiniteScroll({
			queryKey: servicesKeys.search(requestedProfileId, debouncedSearch),
			queryFn: searchServices,
			pageSize: PAGE_SIZE,
			enabled: !!debouncedSearch && displayOptions.services && !service,
		});

		const isFetchingServices =
			fetchStatus === "fetching" || (fetchStatus !== "idle" && isLoading);

		const shouldShowServicesResults =
			!isFetchingServices && !isWaitingForDebounce;

		// Only show no results when we have a search query, no results, and we're not in any loading state
		const showNoResultsServices =
			debouncedSearch &&
			searchedServices.length === 0 &&
			shouldShowServicesResults &&
			displayOptions.services;

		const searchServicesList = useMemo(() => {
			return searchedServices?.map((service) => (
				<Fragment key={service.uuid}>
					<ServiceCard
						showOwnerThumbnail={!isProfilePage}
						data={service}
						onClick={() =>
							navigate(
								`${routes.serviceTag(service.tag)}${
									isProfilePage
										? "?from=profile"
										: "?from=resources"
								}`
							)
						}
						className={styles.custom_card}
					/>
				</Fragment>
			));
		}, [searchedServices, isProfilePage, isProfilePage]);

		// ! ======= PRODUCTS SEARCH ======

		useEffect(() => {
			return () => {
				//CLEARS JOBS SEARCH FILTERS WHEN COMPONENT UNMOUNTS
				dispatch(resetProductsFilters());
			};
		}, []);

		const productsFiltersObject = useSelector(
			(state) => state.filters.productsFilters
		);

		const storedProductsFilters = {
			...productsFiltersObject,
			q: debouncedSearch,
			ownerIds: [`${requestedProfileId}`],
			productFilters: {
				...productsFiltersObject.productFilters,
				onlyUncategorizedProducts: isUncategorised,
				serviceTags: service && !isUncategorised ? [service.uuid] : [],
			},
		};

		const searchProductsApi = useApi(searchApi.searchProducts, true, true);

		const fetchProducts = async ({ queryKey, pageParam = 0 }) => {
			const [_, __, filters] = queryKey;

			const response = await searchProductsApi.request(
				pageParam,
				PAGE_SIZE,
				formatFiltersValues(filters, ["ownerIds", "serviceTags"])
			);
			return response.data;
		};

		const {
			items: searchProducts,
			isFetchingNextPage: isFetchingNextProducts,
			viewRef,
			isLoading: isLoadingProducts,
			fetchStatus: productsFetchStatus,
		} = useInfiniteScroll({
			queryKey: searchKeys.products(storedProductsFilters),
			queryFn: fetchProducts,
			pageSize: PAGE_SIZE,
			enabled:
				(!!debouncedSearch && displayOptions.products) ||
				!!service ||
				(isUncategorised && !!requestedProfileId),
			queryOptions: {
				getNextPageParam: (lastPage, pages) => {
					const nextPage =
						lastPage.list?.length === PAGE_SIZE
							? pages.length
							: undefined;
					return nextPage;
				},
			},
		});

		const searchProductsData = searchProducts?.flatMap((page) => page.list);

		const isFetchingProducts =
			productsFetchStatus === "fetching" ||
			(productsFetchStatus !== "idle" && isLoadingProducts);

		const shouldShowProductsResults =
			!isFetchingProducts && !isWaitingForDebounce;

		const showNoResultsProducts =
			debouncedSearch &&
			searchProductsData.length === 0 &&
			shouldShowProductsResults &&
			displayOptions.products;

		const productsList = useMemo(() => {
			return searchProductsData?.map((product, index) => {
				const isLastItem = index === searchProductsData.length - 1;

				return (
					<Fragment key={product.uuid}>
						<NewProductItem
							ref={isLastItem ? viewRef : null}
							product={product}
							showOwnerThumbnail={!isProfilePage}
						/>

						{isLastItem && isFetchingNextProducts && (
							<ProductLoader />
						)}
					</Fragment>
				);
			});
		}, [
			searchProductsData,
			viewRef,
			isFetchingNextProducts,
			isProfilePage,
		]);

		// !===============================

		if (!searchInput && !service && !isUncategorised) return null;

		return (
			<div className={styles.container}>
				{displayOptions.services && (
					<>
						{service ? (
							<>
								{servicesWithProducts.length === 0 &&
								shouldShowServicesWithProductsResults ? (
									<>
										{debouncedSearch ? (
											<>
												{servicesTitle && (
													<h3>{servicesTitle}</h3>
												)}
												<NoResults
													text="Oh Oh! Nothing matches your search criteria..."
													visible={true}
												/>
											</>
										) : null}
									</>
								) : (
									<div>
										<div
											className={styles.services_wrapper}
										>
											{servicesTitle && (
												<h3>{servicesTitle}</h3>
											)}
											<div
												className={
													styles.services_container
												}
											>
												{shouldShowServicesWithProductsResults &&
													servicesWithProductsList}

												{(servicesWithProductsIsFetchingNextPage ||
													isFetchingServicesWithProducts ||
													isWaitingForDebounce) && (
													<>
														<ServiceCardLoader
															className={
																styles.custom_card
															}
														/>
														<ServiceCardLoader
															className={
																styles.custom_card
															}
														/>
													</>
												)}
											</div>
											{servicesWithProductsHasNextPage && (
												<div
													className={
														styles.gradient_overlay
													}
												/>
											)}
										</div>

										<NoResults
											text="Oh Oh! Nothing matches your search criteria..."
											visible={
												showNoResultsServicesWithProducts
											}
										/>

										{servicesWithProductsHasNextPage && (
											<SeeMore
												onClick={
													loadMoreServicesWithProducts
												}
												horizontal={false}
												text="Show more sub-categories"
											/>
										)}
									</div>
								)}
							</>
						) : (
							<div>
								<div className={styles.services_wrapper}>
									{servicesTitle && <h3>{servicesTitle}</h3>}
									<div className={styles.services_container}>
										{shouldShowServicesResults &&
											searchServicesList}

										{(isFetchingNextPage ||
											isFetchingServices ||
											isWaitingForDebounce) && (
											<>
												<ServiceCardLoader
													className={
														styles.custom_card
													}
												/>
												<ServiceCardLoader
													className={
														styles.custom_card
													}
												/>
											</>
										)}
									</div>
									{hasNextPage && (
										<div
											className={styles.gradient_overlay}
										/>
									)}
								</div>

								<NoResults
									text="Oh Oh! Nothing matches your search criteria..."
									visible={showNoResultsServices}
								/>

								{hasNextPage && (
									<SeeMore
										onClick={loadMore}
										horizontal={false}
										text="Show more sub-categories"
									/>
								)}
							</div>
						)}
					</>
				)}

				{displayOptions.products && (
					<div className={styles.products_wrapper}>
						{productsTitle && <h3>{productsTitle}</h3>}
						<div className={styles.products_container}>
							{shouldShowProductsResults && productsList}
							{(isFetchingNextProducts ||
								isFetchingProducts ||
								isWaitingForDebounce) && (
								<>
									<ProductLoader />
									<ProductLoader />
								</>
							)}
						</div>
						<NoResults
							text="Oh Oh! Nothing matches your search criteria..."
							visible={showNoResultsProducts}
						/>
					</div>
				)}
			</div>
		);
	}
);

export default ResourceSearchView;
