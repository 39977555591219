import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
import NewProductSpecs from "../ProductsComponents/NewProductSpecs";
import styles from "./NewProductDetails.module.scss";
import BackButton from "components/Utils/Button/BackButton";
import { Skeleton } from "primereact/skeleton";
import { useNavigate, useParams } from "react-router-dom";
import { useCustomQuery } from "hooks/useCustomQuery";
import { productsKeys } from "queryKeys/products-key-factory";
import useApi from "hooks/useApi";
import productApi from "api/product";
import ProfilePic from "components/Home/ProfilePic";
import GaawkButton from "components/Utils/Button/GaawkButton";
import useCurrentUser from "hooks/useCurrentUser";
import RoundButton from "components/Utils/Button/RoundButton";
import { setProduct, setModalProduct } from "store/slices/products";
import { useDispatch } from "react-redux";
import useDirectMessage from "hooks/useDirectMessage";
import ProductEntry from "../ProductEntry/ProductEntry";
import { useProductMedia } from "hooks/useProductMedia";
import { Fragment, useMemo, useRef, useState, useEffect } from "react";
import ProductMediaSlider from "components/Utils/ProductMediaSlider/ProductMediaSlider";
import ReadMore from "components/Utils/SubComs/ReadMore/ReadMore";
import TagContainer from "components/Utils/SubComs/Tags/TagContainer";
import Tag from "components/Utils/SubComs/Tags/Tag";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import NewProductItem from "../ProductsComponents/NewProductItem";
import ProductLoader from "components/Utils/SubComs/CustomLoader/ProductLoader";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import ProductPopover from "../ProductsComponents/ProductPopover";
import ReportModal from "components/Utils/ReportModal/ReportModal";
import WarningModal from "components/Utils/GaawkModal/WarningModal";
import useMutate from "hooks/useMutate";
import { vaultKeys } from "queryKeys/vault-key-factory";
import { useQueryClient } from "@tanstack/react-query";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import NavBar from "components/Utils/SubComs/NavBar/NavBar";
import NewFullscreenModal from "components/Utils/NewFullscreenModal/NewFullscreenModal";
import FileViewer from "components/Utils/FileViewer/FileViewer";
import { viewerMapper } from "components/Utils/General";
import HeaderBar from "components/Utils/SubComs/HeaderBar/HeaderBar";
import { ReactComponent as FullscreenIcon } from "images/fullscreen.svg";
import ContextHeader from "components/Utils/SubComs/ContextHeader/ContextHeader";
import { classNames } from "primereact/utils";
import routes from "components/Routing/routing-keys";

const PAGE_SIZE = 50;

const NewProductDetails = ({ isPanel, product: productProp, onClosePanel }) => {
	const fileviewerRef = useRef(null);
	const navigate = useNavigate();
	const { productId: urlProductId } = useParams();
	const { uuid: userId } = useCurrentUser();
	const dispatch = useDispatch();
	const { handleDirectMessage } = useDirectMessage();
	const reportRef = useRef();
	const queryClient = useQueryClient();
	const [currentProductId, setCurrentProductId] = useState(urlProductId);
	const [panelProduct, setPanelProduct] = useState(productProp);

	const getProductByIdApi = useApi(productApi.getProductById, true, true);

	const fetchProduct = async ({ queryKey }) => {
		const [, , productId] = queryKey;
		const response = await getProductByIdApi.request(productId);
		return response.data;
	};

	const { data: product, isLoading } = useCustomQuery({
		queryKey: productsKeys.detail(
			isPanel ? currentProductId : urlProductId
		),
		queryFn: fetchProduct,
		enabled: isPanel
			? !!currentProductId && currentProductId !== productProp?.uuid
			: true,
	});

	useEffect(() => {
		if (isPanel) {
			if (product) {
				setPanelProduct(product);
			} else if (productProp) {
				setPanelProduct(productProp);
			}
		}
	}, [product, productProp, isPanel]);

	const {
		name,
		description,
		owner,
		mainImage,
		mediaCount,
		worldWide,
		locations,
		taggedServices,
		uuid,
	} = isPanel ? panelProduct || {} : product || {};

	const servicesTagsList = useMemo(
		() =>
			taggedServices?.map((service, index) => (
				<Tag key={service.uuid} itemName={service.tag} />
			)),
		[taggedServices]
	);

	const isProductOwner = owner?.uuid === userId;

	// ! ========= DELETE PRODUCT  =========

	const {
		warningModal,
		setWarningModal,
		handleRequestMutate,
		handleConfirmMutate,
		activeItem,
	} = useMutate(
		productApi.deleteProduct,
		() => {
			queryClient.invalidateQueries(vaultKeys.storage());
			trackEvent(eventsDictionary.PRODUCT.DELETE_PRODUCT);
			navigate(-1);
		},
		undefined,
		{
			meta: {
				successMessage: "Product deleted successfully",
			},
		}
	);

	// ! ========= PRODUCT MEDIA =========

	const { data: productMedia = [] } = useProductMedia(uuid, mediaCount > 0);

	const mediaList = useMemo(
		() => [
			{ url: mainImage?.file?.url, alt: mainImage?.file?.name },
			...productMedia?.map((item) => ({
				url: item?.file?.url,
				alt: item?.name,
			})),
		],
		[mainImage, productMedia]
	);

	// ! ========= SUGGESTED PRODUCTS =========

	const getSuggestedProductsApi = useApi(
		productApi.getSuggestedProducts,
		true,
		true
	);

	const fetchSuggestedProducts = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, ___, productId] = queryKey;
		const response = await getSuggestedProductsApi.request(
			pageParam,
			PAGE_SIZE,
			productId
		);
		return response.data;
	};

	const {
		items: suggestedProducts,
		isLoading: isLoadingSuggestedProducts,
		viewRef,
		isFetchingNextPage,
	} = useInfiniteScroll({
		queryKey: productsKeys.suggestedList(uuid),
		queryFn: fetchSuggestedProducts,
		pageSize: 50,
		enabled: !!uuid,
	});

	const handleProductClick = (suggestedProduct) => {
		if (isPanel) {
			setCurrentProductId(suggestedProduct.uuid);
		} else {
			navigate(routes.productDetails(suggestedProduct.uuid));
		}
	};

	const suggestedProductsList = useMemo(() => {
		return suggestedProducts?.map((product, index) => {
			const isLastItem = index === suggestedProducts.length - 1;

			return (
				<Fragment key={product.uuid}>
					<NewProductItem
						ref={isLastItem ? viewRef : null}
						product={product}
						onClick={() => handleProductClick(product)}
					/>

					{isLastItem && isFetchingNextPage && <ProductLoader />}
				</Fragment>
			);
		});
	}, [suggestedProducts, viewRef, isFetchingNextPage, isPanel]);

	// ! ========= EDIT PRODUCT =========

	const handleEditProduct = () => {
		dispatch(setProduct(product));
		dispatch(setModalProduct(true));
	};

	const ProductDetailsLoadingView = () => {
		//TODO >> need to also make the skeleton adapts to mobile screen / panel view

		return (
			<div className={styles.skeleton_container}>
				<div className={styles.skeleton_header}>
					<div className={styles.skeleton_header_left}>
						<div className={styles.user_skeleton}>
							<Skeleton shape="circle" size={25}></Skeleton>
							<Skeleton width={150}></Skeleton>
						</div>
						<Skeleton width={300}></Skeleton>
						<Skeleton width={200}></Skeleton>
					</div>

					<Skeleton width={150} height={38}></Skeleton>
				</div>

				<div className={styles.skeleton_wrapper}>
					<Skeleton
						pt={{
							root: {
								style: { flexShrink: "0" },
							},
						}}
						size="472px"
						borderRadius={24}
					></Skeleton>
					<div className={styles.skeleton_container_content}>
						<Skeleton width={"100%"}></Skeleton>
						<Skeleton width={"90%"}></Skeleton>
						<Skeleton width={"80%"}></Skeleton>
						<Skeleton width={"85%"}></Skeleton>
						<Skeleton width={"70%"}></Skeleton>
						<Skeleton width={"100%"}></Skeleton>
						<Skeleton width={"90%"}></Skeleton>
						<Skeleton width={"80%"}></Skeleton>
						<Skeleton width={"85%"}></Skeleton>
						<Skeleton width={"70%"}></Skeleton>
					</div>
				</div>
			</div>
		);
	};

	// ! ==== FULLSCREEN SLIDER =====
	const media = useMemo(
		() => [mainImage, ...productMedia].filter(Boolean),
		[mainImage, productMedia]
	);

	const formattedMediaList = useMemo(() => viewerMapper(media), [media]);

	const [showSlider, setShowSlider] = useState(false);
	const [initialSlideIndex, setInitialSlideIndex] = useState(0);

	const handleShowSlider = (index) => {
		setInitialSlideIndex(index);
		setShowSlider(true);
	};

	const content =
		!isPanel && isLoading ? (
			<ProductDetailsLoadingView />
		) : (
			<div className={styles.content}>
				<div
					className={classNames(styles.product_details, {
						[styles.isPanel]: isPanel,
					})}
				>
					<div className={styles.left}>
						{isPanel && (
							<RoundButton
								icon={"close"}
								onClick={onClosePanel}
								className={styles.close_panel_btn}
							/>
						)}
						<ProductMediaSlider
							files={mediaList}
							className={styles.product_media_slider}
							onMainImageClick={handleShowSlider}
						/>
					</div>
					<div className={styles.right}>
						<div className={styles.product_header}>
							<div className={styles.product_header_left}>
								<ProfilePic
									thumbnail={owner?.profileImage?.image}
									type={owner?.type}
									name={owner?.name}
									w={25}
									h={25}
									nameClassName={styles.owner_name}
								/>
								<h3>{name}</h3>
							</div>

							{isProductOwner && !isPanel ? (
								<RoundButton
									icon="edit"
									onClick={handleEditProduct}
								/>
							) : (
								<GaawkButton
									text={
										isPanel ? "Check this out" : "Enquire"
									}
									className={styles.enquire_button}
									onClick={() =>
										isPanel
											? navigate(
													routes.productDetails(uuid)
											  )
											: handleDirectMessage(owner)
									}
								/>
							)}
						</div>

						<div>
							<ReadMore
								lines={3}
								customStyle={styles.description}
							>
								{description}
							</ReadMore>
						</div>

						<p className={styles.product_location}>
							Available: {worldWide && "Worldwide"}
							{locations
								.map((location) => location.name)
								.join(", ")}
						</p>

						<NewProductSpecs
							product={isPanel ? panelProduct : product}
						/>

						{taggedServices?.length > 0 && (
							<TagContainer
								label="Categories Tagged"
								items={servicesTagsList}
								scrollToBottomEnabled={false}
							/>
						)}
					</div>
				</div>

				<div
					className={classNames(styles.products_wrapper, {
						[styles.isPanel]: isPanel,
					})}
				>
					<h3>Similar Products</h3>

					<div className={styles.products_container}>
						{isLoadingSuggestedProducts && (
							<>
								<ProductLoader />
								<ProductLoader />
								<ProductLoader />
							</>
						)}

						{suggestedProducts.length > 0 &&
							!isLoadingSuggestedProducts &&
							suggestedProductsList}
					</div>
					<NoResults
						visible={
							suggestedProducts.length === 0 &&
							!isLoadingSuggestedProducts
						}
						title={"Nothing Here Yet!"}
						text={"There are no similar products to show here!"}
					/>
				</div>

				<NewFullscreenModal
					visible={showSlider}
					onHide={() => {
						setShowSlider(false);
					}}
					children={
						<>
							<HeaderBar
								hideSeparator
								hideLeftSide
								headerRightContent={
									<div className={styles.viewer_header}>
										<div className={styles.back_container}>
											<BackButton
												onBack={() =>
													setShowSlider(false)
												}
											/>

											<h3>Product Media</h3>
										</div>
										<button
											onClick={() =>
												fileviewerRef.current.enterFullscreen()
											}
										>
											<FullscreenIcon />
										</button>
									</div>
								}
							/>
							<FileViewer
								ref={fileviewerRef}
								disableScrollToEnd={true}
								files={formattedMediaList}
								initialSlide={initialSlideIndex}
								isLoading={false}
							/>
						</>
					}
				/>

				<WarningModal
					show={warningModal}
					headerText="Are you sure you want to delete this product?"
					warningText="This action cannot be undone."
					cancelButtonText={"Cancel"}
					onCancelButtonClicked={() => setWarningModal(false)}
					submitButtonText={"DELETE"}
					onSubmitButtonClicked={() =>
						handleConfirmMutate(activeItem.uuid)
					}
				/>

				<ReportModal ref={reportRef} category="product" />
				<ProductEntry />
			</div>
		);

	return (
		<>
			{isPanel ? (
				content
			) : (
				<DefaultPage
					headerRightContent={
						<ContextHeader
							title={name}
							isLoading={isLoading}
							rightSideContent={
								<ProductPopover
									product={product}
									onEdit={handleEditProduct}
									onDelete={() =>
										handleRequestMutate(product)
									}
									onReport={() =>
										reportRef.current.showReportModal(
											urlProductId
										)
									}
								/>
							}
						/>
					}
					leftSideChildren={<NavBar />}
					rightSideChildren={
						<div className={styles.container}>
							{!isPanel && isLoading ? (
								<ProductDetailsLoadingView />
							) : (
								content
							)}
						</div>
					}
				/>
			)}
		</>
	);
};

export default NewProductDetails;
