import { useMemo } from "react";
import styles from "./PeopleAndCompanies.module.scss";
import GroupMember from "../../Chat/GroupMember";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import filterIcon from "images/filter-icon-white.svg";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import ContactLoader from "components/Utils/SubComs/CustomLoader/ContactLoader";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";

const PeopleAndCompanies = ({
	count,
	data,
	isCompany = false,
	isPaginated = false,
	isFetchingNextPage,
	viewRef,
	isLoading,
	isMobile,
	onShowModal,
	onClick,
	tagsList,
}) => {
	const itemsList = useMemo(
		() =>
			data?.map((user, index) => {
				const isLastItem = index === data.length - 1;
				return (
					<div
						key={user.uuid}
						ref={isLastItem && isPaginated ? viewRef : null}
						className={styles.contact_item}
					>
						<GroupMember data={user} redirect={true} />
						{isLastItem && isFetchingNextPage && <ContactLoader />}
					</div>
				);
			}),
		[data, isPaginated, viewRef, isFetchingNextPage]
	);

	if (isLoading) return <LoadingSpinner />;

	if (!isLoading && count === 0)
		return (
			<NoResults
				visible={true}
				// isSearch={true}
				text={"No results"}
			/>
		);

	return (
		<>
			<div className={styles.title_wrapper}>
				{isCompany ? (
					<h4>{`${count} ${count > 1 ? "Companies" : "Company"} found`}</h4>
				) : (
					<h4>{`${count} ${count > 1 ? "People" : "Person"} found`}</h4>
				)}

				{isMobile && isPaginated && (
					<button className={styles.filter_button} onClick={onShowModal}>
						<img src={filterIcon} alt="" />
					</button>
				)}
			</div>

			{tagsList?.length > 0 && <div className={styles.active_filters}>{tagsList}</div>}

			<div>{itemsList}</div>

			{!isPaginated && (
				<div className={styles.button_container}>
					<SeeMore onClick={onClick} text="See more" />
				</div>
			)}
		</>
	);
};

export default PeopleAndCompanies;
