import TabMenu from "components/Utils/SubComs/TabMenu/TabMenu";
import usePackages from "hooks/usePackages";
import useVaultStorage from "hooks/useVaultStorage";
import { useEffect, useRef, useState } from "react";
import styles from "./Plans.module.scss";
import PlanDetails from "./PlansComponents/PlanDetails/PlanDetails";
import PlansCarousel from "./PlansComponents/PlansCarroussel/PlansCarousel";

const Plans = () => {
	const plansCarouselRef = useRef();
	const [tab, setTab] = useState("manage");

	const [pendingScroll, setPendingScroll] = useState(null);
	const [shouldAutoScroll, setShouldAutoScroll] = useState(true);

	const handleScrollToPlan = (planType) => {
		setTab("plans");
		setPendingScroll(planType);
		setShouldAutoScroll(false);
	};

	useEffect(() => {
		if (pendingScroll && plansCarouselRef.current && tab === "plans") {
			plansCarouselRef.current.scrollToPlan(pendingScroll);
			setPendingScroll(null);
		}
	}, [pendingScroll, tab]);

	const { data: packages } = usePackages();
	const { data, isFetching } = useVaultStorage();
	const { currentPlan } = data;

	return (
		<div className={styles.container}>
			<div className={styles.tab_menu}>
				<TabMenu
					menuItems={{
						manage: "Manage Subscription",
						plans: "Plans",
					}}
					selectedTab={tab}
					onSelectedTabChanged={setTab}
				/>
			</div>
			{tab === "manage" ? (
				<PlanDetails
					data={data}
					isFetching={isFetching}
					onChangeTab={() => setTab("plans")}
					onNavigateToPlan={handleScrollToPlan}
				/>
			) : (
				<PlansCarousel
					ref={plansCarouselRef}
					packages={packages}
					currentPlan={currentPlan}
					shouldAutoScroll={shouldAutoScroll}
					onScrollFinish={setShouldAutoScroll}
				/>
			)}
		</div>
	);
};

export default Plans;
