import useApi from "hooks/useApi";
import styles from "./JobsTab.module.scss";
import jobApi from "api/job";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { jobsKeys } from "queryKeys/jobs-key-factory";
import { Fragment, useMemo } from "react";
import JobCard from "components/Jobs/JobsComponents/JobCard/JobCard";
import useMutate from "hooks/useMutate";
import { useQueryClient } from "@tanstack/react-query";
import JobLoader from "components/Utils/SubComs/CustomLoader/JobLoader";
import { useNavigate } from "react-router-dom";
import routes from "components/Routing/routing-keys";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";

const PAGE_SIZE = 20;

const JobsTab = ({ userInfo, canEdit }) => {
	const navigate = useNavigate();

	const { uuid: companyId, url: companyUrl } = userInfo;

	const getJobsApi = useApi(jobApi.getJobByCompanyId, true, true);

	const fetchJobs = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, companyId, jobType, projectId, closed, excludeId] = queryKey;

		const response = await getJobsApi.request(
			companyId,
			pageParam,
			PAGE_SIZE,
			jobType,
			closed,
			excludeId,
			projectId
		);
		return response.data;
	};

	const { items, viewRef, isFetchingNextPage, isLoading, fetchStatus } = useInfiniteScroll({
		queryKey: jobsKeys.jobsByCompany(companyId, "", false),
		queryFn: fetchJobs,
		pageSize: PAGE_SIZE,
	});

	const queryClient = useQueryClient();

	const {
		action: { mutate: toggleSave },
	} = useMutate(jobApi.toggleSaveJob, (response, variables) => {
		const { jobId } = variables;

		queryClient.setQueryData(jobsKeys.jobsByCompany(companyId, "", false), (oldData) => {
			return {
				...oldData,
				pages: oldData.pages.map((page) =>
					page.map((job) =>
						job.uuid === jobId ? { ...job, savedJob: !job.savedJob } : job
					)
				),
			};
		});
	});

	const jobsList = useMemo(
		() =>
			items?.map((job, index) => {
				const isLastItem = index === items.length - 1;
				return (
					<Fragment key={job.uuid}>
						<JobCard
							ref={isLastItem ? viewRef : null}
							data={job}
							className={styles.job_card}
							onToggleSave={() => {
								toggleSave({
									jobId: job.uuid,
									state: !job.savedJob,
								});
							}}
							onClick={() => navigate(routes.companyJobs(companyUrl, job.uuid))}
						/>

						{isLastItem && isFetchingNextPage && (
							<div className={styles.job_loader}>
								<JobLoader />
							</div>
						)}
					</Fragment>
				);
			}),
		[items, viewRef]
	);

	return (
		<div className={styles.container}>
			<div className={styles.jobs_container}>
				{isLoading && (
					<div className={styles.job_loader}>
						<JobLoader />
						<JobLoader />
					</div>
				)}

				{items.length > 0 && !isLoading && jobsList}

				<NoResults visible={!isLoading && items.length === 0} title={"Nothing Found!"} />
			</div>
		</div>
	);
};

export default JobsTab;
