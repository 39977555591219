import Switcher from "components/Utils/SubComs/Switcher/Switcher";
import styles from "./ConnectionFilter.module.scss";

const ConnectionFilter = ({
	friends,
	friendLabel = "In my Friend list",
	inMyCircle,
	myCircleLabel = "In my Work Circle",
	inTheirCircle,
	theirCircleLabel = "Added me to their Work Circle",
	onFriendChange,
	onMyWorkChange,
	onTheirWorkChange,
}) => {
	return (
		<div className={styles.container}>
			<h4>Connections</h4>

			{onFriendChange && (
				<div className={styles.switch_wrapper}>
					<label>
						<Switcher value={friends} onChange={onFriendChange} />
						<span>{friendLabel}</span>
					</label>
				</div>
			)}
			{onMyWorkChange && (
				<div className={styles.switch_wrapper}>
					<label>
						<Switcher value={inMyCircle} onChange={onMyWorkChange} />
						<span>{myCircleLabel}</span>
					</label>
				</div>
			)}
			{onTheirWorkChange && (
				<div className={styles.switch_wrapper}>
					<label>
						<Switcher value={inTheirCircle} onChange={onTheirWorkChange} />
						<span>{theirCircleLabel}</span>
					</label>
				</div>
			)}
		</div>
	);
};

export default ConnectionFilter;
