import useCurrentUser from "hooks/useCurrentUser";
import styles from "./Blocked.module.scss";
import useApi from "hooks/useApi";
import connectionApi from "api/connection";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { connectionsKeys } from "queryKeys/connections-key-factory";
import { useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import GroupMember from "components/Chat/GroupMember";
import ContactLoader from "components/Utils/SubComs/CustomLoader/ContactLoader";
import GaawkButton from "components/Utils/Button/GaawkButton";
import useMutate from "hooks/useMutate";
import profileApi from "api/profile";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";

const pageSize = 20;

const Blocked = () => {
	const queryClient = useQueryClient();
	const { uuid } = useCurrentUser();

	const {
		action: { mutate: unblock },
	} = useMutate(profileApi.unblock, () => {
		queryClient.invalidateQueries({
			queryKey: connectionsKeys.list(uuid, "blocked"),
		});
	});

	const getBlockedApi = useApi(connectionApi.getBlocked, true, true);

	const fetchBlocked = async ({ pageParam = 0, signal }) => {
		// eslint-disable-next-line no-unused-vars
		const response = await getBlockedApi.request(pageParam, pageSize);
		return response.data;
	};

	const { items, isLoading, isFetchingNextPage, viewRef } = useInfiniteScroll(
		{
			queryKey: connectionsKeys.list(uuid, "blocked"),
			queryFn: fetchBlocked,
			pageSize,
		}
	);

	const blockedList = useMemo(
		() =>
			items.map((block, index) => {
				const isLastItem = index === items.length - 1;
				return (
					<div className={styles.contact_item} key={block.uuid}>
						<GroupMember
							data={block}
							showConnection={false}
							blocked={true}
							redirect={true}
							ref={isLastItem ? viewRef : null}
							rightSideAction={
								<GaawkButton
									text="Unblock"
									severity="black"
									size="small"
									onClick={() => unblock(block.uuid)}
								/>
							}
						/>
						{isLastItem && isFetchingNextPage && <ContactLoader />}
					</div>
				);
			}),
		[isFetchingNextPage, items, viewRef]
	);

	return (
		<div className={styles.container}>
			<h4>Profiles you have blocked:</h4>

			<div className={styles.blocked_container}>
				{isLoading && (
					<>
						<ContactLoader />
						<ContactLoader />
						<ContactLoader />
					</>
				)}

				{blockedList}

				<NoResults
					visible={!isLoading && items.length === 0}
					title="No blocked profiles!"
					text="Your block list is empty.
                        Looks like everyone is on good terms!"
				/>
			</div>
		</div>
	);
};

export default Blocked;
