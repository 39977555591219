export const jobsKeys = {
	all: ["jobs"],

	landing: () => [...jobsKeys.all, "landing"],

	lists: () => [...jobsKeys.all, "listJobs"],

	details: () => [...jobsKeys.all, "details"],

	detail: (jobId) => [...jobsKeys.details(), jobId],

	recommended: (jobTypeId, userCoordinates) => [
		...jobsKeys.lists(),
		"recommended",
		jobTypeId,
		userCoordinates,
	],

	suggested: (jobTypeId) => [...jobsKeys.lists(), "suggested", jobTypeId],

	nearby: (userCoordinates) => [...jobsKeys.lists(), "nearby", userCoordinates],

	saved: (jobType) => [...jobsKeys.lists(), "saved", jobType],

	applied: (jobType) => [...jobsKeys.lists(), "applied", jobType],

	lastSearchFilters: () => [...jobsKeys.all, "lastSearchFilters"],

	jobTypeByCompany: (companyId, jobType = "", projectId = "") => [
		...jobsKeys.lists(),
		companyId,
		jobType,
		projectId,
	],

	jobsByCompany: (companyId, jobType = "", closed, excludeId = "", projectId = "") => [
		...jobsKeys.jobTypeByCompany(companyId, jobType, projectId),
		closed,
		excludeId,
	],

	similarJobs: (jobId) => [...jobsKeys.lists(), "similar", jobId],

	jobApplicants: (jobId, statusId) => [...jobsKeys.all, "applicants", jobId, statusId],

	applicationsCount: (jobId) => [...jobsKeys.all, "applicationsCount", jobId],

	applicant: (applicationId) => [...jobsKeys.all, "applicant", applicationId],
};
