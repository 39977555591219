import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	showModal: false,
	product: undefined,
	locationModal: false,
	service: undefined,
};

const slice = createSlice({
	name: "products",
	initialState,
	reducers: {
		setModalProduct: (state, action) => {
			state.showModal = action.payload;
		},

		setProduct: (state, action) => {
			state.product = action.payload;
		},

		setService: (state, action) => {
			state.service = action.payload;
		},

		setLocationModal: (state, action) => {
			state.locationModal = action.payload;
		},

		reset: () => {
			return initialState;
		},
	},
});

export const {
	setModalProduct,
	setProduct,
	reset,
	setLocationModal,
	setService,
} = slice.actions;
export default slice.reducer;
