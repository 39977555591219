export const connectionsKeys = {
	all: ["connections"],

	lists: () => [...connectionsKeys.all, "list"],

	//* types options: "sent" / "received"
	requests: (type, query = "") => [...connectionsKeys.lists(), type, query],

	list: (profileId, type, query) => [
		...connectionsKeys.lists(),
		profileId,
		type,
		query,
	],

	recent: () => [...connectionsKeys.lists(), "recent"],

	count: (profileId) => [...connectionsKeys.all, profileId, "count"],
};
