import styles from "./CropModal.module.css";
import CropImage from "../SubComs/CropImage/CropImage";
import NewGaawkModal from "../NewGaawkModal/NewGaawkModal";

const CropModal = ({
	show,
	onClose,
	image,
	onCrop,
	// mode = "default",
	aspectMode,
	isCircle,
	returnBlob,
	canEscape = true,
	fullWidth = false,
}) => {
	return (
		<NewGaawkModal
			visible={show}
			onHide={onClose}
			title={"Crop image"}
			closeOnEscape={canEscape}
			showCloseButton={canEscape}
			children={
				<div className={styles.crop_container}>
					<CropImage
						src={image}
						// mode={mode}
						aspectMode={aspectMode}
						isCircle={isCircle}
						onCrop={onCrop}
						returnBlob={returnBlob}
					/>
				</div>
			}
		/>
	);
};

export default CropModal;
