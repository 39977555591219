import routes from "components/Routing/routing-keys";
import NavItem from "components/Utils/SubComs/NavBar/NavItem";
import { PROFILE_MENU_ITEMS } from "constants/profileEditMenuItems";
import useCurrentUser from "hooks/useCurrentUser";
import { ReactComponent as BioIcon } from "images/bio.svg";
import { ReactComponent as CertificateIcon } from "images/certificate.svg";
import { ReactComponent as CoverIcon } from "images/cover-pic.svg";
import { ReactComponent as EducationIcon } from "images/education.svg";
import { ReactComponent as EmailIcon } from "images/email.svg";
import { ReactComponent as ExperienceIcon } from "images/experience.svg";
import { ReactComponent as InfoIcon } from "images/information.svg";
import { ReactComponent as JobIcon } from "images/job.svg";
import { ReactComponent as LanguageIcon } from "images/language.svg";
import { ReactComponent as AddressIcon } from "images/location_icon.svg";
import { ReactComponent as SkillsIcon } from "images/skills.svg";
import { ReactComponent as MobileIcon } from "images/smartphone.svg";
import { ReactComponent as SocialIcon } from "images/social-media.svg";
import { ReactComponent as SoftwareIcon } from "images/software.svg";
import { ReactComponent as SpecialProfileIcon } from "images/special-profile.svg";
import { ReactComponent as UserIcon } from "images/user.svg";
import { ReactComponent as KeyPeopleIcon } from "images/users.svg";
import styles from "./ProfileEditMenu.module.scss";

const ProfileEditMenu = ({ activeItem }) => {
	const {
		// profileImage,
		// profileCover,
		// bio,
		softwareList = [],
		languages = [],
		certificates,
		contacts,
		sites,
		socials,
		addresses,
		others,
		roles = [],
		skills = [],
		experiences = [],
		educations = [],
		keyPeople,
		specialProfileCounts,
		type,
	} = useCurrentUser();

	const reducer = (previousValue, currentValue) => previousValue + currentValue;
	const exp = experiences.map((exp) => exp.roleList.length);
	const expCount = exp.length > 0 ? exp.reduce(reducer) : 0;

	const editCompany = type === "COMPANY";

	const profileItems = [
		{
			label: "Profile Information",
			path: routes.editProfile, //TODO >> need to check if path is the same for company ?
			isActive: activeItem === PROFILE_MENU_ITEMS.PROFILE_INFO,
			icon: <UserIcon />,
			enabled: true,
		},
		{
			label: "Profile Picture",
			path: routes.editProfilePic,
			isActive: activeItem === PROFILE_MENU_ITEMS.PROFILE_PIC,
			icon: <UserIcon />,
			enabled: true,
		},
		{
			label: "Cover Picture",
			path: routes.editCoverPic,
			isActive: activeItem === PROFILE_MENU_ITEMS.COVER_PIC,
			icon: <CoverIcon />,
			enabled: true,
		},
		{
			label: `Roles ${roles?.length > 0 ? `(${roles.length})` : ``}`,
			path: routes.role,
			isActive: activeItem === PROFILE_MENU_ITEMS.ROLES,
			icon: <JobIcon />,
			enabled: !editCompany,
		},
		{
			label: "Bio",
			path: routes.bio,
			isActive: activeItem === PROFILE_MENU_ITEMS.BIO,
			icon: <BioIcon />,
			enabled: true,
		},
		{
			label: `Experience ${expCount > 0 ? `(${expCount})` : ""}`,
			path: routes.experience,
			isActive: activeItem === PROFILE_MENU_ITEMS.EXPERIENCE,
			icon: <ExperienceIcon />,
			enabled: !editCompany,
		},
		{
			label: `Software ${softwareList?.length > 0 ? `(${softwareList.length})` : ``}`,
			path: routes.software,
			isActive: activeItem === PROFILE_MENU_ITEMS.SOFTWARE,
			icon: <SoftwareIcon />,
			enabled: !editCompany,
		},
		{
			label: `Education ${educations?.length > 0 ? `(${educations.length})` : ``}`,
			path: routes.education,
			isActive: activeItem === PROFILE_MENU_ITEMS.EDUCATION,
			icon: <EducationIcon />,
			enabled: !editCompany,
		},
		{
			label: `Certificates & Awards ${
				certificates?.length > 0 ? `(${certificates.length})` : ""
			}`,
			path: routes.certificate,
			isActive: activeItem === PROFILE_MENU_ITEMS.CERTIFICATE,
			icon: <CertificateIcon />,
			enabled: true,
		},
		{
			label: `Languages ${languages?.length > 0 ? `(${languages.length})` : ``}`,
			path: routes.language,
			isActive: activeItem === PROFILE_MENU_ITEMS.LANGUAGE,
			icon: <LanguageIcon />,
			enabled: !editCompany,
		},
		{
			label: `Skills ${skills?.length > 0 ? `(${skills.length})` : ``}`,
			path: routes.skills,
			isActive: activeItem === PROFILE_MENU_ITEMS.SKILLS,
			icon: <SkillsIcon />,
			enabled: !editCompany,
		},
		{
			label: `Key People ${keyPeople?.length > 0 ? `(${keyPeople.length})` : ``}`,
			path: routes.keyPeople,
			isActive: activeItem === PROFILE_MENU_ITEMS.KEY_PEOPLE,
			icon: <KeyPeopleIcon />,
			enabled: editCompany,
		},
	];

	const contactItems = [
		{
			label: `Mobile / Landline ${contacts?.length > 0 ? `(${contacts.length})` : ""}`,
			path: routes.phone,
			isActive: activeItem === PROFILE_MENU_ITEMS.MOBILE,
			icon: <MobileIcon />,
		},
		{
			label: `Email / Website ${sites?.length > 0 ? `(${sites.length})` : ""}`,
			path: routes.email,
			isActive: activeItem === PROFILE_MENU_ITEMS.EMAIL,
			icon: <EmailIcon />,
		},
		{
			label: `Social Media ${socials?.length > 0 ? `(${socials.length})` : ""}`,
			path: routes.social,
			isActive: activeItem === PROFILE_MENU_ITEMS.SOCIAL,
			icon: <SocialIcon />,
		},
		{
			label: `Addresses ${addresses?.length > 0 ? `(${addresses.length})` : ""}`,
			path: routes.address,
			isActive: activeItem === PROFILE_MENU_ITEMS.ADDRESS,
			icon: <AddressIcon />,
		},
		{
			label: `Other ${others?.length > 0 ? `(${others.length})` : ""}`,
			path: routes.other,
			isActive: activeItem === PROFILE_MENU_ITEMS.OTHER,
			icon: <InfoIcon />,
		},
	];

	return (
		<div className={styles.container}>
			<div className={styles.nav_group}>
				<h3>Profile</h3>
				<div className={styles.navigation}>
					{profileItems.map(
						(item, index) =>
							item.enabled && (
								<NavItem
									key={index}
									// icon={item.icon}
									label={item.label}
									path={item.path}
									isActive={item.isActive}
									labelClassName={styles.label}
									enableToolTip={false}
								/>
							)
					)}
				</div>
			</div>
			{/* {!editCompany && (
				<div className={styles.nav_group}>
					<h3>Special Profile</h3>
					<div className={styles.navigation}>
						<NavItem
							// icon={<SpecialProfileIcon />}
							label={`Add a special profile ${
								specialProfileCounts > 0 ? `(${specialProfileCounts})` : ""
							}`}
							path={routes.specialProfilesList()}
							isActive={activeItem === PROFILE_MENU_ITEMS.SPECIAL_PROFILE}
							labelClassName={styles.label}
							enableToolTip={false}
						/>
					</div>
				</div>
			)} */}
			<div className={styles.nav_group}>
				<h3>Contacts</h3>
				<div className={styles.navigation}>
					{contactItems.map((item, index) => (
						<NavItem
							key={index}
							// icon={item.icon}
							label={item.label}
							path={item.path}
							isActive={item.isActive}
							labelClassName={styles.label}
							enableToolTip={false}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default ProfileEditMenu;
