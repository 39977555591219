import styles from "./WarningModal.module.css";
import NewGaawkModal from "../NewGaawkModal/NewGaawkModal";
import GaawkButton from "../Button/GaawkButton";

const WarningModal = ({
	show,
	headerText,
	warningText,
	submitButtonText,
	onSubmitButtonClicked,
	cancelButtonText,
	onCancelButtonClicked,
}) => {
	return (
		<NewGaawkModal
			visible={show}
			closeOnEscape={false}
			showHeader={false}
			disableMaximize
			className={styles.warning_modal}
			children={
				<div className={styles.delete_confirmation_modal}>
					<p>{headerText}</p>
					<p className={styles.warning}>{warningText}</p>

					<div className={styles.buttons}>
						<GaawkButton
							text={submitButtonText}
							onClick={onSubmitButtonClicked}
							severity={"danger"}
							size="small"
						/>

						<GaawkButton
							text={cancelButtonText}
							onClick={onCancelButtonClicked}
							severity={"quaternary"}
							size="small"
						/>
					</div>
				</div>
			}
		/>
	);
};

export default WarningModal;
