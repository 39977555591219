import { useState } from "react";
import styles from "./TopBar.module.scss";
import GaawkLink from "components/Utils/SubComs/GaawkLink/GaawkLink";
import { useSelector } from "react-redux";

const TopBar = () => {
	const { uuid } = useSelector((state) => state.user.profileInfo);

	// states
	const [menu, setMenu] = useState(false);

	// JSX
	return (
		<div className={styles.container}>
			<GaawkLink to={"/"}>
				<img
					className={styles.gaawkIcon}
					src={require("images/gaawk-logo-79-x-25-black-text.svg").default}
					alt={"icon"}
				/>
			</GaawkLink>
			<div className={styles.buttonView}>
				{!uuid && (
					<GaawkLink
						disableDecoration={true}
						to={"/login"}
						customStyle={styles.loginText}
						tabIndex={1}
					>
						SIGN UP / SIGN IN
					</GaawkLink>
				)}
				<GaawkLink disableDecoration={true} tabIndex={1} customStyle={styles.button}>
					<span className={styles.buttonText}>DOWNLOAD APP</span>
				</GaawkLink>
				<img
					onClick={() => setMenu(true)}
					className={styles.menuImg}
					src={require("images/menu-black.svg").default}
					alt={"menu"}
				/>
			</div>
			{menu && (
				<div className={styles.menuContainer}>
					<div className={styles.menuWrapper}>
						<div className={styles.menuIcons}>
							<img
								className={styles.menuIconsGaawk}
								src={require("images/gaawk-logo-79-x-25-black-text.svg").default}
								alt={"gaawk"}
							/>
							<img
								onClick={() => setMenu(false)}
								className={styles.menuIconsClose}
								src={require("images/close.svg").default}
								alt={"close"}
							/>
						</div>
						{!uuid && (
							<div className={styles.menuItem}>
								<GaawkLink
									disableDecoration={true}
									to={"/login"}
									customStyle={styles.menuItemText}
									tabIndex={1}
								>
									<span className={styles.menuItemText}>Sign Up / Sign in</span>
								</GaawkLink>
							</div>
						)}
						<div className={styles.menuItem}>
							<GaawkLink
								disableDecoration={true}
								tabIndex={2}
								to={"/"}
								customStyle={styles.menuItemText}
							>
								<span className={styles.menuItemText}>Download app - Android</span>
							</GaawkLink>
						</div>
						<div className={styles.menuItem} style={{ borderBottom: "none" }}>
							<GaawkLink
								disableDecoration={true}
								tabIndex={3}
								to={"/"}
								customStyle={styles.menuItemText}
							>
								<span className={styles.menuItemText}>Download app - IOS</span>
							</GaawkLink>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default TopBar;
