import styles from "./GroupModalStep1.module.scss";
import Joi from "joi";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import { forwardRef, useImperativeHandle, useState } from "react";
import imgUpload from "images/imgUpload.svg";
import LazyImage from "components/Utils/LazyImage/LazyImage";
import FileInput from "components/Utils/SubComs/Inputs/FileInput/FileInput";
import TextArea from "components/Utils/SubComs/Inputs/TextArea/TextArea";
import NewGaawkModal from "components/Utils/NewGaawkModal/NewGaawkModal";
import GaawkButton from "components/Utils/Button/GaawkButton";

const GroupModalStep1 = forwardRef(
	({ show, onClose, onNext, editMode = false, groupInfo }, ref) => {
		const [tempImage, setTempImage] = useState([]);

		const {
			groupDTO: {
				name: groupName,
				description,
				image: { file: { customName } = {} } = {},
			} = {},
		} = groupInfo || {};

		const schema = Joi.object({
			groupName: Joi.string()
				.required()
				.label("Group Name")
				.messages({ "string.empty": "Group Name can't be empty" }),
			description: Joi.string().allow("").label("Group Description"),
			// file: Joi.array().min(0),
			croppedImg: Joi.any(),
		});

		const {
			register,
			formState: { errors },
			handleSubmit,
			control,
			setValue,
			watch,
			reset,
		} = useForm({
			resolver: joiResolver(schema),
			mode: "all",
			...(editMode && {
				defaultValues: {
					groupName,
					description,
					// file: undefined,
					croppedImg: undefined,
				},
			}),
		});

		useImperativeHandle(ref, () => ({
			reset,
			revokeCroppedPreview,
		}));

		// const inputRef = useRef(null);

		// const [showCropModal, setShowCropModal] = useState(false);
		// const [showCurrentModal, setShowCurrentModal] = useState(true);

		// const handleShowCropModal = (reverse = false) => {
		// 	setShowCropModal(reverse ? false : true);
		// 	setShowCurrentModal(reverse ? true : false);
		// };

		// const fileWatcher = watch("file");
		const blobWatcher = watch("croppedImg");

		// const [filePreviewURL, setFilePreviewURL] = useState(undefined);
		const [croppedPreviewURL, setCroppedPreviewURL] = useState(undefined);

		// const revokeFilePreview = () => {
		// 	URL.revokeObjectURL(filePreviewURL);
		// 	setFilePreviewURL(undefined);
		// };
		const revokeCroppedPreview = () => {
			URL.revokeObjectURL(croppedPreviewURL);
			setCroppedPreviewURL(undefined);
			setValue("croppedImg", undefined);
			setTempImage([]);
		};

		const handleClose = () => {
			// revokeFilePreview();
			revokeCroppedPreview();
			onClose();
		};

		return (
			<NewGaawkModal
				visible={show}
				onHide={handleClose}
				title={editMode ? "Update Group Info" : "Create a group"}
				children={
					<form
						className={styles.add_group_container}
						onSubmit={handleSubmit((data) => {
							onNext(data);
							// revokeFilePreview();
						})}
					>
						<div className={styles.form_input_wrapper}>
							<label htmlFor="group-name">
								Group Name
								<span className="required">*</span>
							</label>

							<TextInput
								{...register("groupName")}
								error={!!errors.groupName}
								placeholder="Enter the group name"
							/>

							{errors?.groupName?.message && (
								<p className={styles.error_message}>{errors?.groupName?.message}</p>
							)}
						</div>

						<Controller
							name={"description"}
							control={control}
							render={({ field }) => (
								<TextArea
									className={styles.textarea_wrapper}
									label="Description"
									error={errors.description}
									placeholder={"Enter group description"}
									{...field}
								/>
							)}
						/>

						<div className={styles.form_input_wrapper}>
							<label htmlFor="group-description">Group image</label>

							<div className={styles.img_preview_container}>
								{blobWatcher ? (
									<img
										src={croppedPreviewURL} //* added
										alt=""
									/>
								) : customName ? (
									<LazyImage image={<img src={customName} alt="" />} />
								) : (
									<img
										src={imgUpload}
										className={styles.img_placeholder}
										alt="preview"
									/>
								)}
							</div>

							<FileInput
								control={control}
								error={errors.croppedImg}
								name="croppedImg"
								loadedFile={tempImage}
								onCrop={(blob) => {
									setValue("croppedImg", blob);
									setCroppedPreviewURL(URL.createObjectURL(blob));
								}}
								onChange={(value) => {
									setTempImage([value.target.files[0]]);
								}}
								cropAspect={1}
							/>
						</div>

						<div className={styles.form_controls}>
							<GaawkButton type="button" text={"Cancel"} severity={"tertiary"} />
							<GaawkButton type="submit" text={editMode ? "Save" : "Next"} />
						</div>
					</form>
				}
			/>
		);
	}
);
export default GroupModalStep1;
