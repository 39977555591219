// import { Link } from "react-router-dom";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import {
	setStep,
	updateStep1,
	setModal,
} from "../../../../store/slices/service";
import { ReactComponent as DotsIcon } from "images/dots.svg";
import deleteIcon from "images/delete.svg";
import editIcon from "images/edit.svg";
import addIcon from "images/add-icon-gray.png";
import Popover from "../../../Utils/Popover/Popover";
import useCurrentUser from "hooks/useCurrentUser";
import reportIcon from "images/report.svg";
import routes from "components/Routing/routing-keys";
import { useNavigate } from "react-router-dom";
import servicesIcon from "images/services.svg";
import { setModalProduct, setService } from "store/slices/products";

const ServicePopover = ({
	// level,
	// serviceId,
	// threeDotsIcon = OptionsWhite,
	// isTitlePopover = false,
	// isActiveService,
	onDelete,
	onEdit,
	onReport,
	service,
	maxLimitServices,
	maxLimitProducts,
	isServiceProducts = false,
}) => {
	const { uuid: serviceId, level, minifiedProfile, tag } = service || {};
	const navigate = useNavigate();
	const { uuid: userId } = useCurrentUser();

	const isServiceOwner = userId === minifiedProfile?.uuid;

	const popoverRef = useRef(null);
	const dispatch = useDispatch();
	return (
		<Popover
			ref={popoverRef}
			allow={["bottom"]}
			crossAxis={-40}
			render={
				<div className="popover_container">
					{isServiceProducts && (
						<button
							className="popover_item"
							tabIndex={0}
							onClick={(e) => {
								e.stopPropagation();
								navigate(routes.serviceTag(tag));
								popoverRef.current.closePopover();
							}}
						>
							<span>
								<img src={servicesIcon} alt={"service"} />
							</span>
							<span>Visit the category</span>
						</button>
					)}

					{!isServiceOwner ? (
						<button
							className="popover_item"
							tabIndex={0}
							onClick={(e) => {
								e.stopPropagation();
								onReport();
								popoverRef.current.closePopover();
							}}
						>
							<span>
								<img src={reportIcon} alt={"report"} />
							</span>
							<span>Report service</span>
						</button>
					) : (
						<>
							{!isServiceProducts && (
								<button
									onClick={(e) => {
										onEdit();
										e.stopPropagation();
										popoverRef.current.closePopover();
									}}
									className="popover_item"
								>
									<span>
										<img src={editIcon} alt="edit" />
									</span>
									<span>Edit Service</span>
								</button>
							)}

							{level !== 3 && !maxLimitServices && (
								<button
									className="popover_item"
									onClick={(e) => {
										dispatch(
											updateStep1({
												level: level + 1,
												parentId: serviceId,
											})
										);

										dispatch(setStep(2));
										dispatch(setModal(true));
										e.stopPropagation();
										popoverRef.current.closePopover();
									}}
								>
									<span>
										<img src={addIcon} alt="add" />
									</span>
									<span>Add Sub-Category</span>
								</button>
							)}

							{!maxLimitProducts && (
								<button
									className="popover_item"
									onClick={(e) => {
										dispatch(setModalProduct(true));
										dispatch(setService(service));
										e.stopPropagation();
										popoverRef.current.closePopover();
									}}
								>
									<span>
										<img src={addIcon} alt="add" />
									</span>
									<span>Add product to this category</span>
								</button>
							)}

							{!isServiceProducts && (
								<button
									className="popover_item"
									onClick={(e) => {
										popoverRef.current.closePopover();
										e.stopPropagation();
										onDelete();
									}}
								>
									<span>
										<img src={deleteIcon} alt="delete" />
									</span>
									<span>Delete</span>
								</button>
							)}
						</>
					)}
				</div>
			}
		>
			<button
				style={{ display: "flex", alignItems: "center" }}
				onClick={(e) => {
					e.stopPropagation();
					e.preventDefault();
				}}
			>
				<DotsIcon />
			</button>
		</Popover>
	);
};

export default ServicePopover;
