import { classNames } from "primereact/utils";
import GaawkLink from "../GaawkLink/GaawkLink";
import styles from "./NavItem.module.scss";

const NavItem = ({
	label,
	icon,
	path,
	action,
	isCollapsed = false,
	isActive = false,
	labelClassName,
	enableToolTip = true,
	className,
}) => {
	if (path) {
		return (
			<div id="target" data-pr-tooltip={enableToolTip ? label : ""}>
				<GaawkLink
					to={path}
					disableDecoration
					customStyle={classNames(styles.navItem, className, {
						[styles.isActive]: isActive,
					})}
				>
					{icon && <div className={styles.icon}>{icon}</div>}
					{!isCollapsed && (
						<span
							className={classNames(styles.label, labelClassName)}
						>
							{label}
						</span>
					)}
				</GaawkLink>
			</div>
		);
	}

	return (
		<button
			onClick={action}
			id="target"
			type={"button"}
			className={classNames(styles.navItem, className)}
			data-pr-tooltip={enableToolTip ? label : ""}
		>
			{icon && <div className={styles.icon}>{icon}</div>}
			{!isCollapsed && (
				<span className={classNames(styles.label, labelClassName)}>
					{label}
				</span>
			)}
		</button>
	);
};

export default NavItem;
