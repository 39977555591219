import { Dialog } from "primereact/dialog";

const NewFullscreenModal = ({ visible, onHide, children }) => {
	return (
		<Dialog
			showHeader={false}
			draggable={false}
			maximized={true}
			visible={visible}
			onHide={onHide}
			pt={{
				content: {
					style: {
						padding: "0px",
					},
				},
			}}
		>
			{children}
		</Dialog>
	);
};

export default NewFullscreenModal;
