import AddMembersModal from "../../AddMembersModal";
import styles from "./NewGroupModal.module.scss";
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import chatApi from "../../../../api/chat";
import GroupMember from "../../GroupMember";
import useMutate from "../../../../hooks/useMutate";
import GroupModalStep1 from "./GroupModalStep1";
import useSearchNewGroupMember from "../../hooks/useSearchNewGroupMember";
import Tag from "components/Utils/SubComs/Tags/Tag";

const NewGroupModal = forwardRef(({ onCreateGroup }, ref) => {
	const stepOneRef = useRef();

	const [searchGroupInput, setSearchGroupInput] = useState("");
	const [newGroupModal, setNewGroupModal] = useState(false);
	const [nextGroupModal, setNextGroupModal] = useState(false);
	const [groupParticipants, setGroupParticipants] = useState([]);

	useImperativeHandle(ref, () => ({
		handleNewGroup() {
			handleClear();
			setNewGroupModal(true);
			stepOneRef.current.reset();
		},
	}));

	// !=== handlers ===

	const handleSearchInputChange = ({ target }) => {
		const query = target.value;
		setSearchGroupInput(query);
	};

	const [dataFirstModal, setDataFirstModal] = useState(undefined);

	const handleNext = (data) => {
		setDataFirstModal(data);
		setNewGroupModal(false);
		setNextGroupModal(true);
	};

	const {
		action: { mutate: createGroup, isLoading: createGroupLoading },
	} = useMutate(chatApi.createGroup, (data) => {
		onCreateGroup(data);
		handleClear();
	});

	const handleCreateGroup = () => {
		const { groupName, description, croppedImg: groupImg, file } = dataFirstModal;

		createGroup({
			groupName,
			description,
			groupImg,
			groupImgName: groupImg && groupImg.fileName,
			groupParticipants,
		});

		stepOneRef.current.revokeCroppedPreview();
	};

	const handleBack = () => {
		setNextGroupModal(false);
		setNewGroupModal(true);
	};

	const handleClear = () => {
		setSearchGroupInput("");
		setGroupParticipants([]);
		setNewGroupModal(false);
		setNextGroupModal(false);
	};

	const handleCloseGroupModal = () => {
		handleClear();
	};

	const {
		ref: viewRef,
		infiniteQuery: { data, isFetching },
	} = useSearchNewGroupMember(searchGroupInput, nextGroupModal);

	const handleNewGroupParticipant = (participant) => {
		setGroupParticipants((prevState) => [...prevState, participant]);
		setSearchGroupInput("");
	};

	const handleRemoveParticipant = (participant) => {
		const participants = [...groupParticipants];
		const updatedParticipants = participants.filter((item) => item.uuid !== participant.uuid);
		setGroupParticipants(updatedParticipants);
	};

	const participantsList = useMemo(
		() =>
			groupParticipants.map((participant) => (
				<Tag
					key={participant.uuid}
					itemName={participant.name}
					onRemove={() => handleRemoveParticipant(participant)}
				/>
			)),
		[groupParticipants]
	);

	const groupProfileList = useMemo(
		() =>
			data?.pages?.map((page) =>
				page
					.filter(
						(user) => !groupParticipants.map((item) => item.uuid).includes(user.uuid)
					)
					.map((profile, i) => (
						<div
							key={profile.uuid}
							ref={page.length === i + 1 ? viewRef : null}
							className={styles.contact_item}
							onClick={() => handleNewGroupParticipant(profile)}
						>
							<div className={styles.contact_item_wrapper}>
								<GroupMember
									key={profile.uuid}
									data={profile}
									isChatLeftSide={true}
									disableDecoration={true}
								/>
							</div>
						</div>
					))
			),
		[data, groupParticipants]
	);

	return (
		<>
			<GroupModalStep1
				ref={stepOneRef}
				show={newGroupModal}
				onClose={handleCloseGroupModal}
				onNext={handleNext}
			/>

			<AddMembersModal
				show={nextGroupModal}
				onBack={handleBack}
				onClose={handleCloseGroupModal}
				searchInput={searchGroupInput}
				onInputChange={handleSearchInputChange}
				onClear={() => {
					setSearchGroupInput("");
				}}
				participantsList={participantsList}
				contactList={groupProfileList?.flat() || []}
				onConfirm={handleCreateGroup}
				isLoading={isFetching}
				disabled={createGroupLoading}
			/>
		</>
	);
});

export default NewGroupModal;
