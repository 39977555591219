import { Link } from "react-router-dom";
import styles from "./GaawkLink.module.scss";
import { forwardRef } from "react";

const GaawkLink = forwardRef(
	(
		{
			children,
			to,
			customStyle,
			disableDecoration = false,
			tabIndex,
			...props
		},
		ref
	) => {
		return (
			<Link
				ref={ref}
				to={to}
				tabIndex={tabIndex}
				className={`${styles.link} ${
					disableDecoration ? styles.no_decoration : undefined
				} ${customStyle}`}
				{...props}
			>
				{children}
			</Link>
		);
	}
);

export default GaawkLink;
