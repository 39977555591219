import GaawkButton from "components/Utils/Button/GaawkButton";
import styles from "./ManageAccount.module.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import routes from "components/Routing/routing-keys";

const ManageAccount = () => {
	const navigate = useNavigate();
	const { email, mobile, mobileCountryCode } = useSelector(
		(state) => state.user.userLogin
	);

	return (
		<div className={styles.container}>
			<div className={styles.setting_item}>
				<div className={styles.details}>
					<h3>Email</h3>
					<p>Your Primary E-mail:</p>
					<b>{email}</b>
				</div>
				<GaawkButton
					text={"Change"}
					severity={"black"}
					className={styles.manage_btn}
					onClick={() => navigate(routes.emailChange)}
				/>
			</div>

			<div className={styles.setting_item}>
				<div className={styles.details}>
					<h3>Primary Number</h3>
					<p>Your Primary phone number:</p>
					{mobile ? <b>{`+${mobileCountryCode} ${mobile}`}</b> : "-"}
				</div>
				<GaawkButton
					text={mobile ? "Change" : "Add"}
					severity={"black"}
					className={styles.manage_btn}
					onClick={() => navigate(routes.phoneChange)}
				/>
			</div>

			<div className={styles.setting_item}>
				<div className={styles.details}>
					<h3>Change Password</h3>
					<p>
						To change your password, check your email inbox and
						click on 'Change' to proceed.
					</p>
				</div>
				<GaawkButton
					text={"Change"}
					severity={"black"}
					className={styles.manage_btn}
					onClick={() => navigate(routes.passwordChange)}
				/>
			</div>
		</div>
	);
};

export default ManageAccount;
