import { forwardRef, memo } from "react";
import styles from "./ServiceCard.module.scss";
import ProfilePic from "components/Home/ProfilePic";
import { ReactComponent as ArrowIcon } from "images/back.svg";
import { classNames } from "primereact/utils";

const ServiceCard = memo(
	forwardRef(
		({ data, onClick, className, showOwnerThumbnail = true }, ref) => {
			const { name, description, image, minifiedProfile } = data;

			return (
				<button
					ref={ref}
					className={classNames(styles.card, className)}
					onClick={onClick}
				>
					<div className={styles.image_container}>
						<img src={image?.file?.url} alt={name} />
					</div>
					<div className={styles.content}>
						<div className={styles.details}>
							<div className={styles.title}>
								<h4>{name}</h4>
								<ArrowIcon className={styles.arrow_icon} />
							</div>
							<p>{description}</p>
						</div>

						{showOwnerThumbnail && (
							<div className={styles.owner}>
								<ProfilePic
									thumbnail={
										minifiedProfile?.profileImage?.image
									}
									type={minifiedProfile.type}
									name={minifiedProfile?.name}
									nameClassName={styles.name}
									w={25}
									h={25}
								/>
							</div>
						)}
					</div>
				</button>
			);
		}
	)
);

ServiceCard.displayName = "ServiceCard";

export default ServiceCard;
