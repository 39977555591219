import { nanoid } from "nanoid";
import { cloneElement } from "react";
import styles from "./InputWrapper.module.scss";

const InputWrapper = ({
	component,
	label,
	error,
	required = false,
	customError,
	className,
	infoText = "",
}) => {
	const id = nanoid();

	const enhancedComponent = cloneElement(component, {
		id,
		...(error && { error }),
	});

	return (
		<div className={`${styles.container} ${className}`}>
			{label && (
				<div className={styles.label}>
					<label htmlFor={id}>
						{label}
						{required && <span className="required">*</span>}
					</label>
					{infoText && (
						<span className={styles.info}>{infoText}</span>
					)}
				</div>
			)}

			{enhancedComponent}

			{error && (
				<p className={styles.error_message}>
					{customError ?? error.message}
				</p>
			)}
		</div>
	);
};

export default InputWrapper;
