import NavItem from "components/Utils/SubComs/NavBar/NavItem";
import styles from "./SettingsMenu.module.scss";
import ProfilePic from "components/Home/ProfilePic";
import useCurrentUser from "hooks/useCurrentUser";
import { useSelector } from "react-redux";
import routes from "components/Routing/routing-keys";
import { useNavigate } from "react-router-dom";

const SettingsMenu = ({ pathname }) => {
	const navigate = useNavigate();
	const { type, url, prime, firstName, lastName, name, profileImage } =
		useCurrentUser();
	const { email, mobile, mobileCountryCode } = useSelector(
		(state) => state.user.userLogin
	);
	const thumbnail =
		profileImage?.framedImageURL | profileImage?.originalImageURL;
	const isMobile = useSelector((state) => state.ui.isMobile);

	const accountItems = [
		...(type === "USER"
			? [
					{
						label: "Manage Account",
						path: routes.settingsAccount,
						isActive:
							pathname === routes.settingsAccount ||
							(pathname === routes.settings && !isMobile) ||
							pathname === routes.passwordChange ||
							pathname === routes.phoneChange,
					},
			  ]
			: []),
		{
			label: "Find Your Friends / Contacts",
			path: routes.settingsFriends,
			isActive:
				pathname === routes.settingsFriends ||
				(pathname === routes.settings &&
					!isMobile &&
					type === "COMPANY"),
		},
		...(type === "USER"
			? [
					{
						label: "Manage Subscription",
						action: () => navigate(routes.plans),
						path: routes.settingsSubscription,
						isActive: pathname === routes.settingsSubscription,
					},
			  ]
			: []),
		{
			label: `Delete ${type === "COMPANY" ? "Company " : ""}Account`,
			path: routes.settingsDelete,
			isActive: pathname === routes.settingsDelete,
		},
	];
	console.log("🚀 ~ accountItems >>", accountItems);

	const privacyItems = [
		...(type === "USER"
			? [
					{
						label: "Visibility",
						path: routes.settingsVisibility,
						isActive: pathname === routes.settingsVisibility,
					},
			  ]
			: []),
		{
			label: "Favorites",
			path: routes.settingsFavorites,
			isActive: pathname === routes.settingsFavorites,
		},
		{
			label: "Block List",
			path: routes.settingsBlocked,
			isActive: pathname === routes.settingsBlocked,
		},
	];

	// const generalItems = [
	// 	{
	// 		label: "FAQ",
	// 		path: routes.settingsFaq,
	// 		isActive: pathname === routes.settingsFaq,
	// 	},
	// 	{
	// 		label: "Privacy Policy",
	// 		path: routes.settingsPrivacy,
	// 		isActive: pathname === routes.settingsPrivacy,
	// 	},
	// 	{
	// 		label: "Help & Support",
	// 		path: routes.settingsHelp,
	// 		isActive: pathname === routes.settingsHelp,
	// 	},
	// ];

	const userName = type === "USER" ? `${firstName} ${lastName}` : name;

	return (
		<div className={styles.container}>
			<div className={styles.active_profile_container}>
				<ProfilePic
					disableOverflow
					isPrime={prime}
					w={124}
					h={124}
					type={type}
					noMarginRight
					thumbnail={thumbnail}
					name={userName}
					enableName={false}
					url={url}
					className={styles.disable_overflow}
				/>

				<div className={styles.user_info}>
					<h3>{userName}</h3>
					<p>{email}</p>
					{mobile && <p>{`+${mobileCountryCode} ${mobile}`}</p>}
				</div>
			</div>

			<div className={styles.nav_group}>
				<h3>Account</h3>
				<div className={styles.navigation}>
					{accountItems.map((item, index) => (
						<NavItem
							key={index}
							label={item.label}
							path={item.path}
							isActive={item.isActive}
							labelClassName={styles.label}
							enableToolTip={false}
							action={item.action}
						/>
					))}
				</div>
			</div>
			<div className={styles.nav_group}>
				<h3>Privacy</h3>
				<div className={styles.navigation}>
					{privacyItems.map((item, index) => (
						<NavItem
							key={index}
							label={item.label}
							path={item.path}
							isActive={item.isActive}
							labelClassName={styles.label}
							enableToolTip={false}
						/>
					))}
				</div>
			</div>
			{/* <div className={styles.nav_group}>
				<h3>General</h3>
				<div className={styles.navigation}>
					{generalItems.map((item, index) => (
						<NavItem
							key={index}
							label={item.label}
							path={item.path}
							isActive={item.isActive}
							labelClassName={styles.label}
							enableToolTip={false}
							showArrow
						/>
					))}
				</div>
			</div> */}

			{/* //TODO >> add FAQ , Privacy policy and help at the bottom of the navbar */}
		</div>
	);
};

export default SettingsMenu;
