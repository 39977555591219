import styles from "../PeopleFilter.module.scss";
import { genderTypes } from "components/Utils/General";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import {
	defaultFormatter,
	languageFormatter,
} from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import { forwardRef, useImperativeHandle, useRef } from "react";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import NewMultiCheckbox from "components/Utils/NewMultiCheckBox/NewMultiCheckBox";

const CastingFilters = forwardRef(
	(
		{
			onGenderChange,
			gender,
			onMinAge,
			onMaxAge,
			minAge,
			maxAge,
			// * ethnicities props
			ethnicitiesList,
			onEthnicity,
			ethnicities,
			isFetchedEthnicities,
			hasNextPageEthnicities,
			onRefetchEthnicities,
			onFetchNextPageEthnicities,
			baseEthnicitiesCount,
			// * languages props
			languagesList,
			onLanguage,
			languages,
			isFetchedLanguages,
			hasNextPageLanguages,
			onRefetchLanguages,
			onFetchNextPageLanguages,
			baseLanguagesCount,
		},
		ref
	) => {
		const languageRef = useRef();
		const ethnicityRef = useRef();

		useImperativeHandle(ref, () => ({
			clear() {
				languageRef.current?.clear();
				ethnicityRef.current?.clear();
			},
			removeLanguageItem(item) {
				languageRef.current.removeItem(item);
			},
			removeEthnicityItem(item) {
				ethnicityRef.current.removeItem(item);
			},
		}));

		return (
			<div className={styles.dynamic_filter_container}>
				<h3>Casting Call Filters</h3>

				<NewMultiCheckbox
					title={"Gender"}
					options={genderTypes}
					onSelect={onGenderChange}
					selected={gender}
					perRow="2, 150px"
					customStyle={styles.multicheckbox_container}
				/>

				<div className={styles.datePickerContainer}>
					<div className={styles.datePickerWrapper}>
						<div className={styles.datePickerItem}>
							<InputWrapper
								label="Min age"
								component={
									<TextInput
										value={minAge}
										placeholder="Enter min age"
										onChange={onMinAge}
										type="number"
									/>
								}
								className={styles.no_margin}
							/>
						</div>
						<div className={styles.datePickerItem}>
							<InputWrapper
								label="Max age"
								component={
									<TextInput
										value={maxAge}
										placeholder="Enter max age"
										onChange={onMaxAge}
										type="number"
									/>
								}
								className={styles.no_margin}
							/>
						</div>
					</div>
				</div>

				<div className={styles.multicheckbox_container}>
					{baseEthnicitiesCount > 0 && (
						<NewMultiCheckbox
							ref={ethnicityRef}
							title={"Ethnicity"}
							options={ethnicitiesList.map(defaultFormatter)}
							onSelect={onEthnicity}
							selected={ethnicities}
							perRow="2, auto"
							customStyle={styles.multicheckbox_container}
						/>
					)}

					{((!isFetchedEthnicities && baseEthnicitiesCount === 4) ||
						(isFetchedEthnicities && hasNextPageEthnicities)) && (
						<SeeMore
							className={styles.margin_top}
							onClick={
								!isFetchedEthnicities
									? onRefetchEthnicities
									: onFetchNextPageEthnicities
							}
						/>
					)}
				</div>

				<div>
					{baseLanguagesCount > 0 && (
						<NewMultiCheckbox
							ref={languageRef}
							title={"Languages"}
							options={languagesList.map((item) => languageFormatter(item))}
							onSelect={onLanguage}
							selected={languages}
							perRow="2, auto"
							customStyle={styles.multicheckbox_container}
						/>
					)}

					{((!isFetchedLanguages && baseLanguagesCount === 4) ||
						(isFetchedLanguages && hasNextPageLanguages)) && (
						<SeeMore
							className={styles.margin_top}
							onClick={
								!isFetchedLanguages ? onRefetchLanguages : onFetchNextPageLanguages
							}
						/>
					)}
				</div>
			</div>
		);
	}
);

export default CastingFilters;
