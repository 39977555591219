import { useEffect, useState, cloneElement } from "react";
import SearchInput from "../Utils/SubComs/Inputs/SearchInput/SearchInput";
import styles from "./SearchFilterRightSide.module.scss";
import All from "./SearchTabs/All";
import People from "./SearchTabs/People";
import Companies from "./SearchTabs/Companies";
import Posts from "./SearchTabs/Posts";
import useDebounce from "../../hooks/useDebounce";
import Jobs from "./SearchTabs/Jobs";
import Products from "./SearchTabs/Products";
import Services from "./SearchTabs/Services";
import { useSearchParams } from "react-router-dom";
import TabMenu from "components/Utils/SubComs/TabMenu/TabMenu";

const SearchFilterRightSide = ({
	onTabClick,
	activeTab,
	isMobile,
	onShowModal,
	tagsList = [],
	userCoordinates,
	tabItems,
}) => {
	let displayTab;

	const [searchParams, setSearchParams] = useSearchParams();

	const [searchInput, setSearchInput] = useState(searchParams.get("q"));

	const debouncedSearch = useDebounce(searchInput);

	useEffect(() => {
		setSearchParams(
			(prev) => {
				prev.set("q", debouncedSearch);
				return prev;
			},
			{ replace: true }
		);
	}, [debouncedSearch]);

	const handleSearchInputChange = ({ target }) => {
		const query = target.value;
		setSearchInput(query);
	};

	switch (true) {
		case activeTab === "all":
			displayTab = <All onViewMore={onTabClick} />;
			break;

		case activeTab === "people":
			displayTab = <People />;
			break;

		case activeTab === "companies":
			displayTab = <Companies />;
			break;

		case activeTab === "posts":
			displayTab = <Posts />;
			break;

		case activeTab === "jobs":
			displayTab = <Jobs />;
			break;

		case activeTab === "products":
			displayTab = <Products />;
			break;

		case activeTab === "services":
			displayTab = <Services />;
			break;

		default:
			break;
	}

	return (
		<>
			<div className={styles.section}>
				<div className={styles.search_input_wrapper}>
					<SearchInput
						value={searchInput}
						onChange={handleSearchInputChange}
						border={false}
						showIcons={true}
						onClearClicked={() => {
							setSearchInput("");
						}}
					/>
				</div>

				<TabMenu
					menuItems={tabItems}
					selectedTab={activeTab}
					onSelectedTabChanged={onTabClick}
				/>
			</div>

			<div className={styles.section}>
				{cloneElement(displayTab, {
					isMobile: isMobile,
					onShowModal: onShowModal,
					tagsList: tagsList,
					userCoordinates: userCoordinates,
				})}
			</div>
		</>
	);
};

export default SearchFilterRightSide;
