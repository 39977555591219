import { joiResolver } from "@hookform/resolvers/joi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import productApi from "api/product";
import ServicesTagModal from "components/Home/ServicesTagModal";
import UserLocationsModal from "components/Profile/ServicesTab/ServiceAdd/UserLocationsModal";
import WarningModal from "components/Utils/GaawkModal/WarningModal";
import { measurementUnits, weightUnits } from "components/Utils/General";
import CustomSelect from "components/Utils/SubComs/CustomSelect/CustomSelect";
import FileInput from "components/Utils/SubComs/Inputs/FileInput/FileInput";
import InfiniteSearchInput from "components/Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import MultiSelectInput from "components/Utils/SubComs/Inputs/MultiSelectInput/MultiSelectInput";
import {
	defaultFormatter,
	locationFormatter,
	unduplicateIds,
} from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import TextArea from "components/Utils/SubComs/Inputs/TextArea/TextArea";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import TagContainer from "components/Utils/SubComs/Tags/TagContainer";
import useCurrentUser from "hooks/useCurrentUser";
import useFetchLocation from "hooks/useFetchLocation";
import useMutate from "hooks/useMutate";
import { colorsKeys } from "queryKeys/colors-key-factory";
import { locationKeys } from "queryKeys/location-key-factory";
import { materialsKeys } from "queryKeys/materials-key-factory";
import { productsKeys } from "queryKeys/products-key-factory";
import { profileKeys } from "queryKeys/profile-key-factory";
import { vaultKeys } from "queryKeys/vault-key-factory";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { reset as resetForm, setLocationModal } from "store/slices/products";
import { useColors } from "./hooks/useColors";
import { useDeleteMedia } from "./hooks/useDeleteMedia";
import { useMaterials } from "./hooks/useMaterials";
import { useServiceTag } from "./hooks/useServiceTag";
import productSchema from "./product-schema";
import styles from "./ProductEntry.module.scss";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";
import { Checkbox } from "primereact/checkbox";
import NewGaawkModal from "components/Utils/NewGaawkModal/NewGaawkModal";
import { useProductMedia } from "hooks/useProductMedia";
// import { classNames } from "primereact/utils";
// import { useGetUserLocationsList } from "hooks/useGetUserLocationList";

const itemsPerPage = 50;

const sizeLimit = 500;

const uploadSingleFile = async ({ media, productId }) => {
	const formData = new FormData();

	formData.append("multipartFile", media, media.fileName);
	formData.append("productId", productId);

	const response = await productApi.addProductMedia(formData);

	if (!response.ok) {
		throw response.data;
	}

	return response.data;
};

const ProductEntry = ({ onInvalidate }) => {
	const { uuid } = useCurrentUser();
	// const addProductApi = useApi(productApi.addProduct, true);
	// const editProductApi = useApi(productApi.editProduct, true);
	// const addProductMediaApi = useApi(productApi.addProductMedia, true, true);

	const dispatch = useDispatch();
	const { showModal, product, locationModal } = useSelector(
		(state) => state.products
	);

	useEffect(() => {
		if (showModal) {
			if (product) {
				trackEvent(eventsDictionary.PRODUCT.EDIT_PRODUCT);
			} else {
				trackEvent(eventsDictionary.PRODUCT.ADD_PRODUCT);
			}
		}
	}, [showModal, product]);

	const queryClient = useQueryClient();

	const { data: productMedia } = useProductMedia(
		product?.uuid,
		product?.mediaCount > 0
	);

	const originalLocationsRef = useRef([]);

	useEffect(() => {
		if (product) {
			setValue("locationIds", originalLocationsRef?.current);
		}
	}, [product]);

	//* commented below since removed user locations list for now (to be replaced with above useEffect once reimplemented)

	// const [myLocationCheckbox, setMyLocationCheckbox] = useState(false);

	// const { profileLocationLists } =
	// 	useGetUserLocationsList(myLocationCheckbox);

	//*  when editing, will revert back to existing locations if unchecking box
	// useEffect(() => {
	// 	if (myLocationCheckbox) {
	// 		if (profileLocationLists && profileLocationLists?.length > 0) {
	// 			setValue(
	// 				"locationIds",
	// 				profileLocationLists?.map((item) => locationFormatter(item))
	// 			);
	// 		} else {
	// 			setValue("locationIds", []);
	// 		}
	// 	} else if (!myLocationCheckbox && product) {
	// 		setValue("locationIds", originalLocationsRef?.current);
	// 	}
	// }, [profileLocationLists, myLocationCheckbox, product]);

	const [showServicesModal, setShowServicesModal] = useState(false);

	const {
		register,
		formState: { errors, isDirty },
		handleSubmit,
		watch,
		control,
		reset,
		setValue,
		clearErrors,
	} = useForm({
		resolver: joiResolver(productSchema),
		mode: "onChange",
		defaultValues: {
			name: "",
			description: "",
			image: [],
			otherImages: [],
			locationIds: [],
			brand: "",
			model: "",
			colors: [],
			materials: [],
			length: "",
			serviceTags: [],
			lengthUnit: {
				label: "Centimeters",
				value: "CENTIMETER",
			},
			widthUnit: {
				label: "Centimeters",
				value: "CENTIMETER",
			},
			heightUnit: {
				label: "Centimeters",
				value: "CENTIMETER",
			},
			weightUnit: {
				label: "Kilograms",
				value: "KG",
			},
			worldWide: false,
		},
	});

	//! WATCHERS
	const imageWatcher = watch("image");
	const otherImagesWatcher = watch("otherImages");
	const colorsWatcher = watch("colors");
	const materialsWatcher = watch("materials");
	const tagsWatcher = watch("serviceTags");
	const locationsWatcher = watch("locationIds");
	const worldWideWatcher = watch("worldWide");

	// ! editing product default values

	useEffect(() => {
		if (product) {
			const {
				name,
				description,
				brand,
				modelNumber,
				width,
				height,
				length,
				weight,
				widthUnit,
				heightUnit,
				lengthUnit,
				weightUnit,
				taggedServices,
				mainImage,
				productColors,
				materials,
				worldWide,
				locations,
			} = product || {};

			originalLocationsRef.current =
				locations?.map((item) => locationFormatter(item)) || [];

			reset({
				name,
				description,
				image: mainImage ? [mainImage] : [],
				brand,
				model: modelNumber,
				colors: productColors?.map((color) => ({
					label: color.name,
					value: color.uuid,
				})),
				materials: materials?.map((material) => ({
					label: material.name,
					value: material.uuid,
				})),
				locationIds: locations?.map((item) => locationFormatter(item)),
				serviceTags: taggedServices,
				width: width > 0 ? width : "",
				height: height > 0 ? height : "",
				length: length > 0 ? length : "",
				weight: weight > 0 ? weight : "",
				widthUnit: measurementUnits.find(
					(item) => item.value === widthUnit
				),
				heightUnit: measurementUnits.find(
					(item) => item.value === heightUnit
				),
				lengthUnit: measurementUnits.find(
					(item) => item.value === lengthUnit
				),
				weightUnit: weightUnits.find(
					(item) => item.value === weightUnit
				),
				worldWide,
			});
		}
	}, [product, reset]);

	const { service } = useSelector((state) => state.products);

	useEffect(() => {
		if (service) {
			setValue("serviceTags", [service]);
		}
	}, [service, setValue]);

	useEffect(() => {
		if (product) {
			setValue("otherImages", productMedia);
		}
	}, [product, setValue, productMedia]);

	// !==========

	const onAfterSave = () => {
		queryClient.invalidateQueries(vaultKeys.storage());
		queryClient.invalidateQueries(productsKeys.lists());
		queryClient.invalidateQueries(productsKeys.details());
		queryClient.invalidateQueries(profileKeys.details());
		queryClient.invalidateQueries(productsKeys.stats(uuid)); //passing current user because you can only create as a user
		onInvalidate?.();
		closeForm();
	};

	const uploadMutation = useMutation(uploadSingleFile);

	const handleSuccessEntry = async (response) => {
		const newMediaArray = otherImagesWatcher.filter(
			(media) => media instanceof Blob
		);

		if (newMediaArray.length > 0) {
			try {
				for (const media of newMediaArray) {
					await uploadMutation.mutateAsync({
						media,
						productId: response.data.uuid,
					});
				}

				onAfterSave();
			} catch (error) {
				console.error("Upload failed:", error);
			}
		} else {
			onAfterSave();
		}
	};

	const {
		action: { mutate: addProduct, isLoading: isLoadingAdd },
	} = useMutate(productApi.addProduct, (response) => {
		handleSuccessEntry(response);
		trackEvent(eventsDictionary.PRODUCT.ADD_PRODUCT_DONE);
	});

	const {
		action: { mutate: editProduct, isLoading: isLoadingEdit },
	} = useMutate(productApi.editProduct, (response) => {
		handleSuccessEntry(response);
		trackEvent(eventsDictionary.PRODUCT.EDIT_PRODUCT_DONE);
	});

	const handleSave = async (data) => {
		const {
			name,
			description,
			image,
			// otherImages,
			brand,
			model,
			colors,
			materials,
			length,
			lengthUnit,
			width,
			widthUnit,
			height,
			heightUnit,
			weight,
			weightUnit,
			serviceTags,
			locationIds,
			worldWide,
		} = data;

		const formData = new FormData();

		if (product) formData.append("productId", product?.uuid);
		formData.append("name", name);
		formData.append("description", description);
		if (image[0] instanceof Blob)
			formData.append(
				"mainImage",
				image[0],
				image[0] instanceof Blob ? image[0].fileName : undefined
			);
		formData.append("brand", brand);
		formData.append("modelNumber", model);
		formData.append(
			"colors",
			colors?.map((color) => color.value)
		);
		formData.append(
			"materials",
			materials?.map((material) => material.value)
		);
		locationIds?.length > 0 &&
			formData.append(
				"locationIds",
				locationIds?.map((location) => location.value)
			);
		formData.append("worldWide", worldWide);

		if (length) {
			formData.append("length", length);
			formData.append("lengthUnit", lengthUnit.value);
		}

		if (width) {
			formData.append("width", width);
			formData.append("widthUnit", widthUnit.value);
		}

		if (height) {
			formData.append("height", height);
			formData.append("heightUnit", heightUnit.value);
		}

		if (weight) {
			formData.append("weight", weight);
			formData.append("weightUnit", weightUnit.value);
		}

		formData.append(
			"serviceIds",
			serviceTags.map((service) => service.uuid)
		);

		product ? editProduct(formData) : addProduct(formData);
	};

	// ! COLORS SEARCH API =================
	const { colorsList, searchColors } = useColors(
		itemsPerPage,
		colorsWatcher,
		setValue
	);

	// ! MATERIALS SEARCH API =================
	const { materialsList, searchMaterials } = useMaterials(
		itemsPerPage,
		materialsWatcher,
		setValue
	);

	//! CROP MODAL HANDLER --------------------------------------------------

	const [tempImage, setTempImage] = useState([]);
	const [tempOtherImage, setTempOtherImage] = useState([]);

	const handleCrop = (blob, isMulti) => {
		if (isMulti) {
			// otherImagesWatcher[otherImagesWatcher?.length - 1] = blob;
			// setValue("otherImages", otherImagesWatcher);
			setValue("otherImages", [...otherImagesWatcher, blob]);
		} else {
			setValue("image", [blob]);
			clearErrors("image");
		}
	};

	//! Location handlers =========

	const fetchLocation = useFetchLocation(true, itemsPerPage);

	const handleLocation = (location) => {
		if (errors?.locationIds) clearErrors("locationIds");
		setValue("locationIds", [...locationsWatcher, location]);
	};

	const handleRemoveLocation = (locationIndex) => {
		const updatedLocations = locationsWatcher.filter(
			(_, index) => index !== locationIndex
		);

		setValue("locationIds", updatedLocations);
	};

	// !=========================

	//! DELETE MEDIA HANDLER --------------------------------------------------
	const {
		activeItem,
		handleConfirmMutate,
		handleDeleteMulti,
		setWarningModal,
		warningModal,
	} = useDeleteMedia(
		(response, variables) => {
			const { productId, vaultItemId } = variables;
			queryClient.setQueryData(
				productsKeys.productMedia(productId),
				(oldData) => {
					return {
						...oldData,
						pages: oldData.pages.map((page) =>
							page.filter((item) => item.uuid !== vaultItemId)
						),
					};
				}
			);
		},
		product?.uuid,
		otherImagesWatcher,
		setValue
	);

	//! SERVICE TAGS MODAL --------------------------------------------------
	const { serviceTagsList, handleServicesChange } = useServiceTag(
		setValue,
		tagsWatcher
	);

	const closeForm = () => {
		dispatch(resetForm());
		// setMyLocationCheckbox(false); //*commented since removed user locations list for now
		reset();
	};

	return (
		<NewGaawkModal
			visible={showModal}
			onHide={() =>
				locationModal ? dispatch(setLocationModal(false)) : closeForm()
			}
			title={
				locationModal
					? "My locations"
					: product
					? "Edit Product"
					: "Add Product"
			}
			onBack={
				locationModal
					? () => dispatch(setLocationModal(false))
					: undefined
			}
			children={
				!locationModal ? (
					<form
						className={styles.form}
						onSubmit={handleSubmit((data) => handleSave(data))}
						noValidate
					>
						<div className={styles.section}>
							<div className={styles.form_input_wrapper}>
								<label>
									Product Name
									<span className="required">*</span>
								</label>

								<TextInput
									{...register("name")}
									error={!!errors.name}
									placeholder="Product Name"
								/>
								<span className={styles.character_max}>
									30-characters-max
								</span>

								{errors?.name?.message && (
									<p className={styles.error_message}>
										{errors?.name?.message}
									</p>
								)}
							</div>

							<Controller
								name={"description"}
								control={control}
								render={({ field }) => (
									<TextArea
										required={true}
										className={styles.textarea_wrapper}
										label="Description"
										error={errors.description}
										{...field}
									/>
								)}
							/>

							<FileInput
								control={control}
								error={errors.image}
								name="image"
								title="Main Image"
								required={true}
								fullWidthModal={true}
								// loadedFile={imageWatcher}
								loadedFile={tempImage}
								loadedThumbs={imageWatcher}
								onCrop={handleCrop}
								onDelete={() => setValue("image", [])}
								onChange={(value) => {
									setTempImage([value.target.files[0]]);
									// setValue("image", [value.target.files[0]]);
								}}
							/>

							<FileInput
								control={control}
								name="otherImages"
								title="Additionnal Images"
								fullWidthModal={true}
								disabled={otherImagesWatcher?.length >= 10}
								loadedFile={tempOtherImage}
								loadedThumbs={otherImagesWatcher}
								// loadedFile={otherImagesWatcher}
								onCrop={(blob) => handleCrop(blob, true)}
								onDelete={handleDeleteMulti}
								onChange={(value) => {
									// setValue("otherImages", [
									// 	...otherImagesWatcher,
									// 	value.target.files[0],
									// ]);
									// setTempAdditionalImage((prevState) => [
									// 	...prevState,
									// 	value.target.files[0],
									// ]);
									setTempOtherImage([value.target.files[0]]);
								}}
							/>
						</div>
						<div className={styles.section}>
							<div className={styles.radio_button_wrapper}>
								<Controller
									name={"worldWide"}
									control={control}
									render={({
										field: { onChange, value },
									}) => (
										<>
											<Checkbox
												checked={value}
												onChange={(e) => {
													onChange(e.target.checked);
													// setMyLocationCheckbox(false); //*commented since removed user locations list for now
													clearErrors("locationIds");
												}}
												inputId={"worldWide"}
											/>
											<label htmlFor={"worldWide"}>
												<h4>Worldwide</h4>
												<p className={styles.info_text}>
													This service is offered
													worldwide
												</p>
											</label>
										</>
									)}
								/>
							</div>

							<MultiSelectInput
								queryName={locationKeys.cities}
								queryFn={fetchLocation}
								data={locationsWatcher}
								itemsPerPage={itemsPerPage}
								formatter={locationFormatter}
								label="Service Locations"
								required={!worldWideWatcher}
								limit={sizeLimit}
								onChange={handleLocation}
								onRemoveItem={handleRemoveLocation}
								infoText={`You can add up to ${sizeLimit} locations.`}
								error={errors?.locationIds}
								customDisabled={worldWideWatcher}
							/>

							{/* //* below commented since removed user locations list for now */}

							{/* <div
                                    className={classNames(styles.radio_button_wrapper, {
                                        [styles.disabled]: worldWideWatcher,
                                    })}
                                >
                                    <CustomCheckbox
                                        checked={
                                            myLocationCheckbox && !worldWideWatcher
                                        }
                                        onChange={(e) =>
                                            setMyLocationCheckbox(e.target.checked)
                                        }
                                        id="myLocationCheckbox"
                                        customBackgroundColor={"gaawk"}
                                        marginRight="0px"
                                        isBorder={true}
                                        disabled={worldWideWatcher}
                                    />

                                    <label htmlFor="myLocationCheckbox">
                                        Use my location list
                                    </label>

                                    <button
                                        type="button"
                                        disabled={worldWideWatcher}
                                        onClick={() => dispatch(setLocationModal(true))}
                                    >
                                        Edit List
                                    </button>
                                </div> */}
						</div>
						<div className={styles.section}>
							<div className={styles.form_input_wrapper}>
								<label>Brand</label>
								<TextInput
									{...register("brand")}
									placeholder="Brand name"
								/>
							</div>

							<div className={styles.form_input_wrapper}>
								<label>Model Number</label>
								<TextInput
									{...register("model")}
									placeholder="Model Number"
								/>
							</div>

							<InfiniteSearchInput
								queryName={colorsKeys.search}
								queryFn={searchColors}
								itemsPerPage={itemsPerPage}
								label={"Colors"}
								onChange={(color) =>
									setValue("colors", [
										...colorsWatcher,
										color,
									])
								}
								selected={colorsWatcher}
								formatter={defaultFormatter}
								unDuplicateFn={(passedData) =>
									unduplicateIds(
										colorsWatcher?.map(
											(color) => color.value
										),
										passedData
									)
								}
								openMenuOnClick={true}
							/>
							{colorsWatcher?.length > 0 && (
								<TagContainer
									label="Selected Colors"
									items={colorsList}
								/>
							)}

							<InfiniteSearchInput
								queryName={materialsKeys.search}
								queryFn={searchMaterials}
								itemsPerPage={itemsPerPage}
								label={"Materials"}
								onChange={(material) =>
									setValue("materials", [
										...materialsWatcher,
										material,
									])
								}
								selected={materialsWatcher}
								formatter={defaultFormatter}
								unDuplicateFn={(passedData) =>
									unduplicateIds(
										materialsWatcher?.map(
											(material) => material.value
										),
										passedData
									)
								}
								openMenuOnClick={true}
							/>
							{materialsWatcher?.length > 0 && (
								<TagContainer
									label="Selected Materials"
									items={materialsList}
								/>
							)}

							<div className={styles.form_input_wrapper}>
								<label>Length</label>
								<div className={styles.inline_wrapper}>
									<TextInput
										{...register("length")}
										error={!!errors.length}
										placeholder="Length"
									/>

									<div className={styles.dropdown_container}>
										<CustomSelect
											fontSize="14px"
											placeholder="Unit"
											options={measurementUnits}
											isSearchable={false}
											control={control}
											name={"lengthUnit"}
										/>
									</div>
								</div>
								{errors?.length?.message && (
									<p className={styles.error_message}>
										{errors?.length?.message}
									</p>
								)}
							</div>

							<div className={styles.form_input_wrapper}>
								<label>Width</label>
								<div className={styles.inline_wrapper}>
									<TextInput
										{...register("width")}
										error={!!errors.width}
										placeholder="Width"
									/>

									<div className={styles.dropdown_container}>
										<CustomSelect
											fontSize="14px"
											placeholder="Unit"
											options={measurementUnits}
											isSearchable={false}
											control={control}
											name={"widthUnit"}
										/>
									</div>
								</div>
								{errors?.width?.message && (
									<p className={styles.error_message}>
										{errors?.width?.message}
									</p>
								)}
							</div>

							<div className={styles.form_input_wrapper}>
								<label>Height</label>
								<div className={styles.inline_wrapper}>
									<TextInput
										{...register("height")}
										error={!!errors.height}
										placeholder="Height"
									/>

									<div className={styles.dropdown_container}>
										<CustomSelect
											fontSize="14px"
											placeholder="Unit"
											options={measurementUnits}
											isSearchable={false}
											control={control}
											name={"heightUnit"}
										/>
									</div>
								</div>
								{errors?.height?.message && (
									<p className={styles.error_message}>
										{errors?.height?.message}
									</p>
								)}
							</div>

							<div className={styles.form_input_wrapper}>
								<label>Weight</label>
								<div className={styles.inline_wrapper}>
									<TextInput
										{...register("weight")}
										error={!!errors.weight}
										placeholder="Weight"
									/>

									<div className={styles.dropdown_container}>
										<CustomSelect
											fontSize="14px"
											placeholder="Unit"
											options={weightUnits}
											isSearchable={false}
											control={control}
											name={"weightUnit"}
										/>
									</div>
								</div>
								{errors?.weight?.message && (
									<p className={styles.error_message}>
										{errors?.weight?.message}
									</p>
								)}
							</div>
						</div>
						<div className={styles.section}>
							<div className={styles.form_input_wrapper}>
								<label>Service Tags</label>
								<TextInput
									placeholder="Service Tags"
									onClick={() => setShowServicesModal(true)}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											e.preventDefault();
											setShowServicesModal(true);
										}
									}}
									readOnly={true}
								/>
							</div>

							{tagsWatcher?.length > 0 && (
								<TagContainer
									customStyle={styles.m_20}
									label="Selected Service Tags"
									items={serviceTagsList}
								/>
							)}
						</div>
						<div className={styles.button_container}>
							<GaawkButton
								type={"submit"}
								text={product ? "Edit Product" : "Add Product"}
								isLoading={isLoadingAdd || isLoadingEdit}
								// disabled={addProductApi.loading || editProductApi.loading}
							/>
						</div>

						{/* //* IMPROVEMENT GET THE SERVICE TAG MODAL OUT OF THE MODAL */}
						<ServicesTagModal
							show={showServicesModal}
							handleClose={() => setShowServicesModal(false)}
							onConfirm={handleServicesChange}
							currentTags={tagsWatcher}
						/>
						<WarningModal
							show={warningModal}
							headerText="Are you sure you want to delete this image?"
							warningText="This action cannot be undone."
							cancelButtonText={"Cancel"}
							onCancelButtonClicked={() => setWarningModal(false)}
							submitButtonText={"DISCARD"}
							onSubmitButtonClicked={() =>
								handleConfirmMutate(activeItem)
							}
						/>
					</form>
				) : (
					// <ModalLocationContent />
					<UserLocationsModal
						onSave={() => dispatch(setLocationModal(false))}
					/>
				)
			}
		/>
	);
};

export default ProductEntry;
