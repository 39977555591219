import { useQuery } from "@tanstack/react-query";
import searchApi from "api/search";
import {
	formatFiltersValues,
	measurementUnits,
	weightUnits,
} from "components/Utils/General";
import CustomSelect from "components/Utils/SubComs/CustomSelect/CustomSelect";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import { defaultFormatter } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import useApi from "hooks/useApi";
import useDebounce from "hooks/useDebounce";
import useGetQueryParam from "hooks/useGetQueryParam";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { filterKeys } from "queryKeys/filters-key-factory";
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	resetProductsFilters,
	updateProductsFilters,
} from "store/slices/filters";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import styles from "./ResourcesFilter.module.scss";
import useFilterAndSort from "./useFilterAndSort";
import GaawkButton from "components/Utils/Button/GaawkButton";
import NewMultiCheckbox from "components/Utils/NewMultiCheckBox/NewMultiCheckBox";

const FILTERS_PAGE_SIZE = 100;

const ResourcesFilter = forwardRef(
	({ userCoordinates, onApplyFilters, onClearFilters }, ref) => {
		const { paramValue: inputValue } = useGetQueryParam("q");

		const dispatch = useDispatch();

		const productsFiltersObject = useSelector(
			(state) => state.filters.productsFilters
		);

		const storedProductsFilters = {
			...productsFiltersObject,
			q: inputValue || "",
			latitude: userCoordinates.lat,
			longitude: userCoordinates.lng,
		};

		const {
			// q = "",
			gaawkCategories = [],
			ownerIds = [],
			productFilters: {
				colors = [],
				materials = [],
				serviceTags = [],
				minLength = "",
				maxLength = "",
				minWidth = "",
				maxWidth = "",
				minHeight = "",
				maxHeight = "",
				minWeight = "",
				maxWeight = "",
				widthUnit = "METER",
				heightUnit = "METER",
				lengthUnit = "METER",
				weightUnit = "GRAM",
				// onlyUncategorizedProducts = false,
			} = {},
		} = storedProductsFilters || {};

		// Debouncing dimension inputs
		const [minLengthValue, setMinLengthValue] = useState(minLength);
		const debouncedMinLength = useDebounce(minLengthValue);

		const [maxLengthValue, setMaxLengthValue] = useState(maxLength);
		const debouncedMaxLength = useDebounce(maxLengthValue);

		const [minWidthValue, setMinWidthValue] = useState(minWidth);
		const debouncedMinWidth = useDebounce(minWidthValue);

		const [maxWidthValue, setMaxWidthValue] = useState(maxWidth);
		const debouncedMaxWidth = useDebounce(maxWidthValue);

		const [minHeightValue, setMinHeightValue] = useState(minHeight);
		const debouncedMinHeight = useDebounce(minHeightValue);

		const [maxHeightValue, setMaxHeightValue] = useState(maxHeight);
		const debouncedMaxHeight = useDebounce(maxHeightValue);

		const [minWeightValue, setMinWeightValue] = useState(minWeight);
		const debouncedMinWeight = useDebounce(minWeightValue);

		const [maxWeightValue, setMaxWeightValue] = useState(maxWeight);
		const debouncedMaxWeight = useDebounce(maxWeightValue);

		// Update filter values when debounced values change
		useEffect(() => {
			if (debouncedMinLength !== minLength) {
				dispatch(
					updateProductsFilters({
						productFilters: {
							...storedProductsFilters.productFilters,
							minLength: debouncedMinLength,
						},
					})
				);
			}
		}, [debouncedMinLength]);

		useEffect(() => {
			if (debouncedMaxLength !== maxLength) {
				dispatch(
					updateProductsFilters({
						productFilters: {
							...storedProductsFilters.productFilters,
							maxLength: debouncedMaxLength,
						},
					})
				);
			}
		}, [debouncedMaxLength]);

		useEffect(() => {
			if (debouncedMinWidth !== minWidth) {
				dispatch(
					updateProductsFilters({
						productFilters: {
							...storedProductsFilters.productFilters,
							minWidth: debouncedMinWidth,
						},
					})
				);
			}
		}, [debouncedMinWidth]);

		useEffect(() => {
			if (debouncedMaxWidth !== maxWidth) {
				dispatch(
					updateProductsFilters({
						productFilters: {
							...storedProductsFilters.productFilters,
							maxWidth: debouncedMaxWidth,
						},
					})
				);
			}
		}, [debouncedMaxWidth]);

		useEffect(() => {
			if (debouncedMinHeight !== minHeight) {
				dispatch(
					updateProductsFilters({
						productFilters: {
							...storedProductsFilters.productFilters,
							minHeight: debouncedMinHeight,
						},
					})
				);
			}
		}, [debouncedMinHeight]);

		useEffect(() => {
			if (debouncedMaxHeight !== maxHeight) {
				dispatch(
					updateProductsFilters({
						productFilters: {
							...storedProductsFilters.productFilters,
							maxHeight: debouncedMaxHeight,
						},
					})
				);
			}
		}, [debouncedMaxHeight]);

		useEffect(() => {
			if (debouncedMinWeight !== minWeight) {
				dispatch(
					updateProductsFilters({
						productFilters: {
							...storedProductsFilters.productFilters,
							minWeight: debouncedMinWeight,
						},
					})
				);
			}
		}, [debouncedMinWeight]);

		useEffect(() => {
			if (debouncedMaxWeight !== maxWeight) {
				dispatch(
					updateProductsFilters({
						productFilters: {
							...storedProductsFilters.productFilters,
							maxWeight: debouncedMaxWeight,
						},
					})
				);
			}
		}, [debouncedMaxWeight]);

		// API calls for filters
		const resourceFiltersApi = useApi(
			searchApi.getResourceFilters,
			true,
			true
		);

		const fetchResourceFilters = async ({ queryKey }) => {
			const [_, __, filters] = queryKey;

			const response = await resourceFiltersApi.request(
				formatFiltersValues(filters)
			);
			return response.data;
		};

		const { data: resourceFilters, isFetching } = useQuery({
			queryKey: filterKeys.resources(storedProductsFilters),
			queryFn: fetchResourceFilters,
		});

		// Dynamic filters
		const [enableCategoriesFilters, setEnableCategoriesFilters] =
			useState(false);
		const [enableColorsFilters, setEnableColorsFilters] = useState(false);
		const [enableMaterialsFilters, setEnableMaterialsFilters] =
			useState(false);
		const [enableOwnersFilters, setEnableOwnersFilters] = useState(false);
		const [enableServicesFilters, setEnableServicesFilters] =
			useState(false);

		// Categories filters
		const getCategoriesFilterApi = useApi(
			searchApi.getResourceFilterCategories,
			true,
			true
		);

		const fetchCategoriesFilters = async ({ queryKey, pageParam = 0 }) => {
			const [_, __, ___, filters] = queryKey;
			const response = await getCategoriesFilterApi.request(
				pageParam,
				FILTERS_PAGE_SIZE,
				formatFiltersValues(filters)
			);
			return response.data;
		};

		const {
			items: dynamicCategories,
			hasNextPage: hasNextPageCategories,
			refetch: refetchCategories,
			loadMore: loadMoreCategories,
		} = useInfiniteScroll({
			queryKey: filterKeys.resourceCategories(storedProductsFilters),
			queryFn: fetchCategoriesFilters,
			pageSize: FILTERS_PAGE_SIZE,
			enabled: enableCategoriesFilters,
		});

		// Colors filters
		const getColorsFilterApi = useApi(
			searchApi.getResourceFilterColors,
			true,
			true
		);

		const fetchColorsFilters = async ({ queryKey, pageParam = 0 }) => {
			const [_, __, ___, filters] = queryKey;
			const response = await getColorsFilterApi.request(
				pageParam,
				FILTERS_PAGE_SIZE,
				formatFiltersValues(filters)
			);
			return response.data;
		};

		const {
			items: dynamicColors,
			hasNextPage: hasNextPageColors,
			refetch: refetchColors,
			loadMore: loadMoreColors,
		} = useInfiniteScroll({
			queryKey: filterKeys.resourceColors(storedProductsFilters),
			queryFn: fetchColorsFilters,
			pageSize: FILTERS_PAGE_SIZE,
			enabled: enableColorsFilters,
		});

		// Materials filters
		const getMaterialsFilterApi = useApi(
			searchApi.getResourceFilterMaterials,
			true,
			true
		);

		const fetchMaterialsFilters = async ({ queryKey, pageParam = 0 }) => {
			const [_, __, ___, filters] = queryKey;
			const response = await getMaterialsFilterApi.request(
				pageParam,
				FILTERS_PAGE_SIZE,
				formatFiltersValues(filters)
			);
			return response.data;
		};

		const {
			items: dynamicMaterials,
			hasNextPage: hasNextPageMaterials,
			refetch: refetchMaterials,
			loadMore: loadMoreMaterials,
		} = useInfiniteScroll({
			queryKey: filterKeys.resourceMaterials(storedProductsFilters),
			queryFn: fetchMaterialsFilters,
			pageSize: FILTERS_PAGE_SIZE,
			enabled: enableMaterialsFilters,
		});

		// Owners filters
		const getOwnersFilterApi = useApi(
			searchApi.getResourceFilterOwners,
			true,
			true
		);

		const fetchOwnersFilters = async ({ queryKey, pageParam = 0 }) => {
			const [_, __, ___, filters] = queryKey;
			const response = await getOwnersFilterApi.request(
				pageParam,
				FILTERS_PAGE_SIZE,
				formatFiltersValues(filters)
			);
			return response.data;
		};

		const {
			items: dynamicOwners,
			hasNextPage: hasNextPageOwners,
			refetch: refetchOwners,
			loadMore: loadMoreOwners,
		} = useInfiniteScroll({
			queryKey: filterKeys.resourceOwners(storedProductsFilters),
			queryFn: fetchOwnersFilters,
			pageSize: FILTERS_PAGE_SIZE,
			enabled: enableOwnersFilters,
		});

		// Services filters
		const getServicesFilterApi = useApi(
			searchApi.getResourceFilterServices,
			true,
			true
		);

		const fetchServicesFilters = async ({ queryKey, pageParam = 0 }) => {
			const [_, __, ___, filters] = queryKey;
			const response = await getServicesFilterApi.request(
				pageParam,
				FILTERS_PAGE_SIZE,
				formatFiltersValues(filters)
			);
			return response.data;
		};

		const {
			items: dynamicServices,
			hasNextPage: hasNextPageServices,
			refetch: refetchServices,
			loadMore: loadMoreServices,
		} = useInfiniteScroll({
			queryKey: filterKeys.resourceServices(storedProductsFilters),
			queryFn: fetchServicesFilters,
			pageSize: FILTERS_PAGE_SIZE,
			enabled: enableServicesFilters,
		});

		// Filter and sort the lists
		const filteredCategories = useFilterAndSort(
			gaawkCategories,
			dynamicCategories,
			resourceFilters?.gaawkCategories || [],
			dynamicCategories.length > 0
		);

		const filteredColors = useFilterAndSort(
			colors,
			dynamicColors,
			resourceFilters?.colors || [],
			dynamicColors.length > 0
		);

		const filteredMaterials = useFilterAndSort(
			materials,
			dynamicMaterials,
			resourceFilters?.materials || [],
			dynamicMaterials.length > 0
		);

		const filteredOwners = useFilterAndSort(
			ownerIds,
			dynamicOwners,
			resourceFilters?.owners || [],
			dynamicOwners.length > 0
		);

		const filteredServices = useFilterAndSort(
			serviceTags,
			dynamicServices,
			resourceFilters?.services || [],
			dynamicServices.length > 0
		);

		// References for components
		const categoriesRef = useRef();
		const colorsRef = useRef();
		const materialsRef = useRef();
		const ownersRef = useRef();
		const servicesRef = useRef();

		// Expose methods to parent component
		useImperativeHandle(ref, () => ({
			updateDynamicFilters(key, tag) {
				switch (key) {
					case "gaawkCategories":
						categoriesRef.current?.removeItem(tag);
						break;
					case "colors":
						colorsRef.current?.removeItem(tag);
						break;
					case "materials":
						materialsRef.current?.removeItem(tag);
						break;
					case "ownerIds":
						ownersRef.current?.removeItem(tag);
						break;
					case "serviceTags":
						servicesRef.current?.removeItem(tag);
						break;
					default:
						break;
				}
			},
		}));

		if (isFetching)
			return <LoadingSpinner customStyle={styles.loading_spinner} />;

		return (
			<div className={styles.container}>
				{/* Categories */}
				{resourceFilters?.gaawkCategories?.length > 0 && (
					<>
						<NewMultiCheckbox
							ref={categoriesRef}
							title={"Categories"}
							options={filteredCategories.map((item) =>
								defaultFormatter(item)
							)}
							onSelect={(categories) =>
								dispatch(
									updateProductsFilters({
										gaawkCategories: categories,
									})
								)
							}
							selected={gaawkCategories}
							perRow="2, auto"
						/>

						{((dynamicCategories?.length === 0 &&
							resourceFilters?.gaawkCategories.length >= 4) ||
							(dynamicCategories?.length > 0 &&
								hasNextPageCategories)) && (
							<SeeMore
								onClick={() => {
									if (dynamicCategories?.length === 0) {
										refetchCategories();
									} else {
										loadMoreCategories();
									}
									setEnableCategoriesFilters(true);
								}}
							/>
						)}
					</>
				)}

				{/* Colors */}
				{resourceFilters?.colors?.length > 0 && (
					<>
						<NewMultiCheckbox
							ref={colorsRef}
							title={"Colors"}
							options={filteredColors.map((item) =>
								defaultFormatter(item)
							)}
							onSelect={(selectedColors) =>
								dispatch(
									updateProductsFilters({
										productFilters: {
											...storedProductsFilters.productFilters,
											colors: selectedColors,
										},
									})
								)
							}
							selected={colors}
							perRow="2, auto"
						/>

						{((dynamicColors?.length === 0 &&
							resourceFilters?.colors.length >= 4) ||
							(dynamicColors?.length > 0 &&
								hasNextPageColors)) && (
							<SeeMore
								onClick={() => {
									if (dynamicColors?.length === 0) {
										refetchColors();
									} else {
										loadMoreColors();
									}
									setEnableColorsFilters(true);
								}}
							/>
						)}
					</>
				)}

				{/* Materials */}
				{resourceFilters?.materials?.length > 0 && (
					<>
						<NewMultiCheckbox
							ref={materialsRef}
							title={"Materials"}
							options={filteredMaterials.map((item) =>
								defaultFormatter(item)
							)}
							onSelect={(selectedMaterials) =>
								dispatch(
									updateProductsFilters({
										productFilters: {
											...storedProductsFilters.productFilters,
											materials: selectedMaterials,
										},
									})
								)
							}
							selected={materials}
							perRow="2, auto"
						/>

						{((dynamicMaterials?.length === 0 &&
							resourceFilters?.materials.length >= 4) ||
							(dynamicMaterials?.length > 0 &&
								hasNextPageMaterials)) && (
							<SeeMore
								onClick={() => {
									if (dynamicMaterials?.length === 0) {
										refetchMaterials();
									} else {
										loadMoreMaterials();
									}
									setEnableMaterialsFilters(true);
								}}
							/>
						)}
					</>
				)}

				{/* Owners */}
				{resourceFilters?.owners?.length > 0 && (
					<>
						<NewMultiCheckbox
							ref={ownersRef}
							title={"Owners"}
							options={filteredOwners.map((item) =>
								defaultFormatter(item)
							)}
							onSelect={(selectedOwners) =>
								dispatch(
									updateProductsFilters({
										ownerIds: selectedOwners,
									})
								)
							}
							selected={ownerIds}
							perRow="2, auto"
						/>

						{((dynamicOwners?.length === 0 &&
							resourceFilters?.owners.length >= 4) ||
							(dynamicOwners?.length > 0 &&
								hasNextPageOwners)) && (
							<SeeMore
								onClick={() => {
									if (dynamicOwners?.length === 0) {
										refetchOwners();
									} else {
										loadMoreOwners();
									}
									setEnableOwnersFilters(true);
								}}
							/>
						)}
					</>
				)}

				{/* Services */}
				{resourceFilters?.services?.length > 0 && (
					<>
						<NewMultiCheckbox
							ref={servicesRef}
							title={"Services"}
							options={filteredServices.map((item) =>
								defaultFormatter(item)
							)}
							onSelect={(selectedServices) =>
								dispatch(
									updateProductsFilters({
										productFilters: {
											...storedProductsFilters.productFilters,
											serviceTags: selectedServices,
										},
									})
								)
							}
							selected={serviceTags}
							perRow="2, auto"
						/>

						{((dynamicServices?.length === 0 &&
							resourceFilters?.services.length >= 4) ||
							(dynamicServices?.length > 0 &&
								hasNextPageServices)) && (
							<SeeMore
								onClick={() => {
									if (dynamicServices?.length === 0) {
										refetchServices();
									} else {
										loadMoreServices();
									}
									setEnableServicesFilters(true);
								}}
							/>
						)}
					</>
				)}

				{/* Dimensions */}
				<div className={styles.dimensions_container}>
					<div className={styles.dimension_row}>
						<h4>Length</h4>
						<div className={styles.dimension_group}>
							<InputWrapper
								className={styles.mb_10}
								component={
									<TextInput
										value={minLengthValue}
										placeholder="Min"
										onChange={({ target }) =>
											setMinLengthValue(target.value)
										}
										type="number"
									/>
								}
							/>
							<InputWrapper
								className={styles.mb_10}
								component={
									<TextInput
										value={maxLengthValue}
										placeholder="Max"
										onChange={({ target }) =>
											setMaxLengthValue(target.value)
										}
										type="number"
									/>
								}
							/>

							<div className={styles.unit_select}>
								<CustomSelect
									options={measurementUnits}
									placeholder="Select"
									height="35px"
									onChange={(unit) =>
										dispatch(
											updateProductsFilters({
												productFilters: {
													...storedProductsFilters.productFilters,
													lengthUnit: unit
														? unit.value
														: "METER",
												},
											})
										)
									}
									value={measurementUnits.find(
										(item) => item.value === lengthUnit
									)}
								/>
							</div>
						</div>
					</div>

					<div className={styles.dimension_row}>
						<h4>Width</h4>
						<div className={styles.dimension_group}>
							<InputWrapper
								className={styles.mb_10}
								component={
									<TextInput
										value={minWidthValue}
										placeholder="Min"
										onChange={({ target }) =>
											setMinWidthValue(target.value)
										}
										type="number"
									/>
								}
							/>
							<InputWrapper
								className={styles.mb_10}
								component={
									<TextInput
										value={maxWidthValue}
										placeholder="Max"
										onChange={({ target }) =>
											setMaxWidthValue(target.value)
										}
										type="number"
									/>
								}
							/>

							<div className={styles.unit_select}>
								<CustomSelect
									options={measurementUnits}
									placeholder="Select"
									height="35px"
									onChange={(unit) =>
										dispatch(
											updateProductsFilters({
												productFilters: {
													...storedProductsFilters.productFilters,
													widthUnit: unit
														? unit.value
														: "METER",
												},
											})
										)
									}
									value={measurementUnits.find(
										(item) => item.value === widthUnit
									)}
								/>
							</div>
						</div>
					</div>

					<div className={styles.dimension_row}>
						<h4>Height</h4>

						<div className={styles.dimension_group}>
							<InputWrapper
								className={styles.mb_10}
								component={
									<TextInput
										value={minHeightValue}
										placeholder="Min"
										onChange={({ target }) =>
											setMinHeightValue(target.value)
										}
										type="number"
									/>
								}
							/>
							<InputWrapper
								className={styles.mb_10}
								component={
									<TextInput
										value={maxHeightValue}
										placeholder="Max"
										onChange={({ target }) =>
											setMaxHeightValue(target.value)
										}
										type="number"
									/>
								}
							/>

							<div className={styles.unit_select}>
								<CustomSelect
									options={measurementUnits}
									placeholder="Select"
									height="35px"
									onChange={(unit) =>
										dispatch(
											updateProductsFilters({
												productFilters: {
													...storedProductsFilters.productFilters,
													heightUnit: unit
														? unit.value
														: "METER",
												},
											})
										)
									}
									value={measurementUnits.find(
										(item) => item.value === heightUnit
									)}
								/>
							</div>
						</div>
					</div>

					<div className={styles.dimension_row}>
						<h4>Weight</h4>
						<div className={styles.dimension_group}>
							<InputWrapper
								className={styles.mb_10}
								component={
									<TextInput
										value={minWeightValue}
										placeholder="Min"
										onChange={({ target }) =>
											setMinWeightValue(target.value)
										}
										type="number"
									/>
								}
							/>
							<InputWrapper
								className={styles.mb_10}
								component={
									<TextInput
										value={maxWeightValue}
										placeholder="Max"
										onChange={({ target }) =>
											setMaxWeightValue(target.value)
										}
										type="number"
									/>
								}
							/>

							<div className={styles.unit_select}>
								<CustomSelect
									options={weightUnits}
									placeholder="Select"
									height="35px"
									onChange={(unit) =>
										dispatch(
											updateProductsFilters({
												productFilters: {
													...storedProductsFilters.productFilters,
													weightUnit: unit
														? unit.value
														: "GRAM",
												},
											})
										)
									}
									value={weightUnits.find(
										(item) => item.value === weightUnit
									)}
								/>
							</div>
						</div>
					</div>
				</div>

				{/* Uncategorized checkbox */}
				{/* <div className={styles.checkbox_container}>
					<label>
						<input
							type="checkbox"
							checked={onlyUncategorizedProducts}
							onChange={() =>
								dispatch(
									updateProductsFilters({
										productFilters: {
											...storedProductsFilters.productFilters,
											onlyUncategorizedProducts:
												!onlyUncategorizedProducts,
										},
									})
								)
							}
						/>
						Only show uncategorized products
					</label>
				</div> */}

				<div className={styles.button_container}>
					<GaawkButton
						severity={"tertiary"}
						text={"Clear Filters"}
						className={styles.btn}
						onClick={() => {
							dispatch(resetProductsFilters());
							onClearFilters && onClearFilters();
						}}
					/>
					{onApplyFilters && (
						<GaawkButton
							text={"Apply"}
							className={styles.btn}
							onClick={onApplyFilters}
						/>
					)}
				</div>
			</div>
		);
	}
);

export default ResourcesFilter;
