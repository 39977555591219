import styles from "./BackButton.module.scss";
import { ReactComponent as BackIcon } from "images/back.svg";

const BackButton = ({ onBack, color = "#000" }) => {
	return (
		<button className={styles.back_button} type="button" onClick={onBack}>
			<BackIcon fill={color} />
		</button>
	);
};

export default BackButton;
