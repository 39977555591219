import { Fragment, useMemo, useState } from "react";
import useDebounce from "../../../hooks/useDebounce";
import SearchInput from "../../Utils/SubComs/Inputs/SearchInput/SearchInput";
import styles from "./CityModal.module.css";
import NoResults from "../../Utils/SubComs/NoResults/NoResults";
import useFetchLocation from "../../../hooks/useFetchLocation";
import { locationKeys } from "queryKeys/location-key-factory";
import NewGaawkModal from "components/Utils/NewGaawkModal/NewGaawkModal";
import { Skeleton } from "primereact/skeleton";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { classNames } from "primereact/utils";

const PAGE_SIZE = 20;

const CityModal = ({ show, onClose, onSelectCity }) => {
	const fetchCities = useFetchLocation(true, PAGE_SIZE);

	const [selectedCityId, _] = useState(() => JSON.parse(localStorage.getItem("selectedCity")).id);

	const [searchInput, setSearchInput] = useState("");
	const debouncedSearch = useDebounce(searchInput);

	const handleSearchInputChange = ({ target }) => {
		const query = target.value;
		setSearchInput(query);
	};

	const { items, isLoading, isFetchingNextPage, viewRef } = useInfiniteScroll({
		queryKey: locationKeys.cities(debouncedSearch),
		queryFn: fetchCities,
		pageSize: PAGE_SIZE,
		enabled: show,
	});

	const citiesList = useMemo(
		() =>
			items?.map((city, index) => {
				const isLastItem = index === items.length - 1;

				return (
					<Fragment key={city.id}>
						<button
							ref={isLastItem ? viewRef : null}
							className={classNames(styles.city_item, {
								[styles.active]: selectedCityId === city.id,
							})}
							onClick={() => {
								onSelectCity(city);
								onClose();
							}}
						>
							{`${city.name}, ${city.countryDTO.name}`}
						</button>

						{isLastItem && isFetchingNextPage && (
							<div className={styles.loading_item_container}>
								<Skeleton height="2rem"></Skeleton>
								<Skeleton height="2rem"></Skeleton>
							</div>
						)}
					</Fragment>
				);
			}),
		[items, viewRef, isFetchingNextPage]
	);

	return (
		<NewGaawkModal
			visible={show}
			onHide={onClose}
			title={"Location"}
			children={
				<div>
					<div className={styles.search_input_wrapper}>
						<SearchInput
							onChange={handleSearchInputChange}
							value={searchInput}
							showIcons={true}
							onClearClicked={() => setSearchInput("")}
							border={false}
							placeholder={"Search cities"}
						/>
					</div>

					{isLoading && (
						<div className={styles.loading_item_container}>
							<Skeleton height="2rem"></Skeleton>
							<Skeleton height="2rem"></Skeleton>
							<Skeleton height="2rem"></Skeleton>
							<Skeleton height="2rem"></Skeleton>
						</div>
					)}

					{items.length > 0 && !isLoading && citiesList}

					<NoResults
						visible={!isLoading && items.length === 0}
						title={"Nothing Found!"}
					/>
				</div>
			}
		/>
	);
};

export default CityModal;
