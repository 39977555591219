import styles from "./AddMembersModal.module.css";
import NewGaawkModal from "components/Utils/NewGaawkModal/NewGaawkModal";
import ContactSearch from "./ContactSearch";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import GaawkButton from "components/Utils/Button/GaawkButton";
import TagContainer from "components/Utils/SubComs/Tags/TagContainer";
import ContactLoader from "components/Utils/SubComs/CustomLoader/ContactLoader";

const AddMembersModal = ({
	show,
	onClose,
	onBack,
	searchInput,
	onInputChange,
	onClear,
	participantsList,
	contactList,
	onConfirm,
	title = "Add members",
	buttonText = "Create Group",
	isLoading,
	disabled = false,
}) => {
	return (
		<NewGaawkModal
			visible={show}
			onHide={onClose}
			title={title}
			onBack={onBack}
			children={
				<>
					<div className={styles.group_search_wrapper}>
						<ContactSearch
							searchInput={searchInput}
							onSearchInputChange={onInputChange}
							onClear={onClear}
						/>
					</div>
					{participantsList?.length > 0 && (
						<TagContainer
							items={participantsList}
							containerClassName={styles.tags_container}
							border={false}
							scrollToBottomEnabled={true}
						/>
					)}
					<div className={styles.add_group_container}>
						<div className={styles.contact_list_wrapper}>
							{contactList}

							{isLoading && (
								<div className={styles.loading_container}>
									<ContactLoader />
								</div>
							)}

							<NoResults
								// isSearch={true}
								text={"No results related to your search"}
								visible={contactList?.length === 0 && !isLoading}
							/>
						</div>
					</div>

					<div className={styles.form_controls}>
						<GaawkButton
							severity={"tertiary"}
							text="Cancel"
							type="button"
							onClick={onClose}
						/>

						<GaawkButton
							isLoading={disabled}
							onClick={onConfirm}
							text={buttonText}
							type="button"
						/>
					</div>
				</>
			}
		/>
	);
};

export default AddMembersModal;
