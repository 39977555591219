import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
import styles from "./Resources.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useMemo, Fragment, useEffect, useRef } from "react";
import useUserLocation from "hooks/useUserLocation";
import NavBar from "components/Utils/SubComs/NavBar/NavBar";
import CityModal from "components/Boards/BoardsComponents/CityModal";
import useApi from "hooks/useApi";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import servicesApi from "api/services";
import { servicesKeys } from "queryKeys/services-key-factory";
import ServiceCard from "./ServiceCard/ServiceCard";
import ScrollableContainer from "components/Utils/SubComs/ScrollableContainer/ScrollableContainer";
import ResourcePanel from "./ResourcePanel/ResourcePanel";
import routes from "components/Routing/routing-keys";
import ServiceCardLoader from "components/Utils/SubComs/CustomLoader/ServiceCardLoader";
import ContextHeader from "components/Utils/SubComs/ContextHeader/ContextHeader";
import { ReactComponent as FiltersIcon } from "images/filters.svg";
import { productsKeys } from "queryKeys/products-key-factory";
import productApi from "api/product";
import NewProductItem from "components/Profile/ProductsTab/ProductsComponents/NewProductItem";
import ProductLoader from "components/Utils/SubComs/CustomLoader/ProductLoader";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import profileApi from "api/profile";
import { profileKeys } from "queryKeys/profile-key-factory";
import { Skeleton } from "primereact/skeleton";
import ProfilePic from "components/Home/ProfilePic";
import gaawkServiceApi from "api/gaawk-service";
import { gaawkServicesKeys } from "queryKeys/gaawk-service-key-factory";
import Tag from "components/Utils/SubComs/Tags/Tag";
import { useCustomQuery } from "hooks/useCustomQuery";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import SelectSearchInput from "components/Utils/SubComs/Inputs/SelectSearchInput/SelectSearchInput";
import { useDispatch, useSelector } from "react-redux";
import useUrlParams from "hooks/useUrlParams";
import useDebounce from "hooks/useDebounce";
import searchApi from "api/search";
import { searchKeys } from "queryKeys/search-key-factory";
import { jobTitleFormatter } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import {
	updateProductsFilters,
	resetProductsFilters,
	initialState,
} from "store/slices/filters";
import NewGaawkModal from "components/Utils/NewGaawkModal/NewGaawkModal";
import { formatFiltersValues } from "components/Utils/General";
import ResourcesFilter from "components/Search/FilterTabs/ResourcesFilter";
import ScrollContainer from "react-indiana-drag-scroll";

const PAGE_SIZE = 20;

// Custom hooks for data fetching
const useNearbyServices = (userCoordinates, enabled) => {
	const getNearbyServicesApi = useApi(
		servicesApi.getNearbyServices,
		true,
		true
	);

	const fetchSuggestedJobs = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, __, ___, userCoords] = queryKey;
		const response = await getNearbyServicesApi.request(
			pageParam,
			PAGE_SIZE,
			userCoords.lat,
			userCoords.lng
		);
		return response.data;
	};

	return useInfiniteScroll({
		queryKey: servicesKeys.nearby({
			lat: userCoordinates.lat,
			lng: userCoordinates.lng,
		}),
		queryFn: fetchSuggestedJobs,
		pageSize: PAGE_SIZE,
		enabled,
	});
};

const useNearbyProducts = (userCoordinates, enabled) => {
	const getNearbyProductsApi = useApi(
		productApi.getProductNearby,
		true,
		true
	);

	const fetchNearbyProducts = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, __, ___, userCoords] = queryKey;
		const response = await getNearbyProductsApi.request(
			pageParam,
			PAGE_SIZE,
			userCoords.lat,
			userCoords.lng
		);
		return response.data;
	};

	return useInfiniteScroll({
		queryKey: productsKeys.nearby(userCoordinates),
		queryFn: fetchNearbyProducts,
		pageSize: PAGE_SIZE,
		enabled,
	});
};

const useFeaturedProfiles = (userCoordinates, enabled) => {
	const getFeaturesProfilesApi = useApi(
		profileApi.profilesWithServicesOrProducts,
		true,
		true
	);

	const fetchFeaturesProfiles = async ({
		pageParam = 0,
		signal,
		queryKey,
	}) => {
		const [_, __, userCoords] = queryKey;
		const response = await getFeaturesProfilesApi.request(
			pageParam,
			PAGE_SIZE,
			userCoords.lat,
			userCoords.lng
		);
		return response.data;
	};

	return useInfiniteScroll({
		queryKey: profileKeys.featuredProfiles(userCoordinates),
		queryFn: fetchFeaturesProfiles,
		pageSize: PAGE_SIZE,
		enabled,
	});
};

const useSuggestedServices = (userCoordinates, enabled) => {
	const getSuggestedServicesApi = useApi(
		servicesApi.getSuggestedServices,
		true,
		true
	);

	const fetchSuggestedServices = async ({
		pageParam = 0,
		signal,
		queryKey,
	}) => {
		const [_, __, ___, userCoords] = queryKey;
		const response = await getSuggestedServicesApi.request(
			pageParam,
			PAGE_SIZE,
			userCoords.lat,
			userCoords.lng
		);
		return response.data;
	};

	return useInfiniteScroll({
		queryKey: servicesKeys.suggested(userCoordinates),
		queryFn: fetchSuggestedServices,
		pageSize: PAGE_SIZE,
		enabled,
	});
};

const useConnectionsProducts = (enabled) => {
	const getConnectionsProductsApi = useApi(
		productApi.getConnectionsProducts,
		true,
		true
	);

	const fetchConnectionsProducts = async ({ pageParam = 0 }) => {
		const response = await getConnectionsProductsApi.request(
			pageParam,
			PAGE_SIZE
		);
		return response.data;
	};

	return useInfiniteScroll({
		queryKey: productsKeys.connections(),
		queryFn: fetchConnectionsProducts,
		pageSize: PAGE_SIZE,
		enabled,
	});
};

const useServicesByGaawkService = (
	gaawkServiceId,
	userCoordinates,
	enabled
) => {
	const getServicesByGaawkServiceIdApi = useApi(
		servicesApi.getServicesByGaawkServiceId,
		true,
		true
	);

	const fetchServicesByGaawkServiceId = async ({
		pageParam = 0,
		signal,
		queryKey,
	}) => {
		const [_, __, ___, gaawkSvcId, userCoords] = queryKey;
		const response = await getServicesByGaawkServiceIdApi.request(
			pageParam,
			PAGE_SIZE,
			gaawkSvcId,
			userCoords.lat,
			userCoords.lng
		);
		return response.data;
	};

	return useInfiniteScroll({
		queryKey: servicesKeys.byGaawkServiceId(
			gaawkServiceId,
			userCoordinates
		),
		queryFn: fetchServicesByGaawkServiceId,
		pageSize: PAGE_SIZE,
		enabled,
	});
};

const useProductsByGaawkService = (
	gaawkServiceId,
	userCoordinates,
	enabled
) => {
	const getProductsByGaawkServiceIdApi = useApi(
		productApi.getProductByGaawkServiceId,
		true,
		true
	);

	const fetchProductsByGaawkServiceId = async ({
		pageParam = 0,
		signal,
		queryKey,
	}) => {
		const [_, __, ___, gaawkSvcId, userCoords] = queryKey;
		const response = await getProductsByGaawkServiceIdApi.request(
			pageParam,
			PAGE_SIZE,
			gaawkSvcId,
			userCoords.lat,
			userCoords.lng
		);
		return response.data;
	};

	return useInfiniteScroll({
		queryKey: productsKeys.byGaawkServiceId(
			gaawkServiceId,
			userCoordinates
		),
		queryFn: fetchProductsByGaawkServiceId,
		pageSize: PAGE_SIZE,
		enabled,
	});
};

// Components for the different sections
const FeaturedProfileLoader = () => (
	<div className={styles.featured_profile_pic}>
		<Skeleton shape="circle" size={56} />
		<Skeleton width={50} />
	</div>
);

const ServicesList = ({
	items,
	viewRef,
	isFetchingNextPage,
	navigate,
	hasNextPage,
}) => (
	<ScrollableContainer hasNextPage={hasNextPage}>
		{items?.map((resource, index) => {
			const isLastItem = index === items.length - 1;
			return (
				<Fragment key={resource.uuid}>
					<ServiceCard
						className={styles.resource_service_card}
						ref={isLastItem ? viewRef : null}
						data={resource}
						onClick={() => {
							navigate(
								`${routes.serviceTag(
									resource.tag
								)}?from=resources`
							);
						}}
					/>
					{isLastItem && isFetchingNextPage && (
						<ServiceCardLoader
							className={styles.resource_service_card}
						/>
					)}
				</Fragment>
			);
		})}
	</ScrollableContainer>
);

const ProductsList = ({
	products,
	handleProductCardClick,
	isFetchingNextPage,
	fetchStatus,
	hasNextPage,
	loadMore,
}) => (
	<div>
		<div className={styles.products_wrapper}>
			<div className={styles.products_container}>
				{products?.map((product) => (
					<NewProductItem
						key={product.uuid}
						product={product}
						onClick={() => handleProductCardClick(product)}
					/>
				))}

				{(isFetchingNextPage || fetchStatus === "fetching") && (
					<>
						<ProductLoader />
						<ProductLoader />
						<ProductLoader />
					</>
				)}

				{hasNextPage && <div className={styles.gradient_overlay} />}
			</div>
		</div>
		{hasNextPage && (
			<SeeMore
				onClick={loadMore}
				horizontal={false}
				text="Show more products"
			/>
		)}
	</div>
);

const Resources = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isPanelOpen, setIsPanelOpen] = useState(false);
	const [selectedResource, setSelectedResource] = useState(null);

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const l3ServiceId = queryParams.get("L3") || "";
	const l2ServiceId = queryParams.get("L2") || "";
	const l1ServiceId = queryParams.get("L1") || "";
	const hasGaawkServicesParams =
		!!l1ServiceId || !!l2ServiceId || !!l3ServiceId;
	const activeGaawkServiceId = l3ServiceId || l2ServiceId || l1ServiceId;

	const [pageTitle, setPageTitle] = useState("Resources");
	const [showLocationModal, setShowLocationModal] = useState(false);
	const [showFiltersModal, setShowFiltersModal] = useState(false);

	const { userCoordinates, handleSelectedCity } = useUserLocation();

	// Update page title based on URL params or state
	useEffect(() => {
		if (!hasGaawkServicesParams) setPageTitle("Resources");
	}, [hasGaawkServicesParams]);

	useEffect(() => {
		if (location.state?.pageTitle) setPageTitle(location.state.pageTitle);
	}, [location.state?.pageTitle]);

	const handleProductCardClick = (resource) => {
		setSelectedResource(resource);
		setIsPanelOpen(true);
	};

	const handleClosePanel = () => {
		setIsPanelOpen(false);
		// Reset selected resource after animation completes
		setTimeout(() => {
			setSelectedResource(null);
		}, 300);
	};

	// Data fetching hooks
	const {
		items: nearbyServices,
		viewRef: nearbyServicesViewRef,
		isFetchingNextPage: isFetchingNextPageServices,
		isLoading: isLoadingNearbyServices,
		hasNextPage: hasNextPageServices,
	} = useNearbyServices(userCoordinates, !hasGaawkServicesParams);

	const {
		items: nearbyProducts,
		isFetchingNextPage: isFetchingNextPageProducts,
		loadMore: loadMoreProducts,
		hasNextPage: hasNextPageProducts,
		fetchStatus: productsFetchStatus,
	} = useNearbyProducts(userCoordinates, !hasGaawkServicesParams);

	const {
		items: featuredProfiles,
		viewRef: featuredProfilesViewRef,
		isFetchingNextPage: isFetchingNextPageProfiles,
		isLoading: isLoadingProfiles,
		hasNextPage: hasNextPageProfiles,
	} = useFeaturedProfiles(userCoordinates, !hasGaawkServicesParams);

	const {
		items: suggestedServices,
		viewRef: suggestedServicesViewRef,
		isFetchingNextPage: isFetchingNextPageSuggestedServices,
		isLoading: isLoadingSuggestedServices,
		hasNextPage: hasNextPageSuggestedServices,
	} = useSuggestedServices(userCoordinates, !hasGaawkServicesParams);

	const {
		items: connectionsProducts,
		isFetchingNextPage: isFetchingNextPageConnectionsProducts,
		loadMore: loadMoreConnectionsProducts,
		hasNextPage: hasNextPageConnectionsProducts,
		fetchStatus: connectionsProductsFetchStatus,
	} = useConnectionsProducts(!hasGaawkServicesParams);

	const {
		items: servicesByGaawkServiceId,
		viewRef: servicesByGaawkServiceIdViewRef,
		isFetchingNextPage: isFetchingNextPageServicesByGaawkServiceId,
		isLoading: isLoadingServicesByGaawkServiceId,
		hasNextPage: hasNextPageServicesByGaawkServiceId,
	} = useServicesByGaawkService(
		activeGaawkServiceId,
		userCoordinates,
		hasGaawkServicesParams
	);

	const {
		items: productsByGaawkServiceId,
		isFetchingNextPage: isFetchingNextPageProductsByGaawkServiceId,
		loadMore: loadMoreProductsByGaawkServiceId,
		hasNextPage: hasNextPageProductsByGaawkServiceId,
		fetchStatus: productsByGaawkServiceIdFetchStatus,
	} = useProductsByGaawkService(
		activeGaawkServiceId,
		userCoordinates,
		hasGaawkServicesParams
	);

	// Gaawk Services fetch
	const getGaawkCategoriesApi = useApi(
		gaawkServiceApi.getGaawkCategories,
		true,
		true
	);

	const fetchGaawkServices = async ({ signal, queryKey }) => {
		const [_, __, q, serviceLevels, withServicesOrProducts] = queryKey;
		const response = await getGaawkCategoriesApi.request(
			serviceLevels.l1ServiceId,
			serviceLevels.l2ServiceId,
			q,
			withServicesOrProducts
		);
		return response.data;
	};

	const { data: gaawkServices, isFetching: isFetchingGaawkServices } =
		useCustomQuery({
			queryKey: gaawkServicesKeys.gaawkCategories(
				"",
				{
					l1ServiceId,
					l2ServiceId,
				},
				true
			),
			queryFn: fetchGaawkServices,
			enabled:
				((!!l2ServiceId || !!l1ServiceId) && !l3ServiceId) ||
				!hasGaawkServicesParams,
		});

	// Memoized components
	const featuredProfilesList = useMemo(() => {
		return featuredProfiles?.map((profile, index) => {
			const isLastItem = index === featuredProfiles.length - 1;
			return (
				<Fragment key={profile.uuid}>
					<ProfilePic
						thumbnail={profile?.profileImage?.image}
						noMarginRight
						w={56}
						h={56}
						ref={isLastItem ? featuredProfilesViewRef : null}
						type={profile?.type}
						name={profile?.name}
						className={styles.featured_profile_pic}
						nameClassName={styles.featured_profile_name}
						deleted={profile?.deleted}
						url={profile?.url}
					/>

					{isLastItem && isFetchingNextPageProfiles && (
						<FeaturedProfileLoader />
					)}
				</Fragment>
			);
		});
	}, [featuredProfiles, featuredProfilesViewRef, isFetchingNextPageProfiles]);

	const gaawkServicesList = useMemo(() => {
		return gaawkServices?.map((service) => {
			const lastService =
				service.serviceL3 || service.serviceL2 || service.serviceL1;

			// Build query params based on available service levels
			const queryParams = new URLSearchParams();

			if (service.serviceL1) {
				queryParams.append("L1", service.serviceL1.uuid);
			}
			if (service.serviceL2) {
				queryParams.append("L2", service.serviceL2.uuid);
			}
			if (service.serviceL3) {
				queryParams.append("L3", service.serviceL3.uuid);
			}

			return (
				<Tag
					key={lastService.uuid}
					itemName={lastService.name}
					onClick={() => {
						navigate(
							`${routes.resources}?${queryParams.toString()}`,
							{
								state: {
									pageTitle: lastService.name,
								},
							}
						);
					}}
				/>
			);
		});
	}, [gaawkServices, navigate]);

	// ! === search resources =====

	const paramConfigs = {
		q: {
			validator: (query) => typeof query === "string",
			defaultValue: "",
		},
	};

	const { params, setParams } = useUrlParams(paramConfigs);

	const { q } = params;

	const productsFiltersObject = useSelector(
		(state) => state.filters.productsFilters
	);

	const storedProductsFilters = useMemo(
		() => ({
			...productsFiltersObject,
			q: q || "",
			latitude: userCoordinates.lat,
			longitude: userCoordinates.lng,
		}),
		[productsFiltersObject, userCoordinates, q]
	);

	const initialStoredFilters = useRef(storedProductsFilters);

	// const [filtersToApply, setFiltersToApply] = useState(() =>
	// 	q ? storedProductsFilters : undefined
	// );
	const [filtersToApply, setFiltersToApply] = useState(undefined);

	// Separate state for search query
	// const [searchQueryFilters, setSearchQueryFilters] = useState(() =>
	// 	q ? { q } : undefined
	// );

	// Combine filters and query for API calls
	// const combinedFilters = useMemo(() => {
	// 	if (!filtersToApply) return undefined;
	// 	return {
	// 		...filtersToApply,
	// 		latitude: userCoordinates.lat,
	// 		longitude: userCoordinates.lng,
	// 	};
	// }, [filtersToApply, userCoordinates]);

	useEffect(() => {
		if (filtersToApply) {
			setFiltersToApply((prevState) => ({
				...prevState,
				latitude: userCoordinates.lat,
				longitude: userCoordinates.lng,
			}));
		}
	}, [userCoordinates]);

	const [searchQuery, setSearchQuery] = useState("");

	const debouncedQuery = useDebounce(searchQuery);

	const searchResourcesTitlesApi = useApi(
		searchApi.searchResourcesTitles,
		true,
		true
	);

	const searchResourcesTitles = async ({
		pageParam = 0,
		signal,
		queryKey,
	}) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, q] = queryKey;
		const response = await searchResourcesTitlesApi.request(
			pageParam,
			PAGE_SIZE,
			q
		);
		return response.data;
	};

	const {
		items: resourcesTitles,
		loadMore: loadMoreResourcesTitles,
		isFetching: isFetchingResourcesTitles,
	} = useInfiniteScroll({
		queryKey: searchKeys.resourcesTitles(debouncedQuery),
		queryFn: searchResourcesTitles,
		pageSize: PAGE_SIZE,
	});

	// Resource search API calls
	const searchProductsApi = useApi(searchApi.searchProducts, true, true);
	const searchServicesApi = useApi(searchApi.searchServices, true, true);

	// Products search
	const fetchSearchedProducts = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, filters] = queryKey;
		const response = await searchProductsApi.request(
			pageParam,
			PAGE_SIZE,
			formatFiltersValues(filters)
		);
		return response.data;
	};

	const {
		items: searchProductResults,
		isFetchingNextPage: isFetchingNextPageProductSearch,
		isLoading: isLoadingProductSearch,
		fetchStatus: fetchStatusProductSearch,
		isSuccess: isProductSearchSuccess,
		hasNextPage: hasNextPageProductSearch,
		loadMore: loadMoreProductSearch,
	} = useInfiniteScroll({
		queryKey: searchKeys.products({ ...filtersToApply, q }),
		queryFn: fetchSearchedProducts,
		pageSize: PAGE_SIZE,
		enabled: !!filtersToApply || !!q,
	});

	const searchedProductsResults = searchProductResults?.flatMap(
		(page) => page.list
	);

	// Services search
	const fetchSearchedServices = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, filters] = queryKey;
		const response = await searchServicesApi.request(
			pageParam,
			PAGE_SIZE,
			formatFiltersValues(filters)
		);
		return response.data;
	};

	const {
		items: searchServiceResults,
		isFetchingNextPage: isFetchingNextPageServiceSearch,
		isLoading: isLoadingServiceSearch,
		fetchStatus: fetchStatusServiceSearch,
		isSuccess: isServiceSearchSuccess,
		hasNextPage: hasNextPageServiceSearch,
		loadMore: loadMoreServiceSearch,
	} = useInfiniteScroll({
		queryKey: searchKeys.services({ ...filtersToApply, q }),
		queryFn: fetchSearchedServices,
		pageSize: PAGE_SIZE,
		enabled: !!filtersToApply || !!q,
	});

	const searchedServicesResults = searchServiceResults?.flatMap(
		(page) => page.list
	);

	// Combined search status
	const isLoadingSearch = isLoadingProductSearch || isLoadingServiceSearch;
	const isSearchSuccess = isProductSearchSuccess && isServiceSearchSuccess;

	// Filter references
	const resourcesFilterRef = useRef(); //TODO

	// When a user applies filters
	const handleApplyFilters = () => {
		const { q, ...rest } = storedProductsFilters;
		setFiltersToApply({
			...rest,
			latitude: userCoordinates.lat,
			longitude: userCoordinates.lng,
		});

		setShowFiltersModal(false);
	};

	// When a user clears filters
	const handleClearFilters = () => {
		// const resetFilters = {
		// 	...initialState.productsFilters,
		// 	latitude: userCoordinates.lat,
		// 	longitude: userCoordinates.lng,
		// };
		// setFiltersToApply(resetFilters);
		// // If there's no query, we should show regular sections
		// if (!q && !searchQuery) {
		// 	setFiltersToApply(undefined);
		// }
		setFiltersToApply(undefined);
		// setShowFiltersModal(false);
	};

	// If q changes, update only the search query filters
	// useEffect(() => {
	// 	if (q) {
	// 		setSearchQueryFilters({ q });
	// 	} else {
	// 		setSearchQueryFilters(undefined);
	// 	}
	// }, [q]);

	// If we have search results, show them. Otherwise show regular sections
	const showSearchResults = (!!filtersToApply || !!q) && isSearchSuccess;

	const tagsList = useMemo(() => {
		const valuesList = [];

		for (const [k, v] of Object.entries(filtersToApply || {})) {
			if (k === "productFilters") {
				for (const [key, value] of Object.entries(v)) {
					// * below for colors, materials, serviceTags
					if (Array.isArray(value) && value?.length > 0) {
						value.map((item) =>
							valuesList.push(
								<Tag
									key={item.value}
									itemName={item.label}
									customStyle={styles.custom_tag}
									onRemove={() => {
										const updatedFilters = {
											...filtersToApply,
											productFilters: {
												...filtersToApply.productFilters,
												...(key === "colors" && {
													colors: filtersToApply.productFilters.colors.filter(
														(color) =>
															color.value !==
															item.value
													),
												}),
												...(key === "materials" && {
													materials:
														filtersToApply.productFilters.materials.filter(
															(material) =>
																material.value !==
																item.value
														),
												}),
												...(key === "serviceTags" && {
													serviceTags:
														filtersToApply.productFilters.serviceTags.filter(
															(serviceTag) =>
																serviceTag.value !==
																item.value
														),
												}),
											},
										};
										setFiltersToApply(updatedFilters);
										dispatch(
											updateProductsFilters(
												updatedFilters
											)
										);
									}}
								/>
							)
						);
					}
				}
			}
			//* below for gaawkCategories and ownerIds
			else if (Array.isArray(v) && v?.length > 0) {
				v.map((item) =>
					valuesList.push(
						<Tag
							key={item.value}
							itemName={item.label}
							customStyle={styles.custom_tag}
							onRemove={() => {
								const updatedFilters = {
									...filtersToApply,
									...(k === "gaawkCategories" && {
										gaawkCategories:
											filtersToApply.gaawkCategories.filter(
												(gaawkCategory) =>
													gaawkCategory.value !==
													item.value
											),
									}),
									...(k === "ownerIds" && {
										ownerIds:
											filtersToApply.ownerIds.filter(
												(owner) =>
													owner.value !== item.value
											),
									}),
								};
								setFiltersToApply(updatedFilters);
								dispatch(updateProductsFilters(updatedFilters));
							}}
						/>
					)
				);
			}
		}
		return valuesList;
	}, [dispatch, filtersToApply]);

	return (
		<>
			<DefaultPage
				headerRightContent={
					<ContextHeader
						title={pageTitle}
						rightSideContent={
							<button onClick={() => setShowLocationModal(true)}>
								<img
									src={userCoordinates?.countryDTO?.flagUrl}
									alt=""
								/>
							</button>
						}
					/>
				}
				leftSideChildren={<NavBar />}
				rightSideChildren={
					<>
						<div
							className={`${styles.container} ${
								isPanelOpen ? styles.with_panel : ""
							}`}
						>
							{!hasGaawkServicesParams && (
								<div className={styles.search_wrapper}>
									<SelectSearchInput
										value={
											q ? { value: q, label: q } : null
										}
										onChange={(option) => {
											setParams({
												q: option ? option.value : "",
											});
										}}
										isLoading={isFetchingResourcesTitles}
										onInputChange={setSearchQuery}
										onMenuScrollToBottom={
											loadMoreResourcesTitles
										}
										options={resourcesTitles.map(
											jobTitleFormatter
										)}
									/>

									<button
										className={styles.filter_btn}
										type={"button"}
										onClick={() =>
											setShowFiltersModal(true)
										}
									>
										<FiltersIcon />
									</button>
								</div>
							)}

							{tagsList?.length > 0 && (
								<ScrollContainer
									className={styles.scroll_container}
								>
									{tagsList}
								</ScrollContainer>
							)}

							{/* Show search results if we have them */}
							{showSearchResults && (
								<>
									<h3>Results in Products</h3>
									{isLoadingSearch ? (
										<div
											className={styles.products_wrapper}
										>
											<div
												className={
													styles.products_container
												}
											>
												<ProductLoader />
												<ProductLoader />
												<ProductLoader />
											</div>
										</div>
									) : searchedProductsResults?.length > 0 ? (
										<ProductsList
											products={searchedProductsResults}
											handleProductCardClick={
												handleProductCardClick
											}
											isFetchingNextPage={
												isFetchingNextPageProductSearch
											}
											fetchStatus={
												fetchStatusProductSearch
											}
											hasNextPage={
												hasNextPageProductSearch
											}
											loadMore={loadMoreProductSearch}
										/>
									) : (
										<NoResults
											visible={
												searchedProductsResults?.length ===
												0
											}
											text="No products found matching your search criteria"
										/>
									)}

									<h3>Results in Services</h3>

									{isLoadingSearch ? (
										<ScrollableContainer
											hasNextPage={false}
										>
											{Array(12)
												.fill()
												.map((_, index) => (
													<ServiceCardLoader
														key={`service-search-loader-${index}`}
														className={
															styles.resource_service_card
														}
													/>
												))}
										</ScrollableContainer>
									) : searchedServicesResults?.length > 0 ? (
										<ServicesList
											items={searchedServicesResults}
											viewRef={null} //TODO !!
											isFetchingNextPage={
												isFetchingNextPageServiceSearch
											}
											navigate={navigate}
											hasNextPage={
												hasNextPageServiceSearch
											}
										/>
									) : (
										<NoResults
											visible={
												searchedServicesResults?.length ===
												0
											}
											text="No services found matching your search criteria"
										/>
									)}
								</>
							)}

							{/* Regular sections when not showing search results */}
							{!showSearchResults && !hasGaawkServicesParams && (
								<>
									<h3>Popular Services</h3>
									{isLoadingNearbyServices ? (
										<ScrollableContainer
											hasNextPage={false}
										>
											{Array(12)
												.fill()
												.map((_, index) => (
													<ServiceCardLoader
														key={`service-loader-${index}`}
														className={
															styles.resource_service_card
														}
													/>
												))}
										</ScrollableContainer>
									) : (
										<ServicesList
											items={nearbyServices}
											viewRef={nearbyServicesViewRef}
											isFetchingNextPage={
												isFetchingNextPageServices
											}
											navigate={navigate}
											hasNextPage={hasNextPageServices}
										/>
									)}
								</>
							)}

							{!showSearchResults && !hasGaawkServicesParams && (
								<>
									<h3>Trending Products</h3>
									<ProductsList
										products={nearbyProducts}
										handleProductCardClick={
											handleProductCardClick
										}
										isFetchingNextPage={
											isFetchingNextPageProducts
										}
										fetchStatus={productsFetchStatus}
										hasNextPage={hasNextPageProducts}
										loadMore={loadMoreProducts}
									/>
								</>
							)}

							{!showSearchResults && !hasGaawkServicesParams && (
								<>
									<h3>Featured Profiles</h3>
									{isLoadingProfiles ? (
										<ScrollableContainer
											hasNextPage={false}
											isSingleLine
										>
											{Array(12)
												.fill()
												.map((_, index) => (
													<FeaturedProfileLoader
														key={`featured-profile-loader-${index}`}
													/>
												))}
										</ScrollableContainer>
									) : (
										<ScrollableContainer
											hasNextPage={hasNextPageProfiles}
											isSingleLine
										>
											{featuredProfilesList}
										</ScrollableContainer>
									)}
								</>
							)}

							{!showSearchResults && !hasGaawkServicesParams && (
								<>
									<h3>Services by your connections</h3>
									{isLoadingSuggestedServices ? (
										<ScrollableContainer
											hasNextPage={false}
										>
											{Array(12)
												.fill()
												.map((_, index) => (
													<ServiceCardLoader
														key={`suggested-service-loader-${index}`}
														className={
															styles.resource_service_card
														}
													/>
												))}
										</ScrollableContainer>
									) : (
										<ServicesList
											items={suggestedServices}
											viewRef={suggestedServicesViewRef}
											isFetchingNextPage={
												isFetchingNextPageSuggestedServices
											}
											navigate={navigate}
											hasNextPage={
												hasNextPageSuggestedServices
											}
										/>
									)}
								</>
							)}

							{!showSearchResults && !hasGaawkServicesParams && (
								<>
									<h3>New Arrivals</h3>
									<ProductsList
										products={connectionsProducts}
										handleProductCardClick={
											handleProductCardClick
										}
										isFetchingNextPage={
											isFetchingNextPageConnectionsProducts
										}
										fetchStatus={
											connectionsProductsFetchStatus
										}
										hasNextPage={
											hasNextPageConnectionsProducts
										}
										loadMore={loadMoreConnectionsProducts}
									/>
								</>
							)}

							{/* ONLY DISPLAYED IF THERE'S A GAAWK SERVICE ID PARAM */}
							{hasGaawkServicesParams && (
								<>
									<h3>Services tagged with {pageTitle}</h3>
									{isLoadingServicesByGaawkServiceId ? (
										<ScrollableContainer
											hasNextPage={false}
										>
											{Array(12)
												.fill()
												.map((_, index) => (
													<ServiceCardLoader
														key={`servicebygaawkserviceid-loader-${index}`}
														className={
															styles.resource_service_card
														}
													/>
												))}
										</ScrollableContainer>
									) : (
										<ServicesList
											items={servicesByGaawkServiceId}
											viewRef={
												servicesByGaawkServiceIdViewRef
											}
											isFetchingNextPage={
												isFetchingNextPageServicesByGaawkServiceId
											}
											navigate={navigate}
											hasNextPage={
												hasNextPageServicesByGaawkServiceId
											}
										/>
									)}
									<NoResults
										visible={
											!servicesByGaawkServiceId?.length
										}
										text={`No services found for ${pageTitle}`}
									/>

									<h3>Products tagged with {pageTitle}</h3>
									<ProductsList
										products={productsByGaawkServiceId}
										handleProductCardClick={
											handleProductCardClick
										}
										isFetchingNextPage={
											isFetchingNextPageProductsByGaawkServiceId
										}
										fetchStatus={
											productsByGaawkServiceIdFetchStatus
										}
										hasNextPage={
											hasNextPageProductsByGaawkServiceId
										}
										loadMore={
											loadMoreProductsByGaawkServiceId
										}
									/>
									<NoResults
										visible={
											!productsByGaawkServiceId?.length
										}
										text={`No products found for ${pageTitle}`}
									/>
								</>
							)}

							{gaawkServices?.length > 0 &&
								!showSearchResults &&
								!l3ServiceId && (
									<>
										<p style={{ textAlign: "center" }}>
											Try popular gaawk tags, others have
											used them to discover exactly what
											they need!
										</p>
										<ScrollableContainer
											hasNextPage={false}
											isSingleLine
										>
											{gaawkServicesList}
											{isFetchingGaawkServices && (
												<>
													{Array(12)
														.fill()
														.map((_, index) => (
															<Skeleton
																key={`gaawk-service-loader-${index}`}
																width={70}
																height={30}
															/>
														))}
												</>
											)}
										</ScrollableContainer>
									</>
								)}
						</div>

						{/* Resource panel will only be visible when clicking on a product */}
						<ResourcePanel
							resource={selectedResource}
							isOpen={isPanelOpen}
							onClose={handleClosePanel}
						/>

						<NewGaawkModal
							visible={showFiltersModal}
							onHide={() => {
								setShowFiltersModal(false);
								dispatch(
									updateProductsFilters(
										// filtersToApply ??
										// 	initialStoredFilters.current
										{ ...filtersToApply, q }
									)
								);
							}}
							title={"Filter Resources"}
							children={
								<ResourcesFilter
									// ref={resourcesFilterRef}
									userCoordinates={userCoordinates}
									onApplyFilters={handleApplyFilters}
									onClearFilters={handleClearFilters}
								/>
							}
						/>
					</>
				}
			/>

			{showLocationModal && (
				<CityModal
					show={showLocationModal}
					onClose={() => setShowLocationModal(false)}
					onSelectCity={handleSelectedCity}
				/>
			)}
		</>
	);
};

export default Resources;
