import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
import BackButton from "components/Utils/Button/BackButton";
import { useSelector } from "react-redux";
import useUrlParams from "hooks/useUrlParams";
import { useNavigate } from "react-router";
import useJob from "hooks/useJob";
import useProject from "components/Jobs/EmployerLanding/hooks/useProject";
import JobActions from "../JobActions/JobActions";
import TabMenu from "components/Utils/SubComs/TabMenu/TabMenu";
import { ReactComponent as GaawkJobsLogo } from "images/gaawk-logos/gaawk-jobs.svg";
import JobDetails from "components/Jobs/JobDetails/JobDetails";
import ProjectDetails from "components/Jobs/ProjectDetails/ProjectDetails";
import { classNames } from "primereact/utils";
import JobLoader from "components/Utils/SubComs/CustomLoader/JobLoader";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import { useQueryClient } from "@tanstack/react-query";
import useApi from "hooks/useApi";
import { Fragment, useEffect, useMemo } from "react";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import jobApi from "api/job";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { jobsKeys } from "queryKeys/jobs-key-factory";
import JobCard from "../JobCard/JobCard";
import useMutate from "hooks/useMutate";
import styles from "components/Jobs/Landing/JobsLanding.module.scss";

const PAGE_SIZE = 20;

//* jobCategory possible values: "saved" or "applied"

const OtherUserJobs = ({ jobCategory }) => {
	const navigate = useNavigate();
	const isMobile = useSelector((state) => state.ui.isMobile);
	const queryClient = useQueryClient();

	useEffect(() => {
		if (jobCategory === "applied") {
			trackEvent(eventsDictionary.JOB.APPLIED);
		} else if (jobCategory === "saved") {
			trackEvent(eventsDictionary.JOB.SAVED);
		}
	}, [jobCategory]);

	// !---- url params handlers -----

	const paramConfigs = {
		tab: {
			validator: (tab) => ["long_term", "freelance", "casting"].includes(tab),
			defaultValue: "long_term",
		},
		projectTab: {
			validator: (tab) => ["description", "long_term", "freelance", "casting"].includes(tab),
			defaultValue: "",
		},
		projectId: {
			validator: (id) => typeof id === "string",
			defaultValue: "",
		},
		projectJobId: {
			validator: (id) => typeof id === "string",
			defaultValue: "",
		},
		jobId: {
			validator: (id) => typeof id === "string",
			defaultValue: "",
		},
	};

	const { params, setParams } = useUrlParams(paramConfigs);

	const { tab, jobId, projectTab, projectId, projectJobId } = params;

	const tabNumericValue = tab === "long_term" ? 1 : tab === "freelance" ? 2 : 3;

	const handleTabChange = (tab) => {
		setParams({ tab });
	};

	const handleBackButton = () => {
		if (projectJobId) {
			setParams({ projectJobId: "" });
		} else if (projectId) {
			setParams({ projectId: "" });
		} else if (isMobile && jobId) {
			setParams({ jobId: "" });
		} else {
			navigate(-1);
		}
	};
	// !===================== JOB APPLY =========================
	const handleApplyInvalidation = () => {
		queryClient.setQueryData(
			jobCategory === "saved"
				? jobsKeys.saved(tabNumericValue)
				: jobsKeys.applied(tabNumericValue),
			(oldData) => {
				return {
					...oldData,
					pages: oldData.pages.map((page) =>
						page.map((job) =>
							job.uuid === jobId ? { ...job, appliedTime: true } : job
						)
					),
				};
			}
		);
	};

	// !===================== SELECTED JOB =========================

	const { data: job, isFetching: isFetchingJob } = useJob(jobId);

	const { data: projectJob, isFetching: isFetchingProjectJob } = useJob(projectJobId);

	// !===================== FETCH PROJECT DETAILS ================

	const {
		data: projectData,
		isFetching: isFetchingProject,
		// isLoading: isLoadingProject,
		// fetchStatus: projectFetchStatus,
	} = useProject(job?.project?.uuid);

	// !=============================TOGGLE SAVE JOB===============================

	const {
		action: { mutate: toggleSave },
	} = useMutate(jobApi.toggleSaveJob, (response, variables) => {
		const { jobId } = variables;

		queryClient.setQueryData(
			jobCategory === "saved"
				? jobsKeys.saved(tabNumericValue)
				: jobsKeys.applied(tabNumericValue),
			(oldData) => {
				return {
					...oldData,
					pages: oldData.pages.map((page) =>
						page.map((job) =>
							job.uuid === jobId ? { ...job, savedJob: !job.savedJob } : job
						)
					),
				};
			}
		);
	});

	// !============================================================

	const getJobsApi = useApi(
		jobCategory === "saved" ? jobApi.getSavedJobs : jobApi.getAppliedJobs,
		true,
		true
	);

	const fetchJobs = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, __, ___, jobType] = queryKey;

		const response = await getJobsApi.request(pageParam, PAGE_SIZE, jobType);
		return response.data;
	};

	const { items, isLoading, isFetchingNextPage, viewRef } = useInfiniteScroll({
		queryKey:
			jobCategory === "saved"
				? jobsKeys.saved(tabNumericValue)
				: jobsKeys.applied(tabNumericValue),
		queryFn: fetchJobs,
		pageSize: PAGE_SIZE,
	});

	const jobsList = useMemo(
		() =>
			items?.map((job, index) => {
				const isLastItem = index === items.length - 1;
				const isActive = jobId === job.uuid;
				return (
					<Fragment key={job.uuid}>
						<JobCard
							ref={isLastItem ? viewRef : null}
							isActive={isActive}
							data={job}
							onClick={() => {
								setParams({ jobId: job.uuid });
							}}
							onToggleSave={() => {
								toggleSave({
									jobId: job.uuid,
									state: !job.savedJob,
								});
							}}
						/>

						{isLastItem && isFetchingNextPage && (
							<div className={styles.loading_item_container}>
								<JobLoader />
							</div>
						)}
					</Fragment>
				);
			}),
		[items, viewRef, jobId]
	);

	return (
		<DefaultPage
			headerRightContent={
				<>
					<div className={styles.header_bar}>
						<BackButton onBack={handleBackButton} />
						{jobCategory === "Saved" ? "Saved" : "Applied"} Jobs
					</div>

					{!isFetchingJob && job && !projectId && <JobActions job={job} />}
				</>
			}
			hideLeftSide={isMobile && jobId}
			hideRightSide={isMobile && !jobId}
			enableCollapsedNavBar
			hybridNavigation
			// disableScrollbar
			leftSideChildren={
				<div className={styles.left_side}>
					<div className={styles.menu}>
						<TabMenu
							menuItems={{
								long_term: "Job Openings",
								freelance: "Freelancing",
								casting: "Casting Calls",
							}}
							selectedTab={tab}
							onSelectedTabChanged={handleTabChange}
						/>
					</div>

					<div className={styles.jobs_container}>
						<h3>{jobCategory === "Saved" ? "Saved" : "Applied"} Jobs</h3>

						{isLoading && (
							<div className={styles.loading_item_container}>
								<JobLoader />
								<JobLoader />
							</div>
						)}

						{items.length > 0 && !isLoading && jobsList}

						<NoResults
							visible={!isLoading && items.length === 0}
							title={"Nothing Found!"}
						/>
					</div>
				</div>
			}
			rightSideChildren={
				<div className={styles.container}>
					<div
						className={classNames(styles.right_side, {
							[styles.align_middle]: !jobId,
						})}
					>
						{jobId ? (
							projectId ? (
								projectJobId ? (
									<JobDetails
										job={projectJob}
										isLoading={isFetchingProjectJob}
										onProjectClick={() =>
											setParams({
												projectId: job?.project?.uuid,
												projectJobId: "",
											})
										}
									/>
								) : (
									<ProjectDetails
										project={projectData}
										isLoading={isFetchingProject}
										activeTab={projectTab || "description"}
										onTabChange={(projectTab) => setParams({ projectTab })}
										onJobClick={(job) => {
											setParams({
												projectJobId: job.uuid,
												projectTab: "",
											});
										}}
									/>
								)
							) : (
								<JobDetails
									job={job}
									isLoading={isFetchingJob}
									onProjectClick={() =>
										setParams({ projectId: job?.project?.uuid })
									}
									onApply={handleApplyInvalidation}
								/>
							)
						) : (
							<GaawkJobsLogo />
						)}
					</div>
				</div>
			}
		/>
	);
};

export default OtherUserJobs;
