import projectApi from "api/project";
import useMutate from "./useMutate";
import { useQueryClient } from "@tanstack/react-query";
import { projectKeys } from "queryKeys/projects-key-factory";
import { jobsKeys } from "queryKeys/jobs-key-factory";

const useDeleteProject = (project, callBack) => {
	const queryClient = useQueryClient();

	const { owner: { uuid: companyId } = {} } = project || {};

	const mutation = useMutate(projectApi.deleteProject, () => {
		queryClient.invalidateQueries(projectKeys.projectsByCompany(companyId));
		queryClient.invalidateQueries(jobsKeys.landing());
		callBack && callBack();
	});
	return mutation;
};

export default useDeleteProject;
