import { joiResolver } from "@hookform/resolvers/joi";
import GaawkButton from "components/Utils/Button/GaawkButton";
import NewGaawkModal from "components/Utils/NewGaawkModal/NewGaawkModal";
import TextArea from "components/Utils/SubComs/Inputs/TextArea/TextArea";
import useMutate from "hooks/useMutate";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import gaawkServiceApi from "../../../../api/gaawk-service";
import { setModal, setSuggModal } from "../../../../store/slices/service";
import TextInput from "../../../Utils/SubComs/Inputs/TextInput/TextInput";
import serviceSuggestionSchema from "./service-suggestion-schema";
import styles from "./ServiceSuggestionModal.module.css";

const ServiceSuggestionModal = () => {
	const dispatch = useDispatch();

	const {
		register,
		formState: {
			errors,
			// isDirty
		},
		handleSubmit,
		// watch,
		control,
		reset,
		// setValue,
	} = useForm({
		resolver: joiResolver(serviceSuggestionSchema),
		mode: "onChange",
		// defaultValues: {
		// 	category: "NEW",
		// },
	});

	// const category = watch("category");
	// const gaawkLvl1Watcher = watch("gaawkLvl1");

	const [successModal, setSuccessModal] = useState(false);

	const resetAndClose = () => {
		reset();
		dispatch(setSuggModal(false));
		dispatch(setModal(true));
	};

	const {
		action: { mutate: addGaawkService },
	} = useMutate(
		gaawkServiceApi.addGaawkServiceSuggestion,
		() => setSuccessModal(true)
		// undefined,
		// {
		// 	meta: {
		// 		successMessage: "Suggestion Sent!",
		// 	},
		// }
	);

	const handleSave = async (data) => {
		const { name, description } = data;

		// let parentId = "";

		// if (gaawkLvl2) parentId = gaawkLvl2.value;
		// else if (gaawkLvl1 && !gaawkLvl2) {
		// 	parentId = gaawkLvl1.value;
		// }

		addGaawkService({
			name,
			description,
			// parentId,
		});
	};

	// useEffect(() => {
	// 	setValue("gaawkLvl2", null);
	// }, [gaawkLvl1Watcher]);

	return (
		<>
			<form
				onSubmit={(e) => {
					e.stopPropagation();
					handleSubmit((data) => handleSave(data))(e);
				}}
				noValidate
			>
				<h4>Think we missed a category?</h4>
				<p>
					Let us know! Share the details below, and we'll consider
					adding it to our list.
				</p>

				{/* <Controller
    				name={"category"}
    				control={control}
    				render={({ field: { onChange, value } }) => (
    					<>
    						<div className={styles.radio_wrapper}>
    							<RadioButton
    								checked={value === "NEW"}
    								onChange={onChange}
    								inputId={"NEW"}
    								value={"NEW"}
    							/>
    							<label htmlFor={"NEW"}>
    								<h4 className={styles.label_title}>
    									New Category
    								</h4>
    								<p className={styles.label_text}>
    									Your newly created service will be listed as
    									a first level service.
    								</p>
    							</label>
    						</div>

    						<div className={styles.radio_wrapper}>
    							<RadioButton
    								checked={value === "SUB"}
    								onChange={onChange}
    								inputId={"SUB"}
    								value={"SUB"}
    							/>
    							<label htmlFor={"SUB"}>
    								<h4 className={styles.label_title}>
    									Sub Category
    								</h4>
    								<p className={styles.label_text}>
    									Your newly created service will be listed as
    									a child to the category you select from the
    									list below.
    								</p>
    							</label>
    						</div>
    					</>
    				)}
    			/> */}

				{/* {category === "SUB" && (
    				<>
    					<div className={styles.form_input_wrapper}>
    						<label>Level One Services</label>

    						<div onKeyUp={handleKeyUp}>
    							<CustomSelect
    								placeholder="Select Service"
    								options={gaawkServices}
    								height="35px"
    								error={!!errors.gaawkLvl1}
    								control={control}
    								name="gaawkLvl1"
    								isLoading={isFetching}
    								onMenuScrollToBottom={() => {
    									if (hasNextPage && !isFetching)
    										fetchNextPage();
    								}}
    							/>
    						</div>
    						{errors?.gaawkLvl1?.message && (
    							<p className={styles.error_message}>
    								{errors?.gaawkLvl1?.message}
    							</p>
    						)}
    					</div>

    					{gaawkLvl1Watcher && (
    						<div className={styles.form_input_wrapper}>
    							<label>Level Two Services</label>

    							<div onKeyUp={handleKeyUpLvl2}>
    								<CustomSelect
    									placeholder="Select Service"
    									options={gaawkServicesLvl2}
    									height="35px"
    									error={!!errors.gaawkLvl2}
    									control={control}
    									name="gaawkLvl2"
    									isLoading={isFetchingLvl2}
    									onMenuScrollToBottom={() => {
    										if (hasNextPageLvl2 && !isFetchingLvl2)
    											fetchNextPageLvl2();
    									}}
    									isClearable={true}
    								/>
    							</div>
    							{errors?.gaawkLvl2?.message && (
    								<p className={styles.error_message}>
    									{errors?.gaawkLvl2?.message}
    								</p>
    							)}
    						</div>
    					)}
    				</>
    			)} */}

				<div className={styles.form_input_wrapper}>
					<label>
						Service Name<span className="required">*</span>
					</label>

					<div className={styles.input_wrapper}>
						<span>+</span>
						<TextInput
							{...register("name")}
							error={!!errors.name}
							placeholder="Service Name"
						/>
					</div>

					{errors?.name?.message && (
						<p className={styles.error_message}>
							{errors?.name?.message}
						</p>
					)}
				</div>

				<Controller
					name={"description"}
					control={control}
					render={({ field, fieldState }) => (
						<TextArea
							required={true}
							className={styles.textarea_wrapper}
							label="Description"
							error={fieldState.error}
							{...field}
						/>
					)}
				/>

				<GaawkButton
					type={"submit"}
					className={styles.send_btn}
					text={"Send"}
				/>
			</form>

			<NewGaawkModal
				visible={successModal}
				title="Thank you! Suggestion Submitted"
				onHide={resetAndClose}
				children={
					<>
						<p>
							Thank you for helping us improve! Your category
							suggestion has been successfully submitted for
							review.
						</p>
						<GaawkButton
							text="Keep Gaawking!"
							onClick={resetAndClose}
							style={{ marginTop: "20px" }}
						/>
					</>
				}
			/>
		</>
	);
};

export default ServiceSuggestionModal;
