import { useQuery } from "@tanstack/react-query";
import jobApi from "api/job";
import {
	experienceLvlTypes,
	formatFiltersValues,
	frequencyTypes,
	genderTypes,
	// jobTypeOptions,
	// jobTypes,
	// postedDateTypes,
	workLocation,
} from "components/Utils/General";
import CustomSelect from "components/Utils/SubComs/CustomSelect/CustomSelect";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import { defaultFormatter } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import useApi from "hooks/useApi";
import useCurrencies from "hooks/useCurrencies";
import useDebounce from "hooks/useDebounce";
import useGetQueryParam from "hooks/useGetQueryParam";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { filterKeys } from "queryKeys/filters-key-factory";
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetJobsFilters, updateJobsFilters } from "store/slices/filters";
// import MultiCheckbox from "../../Utils/MultiCheckbox/MultiCheckbox";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import styles from "./PeopleFilter.module.scss";
import CastingFilters from "./SubFilters/CastingFilters";
import LongTermFilters from "./SubFilters/LongTermFilters";
import useFilterAndSort from "./useFilterAndSort";
import GaawkButton from "components/Utils/Button/GaawkButton";
import DayPicker from "components/Utils/SubComs/DayPicker/DayPicker";
import NewMultiCheckbox from "components/Utils/NewMultiCheckBox/NewMultiCheckBox";

const FILTERS_PAGE_SIZE = 100;

const JobsFilter = forwardRef(
	({ userCoordinates, onApplyFilters, onClearFilters }, ref) => {
		const { paramValue: inputValue } = useGetQueryParam("q");

		const dispatch = useDispatch();

		const jobsFiltersObject = useSelector(
			(state) => state.filters.jobsFilters
		);

		const storedJobsFilters = {
			...jobsFiltersObject,
			jobType: 0,
			q: inputValue || "",
			locationId: userCoordinates.id,
		};
		// console.log("🚀 ~ storedJobsFilters >>", storedJobsFilters);

		// useEffect(() => {
		// 	return () => {
		// 		//CLEARS JOBS SEARCH FILTERS WHEN COMPONENT UNMOUNTS
		// 		dispatch(resetJobsFilters());
		// 	};
		// }, []);

		const {
			// jobType,
			compensation: storedCompensation = "",
			currencyId = "",
			frequency = "",
			jobRequest: { longTermJobTypeId, workingLocations, experience },
			freelanceRequest: {
				// workingLocation: freelanceWorkingLocation, //* already using the workingLocation from jobRequest (same as for freelance)
				// experience: storedFreelanceExp, //* already using the experience jobRequest (same as for freelance)
				showBasedOnCalendar,
				startDate,
				endDate,
			},
			castingCallRequest: {
				gender,
				minAge: storedMinAge = "",
				maxAge: storedMaxAge = "",
				// ethnicityObject = null, //! removed
				languageIds = [],
				ethnicityIds = [],
				// startDate: castingStartDate, //* already using the freelance dates (same fas or casting)
				// endDate: castingEndDate, //* already using the freelance dates (same as for casting)
			},
			skillIds = [],
		} = storedJobsFilters || {};

		//! debouncing min compensation ==========================

		const [minCompensation, setMinCompensation] =
			useState(storedCompensation);
		const debouncedCompensation = useDebounce(minCompensation);

		useEffect(() => {
			setMinCompensation(storedCompensation);
		}, [storedCompensation]);

		useEffect(() => {
			dispatch(
				updateJobsFilters({
					compensation: debouncedCompensation,
				})
			);
		}, [dispatch, debouncedCompensation]);

		//! debouncing min age ==========================

		const [minAge, setMinAge] = useState(storedMinAge);
		const debouncedMinAge = useDebounce(minAge);

		useEffect(() => {
			setMinAge(storedMinAge);
		}, [storedMinAge]);

		useEffect(() => {
			dispatch(
				updateJobsFilters({
					castingCallRequest: {
						...storedJobsFilters.castingCallRequest,
						minAge: debouncedMinAge,
					},
				})
			);
		}, [dispatch, debouncedMinAge]);

		//! debouncing max age ==========================

		const [maxAge, setMaxAge] = useState(storedMaxAge);
		const debouncedMaxAge = useDebounce(maxAge);

		useEffect(() => {
			setMaxAge(storedMaxAge);
		}, [storedMaxAge]);

		useEffect(() => {
			dispatch(
				updateJobsFilters({
					castingCallRequest: {
						...storedJobsFilters.castingCallRequest,
						maxAge: debouncedMaxAge,
					},
				})
			);
		}, [dispatch, debouncedMaxAge]);

		// ! ========= FETCHING OF DEFAULT JOBS FILTERS ============

		const jobsFiltersApi = useApi(jobApi.jobsFilters, true, true);

		const fetchGeneralFilters = async ({ queryKey }) => {
			const [_, __, filters] = queryKey;

			const response = await jobsFiltersApi.request(
				0,
				4,
				formatFiltersValues(filters)
			);
			return response.data;
		};

		const {
			data: jobsFilters,
			// isLoading, //TODO >> loading spinner for 1st render
			isFetching,
			// isError,
		} = useQuery({
			queryKey: filterKeys.jobs(storedJobsFilters),
			queryFn: fetchGeneralFilters,
		});

		// !====================

		const getJobsDynamicFilterApi = useApi(
			jobApi.jobsDynamicFilters,
			true,
			true
		);

		const fetchDynamicFilters = async ({ queryKey, pageParam = 0 }) => {
			const [_, __, type, filters] = queryKey;
			const response = await getJobsDynamicFilterApi.request(
				type,
				pageParam,
				FILTERS_PAGE_SIZE,
				formatFiltersValues(filters)
			);
			return response.data;
		};

		const [enableSkillsFilters, setEnableSkillsFilters] = useState(false);

		const {
			items: dynamicSkills,
			hasNextPage: hasNextPageSkills,
			refetch: refetchSkills,
			loadMore: loadMoreSkills,
		} = useInfiniteScroll({
			queryKey: filterKeys.dynamicJobsFilter("skills", storedJobsFilters),
			queryFn: fetchDynamicFilters,
			pageSize: FILTERS_PAGE_SIZE,
			enabled: enableSkillsFilters,
		});

		const {
			items: dynamicLanguages,
			hasNextPage: hasNextPageLanguages,
			isSuccess: isSuccessLanguages,
			refetch: refetchLanguages,
			loadMore: loadMoreLanguages,
		} = useInfiniteScroll({
			queryKey: filterKeys.dynamicJobsFilter(
				"languages",
				storedJobsFilters
			),
			queryFn: fetchDynamicFilters,
			pageSize: FILTERS_PAGE_SIZE,
			enabled: false,
		});

		const {
			items: dynamicEthnicities,
			isSuccess: isSuccessEthnicities,
			hasNextPage: hasNextPageEthnicities,
			refetch: refetchEthnicities,
			loadMore: loadMoreEthnicities,
		} = useInfiniteScroll({
			queryKey: filterKeys.dynamicJobsFilter(
				"ethnicities",
				storedJobsFilters
			),
			queryFn: fetchDynamicFilters,
			pageSize: FILTERS_PAGE_SIZE,
			enabled: false,
		});

		const skillRef = useRef();
		const castingFilterRef = useRef();

		const { data: currencies = [], isLoading: isLoadingCurrencies } =
			useCurrencies();

		//* below effect to dispatch default currencyId in jobFilters (first one in the response from the API)
		useEffect(() => {
			if (!currencyId && currencies?.length > 0 && !isLoadingCurrencies) {
				dispatch(
					updateJobsFilters({
						currencyId: currencies[0].uuid,
					})
				);
			}
		}, [currencies, isLoadingCurrencies, currencyId]);

		const {
			ethnicities: ethnicitiesList = [],
			languages: languagesList = [],
			skills: skillsList = [],
		} = jobsFilters || {};

		const filteredSkills = useFilterAndSort(
			skillIds,
			dynamicSkills,
			skillsList,
			dynamicSkills.length > 0
		);

		const filteredLanguages = useFilterAndSort(
			languageIds,
			dynamicLanguages,
			languagesList,
			dynamicLanguages.length > 0,
			"code"
		);

		const filteredEthnicities = useFilterAndSort(
			ethnicityIds,
			dynamicEthnicities,
			ethnicitiesList,
			dynamicEthnicities.length > 0
		);

		//TODO >> not needed anymore since we're using filters from redux

		useImperativeHandle(ref, () => ({
			updateDynamicFilters(key, tag) {
				switch (key) {
					case "skillIds":
						skillRef.current?.removeItem(tag);
						break;
					case "languageIds":
						castingFilterRef.current?.removeLanguageItem(tag);
						break;
					case "ethnicityIds":
						castingFilterRef.current?.removeEthnicityItem(tag);
						break;
					default:
						break;
				}
			},
		}));

		if (isFetching)
			return <LoadingSpinner customStyle={styles.loading_spinner} />;

		return (
			<>
				<div
					// className={`${styles.container} ${
					// 	jobType === jobTypes.all ? styles.no_top_padding : undefined
					// }`}
					className={styles.container}
				>
					{skillsList?.length > 0 && (
						<NewMultiCheckbox
							ref={skillRef}
							title={"Skills"}
							options={filteredSkills.map((item) =>
								defaultFormatter(item)
							)}
							onSelect={(skills) =>
								dispatch(
									updateJobsFilters({
										skillIds: skills,
									})
								)
							}
							selected={skillIds}
							perRow="2, auto"
							// customStyle={styles.multicheckbox_container}
						/>
					)}

					{((dynamicSkills?.length === 0 &&
						skillsList.length === 4) ||
						(dynamicSkills?.length > 0 && hasNextPageSkills)) && (
						<SeeMore
							onClick={() => {
								if (dynamicSkills?.length === 0) {
									refetchSkills();
								} else {
									loadMoreSkills();
								}

								setEnableSkillsFilters(true);
							}}
							className={styles.margin_top}
						/>
					)}

					<div className={styles.datePickerContainer}>
						<div className={styles.datePickerWrapper}>
							<div className={styles.datePickerItem}>
								<label>Start Date</label>
								<DayPicker
									className={`form-start-date`}
									onChange={(startDate) => {
										dispatch(
											updateJobsFilters({
												freelanceRequest: {
													...storedJobsFilters.freelanceRequest,
													startDate:
														startDate?.getTime() ||
														null,
												},
												castingCallRequest: {
													...storedJobsFilters.castingCallRequest,
													startDate:
														startDate?.getTime() ||
														null,
												},
											})
										);
									}}
									value={startDate && new Date(startDate)}
									isClearable={true}
								/>
							</div>
							<div className={styles.datePickerItem}>
								<label>End Date</label>
								<DayPicker
									className={`form-end-date`}
									onChange={(endDate) => {
										dispatch(
											updateJobsFilters({
												freelanceRequest: {
													...storedJobsFilters.freelanceRequest,
													endDate:
														endDate?.getTime() ||
														null,
												},
												castingCallRequest: {
													...storedJobsFilters.castingCallRequest,
													endDate:
														endDate?.getTime() ||
														null,
												},
											})
										);
									}}
									value={endDate && new Date(endDate)}
									isClearable={true}
								/>
							</div>
						</div>
					</div>

					<InputWrapper
						label="Minimum compensation"
						component={
							<TextInput
								value={minCompensation}
								placeholder="Enter amount"
								onChange={({ target }) =>
									setMinCompensation(target.value)
								}
								type="number"
							/>
						}
					/>

					<div className={styles.inline_input_wrapper}>
						<div className={styles.item}>
							<label>Currency</label>
							<CustomSelect
								isLoading={isLoadingCurrencies}
								options={currencies?.map((currency) =>
									defaultFormatter(currency)
								)}
								placeholder="Select"
								height="35px"
								// isClearable={true}
								onChange={(currency) =>
									dispatch(
										updateJobsFilters({
											currencyId: currency
												? currency.value
												: "",
										})
									)
								}
								value={currencies
									?.map((currency) =>
										defaultFormatter(currency)
									)
									.find((item) => item.value === currencyId)}
							/>
						</div>
						<div className={styles.item}>
							<label>Frequency</label>
							<CustomSelect
								options={frequencyTypes}
								placeholder="Select"
								height="35px"
								// isClearable={true}
								onChange={(freq) =>
									dispatch(
										updateJobsFilters({
											frequency: freq ? freq.value : "",
										})
									)
								}
								value={frequencyTypes.find(
									(item) => item.value === frequency
								)}
							/>
						</div>
					</div>
					{/* <div className={styles.container}> */}
					{/* <MultiCheckbox
					title={"Type"}
					type="radio"
					options={jobTypeOptions}
					onSelect={
						(type) => dispatch(resetJobsFilters(type[0].value))

						// dispatch(
						// 	updateJobsFilters({
						// 		jobType: type[0].value,
						// 		// //* >> below will reset skills if job type is changed
						// 		// //* >> (skills should only work for jobType 0 (all) and 1 (jobs))
						// 		// ...(jobType !== type[0].value && {
						// 		// 	skillIds: [],
						// 		// }),
						// 	})
						// )
					}
					selected={[jobTypeOptions.find((item) => item.value === jobType)]}
					perRow="2, 150px"
					customStyle={styles.multicheckbox_container}
				/> */}

					{/* <MultiCheckbox
					title={"Date Posted"}
					type="radio"
					options={postedDateTypes}
					onSelect={(timeType) =>
						dispatch(updateJobsFilters({ time: timeType[0].value }))
					}
					selected={[postedDateTypes.find((item) => item.value === time)]}
					perRow="2, 150px"
					customStyle={styles.multicheckbox_container}
				/> */}
					{/* </div> */}

					{/* //~ =============== FILTERS BASED ON JOB TYPE ==================== */}

					<LongTermFilters
						onEmployementSelect={(employementType) =>
							dispatch(
								updateJobsFilters({
									jobRequest: {
										...storedJobsFilters.jobRequest,
										longTermJobTypeId: employementType,
									},
								})
							)
						}
						employement={longTermJobTypeId}
						onWorkLocation={(workLocation) => {
							dispatch(
								updateJobsFilters({
									jobRequest: {
										...storedJobsFilters.jobRequest,
										workingLocations: workLocation,
									},
									freelanceRequest: {
										...storedJobsFilters.freelanceRequest,
										workingLocations: workLocation,
									},
								})
							);
						}}
						workLocation={workLocation.filter((item) =>
							workingLocations
								.map((i) => i.value)
								.includes(item.value)
						)}
						onExpLvl={(exp) => {
							dispatch(
								updateJobsFilters({
									jobRequest: {
										...storedJobsFilters.jobRequest,
										experience: exp,
									},
									freelanceRequest: {
										...storedJobsFilters.freelanceRequest,
										experience: exp,
									},
								})
							);
						}}
						expLvl={experienceLvlTypes.filter((item) =>
							experience.map((i) => i.value).includes(item.value)
						)}
						onCalendarAvailability={(e) =>
							dispatch(
								updateJobsFilters({
									freelanceRequest: {
										...storedJobsFilters.freelanceRequest,
										showBasedOnCalendar:
											!showBasedOnCalendar,
									},
								})
							)
						}
						calendarAvailability={showBasedOnCalendar}
					/>

					<CastingFilters
						ref={castingFilterRef}
						onGenderChange={(gender) => {
							dispatch(
								updateJobsFilters({
									castingCallRequest: {
										...storedJobsFilters.castingCallRequest,
										gender:
											gender.length === 1
												? gender[0].value
												: 2,
									},
								})
							);
						}}
						gender={
							gender === 2
								? genderTypes
								: gender === -1
								? []
								: [
										genderTypes.find(
											(item) => item.value === gender
										),
								  ]
						}
						onMinAge={({ target }) => setMinAge(target.value)}
						onMaxAge={({ target }) => setMaxAge(target.value)}
						minAge={minAge}
						maxAge={maxAge}
						// * ethnicities props
						ethnicitiesList={filteredEthnicities}
						onEthnicity={(ethnicities) =>
							dispatch(
								updateJobsFilters({
									castingCallRequest: {
										...storedJobsFilters.castingCallRequest,
										ethnicityIds: ethnicities,
									},
								})
							)
						}
						ethnicities={ethnicityIds}
						isFetchedEthnicities={isSuccessEthnicities}
						hasNextPageEthnicities={hasNextPageEthnicities}
						onRefetchEthnicities={refetchEthnicities}
						onFetchNextPageEthnicities={loadMoreEthnicities}
						baseEthnicitiesCount={ethnicitiesList.length}
						// * languages props
						languagesList={filteredLanguages}
						onLanguage={(languages) =>
							dispatch(
								updateJobsFilters({
									castingCallRequest: {
										...storedJobsFilters.castingCallRequest,
										languageIds: languages,
									},
								})
							)
						}
						languages={languageIds}
						// isFetchedLanguages={dynamicLanguages?.length > 0}
						isFetchedLanguages={isSuccessLanguages} //* replaced above by "isSuccessLanguages"
						hasNextPageLanguages={hasNextPageLanguages}
						onRefetchLanguages={refetchLanguages}
						onFetchNextPageLanguages={loadMoreLanguages}
						baseLanguagesCount={languagesList.length}
					/>

					{/* //~ ============================================================= */}

					<div className={styles.button_container}>
						<GaawkButton
							severity={"tertiary"}
							text={"Clear Filters"}
							className={styles.btn}
							onClick={() => {
								dispatch(resetJobsFilters());
								onClearFilters && onClearFilters();
							}}
						/>
						{onApplyFilters && (
							<GaawkButton
								text={"Apply"}
								className={styles.btn}
								onClick={onApplyFilters}
							/>
						)}
					</div>
				</div>
			</>
		);
	}
);

export default JobsFilter;
