import routes from "components/Routing/routing-keys";

const getNotificationDetails = (notification) => {
	const {
		action,
		from: {
			name,
			url: fromUrl,
			connection: { inMyWorkCircle, inTheirWorkCircle, following, followsMe },
		},
		to: { url: toUrl },
		payload,
	} = notification;

	const parsedPayload = JSON.parse(payload);

	let title;
	let body;
	let url;

	switch (action) {
		case "FRIEND_REQUEST_RECEIVED":
			title = "Friend Request";

			body = (
				<p>
					<b>{name}</b> sent you a friend request.
				</p>
			);

			url = routes.profile(fromUrl);

			break;

		case "FRIEND_REQUEST_ACCEPTED":
			title = "Friend Request";

			body = (
				<p>
					<b>{name}</b> accepted your friend request.
				</p>
			);

			url = routes.profile(fromUrl);

			break;

		case "WORK_CIRCLE_ADDED":
			title = "Work Circle Update";

			if (!inMyWorkCircle && inTheirWorkCircle) {
				body = (
					<p>
						<b>{name}</b> has added you to their work circle. Do you want to add them
						back?
					</p>
				);
			} else {
				//else if (inMyWorkCircle && inTheirWorkCircle)
				body = (
					<p>
						One of your work connection <b>{name}</b> has added you back to their work
						circle.
					</p>
				);
			}

			url = routes.profile(fromUrl);

			break;

		case "STARTED_FOLLOWING":
			title = "New Follower";

			if (!following && followsMe) {
				body = (
					<p>
						<b>{name}</b> has started following you. Do you want to follow them back?
					</p>
				);
			} else {
				//else if (following && followsMe)
				body = (
					<p>
						<b>{name}</b> has started following you.
					</p>
				);
			}

			url = routes.profile(fromUrl);

			break;

		case "POST_REACTION":
			title = `${name} has reacted ${parsedPayload.reactionText} to
					your post.`;

			body = <p>{parsedPayload.postText}</p>;

			url = routes.post(parsedPayload.postId);

			break;

		case "ALBUM_REACTION":
			title = `${parsedPayload.albumTitle}`;

			body = (
				<p>
					<b>{name}</b> has reacted {parsedPayload.reactionText} to your album.
				</p>
			);

			url = routes.album(parsedPayload.albumId);

			break;

		case "POST_COMMENT":
			title = `${name} has commented on your post.`;

			body = <p>{parsedPayload.commentText}</p>;

			url = routes.post(parsedPayload.postId);

			break;

		case "POST_SHARED":
			title = `${name} has shared your post.`;

			body = <p>{parsedPayload.postText}</p>;

			url = routes.post(parsedPayload.postId);

			break;

		case "ALBUM_COMMENT":
			title = `${parsedPayload.albumTitle}`;

			body = (
				<p>
					<b>{name}</b> has commented on your album:
					<br />
					{parsedPayload.commentText}
				</p>
			);

			url = routes.album(parsedPayload.albumId);

			break;

		case "POST_COMMENT_REACTION":
			title = `${name} has reacted ${parsedPayload.reactionText} to your comment from a post.`;

			body = <p>{parsedPayload.commentText}</p>;

			url = routes.post(parsedPayload.postId);

			break;

		case "ALBUM_COMMENT_REACTION":
			title = `${name} has reacted ${parsedPayload.reactionText} to
                your comment from an album: ${parsedPayload.albumTitle}.`;

			body = <p>{parsedPayload.commentText}</p>;

			url = routes.album(parsedPayload.albumId);

			break;

		case "NEW_POST_GENERAL":
			title = `${name} has updated their feed. Have a look.`;

			body = <p>{parsedPayload.postText}</p>;

			url = routes.post(parsedPayload.postId);

			break;

		case "NEW_POST_PROFILE":
			title = `${name} has updated their profile picture.`;

			// body = (
			// 	<p>
			// 		<b>{name}</b> has updated their profile picture.
			// 	</p>
			// );

			url = routes.post(parsedPayload.postId);

			break;

		case "NEW_POST_COVER":
			title = `${name} has updated their cover picture.`;

			// body = (
			// 	<p>
			// 		<b>{name}</b> has updated their cover picture.
			// 	</p>
			// );

			url = routes.post(parsedPayload.postId);

			break;

		case "NEW_ALBUM":
			title = `${name} has created a new album: ${parsedPayload.albumTitle}.`;

			// body = (
			// 	<p>
			// 		<b>{name}</b> has created a new album:{" "}
			// 		{parsedPayload.albumTitle}.
			// 	</p>
			// );

			url = routes.album(parsedPayload.albumId);

			break;

		case "ADDED_ALBUM_CONTRIBUTOR":
			title = `${parsedPayload.albumTitle}`;

			body = (
				<p>
					<b>{name}</b> has added you as the contributor to their album.
				</p>
			);

			url = routes.album(parsedPayload.albumId);

			break;

		case "ADDED_BOARD_MODERATOR":
			title = `${parsedPayload.boardName}`;

			body = (
				<p>
					<b>{name}</b> has added you as the moderator of the board.
				</p>
			);

			url = routes.board(parsedPayload.boardId);

			break;

		case "STARTED_FOLLOWING_BOARD":
			title = `${parsedPayload.boardName}`;

			body = (
				<p>
					<b>{name}</b> has started following the board.
				</p>
			);

			url = routes.board(parsedPayload.boardId);

			break;

		case "NEW_POST_BOARD":
			title = `${parsedPayload.boardName}`;

			body = (
				<p>
					<b>{name}</b> has posted on the board.
					<br />
					{parsedPayload.postText}
				</p>
			);

			url = routes.post(parsedPayload.postId);

			break;

		case "NEW_BOARD_FOLLOWER_REQUEST":
			title = `${parsedPayload.boardName}`;

			body = (
				<p>
					<b>{name}</b> has requested to follow the board.
				</p>
			);

			url = routes.board(parsedPayload.boardId);

			break;

		case "BOARD_FOLLOWER_REQUEST_APPROVED":
			title = `${parsedPayload.boardName}`;

			body = <p>Your request to join the board has been approved.</p>;

			url = routes.board(parsedPayload.boardId);

			break;

		case "BOARD_DELETED":
			title = `${parsedPayload.boardName}`;

			body = <p>Board you were part of has just been deleted.</p>;

			url = ""; //* test if doesn't break

			break;

		case "BOARD_POST_DELETED":
			title = `${parsedPayload.boardName}`;

			body = <p>Your post in this board has been deleted by one of the moderators.</p>;

			url = routes.board(parsedPayload.boardId);

			break;

		case "POST_MENTION_PROFILE":
			title = `${name} has mentioned you in their post.`;

			body = <p>{parsedPayload.postText}</p>;

			url = routes.post(parsedPayload.postId);

			break;

		case "POST_TAGGED_PROFILE":
			title = `${name} has tagged you in their post.`;

			body = <p>{parsedPayload.postText}</p>;

			url = routes.post(parsedPayload.postId);

			break;

		case "COMMENT_MENTION":
			title = `${name} has mentioned you in their comment on a post.`;

			body = <p>{parsedPayload.postText}</p>;

			url = routes.post(parsedPayload.postId);

			break;

		case "POST_MENTION_SERVICE":
			title = `${parsedPayload.serviceName}`;

			body = (
				<p>
					<b>{name}</b> has tagged your service in a post.
					<br />
					{parsedPayload.postText}
				</p>
			);

			url = routes.post(parsedPayload.postId);

			break;

		case "COMMENT_MENTION_SERVICE":
			title = `${parsedPayload.serviceName}`;

			body = (
				<p>
					<b>{name}</b> has tagged your service in a comment.
					<br />
					{parsedPayload.commentText}
				</p>
			);

			url = routes.post(parsedPayload.postId);

			break;

		case "COMPANY_CHANGE_OWNER":
			title = `${name}`;
			body = <p>You have been assigned as the owner of the company.</p>;
			url = routes.profile(fromUrl);

			break;

		case "COMPANY_ADMIN_ADDED":
			title = `${name}`;
			body = <p>You have been assigned as the admin of the company.</p>;
			url = routes.profile(fromUrl);

			break;

		case "COMPANY_ADMIN_REMOVED":
			title = `${name}`;
			body = <p>You are no longer the admin of the company.</p>;
			url = routes.profile(fromUrl);

			break;

		case "COMPANY_REPRESENTATIVE_ADDED":
			title = `${name}`;
			body = <p>You have been assigned as the representative of the company.</p>;
			url = routes.profile(fromUrl);

			break;

		case "COMPANY_REPRESENTATIVE_REMOVED":
			title = `${name}`;
			body = <p>You are no longer the representative of the company.</p>;
			url = routes.profile(fromUrl);

			break;

		case "COMPANY_KEY_PEOPLE_ADDED":
			title = `${name}`;
			body = <p>You have been added as one of the key people of the company.</p>;
			url = routes.profile(fromUrl);

			break;

		case "COMPANY_KEY_PEOPLE_REMOVED":
			title = `${name}`;
			body = (
				<p>
					<b>{name}</b> has removed you as one of the key people of the company.
				</p>
			);
			url = routes.profile(fromUrl);

			break;

		case "COMPANY_ROLE_VERIFIED":
			title = "Experience Verified";
			body = (
				<p>
					Your request for verifying experience has been accepted by the company:{" "}
					<b>{name}</b>.
				</p>
			);
			url = routes.profile(fromUrl);

			break;

		case "COMPANY_NEW_EXPERIENCE_REQUEST":
			title = "Verify Experience Request";
			body = (
				<p>
					<b>{name}</b> has requested for their role to be verified in the company.
				</p>
			);
			url = routes.profile(toUrl);

			break;

		case "JOB_SHORTLISTED":
			title = `${name}`;

			body = (
				<p>
					You have been shortlisted for the job: <b>{parsedPayload.jobName}</b>
				</p>
			);
			url = `${routes.jobs}?jobId=${parsedPayload.jobId}`;

			break;

		case "JOB_APPLIED":
			title = `${parsedPayload.jobName}`;

			body = (
				<p>
					<b>{name}</b> has applied for the job.
				</p>
			);
			url = routes.jobApplicants(parsedPayload.jobId);

			break;

		case "POSTED_A_JOB":
			title = `New Job ${parsedPayload.jobName}`;

			body = (
				<p>
					<b>{name}</b> has posted a new job you might be interested in.
				</p>
			);

			url = `${routes.jobs}?jobId=${parsedPayload.jobId}`;

			break;

		case "POSTED_A_CASTING_CALL":
			title = `New Casting Call: ${parsedPayload.jobName}`;

			body = (
				<p>
					<b>{name}</b> has posted a new casting call you might be interested in.
				</p>
			);
			url = `${routes.jobs}?jobId=${parsedPayload.jobId}`;

			break;

		case "ADDED_TO_GROUP":
			title = `${parsedPayload.groupName}`;

			body = (
				<p>
					You have been added to the chat group by <b>{name}</b>.
				</p>
			);
			url = routes.chat;

			break;

		case "MADE_ADMIN":
			title = `${parsedPayload.groupName}`;

			body = (
				<p>
					You have been made admin of the chat group by <b>{name}</b>.
				</p>
			);
			url = routes.chat;

			break;

		case "MESSAGE_SENT":
			title = `${name}`;
			body = <p>{parsedPayload.messageText}</p>;
			url = routes.chat;

			break;

		case "VAULT_ITEM_SHARED":
			title = "Gaawk Vault";
			body = (
				<p>
					<b>{name}</b> has shared a Vault {parsedPayload.isFile ? "file" : "folder"} with
					you.
				</p>
			);
			url = parsedPayload.isFile
				? routes.vaultItem(parsedPayload.itemId)
				: routes.vaultFolder(parsedPayload.itemId);

			break;

		case "TALENT_REQUEST_RAISED":
			title = `${parsedPayload.specialProfileName}`;
			body = (
				<p>
					<b>{name}</b> has requested for their special profile to be listed on your
					talent page.
				</p>
			);
			// url = routes.profile(toUrl);
			url = routes.specialProfile(parsedPayload.specialProfileURL);

			break;

		case "TALENT_REQUEST_ACCEPTED":
			title = `${parsedPayload.specialProfileName}`;
			body = (
				<p>
					<b>{name}</b> has accepted your special profile request to be listed on their
					talent page.
				</p>
			);
			url = routes.specialProfile(parsedPayload.specialProfileURL);

			break;

		case "TALENT_PROFILE_REMOVED_BY_COMPANY":
			title = `${parsedPayload.specialProfileName}`;
			body = (
				<p>
					<b>{name}</b> has removed your special profile from their talent page.
				</p>
			);
			url = routes.specialProfile(parsedPayload.specialProfileURL);

			break;

		case "TALENT_PROFILE_REMOVED_BY_USER":
			title = `${parsedPayload.specialProfileName}`;
			body = (
				<p>
					<b>{name}</b> has removed their special profile from your talent page.
				</p>
			);
			url = routes.specialProfile(parsedPayload.specialProfileURL);

			break;

		case "SERVICE_CREATED":
			title = `${parsedPayload.serviceName}`;
			body = (
				<p>
					<b>{name}</b> has created a service category. You should check it out.
				</p>
			);

			url = routes.serviceDetails(parsedPayload.serviceId);

			break;

		case "UPDATE_WORK_STATUS":
			title = "Update Work Status";
			body = (
				<p>
					It's been a while since you updated your work status.
					<br />
					Do you want to update it now?
				</p>
			);
			url = routes.updateWorkStatus;

			break;

		case "START_CREATING_POST":
			title = "Create a post";

			body = (
				<p>
					You haven't created a post in a while.
					<br />
					Do you want to create it now?
				</p>
			);
			url = routes.root;

			break;

		case "START_POSTING_JOBS":
			title = "Post a job";

			body = (
				<p>
					You haven't posted a job in a while.
					<br />
					Do you want to post it now?
				</p>
			);
			url = routes.jobs;

			break;

		case "COMPANIES_YOU_MAY_KNOW":
			title = "New companies";

			body = <p>There are companies you might know. Start building your network.</p>;
			url = routes.companies;

			break;

		default:
			break;
	}

	return { url, body, title };
};

export default getNotificationDetails;
