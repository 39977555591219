import styles from "./NewMultiCheckBox.module.scss";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Controller } from "react-hook-form";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";

const NewMultiCheckbox = forwardRef(
	(
		{
			options,
			title,
			infoText,
			perRow,
			canSelectAll = false,
			onSelect,
			selected,
			type = "checkbox",
			control,
			name,
			error,
			required = false,
			customStyle,
		},
		ref
	) => {
		const [selectedItems, setSelectedItems] = useState(selected || []);
		const isControlled = selected !== undefined;

		const areArraysEqual = (arr1, arr2) => {
			if (!arr1 || !arr2) return false;
			if (arr1.length !== arr2.length) return false;
			return arr1.every(
				(item, index) => item.value === arr2[index]?.value
			);
		};

		useEffect(() => {
			if (isControlled && !areArraysEqual(selected, selectedItems)) {
				setSelectedItems(selected);
			}
		}, [selected, isControlled]);

		useImperativeHandle(ref, () => ({
			clear() {
				setSelectedItems([]);
			},
			set(value) {
				setSelectedItems([value]);
			},
			removeItem(item) {
				setSelectedItems((prevState) =>
					prevState.filter((x) => x.value !== item.value)
				);
			},
		}));

		const handleSelectAll = () => {
			const newItems =
				selectedItems.length === options.length ? [] : options;
			setSelectedItems(newItems);
			onSelect?.(newItems);
		};

		const handleOptionChange = (option) => {
			const newItems =
				type === "radio"
					? [option]
					: selectedItems
							.map((item) => item.value)
							.includes(option.value)
					? selectedItems.filter(
							(item) => item.value !== option.value
					  )
					: [...selectedItems, option];

			setSelectedItems(newItems);
			onSelect?.(newItems);
		};

		const renderOptions = (value, onChange) => {
			const currentValue = control ? [value] : selectedItems;
			const handleChange = control
				? (option) => {
						const newValue =
							type === "radio"
								? option
								: currentValue
										?.map((item) => item.value)
										.includes(option.value)
								? currentValue.filter(
										(item) => item.value !== option.value
								  )
								: [...(currentValue || []), option];
						onChange(newValue);
						onSelect?.(newValue);
				  }
				: handleOptionChange;

			return options.map((option, index) => (
				<div key={index} className={styles.input_wrapper}>
					{type === "radio" ? (
						<div className={styles.radio_wrapper}>
							<div>
								<RadioButton
									inputId={`${name}-${option.value}`}
									key={index}
									checked={currentValue
										?.map((item) => item.value)
										.includes(option.value)}
									onChange={() => handleChange(option)}
									name={name}
								/>
								<label htmlFor={`${name}-${option.value}`}>
									{option.label}
								</label>
							</div>
							{option.subLabel && (
								<p className={styles.sub_label}>
									{option.subLabel}
								</p>
							)}
						</div>
					) : (
						<div className={styles.checkbox_wrapper}>
							<div>
								<Checkbox
									key={index}
									inputId={`${name}-${option.value}`}
									checked={currentValue
										?.map((item) => item.value)
										.includes(option.value)}
									onChange={() => handleChange(option)}
									name={name}
								/>
								<label htmlFor={`${name}-${option.value}`}>
									{option.label}
								</label>
							</div>
							{option.subLabel && (
								<p className={styles.sub_label}>
									{option.subLabel}
								</p>
							)}
						</div>
					)}
				</div>
			));
		};

		const renderContent = (value, onChange) => (
			<div className={customStyle}>
				<h4 className={styles.title}>
					{title}
					{required && <span className="required">*</span>}
				</h4>
				<div
					className={styles.options_container}
					style={{
						gridTemplateColumns: perRow
							? `repeat(${perRow})`
							: undefined,
					}}
				>
					{canSelectAll && !control && (
						<div className={styles.input_wrapper}>
							<Checkbox
								checked={
									selectedItems.length === options.length
								}
								onChange={handleSelectAll}
							/>
							<span onClick={handleSelectAll}>Select All</span>
						</div>
					)}
					{renderOptions(value, onChange)}
				</div>
				{error && (
					<p className={styles.error_message}>{error.message}</p>
				)}
				{infoText && (
					<span className={styles.info_text}>{infoText}</span>
				)}
			</div>
		);

		return control ? (
			<Controller
				name={name}
				control={control}
				render={({ field: { onChange, value } }) =>
					renderContent(value, onChange)
				}
			/>
		) : (
			renderContent(selectedItems, handleOptionChange)
		);
	}
);

export default NewMultiCheckbox;
