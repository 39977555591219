import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import PromptModal from "components/Utils/Prompt/PromptModal";
// import { format } from "date-fns";
// import { saveAs } from "file-saver";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import useUrlPreview from "hooks/useUrlPreview";
// import { ReactComponent as DeleteIcon } from "images/delete.svg";
// import { ReactComponent as DownloadIcon } from "images/download.svg";
import { ReactComponent as DeleteIcon } from "images/deleteInMenu.svg";
import { ReactComponent as FrameIcon } from "images/frame.svg";
// import tempIconWhite from "images/temporary-white-35-x-35.svg";
// import { ReactComponent as TempIcon } from "images/temporary.svg";
// import { ReactComponent as UploadIcon } from "images/upload.svg";
import { ReactComponent as UploadIcon } from "images/upload-image.svg";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import WarningModal from "../../../../components/Utils/GaawkModal/WarningModal";
import { companyProfileImageUpdated } from "../../../../store/slices/company";
import { profileImageUpdated } from "../../../../store/slices/user";
import CropModal from "../../../Utils/CropModal/CropModal";
import LazyImage from "../../../Utils/LazyImage/LazyImage";
// import AddCaption from "./AddCaption";
import FrameModal from "./Frame/FrameModal";
import styles from "./ProfileEdit.module.css";
import PublicInfo from "./PublicInfo";
// import TempModal from "./Temp/TempModal";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";

const Index = () => {
	const dispatch = useDispatch();

	const userData = useCurrentUser();
	const editCompany = userData.type === "COMPANY";

	const userImage = userData.profileImage?.originalImageURL;
	const userFrame = userData.profileImage?.frame?.url;
	const userFrameId = userData.profileImage?.frame?.uuid || "";
	// const userTempTime = userData.profileImage?.tempTime || 0;
	// const userCaption = userData.profileImage?.postText || "";

	const [image, setImage] = useState(userImage);
	const [tempImage, setTempImage] = useState("");
	const [frame, setFrame] = useState(userFrame);
	const [frameId, setFrameId] = useState(userFrameId);
	// const [caption, setCaption] = useState(userCaption);

	// const [tempTime, setTempTime] = useState(userTempTime || 0);
	// const [shareCheck, setShareCheck] = useState(false);
	const [deleteModalOpened, setDeleteModalOpened] = useState(false);
	const [cropModal, setCropModal] = useState(false);

	const { objectUrls, setObjectUrls, cleanupObjectUrls } = useUrlPreview();
	const [previewCropURL, setPreviewCropURL] = useState(undefined);

	const isImageBlob = image instanceof Blob && !(image instanceof File);

	useEffect(() => {
		if (isImageBlob) {
			setObjectUrls([URL.createObjectURL(image)]);
		}

		return () => {
			cleanupObjectUrls();
		};
	}, [image]);

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.EDIT_PROFILE);
	}, []);

	const {
		action: { mutate: deleteProfilePic },
	} = useMutate(
		profileApi.deleteProfilePic,
		(response) => {
			dispatch(
				editCompany
					? companyProfileImageUpdated(response.data)
					: profileImageUpdated(response.data)
			);
			setDeleteModalOpened(false);
			navigate(routes.profile());
		},
		undefined,
		{
			meta: {
				successMessage: "Changes saved!",
			},
		}
	);

	// const {
	// 	action: { mutate: makePermanent },
	// } = useMutate(
	// 	editCompany
	// 		? profileApi.makeCompanyProfilePicPermanent
	// 		: profileApi.makeProfilePicPermanent,
	// 	(response) => {
	// 		dispatch(
	// 			editCompany
	// 				? companyProfileImageUpdated(response.data)
	// 				: profileImageUpdated(response.data)
	// 		);
	// 	},
	// 	undefined,
	// 	{
	// 		meta: {
	// 			successMessage: "Changes saved!",
	// 		},
	// 	}
	// );

	// const {
	// 	action: { mutate: removeTemp },
	// } = useMutate(
	// 	editCompany ? profileApi.removeCompanyTempImg : profileApi.removeTempImg,
	// 	(response) => {
	// 		dispatch(
	// 			editCompany
	// 				? companyProfileImageUpdated(response.data)
	// 				: profileImageUpdated(response.data)
	// 		);
	// 	},
	// 	undefined,
	// 	{
	// 		meta: {
	// 			successMessage: "Changes saved!",
	// 		},
	// 	}
	// );

	// const handleDownload = () => {
	// 	const timeStamp = new Date().getTime();
	// 	saveAs(image, `gaawk_${timeStamp}.jpg`);
	// };

	const handleImage = (e) => {
		setTempImage(e.target.files[0]);
		setPreviewCropURL(URL.createObjectURL(e.target.files[0])); //* ADDED
		setCropModal(true);
	};

	const revokePreviewCropURL = () => {
		URL.revokeObjectURL(previewCropURL);
		setPreviewCropURL(undefined);
	};

	// const handleCaption = (text) => {
	// 	setCaption(text);
	// };

	const didMountRef = useRef(false);

	useEffect(() => {
		// * below to apply change after saving in states, and also not trigger unsavedChanges
		if (didMountRef.current) {
			setImage(userData.profileImage.originalImageURL);
			// setTempTime(userData.profileImage?.tempTime || 0);
			// setCaption(userData.profileImage?.postText || "");
			setFrame(userData.profileImage?.frame?.url || "");
			setFrameId(userData.profileImage?.frame?.uuid || "");
		}
		didMountRef.current = true;
	}, [userData.profileImage]);

	const checkUnsavedChanges = () => {
		if (
			image !== userImage ||
			frameId !== userFrameId
			// || tempTime !== userTempTime
			// || caption !== userCaption
		) {
			return true;
		} else return false;
	};

	// ! == temp modal ===

	// const [showTempModal, setShowTempModal] = useState(false);

	// ! == frame modal ===

	const [showFrameModal, setShowFrameModal] = useState(false);

	//! navigation blocker
	const [allowNavigation, setAllowNavigation] = useState(false);

	const onBlock = (currentLocation, nextLocation) => {
		if (allowNavigation) return false;

		if (checkUnsavedChanges() && nextLocation.pathname !== currentLocation.pathname) {
			return true;
		}
	};

	//! ==================
	const navigate = useNavigate();

	const {
		action: { mutate: updatePicture, isLoading },
	} = useMutate(
		profileApi.updatePicture,
		(response) => {
			dispatch(
				editCompany
					? companyProfileImageUpdated(response.data)
					: profileImageUpdated(response.data)
			);
			navigate(routes.profile());
		},
		undefined,
		{
			meta: {
				successMessage: "Changes saved!",
			},
		}
	);

	const handleSave = () => {
		const formData = new FormData();

		if (image instanceof Blob) {
			formData.append("profileImageFile", image, image.fileName);
		}

		// if (tempTime) {
		// 	formData.append("tempTime", tempTime);
		// 	formData.append("temp", true);
		// }

		// formData.append("share", shareCheck);

		// if (caption) {
		// 	formData.append("caption", caption);
		// }

		if (frameId) {
			formData.append("frameId", frameId);
		}

		if (editCompany) formData.append("companyId", userData.uuid);

		// // * to check the content of formData:
		// for (const pair of formData.entries()) {
		// 	console.log(pair[0] + ", " + pair[1]);
		// }

		setAllowNavigation(true);

		updatePicture({
			formData,
			isCompany: editCompany ? true : false,
		});
	};

	const inputFile = useRef(null);

	return (
		<>
			<div className={styles.container}>
				<div className={styles.image_options_container}>
					<div className={styles.img_canvas}>
						<LazyImage
							image={
								<img
									src={
										image instanceof Blob && !(image instanceof File)
											? objectUrls[0]
											: image
									}
									alt={"profile"}
								/>
							}
						/>

						{frame && (
							<LazyImage
								image={<img src={frame} alt={"frame"} />}
								customWrapper={styles.frame_wrapper}
							/>
						)}

						{/* {tempTime !== 0 && (
							<div className={styles.temp_custom_date_wrapper}>
								<img src={tempIconWhite} alt={"temp"} />
								<label>{format(new Date(tempTime), "dd/MM/yyyy")}</label>
							</div>
						)} */}
					</div>
				</div>

				<PublicInfo subtext="profile picture" />

				{/* <AddCaption onChange={handleCaption} disabled={!image} value={caption} /> */}

				<div className={styles.img_options}>
					<input
						type="file"
						accept="image/png, image/jpg, image/jpeg"
						onChange={(e) => {
							handleImage(e);
							e.target.value = "";
						}}
						ref={inputFile}
						style={{ display: "none" }}
					/>
					<button
						onClick={() => {
							inputFile.current.click();
						}}
					>
						<UploadIcon className={styles.icon} />
						{/* <img src={uploadIcon} className={styles.icon} alt={"upload"} /> */}
						{`${image ? "Change" : "Upload"} Profile Picture`}
					</button>

					{/* //*  can only make an image temporary when it's just been uploaded  */}
					{/* {image instanceof Blob && tempTime === 0 && (
						<button onClick={() => setShowTempModal(true)}>
							<TempIcon className={styles.icon} />
							Temporary Profile Picture
						</button>
					)}

                    {tempTime !== 0 && (
						<button
							onClick={() => {
								//* when already having a temp time
								if (userTempTime !== 0) {
									removeTemp(editCompany ? userData.uuid : undefined);
								} else {
									//* when just set temp time without saving it
									setTempTime(0);
								}


							}}
						>
							<TempIcon className={styles.icon} />
							Remove Temporary Profile Picture
						</button>
					)}

					{typeof image === "string" && tempTime !== 0 && (
						<button
							onClick={() => makePermanent(editCompany ? userData.uuid : undefined)}
						>
							<TempIcon className={styles.icon} />
							Make Temporary Profile Picture permanent
						</button>
					)} */}

					{/* {image && !frame && (
						<button onClick={() => setShowFrameModal(true)}>
							<FrameIcon className={styles.icon} />
							Add Frame
						</button>
					)} */}

					<button
						onClick={() => {
							if (!frame) setShowFrameModal(true);
							else {
								setFrame(undefined);
								setFrameId(undefined);
							}
						}}
						disabled={!image && !frame}
					>
						<FrameIcon className={styles.icon} />
						{`${frame ? "Remove" : "Add"}`} Frame
					</button>

					{/* {image && (
						<button onClick={handleDownload}>
							<DownloadIcon className={styles.icon} />
							Download
						</button>
					)} */}

					<button onClick={() => setDeleteModalOpened(true)} disabled={!image}>
						<DeleteIcon className={styles.icon} />
						Remove Profile Picture
					</button>
				</div>

				<div className={styles.button_wrapper}>
					<GaawkButton
						onClick={handleSave}
						isLoading={isLoading}
						text={"Save"}
						disabled={
							image === userImage && frame === userFrame
							// && caption === userCaption
						}
					/>
				</div>
			</div>

			<WarningModal
				show={deleteModalOpened}
				headerText="Are you sure you want to delete this image?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setDeleteModalOpened(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() => {
					setAllowNavigation(true);
					deleteProfilePic(editCompany && userData.uuid);
				}}
			/>

			<PromptModal when={onBlock} />

			<CropModal
				image={tempImage instanceof File && previewCropURL}
				show={cropModal}
				onClose={() => {
					setCropModal(false);
					revokePreviewCropURL();
				}}
				aspectMode={1}
				isCircle={true}
				onCrop={(cropped) => {
					cropped.fileName = tempImage.name;
					setImage(cropped);
					revokePreviewCropURL();
					setCropModal(false);
				}}
				returnBlob={true}
				canEscape={true}
			/>

			<FrameModal
				show={showFrameModal}
				onClose={() => setShowFrameModal(false)}
				imagePreview={isImageBlob ? objectUrls[0] : image}
				onSelect={(frameUrl, frameId) => {
					setFrame(frameUrl);
					setFrameId(frameId);
					setShowFrameModal(false);
				}}
			/>

			{/* <TempModal
				show={showTempModal}
				onClose={() => setShowTempModal(false)}
				imagePreview={isImageBlob && objectUrls[0]}
				onSelect={(temp) => {
					setTempTime(temp);
					setShowTempModal(false);
				}}
			/> */}
		</>
	);
};

export default Index;
