import styles from "../Landing/JobsLanding.module.scss";
import BackButton from "components/Utils/Button/BackButton";
import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
import JobLoader from "components/Utils/SubComs/CustomLoader/JobLoader";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import { classNames } from "primereact/utils";
import JobActions from "../JobsComponents/JobActions/JobActions";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import useUrlParams from "hooks/useUrlParams";
import useJob from "hooks/useJob";
import useProject from "../EmployerLanding/hooks/useProject";
import JobDetails from "../JobDetails/JobDetails";
import ProjectDetails from "../ProjectDetails/ProjectDetails";
import { ReactComponent as GaawkJobsLogo } from "images/gaawk-logos/gaawk-jobs.svg";
import useApi from "hooks/useApi";
import jobApi from "api/job";
import { jobsKeys } from "queryKeys/jobs-key-factory";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import useMutate from "hooks/useMutate";
import { Fragment, useEffect, useMemo } from "react";
import JobCard from "../JobsComponents/JobCard/JobCard";
import { profileKeys } from "queryKeys/profile-key-factory";
import { useCustomQuery } from "hooks/useCustomQuery";
import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";

const PAGE_SIZE = 20;

const JobsCompany = () => {
	const navigate = useNavigate();
	const isMobile = useSelector((state) => state.ui.isMobile);
	const queryClient = useQueryClient();
	const { companyUrl } = useParams();

	//! FETCH USER BY URL ====

	const getUserByUrlApi = useApi(profileApi.getUserByUrl, true, true);
	const fetchProfileByUrl = async ({ queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, url] = queryKey;
		const response = await getUserByUrlApi.request(url);
		return response.data;
	};
	const { data: urlData, isSuccess: isUrlSuccess } = useCustomQuery({
		queryKey: profileKeys.profileByUrl(companyUrl),
		queryFn: fetchProfileByUrl,
	});

	useEffect(() => {
		if (isUrlSuccess && urlData?.type === "USER") navigate(routes.jobs);
	}, [isUrlSuccess]);

	//! ======================

	const paramConfigs = {
		projectTab: {
			validator: (tab) => ["description", "long_term", "freelance", "casting"].includes(tab),
			defaultValue: "",
		},
		projectId: {
			validator: (id) => typeof id === "string",
			defaultValue: "",
		},
		projectJobId: {
			validator: (id) => typeof id === "string",
			defaultValue: "",
		},
		jobId: {
			validator: (id) => typeof id === "string",
			defaultValue: "",
		},
	};

	const { params, setParams } = useUrlParams(paramConfigs);

	const { jobId, projectTab, projectId, projectJobId } = params;

	const handleBackButton = () => {
		if (projectJobId) {
			setParams({ projectJobId: "" });
		} else if (projectId) {
			setParams({ projectId: "" });
		} else if (isMobile && jobId) {
			setParams({ jobId: "" });
		} else {
			navigate(-1);
		}
	};

	// ! ========= FETCH JOBS FROM COMPANY ========

	const getJobsApi = useApi(jobApi.getJobByCompanyId, true, true);

	const fetchJobs = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, companyId, jobType, projectId, closed, excludeId] = queryKey;

		const response = await getJobsApi.request(
			companyId,
			pageParam,
			PAGE_SIZE,
			jobType,
			closed,
			excludeId,
			projectId
		);
		return response.data;
	};

	const { items, viewRef, isFetchingNextPage, isLoading, fetchStatus } = useInfiniteScroll({
		queryKey: jobsKeys.jobsByCompany(urlData?.uuid, "", false),
		queryFn: fetchJobs,
		pageSize: PAGE_SIZE,
		enabled: isUrlSuccess,
	});

	const {
		action: { mutate: toggleSave },
	} = useMutate(jobApi.toggleSaveJob, (response, variables) => {
		const { jobId } = variables;

		queryClient.setQueryData(jobsKeys.jobsByCompany(urlData?.uuid, "", false), (oldData) => {
			return {
				...oldData,
				pages: oldData.pages.map((page) =>
					page.map((job) =>
						job.uuid === jobId ? { ...job, savedJob: !job.savedJob } : job
					)
				),
			};
		});
	});

	const jobsList = useMemo(
		() =>
			items?.map((job, index) => {
				const isLastItem = index === items.length - 1;
				const isActive = jobId === job.uuid;

				return (
					<Fragment key={job.uuid}>
						<JobCard
							ref={isLastItem ? viewRef : null}
							isActive={isActive}
							data={job}
							onToggleSave={() => {
								toggleSave({
									jobId: job.uuid,
									state: !job.savedJob,
								});
							}}
							onClick={() => {
								setParams({ jobId: job.uuid });
							}}
						/>

						{isLastItem && isFetchingNextPage && (
							<div className={styles.loading_item_container}>
								<JobLoader />
							</div>
						)}
					</Fragment>
				);
			}),
		[items, viewRef, jobId]
	);

	// !===================== SELECTED JOB =========================

	const { data: job, isFetching: isFetchingJob } = useJob(jobId);

	const { data: projectJob, isFetching: isFetchingProjectJob } = useJob(projectJobId);

	// !===================== FETCH PROJECT DETAILS ================

	const {
		data: projectData,
		isFetching: isFetchingProject,
		// isLoading: isLoadingProject,
		// fetchStatus: projectFetchStatus,
	} = useProject(job?.project?.uuid);

	// !===================== JOB APPLY =========================
	const handleApplyInvalidation = () => {
		queryClient.setQueryData(jobsKeys.jobsByCompany(urlData?.uuid, "", false), (oldData) => {
			return {
				...oldData,
				pages: oldData.pages.map((page) =>
					page.map((job) => (job.uuid === jobId ? { ...job, appliedTime: true } : job))
				),
			};
		});
	};

	// !======

	return (
		<DefaultPage
			headerRightContent={
				<>
					<div className={styles.header_bar}>
						<BackButton onBack={handleBackButton} />
						{projectId ? "Project" : "Jobs"}
					</div>
					{!isFetchingJob && job && !projectId && <JobActions job={job} />}
				</>
			}
			hideLeftSide={isMobile && jobId}
			hideRightSide={isMobile && !jobId}
			enableCollapsedNavBar
			hybridNavigation
			// disableScrollbar
			leftSideChildren={
				<div className={styles.left_side}>
					<div className={styles.jobs_container}>
						{isLoading && (
							<div className={styles.loading_item_container}>
								<JobLoader />
								<JobLoader />
							</div>
						)}

						{items.length > 0 && !isLoading && jobsList}

						<NoResults
							visible={!isLoading && items.length === 0}
							title={"Nothing Found!"}
						/>
					</div>
				</div>
			}
			rightSideChildren={
				<div className={styles.container}>
					<div
						className={classNames(styles.right_side, {
							[styles.align_middle]: !jobId,
						})}
					>
						{jobId ? (
							projectId ? (
								projectJobId ? (
									<JobDetails
										job={projectJob}
										isLoading={isFetchingProjectJob}
										onProjectClick={() =>
											setParams({
												projectId: job?.project?.uuid,
												projectJobId: "",
											})
										}
									/>
								) : (
									<ProjectDetails
										project={projectData}
										isLoading={isFetchingProject}
										activeTab={projectTab || "description"}
										onTabChange={(projectTab) => setParams({ projectTab })}
										onJobClick={(job) => {
											setParams({
												projectJobId: job.uuid,
												projectTab: "",
											});
										}}
									/>
								)
							) : (
								<JobDetails
									job={job}
									isLoading={isFetchingJob}
									onProjectClick={() =>
										setParams({ projectId: job?.project?.uuid })
									}
									onApply={handleApplyInvalidation}
								/>
							)
						) : (
							<GaawkJobsLogo />
						)}
					</div>
				</div>
			}
		/>
	);
};

export default JobsCompany;
