// import { useQueryClient } from "@tanstack/react-query";
// import projectApi from "api/project";
import CompanyItem from "components/Companies/CompanyItem";
// import routes from "components/Routing/routing-keys";
// import BackButton from "components/Utils/Button/BackButton";
import { companySizeList } from "components/Utils/General";
import LazyImage from "components/Utils/LazyImage/LazyImage";
// import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
// import LoadingPage from "components/Utils/SubComs/CustomLoader/LoadingPage";
import TabMenu from "components/Utils/SubComs/TabMenu/TabMenu";
import Tag from "components/Utils/SubComs/Tags/Tag";
import { format } from "date-fns";
import useCurrentUser from "hooks/useCurrentUser";
// import useMutate from "hooks/useMutate";
// import { ReactComponent as ImageIcon } from "images/gallery.svg";
// import { projectKeys } from "queryKeys/projects-key-factory";
import {
	// useEffect,
	useMemo,
} from "react";
// import { useNavigate, useParams } from "react-router-dom";
import CollapsibleJobSection from "../EmployerLanding/CollapsibleSection/CollapsibleJobSection";
// import useProject from "../EmployerLanding/hooks/useProject";
import CommonConnections from "../JobsComponents/CommonConnections";
import JobDates from "../JobsComponents/JobDates/JobDates";
import JobInfoItem from "../JobsComponents/JobInfoItem/JobInfoItem";
import JobLocation from "../JobsComponents/JobLocation";
import JobStatus from "../JobsComponents/JobStatus/JobStatus";
// import ProjectActions from "../JobsComponents/ProjectActions/ProjectActions";
import styles from "./ProjectDetails.module.scss";
// import useUrlParams from "hooks/useUrlParams";
// import { trackEvent } from "analytics/amplitude-config";
// import { eventsDictionary } from "analytics/events-dictionnary";
// import RoundButton from "components/Utils/Button/RoundButton";
// import NavBar from "components/Utils/SubComs/NavBar/NavBar";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import useUpdateProjectStatus from "hooks/useUpdateProjectStatus";

const ProjectDetails = ({ project, isLoading, activeTab, onTabChange, onJobClick }) => {
	const { uuid } = useCurrentUser();

	const {
		uuid: projectId,
		active,
		owner: {
			uuid: ownerId,
			bio,
			size,
			// industry //TODO >> not coming from backend at the moment
		} = {},
		owner,
		title,
		tags,
		startDate,
		endDate,
		descriptionText,
		location,
		commonConnections,
		commonConnectionsCount,
		insertionTime,
		image: { file: { customName: projectImage } = {} } = {},
		activeJobsCount,
		passedJobsCount,
		activeFreelanceCount,
		passedFreelanceCount,
		activeCastingCallsCount,
		passedCastingCallsCount,
	} = project || {};

	const isProjectOwner = uuid === ownerId;

	const tagsList = useMemo(
		() => tags?.map((tag) => <Tag key={tag.uuid} itemName={tag.name} />),
		[tags]
	);

	const toggleProjectStatus = useUpdateProjectStatus(project);

	const jobCounts = {
		long_term: {
			open: activeJobsCount,
			closed: passedJobsCount,
		},
		freelance: {
			open: activeFreelanceCount,
			closed: passedFreelanceCount,
		},
		casting: {
			open: activeCastingCallsCount,
			closed: passedCastingCallsCount,
		},
	};

	return isLoading ? (
		<LoadingSpinner customStyle={styles.full_height} />
	) : (
		<div className={styles.container}>
			<JobStatus
				canEdit={isProjectOwner}
				active={active}
				onToggleStatus={() =>
					toggleProjectStatus({
						projectId,
						status: !active,
					})
				}
				isProject={true}
			/>

			<div className={styles.project_card}>
				{projectImage && (
					<div className={styles.img_wrapper}>
						<LazyImage image={<img src={projectImage} alt={`${title}`} />} />
					</div>
				)}

				<div className={styles.project_details}>
					<div className={styles.title_wrapper}>
						<h3 className={styles.title}>{title}</h3>
						{/* {isProjectOwner && (
							<RoundButton
								icon="edit"
								onClick={() => navigate(routes.projectEdit(projectId))}
							/>
						)} */}
					</div>
					<div className={styles.project_more_info}>
						{tags.length > 0 && <div className={styles.tags_container}>{tagsList}</div>}
						<div className={styles.date_container}>
							<p>
								Posted on{" "}
								<span>{format(new Date(insertionTime), "dd/MM/yyyy")}</span>
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className={styles.menu}>
				<TabMenu
					menuItems={{
						description: "Description",
						long_term: "Job Openings",
						freelance: "Freelancing",
						casting: "Casting Calls",
					}}
					selectedTab={activeTab}
					onSelectedTabChanged={onTabChange}
					customStyle={styles.menu_button}
				/>
			</div>

			{activeTab === "description" ? (
				<div className={styles.project_description_wrapper}>
					<JobInfoItem
						title={"Project Dates"}
						content={<JobDates startDate={startDate} endDate={endDate} />}
					/>

					{descriptionText && (
						<JobInfoItem
							title={"Project Description"}
							content={<p>{descriptionText}</p>}
						/>
					)}

					<JobLocation location={location} isProject={true} />

					<JobInfoItem
						title={"About the company"}
						content={
							<>
								<CompanyItem
									item={owner}
									subdata={`${owner.locationDTO.name}, ${owner.locationDTO.countryDTO.name}`}
								/>
								<div className={styles.company_stats}>
									{/* <p>Industry: </p> */}
									<p>
										Company Size:{" "}
										{companySizeList.find((item) => item.value === size).label}
									</p>
								</div>
								<p>{bio}</p>
							</>
						}
					/>

					{commonConnections?.length > 0 && (
						<CommonConnections
							count={commonConnectionsCount}
							data={commonConnections}
							company={owner}
						/>
					)}
				</div>
			) : (
				<div className={styles.jobs_container}>
					<CollapsibleJobSection
						activeTab={activeTab}
						open
						count={jobCounts[activeTab].open}
						projectId={projectId}
						companyId={ownerId}
						onClick={onJobClick}
						disableActions={true}
					/>

					<CollapsibleJobSection
						activeTab={activeTab}
						open={false}
						count={jobCounts[activeTab].closed}
						projectId={projectId}
						companyId={ownerId}
						onClick={onJobClick}
						disableActions={true}
					/>
				</div>
			)}
		</div>
	);
};

export default ProjectDetails;
