import msgIcon from "images/message-icon-black.svg";
// import shareIcon from "images/share-option.svg";
import linkIcon from "images/link.svg";
import deleteIcon from "images/delete-black.svg";
import editIcon from "images/edit-icon-20-x-20-black.svg";

//TODO >> to be deleted (replace by ProductPopover)
const ProductPopoverActions = ({
	isOwner,
	onMessage,
	onEdit,
	// onShare,
	onDelete,
	onCopy,
	onClose,
}) => {
	return (
		<div className="popover_container">
			{isOwner ? (
				<>
					<div
						className="popover_item"
						onClick={(e) => {
							e.preventDefault();
							onEdit();
							onClose();
						}}
					>
						<img src={editIcon} alt="edit" />
						<span>Edit</span>
					</div>

					{/* <div
                        className="popover_item"
                        onClick={(e) => {
                            e.preventDefault();
                            onShare();
                        }}
                    >
                        <img src={shareIcon} alt="share" />
                        <span>Share</span>
                    </div> */}

					<div
						className="popover_item"
						onClick={(e) => {
							e.preventDefault();
							onDelete();
							onClose();
						}}
					>
						<img src={deleteIcon} alt="delete" />
						<span>Delete</span>
					</div>
				</>
			) : (
				<div
					className="popover_item"
					onClick={(e) => {
						e.preventDefault();
						onMessage();
					}}
				>
					<img src={msgIcon} alt="chat" />
					<span>Ask about Product</span>
				</div>
			)}

			<div
				className="popover_item"
				onClick={(e) => {
					e.preventDefault();
					onCopy();
					onClose();
				}}
			>
				<img src={linkIcon} alt="copy" />
				<span>Copy Link</span>
			</div>
		</div>
	);
};

export default ProductPopoverActions;
