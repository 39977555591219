import useReportPost from "hooks/useReportPost";
import styles from "./ReportModal.module.scss";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import reportSchema from "./report-schema";
import InputWrapper from "../SubComs/Inputs/InputWrapper/InputWrapper";
import TextInput from "../SubComs/Inputs/TextInput/TextInput";
import SimpleDropZone from "../SubComs/CustomDropZone/SimpleDropZone";
import GalleryFileItem from "components/Profile/GalleryTab/GalleryFileItem";
import useMutate from "hooks/useMutate";
import reportApi from "api/report";
import reportThumbIcon from "images/report-thumb.svg";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "../Button/GaawkButton";
import TextArea from "../SubComs/Inputs/TextArea/TextArea";
import { RadioButton } from "primereact/radiobutton";
import NewGaawkModal from "../NewGaawkModal/NewGaawkModal";

const ReportModal = forwardRef(({ category }, ref) => {
	const { showReportModal, setShowReportModal, reasons } = useReportPost(category);

	const [step, setStep] = useState(1);
	const [itemId, setItemId] = useState("");

	useImperativeHandle(ref, () => ({
		showReportModal(itemId) {
			setItemId(itemId);
			setShowReportModal(true);
		},
	}));

	const {
		register,
		formState: {
			errors,
			// isDirty,
			// dirtyFields
		},
		control,
		// setValue,
		// clearErrors,
		handleSubmit,
		watch,
		setError,
		reset,
	} = useForm({
		resolver: joiResolver(reportSchema),
		mode: "onChange",
		// defaultValues: {
		// 	name: "",
		// 	url: "",
		// 	urlValidity: undefined,
		// 	file: [],
		// 	type: null,
		// 	privacy: "public",
		// },
	});

	const reasonId = watch("reason");
	const reasonText = watch("reasonText");

	useEffect(() => {
		if (reasons) {
			reset({
				reason: reasons[0].id.toString(),
			});
		}
	}, [reasons]);

	const reasonsList = useMemo(
		() => (onChange, value) =>
			reasons?.map((reason) => {
				return (
					<div className={styles.radio_item}>
						<RadioButton
							checked={value === reason.id.toString()}
							onChange={(e) => onChange(e.value)}
							inputId={reason.id.toString()}
							value={reason.id.toString()}
						/>
						<label htmlFor={reason.id.toString()}>
							<h4>{reason.title}</h4>
							<p className={styles.label_text}>{reason.subTitle}</p>
						</label>
					</div>
				);
			}),
		[reasons]
	);

	const handleNext = () => {
		const { error } = reportSchema.validate({
			reason: reasonId,
			reasonText: reasonText,
		});

		if (error) {
			setError("reasonText", {
				message: "Please provide a reason",
			});
		} else {
			setStep(2);
		}
	};

	const [droppedFiles, setDroppedFiles] = useState([]);

	const onDropFiles = useCallback(
		(acceptedFiles) => {
			if (acceptedFiles.length > 0) {
				const formattedFiles = [];
				for (let i = 0; i < acceptedFiles.length; i++) {
					formattedFiles.push({
						caption: "",
						multipartFile: acceptedFiles[i],
						type: acceptedFiles[i].type.includes("video") ? "VIDEO" : "IMAGE",
						taggedIds: [],
						newFile: true,
					});
				}
				setDroppedFiles(formattedFiles);
			}
		},
		[droppedFiles]
	);

	const mappedFiles = useMemo(
		() =>
			droppedFiles.map((file, index) => {
				return (
					<GalleryFileItem
						key={index}
						file={file}
						onRemove={() => setDroppedFiles([])}
						className={styles.file_wrapper}
						// onEdit={() => handleShowEditModal(file, index)}
					/>
				);
			}),
		[droppedFiles]
	);

	const {
		action: { mutate: report, isLoading },
	} = useMutate(reportApi.report, () => {
		// queryClient.invalidateQueries(boardsKeys.detail(boardId));
		// navigate(`/boards/${board.uuid}`);
		trackEvent(eventsDictionary.REPORT.REPORT_DONE);
		setStep(3);
	});

	const handleSave = (data) => {
		const { reason: reasonId, reasonText, message } = data;

		const formData = new FormData();

		// formData.append("uuid", uuid);
		// formData.append("name", boardName);
		if (message) formData.append("message", message);
		if (reasonText) formData.append("reasonText", reasonText);
		if (droppedFiles.length > 0)
			formData.append(
				"file",
				droppedFiles[0].multipartFile,
				droppedFiles[0].multipartFile.name
			);

		// if (boardImage[0] instanceof Blob)
		// 	formData.append("image", boardImage[0], boardImageName);
		// if (feedImage[0] instanceof Blob)
		// 	formData.append("feedImage", feedImage[0], feedImageName);

		// setShouldBlockSave(false);
		// editBoard(formData);

		report({
			category,
			itemId,
			reasonId: reasonId === "other" ? "" : reasonId,
			formData,
		});
	};

	const handleClose = () => {
		setStep(1);
		reset();
		setDroppedFiles([]);
		setShowReportModal(false);
		setItemId("");
	};

	return (
		<NewGaawkModal
			visible={showReportModal}
			onHide={handleClose}
			title={`Report ${category === "specialProfile" ? "special profile" : category}`}
			onBack={step === 2 ? () => setStep(1) : undefined}
			children={
				<form onSubmit={handleSubmit((data) => handleSave(data))}>
					{step === 1 ? (
						<>
							<p>
								If you come across any of the following issues, please don’t
								hesitate to report them to us:
							</p>

							<div className={styles.reasons_wrapper}>
								<Controller
									name={"reason"}
									control={control}
									render={({ field: { onChange, value } }) => (
										<>
											{reasonsList(onChange, value)}

											<div className={styles.radio_item}>
												<RadioButton
													checked={value === "other"}
													onChange={onChange}
													inputId={"other"}
													value={"other"}
												/>
												<label htmlFor={"other"}>
													<h4>Other</h4>
													<p className={styles.label_text}>
														Choose this if you didn't find your reason
														listed above.
													</p>
												</label>
											</div>
										</>
									)}
								/>

								{reasonId === "other" && (
									<InputWrapper
										className={styles.reason_input}
										error={errors?.reasonText}
										component={
											<TextInput
												{...register("reasonText")}
												placeholder="Explain here please..."
											/>
										}
									/>
								)}
							</div>

							<GaawkButton
								onClick={handleNext}
								text={"Next"}
								className={styles.button}
							/>
						</>
					) : step === 2 ? (
						<>
							<p>
								Could you please provide more information or consider sharing a
								screenshot or a screen recording to facilitate better understanding?
								We value your feedback and would like to assist you effectively.
							</p>

							<Controller
								name={"message"}
								control={control}
								render={({ field }) => (
									<TextArea
										className={styles.textarea_wrapper}
										placeholder="Explain here please..."
										error={errors.message}
										{...field}
									/>
								)}
							/>

							<SimpleDropZone
								onDrop={onDropFiles}
								acceptedFiles={[
									"image/png, image/jpg, image/jpeg, application/pdf, video/*",
								]}
								multiple={false}
							/>

							{mappedFiles.length > 0 && mappedFiles}

							<GaawkButton
								text={"Submit"}
								type={"submit"}
								className={styles.button}
								isLoading={isLoading}
							/>
						</>
					) : (
						<div className={styles.success_container}>
							<img
								src={reportThumbIcon}
								className={styles.success_icon}
								alt="report"
							/>

							<h3>Thank you for your feedback!</h3>

							<p>
								Our team has received it and will use your valuable input to enhance
								your app experience.
							</p>

							<GaawkButton
								onClick={handleClose}
								text={"Close"}
								className={styles.button}
							/>
						</div>
					)}
				</form>
			}
		/>
	);
});

export default ReportModal;
