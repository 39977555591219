import { useLocation, useNavigate } from "react-router-dom";
import Resources from "components/Resources/Resources";
import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
import BackButton from "components/Utils/Button/BackButton";
import NavBar from "components/Utils/SubComs/NavBar/NavBar";
import styles from "../Header.module.css";

const ResourcesView = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	let component;
	let headerBtn;
	let headerTitle;
	let directRender = false;

	switch (pathname) {
		case "/resources":
			directRender = true;
			component = <Resources />;
			break;

		default:
			break;
	}

	return (
		<>
			{directRender ? (
				component
			) : (
				<DefaultPage
					headerRightContent={
						<>
							<div className={styles.header}>
								<BackButton onBack={() => navigate(-1)} />
								{headerTitle}
							</div>
							{headerBtn}
						</>
					}
					leftSideChildren={<NavBar />}
					rightSideChildren={component}
				/>
			)}
		</>
	);
};

export default ResourcesView;
