import GroupMember from "components/Chat/GroupMember";
import styles from "./ApplicantDetails.module.scss";
import { format } from "date-fns";
import JobInfoItem from "../JobsComponents/JobInfoItem/JobInfoItem";
import GaawkButton from "components/Utils/Button/GaawkButton";
import { useQueryClient } from "@tanstack/react-query";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import useMutate from "hooks/useMutate";
import { viewerMapper } from "components/Utils/General";
import jobApi from "api/job";
import { jobsKeys } from "queryKeys/jobs-key-factory";
import FullScreenModal from "components/Utils/GaawkModal/FullScreenModal";
import ViewerHeader from "components/Utils/FileViewer/ViewerHeader";
import { ReactComponent as FullscreenIcon } from "images/fullscreen-icon-white.svg";
import FileViewer from "components/Utils/FileViewer/FileViewer";
import { useSelector } from "react-redux";
import { Skeleton } from "primereact/skeleton";

const ApplicantDetails = forwardRef(
	({ applicant, isLoading, onInvalidate }, ref) => {
		const queryClient = useQueryClient();
		const isMobile = useSelector((state) => state.ui.isMobile);

		const {
			uuid: applicationId,
			applyTime,
			coverText,
			coverUploaded,
			cover,
			portfolioUploaded,
			portfolio,
		} = applicant || {};

		const [showSlider, setShowSlider] = useState(false);
		const [media, setMedia] = useState();
		const fileviewerRef = useRef();

		const handleViewer = (file) => {
			setMedia(viewerMapper([file]));
			setShowSlider(true);
		};

		const {
			action: { mutate: updateApplicationStatus },
		} = useMutate(jobApi.updateApplicantStatus, () => {
			queryClient.invalidateQueries(jobsKeys.applicant(applicationId));
			onInvalidate();
		});

		useImperativeHandle(ref, () => ({
			turnDownApplicant() {
				updateApplicationStatus({
					applicationId: applicant.uuid,
					status: "TURNED_DOWN",
				});
			},
		}));

		if (isLoading)
			return (
				<div className={styles.container}>
					<div className={styles.applicant_wrapper}>
						<div className={styles.user_skeleton}>
							<Skeleton
								shape="circle"
								size="40px"
								className="mr-2"
							></Skeleton>
							<div className={styles.user_skeleton_name}>
								<Skeleton
									width={"20rem"}
									height={"1rem"}
								></Skeleton>
								<Skeleton
									width={"10rem"}
									height={"1rem"}
								></Skeleton>
							</div>
						</div>
						<Skeleton
							width={"10rem"}
							height={"1rem"}
							pt={{
								root: {
									style: {
										marginTop: "5px",
									},
								},
							}}
						></Skeleton>
					</div>
				</div>
			);

		return (
			<div className={styles.container}>
				<div className={styles.applicant_wrapper}>
					<GroupMember
						redirect
						data={applicant.minifiedProfile}
						showConnection={false}
						customStyle={styles.applicant}
						showMutualConnections={false}
						rightSideAction={
							<p
								className={styles.application_date}
								style={{ display: isMobile ? "none" : "block" }}
							>
								Applied on:{" "}
								{format(new Date(applyTime), "dd/MM/yyyy")}
							</p>
						}
					/>

					<p
						className={styles.application_date}
						style={{ display: isMobile ? "block" : "none" }}
					>
						Applied on: {format(new Date(applyTime), "dd/MM/yyyy")}
					</p>
				</div>

				<JobInfoItem
					title="Portfolio"
					content={
						portfolioUploaded ? (
							<div className={styles.application_item_container}>
								{portfolio.name}
								<GaawkButton
									severity={"tertiary"}
									text={"View"}
									className={styles.item_btn}
									onClick={() => handleViewer(portfolio)}
								/>
							</div>
						) : (
							<i>No portfolio attached</i>
						)
					}
				/>

				<JobInfoItem
					title="Cover"
					content={
						coverText ? (
							<p>{coverText}</p>
						) : coverUploaded ? (
							<div className={styles.application_item_container}>
								{cover.name}
								<GaawkButton
									severity={"tertiary"}
									text={"View"}
									className={styles.item_btn}
									onClick={() => handleViewer(cover)}
								/>
							</div>
						) : (
							<i>No cover letter attached</i>
						)
					}
				/>

				<div className={styles.button_container}>
					<GaawkButton
						text={
							applicant.status === "TURNED_DOWN"
								? "Turned Down"
								: "Turn Down"
						}
						className={styles.btn}
						severity={
							applicant.status === "TURNED_DOWN"
								? "black-secondary"
								: "danger"
						}
						type="button"
						disabled={applicant.status === "TURNED_DOWN"}
						onClick={() =>
							updateApplicationStatus({
								applicationId: applicant.uuid,
								status: "TURNED_DOWN",
							})
						}
					/>

					<GaawkButton
						text={
							applicant.status === "SHORTLISTED"
								? "Shortlisted"
								: "Shortlist"
						}
						className={styles.btn}
						disabled={applicant.status === "SHORTLISTED"}
						severity={
							applicant.status === "SHORTLISTED"
								? "black-secondary"
								: "primary"
						}
						onClick={() =>
							updateApplicationStatus({
								applicationId: applicant.uuid,
								status: "SHORTLISTED",
							})
						}
					/>
				</div>

				<FullScreenModal
					show={showSlider}
					fullWidth={true}
					onClose={() => setShowSlider(false)}
					header={
						<ViewerHeader
							onClose={() => setShowSlider(false)}
							leftSide={""}
							rightSide={
								<button
									onClick={() =>
										fileviewerRef.current.enterFullscreen()
									}
								>
									<FullscreenIcon width={20} />
								</button>
							}
						/>
					}
					children={
						<div>
							<FileViewer ref={fileviewerRef} files={media} />
						</div>
					}
				/>
			</div>
		);
	}
);

export default ApplicantDetails;
