/* eslint-disable jsx-a11y/img-redundant-alt */
// import ProfilePic from "components/Home/ProfilePic";
// import routes from "components/Routing/routing-keys";
import {
	// cssValue,
	pluralize,
} from "components/Utils/General";
import LazyImage from "components/Utils/LazyImage/LazyImage";
// import Tag from "components/Utils/SubComs/Tags/Tag";
import { format } from "date-fns";
// import { ReactComponent as ImageIcon } from "images/gallery.svg";
import { classNames } from "primereact/utils";
// import { useNavigate } from "react-router-dom";
import ProjectActions from "../ProjectActions/ProjectActions";
import styles from "./ProjectCard.module.scss";
import { useSelector } from "react-redux";

const ProjectCard = ({ project, isMenu = false, onClick, isActive = false }) => {
	// const navigate = useNavigate();

	const isMobile = useSelector((state) => state.ui.isMobile);

	const {
		// uuid,
		image,
		active: opened,
		title,
		activeCastingCallsCount,
		activeFreelanceCount,
		activeJobsCount,
		// owner: { name: ownerName, profileImage },
		insertionTime,
	} = project;

	const activeJobs = activeJobsCount + activeFreelanceCount;

	// * ===============================

	return (
		<div
			className={classNames(styles.container, {
				[styles.is_menu]: isMenu,
				[styles.active]: isActive,
				[styles.closed]: !opened,
			})}
			role={"button"}
			onClick={onClick}
			tabIndex={0}
		>
			{/* <div className={styles.img_wrapper}>
				{image ? (
					<LazyImage image={<img src={image?.file?.customName} alt="project image" />} />
				) : (
					<ImageIcon width={35} height={35} fill={cssValue("--gray30")} />
				)}
			</div> */}
			{image && ((isMenu && isMobile) || !isMenu) && (
				<div className={styles.img_wrapper}>
					<LazyImage image={<img src={image?.file?.customName} alt="project image" />} />
				</div>
			)}
			<div className={styles.project_details}>
				{/* <div>
					<div className={styles.project_header}>


						{isMobile && isMenu && <ProjectActions project={project} />}
					</div>

					<h3 className={styles.title}>{title}</h3>
				</div> */}

				<h3 className={styles.title}>{title}</h3>

				<p className={styles.job_count_container}>blablabla</p>
				{activeJobs + activeCastingCallsCount > 0 && (
					<p className={styles.job_count_container}>
						{activeJobs > 0 &&
							`${activeJobs} Active ${pluralize(activeJobs, "Job")}${
								activeCastingCallsCount > 0 ? ", " : ""
							}`}
						{activeCastingCallsCount > 0 &&
							`${activeCastingCallsCount} Active ${pluralize(
								activeCastingCallsCount,
								"Casting Call"
							)}`}
					</p>
				)}

				<div className={styles.project_footer}>
					{/* <ProfilePic
							w={24}
							h={24}
							type={"COMPANY"}
							name={ownerName}
							nameClassName={styles.companyName}
							thumbnail={profileImage?.image}
						/> */}

					<p
						className={classNames(styles.date, {
							[styles.active]: isActive,
						})}
					>
						Posted on: {format(new Date(insertionTime), "dd.MM.yyyy")}
					</p>

					{isMobile && isMenu && <ProjectActions project={project} />}
				</div>
			</div>
		</div>
	);
};

export default ProjectCard;
