import { useState, useEffect, useMemo } from "react";
import styles from "./CreatePost.module.css";
import WarningModal from "../Utils/GaawkModal/WarningModal";
import PostItem from "./PostItem";
import StaticMap from "../Utils/StaticMap/StaticMap";
import PostMedia from "./PostMedia";
import VisibilitySelector from "../Utils/Visibility/VisibilitySelector";
import LocationModal from "./LocationModal";
import Close from "images/cross-white-14-x-14.svg";
import publicIcon from "images/public_icon.svg";
import editIcon from "images/edit-icon-20-x-20-black.svg";
import closeIcon from "images/close-icon-black-20-x-20.svg";
import MediaList from "./MediaList";
import postApi from "../../api/post";
import PostButtons from "./PostButtons";
import EmotionModal from "./EmotionModal";
import EditMediaModal from "./EditMediaModal";
import PostHeader from "./PostHeader";
import CustomMentions from "../Utils/Mentions/CustomMentions";
import TextInput from "../Utils/SubComs/Inputs/TextInput/TextInput";
import ProfilePic from "./ProfilePic";
import {
	// getFileIcon,
	getLocationType,
	getVideoFrame,
	maxPostLength,
	replaceItemInArray,
} from "../Utils/General";
import useEditSubPost from "../../hooks/useEditSubPost";
import PostDocuments from "./Components/PostDocuments";
import useMutate from "hooks/useMutate";
import useUrlPreview from "hooks/useUrlPreview";
import UserListModal from "components/Utils/UserListModal/UserListModal";
import profileApi from "api/profile";
import GroupMember from "components/Chat/GroupMember";
import { profileKeys } from "queryKeys/profile-key-factory";
import { useMutation } from "@tanstack/react-query";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";
import NewGaawkModal from "components/Utils/NewGaawkModal/NewGaawkModal";

const itemsPerPage = 20;

const uploadSingleFile = async ({ media, post }) => {
	const formData = new FormData();

	formData.append("parentPostId", post.uuid);
	formData.append(
		"multipartFile",
		media.multipartFile,
		media.multipartFile instanceof Blob ? media.multipartFile.fileName : undefined
	);
	formData.append("caption", media.caption);
	if (media.date) formData.append("date", media.date);
	if (media.location) {
		formData.append("location.latitude", media.location.latitude);
		formData.append("location.longitude", media.location.longitude);
		formData.append("location.type", media.location.type);
		formData.append("location.title", media.location.title);
	}
	if (media.taggedIds) {
		formData.append(
			"taggedIds",
			media.taggedIds.map((profile) => profile.uuid)
		);
	}

	if (media.services) {
		formData.append(
			"serviceIds",
			media.services.map((service) => service.uuid)
		);
	}

	if (post?.board) {
		formData.append("boardId", post.board.uuid);
		formData.append("title", post.title);
	}

	const response = await postApi.addMedia(formData);
	if (!response.ok) {
		throw response.data;
	}
	return response.data;
};

const EditPostModal = ({
	existingPost,
	show,
	toggleShow,
	name,
	profileImg,
	type,
	// onSaveEdit,
	// onDeleteMedia,
	// onDeleteAllMedia,
	onInvalidate,
	onClose,
	// url,
}) => {
	const [showWarning, setShowWarning] = useState(false);

	const [post, setPost] = useState("");

	const [subPosts, setSubPosts] = useState([]);

	const [pdfPosts, setPdfPosts] = useState([]);

	useEffect(() => {
		const mediaListArray = [];

		if (post.totalMediaCount > 0) {
			mediaListArray.push(...post.mediaList);
		}

		setSubPosts(mediaListArray);
	}, [post.mediaList, post.totalMediaCount]);

	useEffect(() => {
		const pdfListArray = [];

		if (post.totalPDFCount > 0) {
			pdfListArray.push(...post.pdfList);
		}

		setPdfPosts(pdfListArray);
	}, [post.pdfList, post.totalPDFCount]);

	const postMedia = useMemo(
		() =>
			subPosts.map((media) => {
				if (media.file) {
					return media.file;
				}
				return media;
			}),
		[subPosts]
	);

	useEffect(() => {
		if (existingPost) {
			setPost(existingPost);
		}
	}, [existingPost]);

	const thumbnail = profileImg ? profileImg.originalImageURL : undefined;

	const handleWarningDiscard = () => {
		setShowWarning(false);
		closeEditModal();
	};

	const closeEditModal = () => {
		toggleShow(false);
		setPost("");
		setSubPosts([]);
		setPdfPosts([]);
		onClose && onClose();
	};

	// ! ===== TEXT HANDLER

	const handlePostTextChange = (text) => {
		setPost((post) => ({
			...post,
			postText: text,
		}));
	};

	const onEmojiClick = (event, emojiObject) => {
		setPost((post) => ({
			...post,
			postText: post.postText + emojiObject.emoji,
		}));
	};

	// ! ===== MEDIA HANDLER

	const [showPhotosVideos, setShowPhotosVideos] = useState(false);

	const [showEditMedia, setShowEditMedia] = useState(false);
	const [editItem, setEditItem] = useState("");
	const [editItemIndex, setEditItemIndex] = useState("");

	const handleShowPhotoVideoModal = () => {
		toggleShow(false);
		setShowPhotosVideos(true);
	};

	const handleHidePhotoVideoModal = () => {
		setShowPhotosVideos(false);
		toggleShow(true);
	};

	const handleShowEditModal = (media, index) => {
		setEditItem(media);
		setEditItemIndex(index);
		setShowPhotosVideos(false);
		setShowEditMedia(true);
	};

	const handleHideEditMedia = () => {
		setShowEditMedia(false);
		setShowPhotosVideos(true);
	};

	const handleUpdateSubPost = (updatedSubPost) => {
		setSubPosts((prevState) => replaceItemInArray(updatedSubPost, prevState, "uuid"));
	};

	const { handleEdit } = useEditSubPost(postApi.updateMedia, handleUpdateSubPost);

	const handleConfirmEdit = (newItem) => {
		if (newItem.newFile) {
			setSubPosts((prevSubPosts) => {
				const updatedSubPosts = [...prevSubPosts];
				updatedSubPosts[editItemIndex] = newItem;
				return updatedSubPosts;
			});
		} else {
			handleEdit(newItem);
		}
	};

	// useEffect(() => {
	// 	if (post && post.subPosts.length > 0) {
	// 		setSubPosts(post.subPosts);
	// 	}
	// }, [post.subPosts]);

	// const {
	// 	action: { mutate: deletePdf },
	// } = useMutate(postApi.deleteMedia, () => {
	// 	setPdfPosts((prevState) => prevState.filter((item) => item.uuid !== subIdToDelete));
	// 	setSubIdToDelete("");
	// 	onInvalidate();
	// });

	const { objectUrls, setObjectUrls, cleanupObjectUrls } = useUrlPreview();

	useEffect(() => {
		// Create object URLs when subPosts changes

		// const newObjectUrls = subPosts.map((media) =>
		// 	// * condition necessary to keep index between existing files and newFiles
		// 	media.newFile ? URL.createObjectURL(media.multipartFile) : ""
		// );
		// setObjectUrls(newObjectUrls);

		const loadItems = async () => {
			const promises = subPosts.map(async (media) => {
				if (media.newFile) {
					if (media.multipartFile.type.startsWith("image")) {
						return URL.createObjectURL(media.multipartFile);
					} else if (media.multipartFile.type.startsWith("video")) {
						const videoFirstFrame = await getVideoFrame(media.multipartFile);
						return videoFirstFrame;
					}
					// else {
					// 	return getFileIcon(media, media.name).icon;
					// }
				} else {
					return "";
				}
			});

			const resolvedUrls = await Promise.all(promises);
			setObjectUrls(resolvedUrls);
		};

		loadItems();

		// Cleanup function
		return () => {
			// newObjectUrls.forEach((url) => URL.revokeObjectURL(url));
			cleanupObjectUrls();
		};
	}, [subPosts]);

	//!========= DELETE SINGLE MEDIA / PDF HANDLERS ===============

	const {
		action: { mutate: deleteMedia },
	} = useMutate(postApi.deleteMedia, (response, variables) => {
		const { subId, isPdf } = variables;

		if (isPdf) {
			setPdfPosts((prevState) => prevState.filter((item) => item.uuid !== subId));
		} else {
			setSubPosts((prevState) => prevState.filter((item) => item.uuid !== subId));
			if (subPosts.length === 1) handleHidePhotoVideoModal();
		}

		onInvalidate();
	});

	const handleRemovePDFItem = (item, index) => {
		if (item.newFile) {
			setPdfPosts((prevState) => prevState.filter((_, i) => i !== index));
		} else {
			deleteMedia({ postId: post.uuid, subId: item.uuid, isPdf: true });
		}
	};

	const handleRemoveMediaItem = (sub, index) => () => {
		if (sub.newFile) {
			setSubPosts((prevState) => prevState.filter((_, i) => i !== index));
			if (subPosts.length === 1) handleHidePhotoVideoModal();
		} else {
			deleteMedia({ postId: post.uuid, subId: sub.uuid });
		}
	};

	//!========= DELETE ALL MEDIAS HANDLERS ===============

	const {
		action: { mutate: deleteAllMedia },
	} = useMutate(postApi.deleteAllMedia, () => {
		onInvalidate();
		setSubPosts([]);
	});

	// !===================================================

	const mediaList = useMemo(
		() =>
			subPosts.map((sub, index) => (
				<MediaList
					key={index}
					mediaType={sub.newFile ? sub.type : sub.file.mediaType}
					url={sub.newFile ? objectUrls[index] : sub.file.url}
					fileName={sub.newFile ? sub.multipartFile.name : sub.file.originalName}
					text={sub.newFile ? sub.caption : sub.postText}
					onClick={handleRemoveMediaItem(sub, index)}
					onEdit={() => handleShowEditModal(sub, index)}
				/>
			)),
		[subPosts, objectUrls]
	);

	const handleAppendFileChange = ({ target }) => {
		const files = target.files;
		const fileData = [];

		if (files.length > 0) {
			for (let i = 0; i < files.length; i++) {
				fileData.push({
					caption: "",
					multipartFile: files[i],
					postId: "",
					type: files[i].type.includes("video")
						? "VIDEO"
						: files[i].type.includes("image")
						? "IMAGE"
						: "PDF",
					taggedIds: [],
					newFile: true,
				});
			}
		}

		setSubPosts((prevState) => [...prevState, ...fileData]);
	};
	const handleAppendPdf = ({ target }) => {
		const files = target.files;
		const fileData = [];

		if (files.length > 0) {
			for (let i = 0; i < files.length; i++) {
				fileData.push({
					caption: "",
					multipartFile: files[i],
					postId: "",
					type: "PDF",
					taggedIds: [],
					newFile: true,
				});
			}
		}

		setPdfPosts((prevState) => [...prevState, ...fileData]);
	};

	// ! ===== LOCATION HANDLER

	const [showLocationModal, setShowLocationModal] = useState(false);

	const handleShowLocationModal = () => {
		toggleShow(false);
		setShowLocationModal(true);
	};

	const handleClearLocation = () => {
		setPost((prevState) => ({ ...prevState, location: null }));
	};

	const handleLocationClicked = (place) => {
		setPost((prevState) => ({
			...prevState,
			location: {
				latitude: place.geometry.location.lat,
				longitude: place.geometry.location.lng,
				title: place.name,
				placeId: place.place_id,
				type: getLocationType(place.types),
			},
		}));

		handleCloseLocationModal();
	};

	const handleCloseLocationModal = () => {
		setShowLocationModal(false);
		toggleShow(true);
	};

	// ! ===== VISIBILITY HANDLER

	const handlePostVisibilityChange = (value) => () => {
		setPost((prevState) => ({
			...prevState,
			postVisibility: value,
		}));
	};

	// ! ===== EMOTION HANDLER

	const [showEmotionModal, setShowEmotionModal] = useState(false);

	const handleEmotionClicked = (emotion) => {
		setPost((prevState) => ({
			...prevState,
			emotion,
		}));
		handleHideEmotionModal();
	};

	const handleShowEmotionModal = () => {
		toggleShow(false);
		setShowEmotionModal(true);
	};

	const handleHideEmotionModal = () => {
		setShowEmotionModal(false);
		toggleShow(true);
	};

	const handleRemoveEmotion = (e) => {
		e.stopPropagation();
		setPost((prevState) => ({
			...prevState,
			emotion: null,
		}));
	};

	// !=========== TAGS HANDLER ==================
	const [showTagModal, setShowTagModal] = useState(false);
	const [postTags, setPostTags] = useState([]);

	const handleShowTagModal = () => {
		toggleShow(false);
		setShowTagModal(true);
		setPostTags(post.taggedProfiles || []);
	};

	const handleHideTagModal = () => {
		setShowTagModal(false);
		toggleShow(true);
		setPostTags([]);
	};

	const fetchTags = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, __, query] = queryKey;
		const response = await profileApi.searchTags(pageParam, itemsPerPage, query);
		return response.data;
	};

	const handleConfirmTags = () => {
		setPost((prevState) => ({
			...prevState,
			taggedProfiles: postTags,
		}));
		handleHideTagModal();
	};

	const handleRemoveTag = (tag) => {
		setPostTags((prevTags) => prevTags.filter((t) => t.uuid !== tag.uuid));
	};

	const renderTag = ({ user: tag, ref }) => {
		const isSelected = postTags.some((t) => t.uuid === tag.uuid);

		return (
			<GroupMember
				ref={ref}
				key={tag.uuid}
				redirect={false}
				onClick={() => {
					if (!isSelected) {
						setPostTags((prevState) => [...prevState, tag]);
					}
				}}
				disabled={isSelected}
				data={tag}
			/>
		);
	};

	// ! ===== SAVE HANDLER

	const uploadMutation = useMutation(uploadSingleFile);

	const handleEditSuccess = () => {
		onInvalidate();
		closeEditModal();
	};

	const {
		action: { mutate: updatePost },
	} = useMutate(postApi.updatePost, async (response) => {
		trackEvent(eventsDictionary.POST.EDIT_DONE);
		const newMediaArray = [...subPosts, ...pdfPosts].filter((media) => media.newFile);

		if (newMediaArray.length > 0) {
			try {
				for (const media of newMediaArray) {
					await uploadMutation.mutateAsync({
						media,
						post: response.data,
					});
				}

				handleEditSuccess();
			} catch (error) {
				console.error("Upload failed:", error);
			}
		} else {
			handleEditSuccess();
		}
	});

	const handleSave = () => {
		if (uploadMutation.isLoading) return; //safety in case users try to resubmit when loading files

		const { uuid, emotion, location, postText, postVisibility, taggedProfiles, board, title } =
			post;

		const updatedPost = {
			postId: uuid,
			...(emotion && { emotionId: emotion.uuid }),
			...(subPosts.length > 0 || pdfPosts.length > 0
				? { filesIncluded: true }
				: { filesIncluded: false }),
			...(location && {
				location: {
					latitude: location.latitude,
					longitude: location.longitude,
					title: location.title,
					type: location.type,
					placeId: location.placeId,
				},
			}),
			postText,
			postVisibility: board ? "PUBLIC" : postVisibility,
			// sharedPostId: "",
			...(taggedProfiles.length > 0 && {
				taggedProfiles: taggedProfiles.map((profile) => profile.uuid),
			}),
			...(board && {
				boardId: board.uuid,
				title,
			}),
		};

		//TODO IMPROVEMENT: should compare original post using shallowEqual, to see if it's necessary to call the edit post API in case there are 0 changes?

		updatePost({ updatedPost });
	};

	return (
		<>
			<NewGaawkModal
				visible={show}
				onHide={() => setShowWarning(true)}
				title={"Edit a Post"}
				children={
					<>
						<div className={styles.user_wrapper}>
							<PostHeader
								name={name}
								img={thumbnail}
								emotion={post.emotion}
								location={post.location}
								tags={post.taggedProfiles}
								createMode={true}
								postTime={null}
								visibility={null}
								type={type}
								// url={owner.url}
								onTagClicked={(e) => {
									e.stopPropagation();
									handleShowTagModal();
								}}
								onOtherTagsClicked={(e) => {
									e.stopPropagation();
									handleShowTagModal();
								}}
							/>
						</div>

						{post?.board && (
							<>
								<div className={styles.board_name}>
									<ProfilePic thumbnail={post.board.feedImage} type="BOARD" />
									{post.board.name}
								</div>
								<div className={styles.title_input}>
									<label>Title</label>

									<TextInput
										onChange={({ target }) =>
											setPost((prevState) => ({
												...prevState,
												title: target.value,
											}))
										}
										value={post.title}
										placeholder="Enter title"
										cursor="text"
									/>
								</div>
							</>
						)}

						<div className={styles.create_body_wrapper}>
							<div className={styles.post_text_container}>
								<CustomMentions
									placeholder={"What's on your mind?"}
									value={post && post.postText}
									onChange={handlePostTextChange}
									onEmojiSelected={onEmojiClick}
									fixedHeight={true}
									h={177}
									maxLength={maxPostLength}
								/>
							</div>

							{post && post.location && postMedia.length <= 0 && (
								<div className={styles.post_location_map_container}>
									<StaticMap
										lat={post.location.latitude}
										lng={post.location.longitude}
										title={post.location.title}
									/>

									<button
										className={styles.location_clear_button}
										onClick={handleClearLocation}
									>
										<img src={Close} alt={"dismiss"} />
									</button>
								</div>
							)}

							{(post.postType === "PROFILE_PIC" || post.postType === "COVER_PIC") && (
								<div className={styles.create_post_media_container}>
									<PostMedia mediaList={[post.file]} viewer={true} />
								</div>
							)}

							{postMedia.length > 0 && (
								<div className={styles.create_post_media_container}>
									<PostMedia mediaList={postMedia} viewer={true} />
									<div className={styles.create_post_media_controllers}>
										<button
											className={styles.edit_post_media_button}
											onClick={handleShowPhotoVideoModal}
										>
											<img src={editIcon} alt={"edit"} />
											<label>Edit</label>
										</button>

										<button
											className={styles.remove_post_media_button}
											onClick={() => deleteAllMedia(post.uuid)}
										>
											<img src={closeIcon} alt={"close"} />
										</button>
									</div>
								</div>
							)}

							{post.sharedPost && (
								<PostItem
									post={post.sharedPost}
									inShareModal={true}
									isSharePost={true}
								/>
							)}

							{pdfPosts.length > 0 && (
								<PostDocuments
									items={pdfPosts}
									onDelete={handleRemovePDFItem}
									canEdit={true}
								/>
							)}

							<div className={styles.post_visibility_row}>
								{post?.board ? (
									<div className={styles.board_visibility}>
										<img src={publicIcon} alt="" />
										Posting to boards is always public.
									</div>
								) : (
									<>
										<label>Post to:</label>

										<div className={styles.visibility_wrapper}>
											<VisibilitySelector
												value={post && post.postVisibility}
												onValueChange={handlePostVisibilityChange}
											/>
										</div>
									</>
								)}
							</div>

							{!post.sharedPost &&
								post.postType !== "PROFILE_PIC" &&
								post.postType !== "COVER_PIC" && (
									<PostButtons
										onChange={handleAppendFileChange}
										onChangePdf={handleAppendPdf}
										onEmotion={handleShowEmotionModal}
										onLocation={handleShowLocationModal}
										onTag={handleShowTagModal}
									/>
								)}

							<div className={styles.post_button_wrapper}>
								<GaawkButton
									onClick={handleSave}
									isLoading={uploadMutation.isLoading}
									text={"Update"}
								/>
							</div>
						</div>
					</>
				}
			/>

			<WarningModal
				show={showWarning}
				headerText="Are you sure you want to discard your changes?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setShowWarning(false)}
				submitButtonText={"DISCARD"}
				onSubmitButtonClicked={handleWarningDiscard}
			/>

			<NewGaawkModal
				visible={showPhotosVideos}
				onHide={handleHidePhotoVideoModal}
				title={"Photos / Videos"}
				children={<>{mediaList}</>}
			/>

			<EditMediaModal
				show={showEditMedia}
				onClose={handleHideEditMedia}
				item={editItem}
				// isEditing={true}
				onConfirm={handleConfirmEdit}
				onToggle={(bool) => setShowEditMedia(bool)}
				onReset={() => setEditItem("")}
			/>

			<EmotionModal
				emotionId={post.emotion && post.emotion.uuid}
				show={showEmotionModal}
				onClose={handleHideEmotionModal}
				onSelect={handleEmotionClicked}
				onRemoveEmotion={handleRemoveEmotion}
			/>

			<UserListModal
				show={showTagModal}
				onBack={handleHideTagModal}
				title="Tag People / Companies"
				fetchUsers={fetchTags}
				renderUser={renderTag}
				hasSearch={true}
				queryKey={(query) => profileKeys.searchTags(query)}
				showCloseButton={false}
				showSelectedContainer={true}
				selectedUsers={postTags}
				onConfirm={handleConfirmTags}
				onRemove={handleRemoveTag}
				pageSize={itemsPerPage}
			/>

			<LocationModal
				show={showLocationModal}
				onClose={handleCloseLocationModal}
				onLocationClicked={handleLocationClicked}
				activeLocation={post.location}
				onRemove={handleClearLocation}
			/>
		</>
	);
};

export default EditPostModal;
