import styles from "../PeopleFilter.module.scss";
import {
	employementTypes,
	experienceLvlTypes,
	workLocation as workLocationTypes,
} from "components/Utils/General";
import NewMultiCheckbox from "components/Utils/NewMultiCheckBox/NewMultiCheckBox";
import Switcher from "components/Utils/SubComs/Switcher/Switcher";
// import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
// import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";

const LongTermFilters = ({
	onEmployementSelect,
	employement,
	onWorkLocation,
	workLocation,
	onExpLvl,
	expLvl,
	onCalendarAvailability,
	calendarAvailability,
}) => {
	const excludedEmployement = ["SELF_EMPLOYED", "FREELANCE", "APPRENTICESHIP", "CASTING_CALLS"];

	const filteredEmployement = employementTypes.filter(
		(item) => !excludedEmployement.includes(item.value)
	);

	return (
		// <div className={styles.separator_container}>
		// 	<div className={styles.container}>
		<div className={styles.dynamic_filter_container}>
			<h3>Job & Freelance Filters</h3>

			<NewMultiCheckbox
				title={"Job Type"}
				options={filteredEmployement}
				onSelect={onEmployementSelect}
				selected={employement}
				perRow="2, auto"
				customStyle={styles.multicheckbox_container}
			/>

			<NewMultiCheckbox
				title={"Working Location"}
				options={workLocationTypes}
				onSelect={onWorkLocation}
				selected={workLocation}
				perRow="3, auto"
				customStyle={styles.multicheckbox_container}
			/>

			<NewMultiCheckbox
				title={"Experience Level"}
				options={experienceLvlTypes}
				onSelect={onExpLvl}
				selected={expLvl}
				perRow="2, auto"
				customStyle={styles.multicheckbox_container}
			/>

			<div className={styles.switch_container}>
				<label>
					<Switcher value={calendarAvailability} onChange={onCalendarAvailability} />
					<span>Show Jobs as per calendar availability</span>
				</label>
			</div>

			{/* //TODO >> this one will need to be a multicheckbox component now  */}
			{/* <InputWrapper
    					label="Experience Level"
    					component={
    						<TextInput
    							value={expLvl}
    							placeholder="Enter number of years"
    							onChange={onExpLvl}
    							type={"number"}
    						/>
    					}
    				/> */}
		</div>
		// </div>
		// </div>
	);
};

export default LongTermFilters;
