import TabMenu from "components/Utils/SubComs/TabMenu/TabMenu";
import styles from "./Favorites.module.scss";
import useUrlParams from "hooks/useUrlParams";
import useConnectionsCount from "hooks/useConnectionsCount";
import useCurrentUser from "hooks/useCurrentUser";
import { getCounter } from "components/Profile/Connection/connection-counter-messages";
import ConnectionSection from "components/Profile/Connection/ConnectionSection";
import { connectionsKeys } from "queryKeys/connections-key-factory";
import { useConnectionQueries } from "hooks/useConnectionQueries";
import { useState } from "react";
import useDebounce from "hooks/useDebounce";
import SearchInput from "components/Utils/SubComs/Inputs/SearchInput/SearchInput";

const pageSize = 20;

const Favorites = () => {
	const { uuid } = useCurrentUser();
	const { data: connectionCount } = useConnectionsCount(uuid);

	const queryFunctions = useConnectionQueries(pageSize);

	// ! ======= TABS URL LOGIC ==========

	const paramConfigs = {
		tab: {
			validator: (tab) =>
				["followers", "following", "favorites", "blocked"].includes(
					tab
				),
			defaultValue: "followers",
		},
	};

	const { params, setParams } = useUrlParams(paramConfigs);
	const { tab } = params;

	const handleTabChange = (tab) => {
		setParams({ tab });
	};

	// ! ========= Search handlers =========
	const [searchInput, setSearchInput] = useState("");
	const debouncedSearch = useDebounce(searchInput);

	const handleClear = () => {
		setSearchInput("");
	};

	const handleChangeInput = ({ target }) => {
		const query = target.value;
		setSearchInput(query);
	};

	const isWaitingForDebounce = searchInput && searchInput !== debouncedSearch;
	console.log("🚀 ~ isWaitingForDebounce >>", isWaitingForDebounce);

	return (
		<div className={styles.container}>
			<div className={styles.menu}>
				<TabMenu
					menuItems={{
						followers: "Followers",
						following: "Following",
						favorites: "Favorites",
						blocked: "Blocked",
					}}
					selectedTab={tab}
					onSelectedTabChanged={handleTabChange}
					customStyle={styles.menu_button}
				/>
			</div>

			{connectionCount && (
				<div className={styles.counter}>
					{getCounter(tab, connectionCount, true)}
				</div>
			)}

			<div className={styles.search_container}>
				<SearchInput
					onChange={handleChangeInput}
					value={searchInput}
					showIcons={true}
					onClearClicked={handleClear}
					border={false}
				/>
			</div>

			<ConnectionSection
				pageSize={pageSize}
				queryFn={queryFunctions[tab]}
				queryKey={connectionsKeys.list(uuid, tab, debouncedSearch)}
				isWaitingForDebounce={isWaitingForDebounce}
			/>
		</div>
	);
};

export default Favorites;
