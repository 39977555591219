export const projectKeys = {
	all: ["projects"],
	// lists: (companyId, status) => [
	// 	...projectKeys.all,
	// 	"listProjects",
	// 	companyId,
	// 	status,
	// ],
	lists: () => [...projectKeys.all, "lists"],

	projectsByCompany: (companyId) => [...projectKeys.lists(), companyId],

	filteredProjectsByCompany: (companyId, status) => [
		...projectKeys.projectsByCompany(companyId),
		status,
	],

	details: () => [...projectKeys.all, "details"],
	detail: (id) => [...projectKeys.details(), id],
	projectTags: (query) => [...projectKeys.all, "tags", query],
	// medias: (uuid) => [...specialProfileKeys.all, "medias", uuid],
	// getTalents: (uuid, confirmed = true, type = "N_A") => [
	// 	...specialProfileKeys.all,
	// 	"talents",
	// 	uuid,
	// 	confirmed,
	// 	type,
	// ],
};
