import { useCustomQuery } from "hooks/useCustomQuery";
import useApi from "hooks/useApi";
import { workStatusKeys } from "queryKeys/workStatus-key-factory";
import workStatusApi from "api/workStatus";
import { locationFormatter } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";

const useWorkStatus = (uuid) => {
	const getWorkStatusApi = useApi(workStatusApi.getWorkStatus, true, true);

	const fetchWorkStatus = async ({ queryKey }) => {
		const [_, profileId] = queryKey;
		const response = await getWorkStatusApi.request(profileId);
		return response.data;
	};

	const query = useCustomQuery({
		queryKey: workStatusKeys.getWorkStatus(uuid),
		queryFn: fetchWorkStatus,
		options: {
			select: (fetchedData) => {
				const {
					roles,
					locations,
					// legalCountries,
					...rest
				} = fetchedData;

				const formattedRoles = roles?.map((role) => ({
					label: role.roleDTO ? role.roleDTO.name : role.roleName,
					value: role.roleDTO ? role.roleDTO.uuid : role.roleName,
				}));

				return {
					...rest,
					locations: locations?.map((item) => locationFormatter(item)),
					// legalCountries: legalCountries?.map((item) => locationFormatter(item)),
					roles: roles ? formattedRoles : [],
				};
			},
		},
	});

	return query;
};

export default useWorkStatus;
