import { useRef } from "react";
import { ReactComponent as DotsIcon } from "images/dots.svg";
import editIcon from "images/edit-icon-20-x-20-black.svg";
import pinIcon from "images/pin-icon-gray.svg";
import deleteIcon from "images/delete-icon-black-20-x-20.svg";
import shareIcon from "images/share-option.svg";
// import warningIcon from "images/exclamation-mark.svg";
import Popover from "../../Utils/Popover/Popover";
import linkIcon from "images/link.svg";
import reportIcon from "images/report.svg";
import styles from "./BoardPostActions.module.css";
import { useLocation } from "react-router";
import routes from "components/Routing/routing-keys";

const BoardPostActions = ({
	whiteButton = false,
	isOwner,
	canEditBoard,
	onEdit,
	onTogglePin,
	isPinned,
	onDelete,
	onShare,
	onCopy,
	onReport,
}) => {
	const popoverRef = useRef(null);

	const { pathname } = useLocation();

	return (
		<>
			{isPinned && (
				<img
					src={pinIcon}
					className={styles.pin_icon}
					// style={{ height: "19px", width: "auto" }}
					alt=""
				/>
			)}
			<Popover
				allow={["left"]}
				// crossAxis={15}
				ref={popoverRef}
				render={
					<div className="popover_container">
						{isOwner && (
							<button
								className="popover_item"
								tabIndex={0}
								onClick={() => {
									onEdit();
									popoverRef.current.closePopover();
								}}
							>
								<span className="img-sm">
									<img src={editIcon} alt={"edit"} />
								</span>
								<span>Edit post</span>
							</button>
						)}

						{canEditBoard && pathname.startsWith(routes.boards) && (
							<button
								className="popover_item"
								tabIndex={0}
								onClick={() => {
									onTogglePin();
									popoverRef.current.closePopover();
								}}
							>
								{isPinned ? (
									<>
										<span className="img-sm">
											<img src={pinIcon} alt={"pin"} />
										</span>
										<span>Remove Pin</span>
									</>
								) : (
									<>
										<span className="img-sm">
											<img src={pinIcon} alt={"pin"} />
										</span>
										<span>Pin post to board</span>
									</>
								)}
							</button>
						)}

						<button
							className="popover_item"
							tabIndex={0}
							onClick={() => {
								onShare();
								popoverRef.current.closePopover();
							}}
						>
							<span className="img-sm">
								<img src={shareIcon} alt={"share"} />
							</span>
							<span>Share on Gaawk</span>
						</button>

						<button
							className="popover_item"
							tabIndex={0}
							onClick={(e) => {
								e.stopPropagation();
								onCopy();
								popoverRef.current.closePopover();
							}}
						>
							<span className="img-sm">
								<img src={linkIcon} alt={"link"} />
							</span>
							<span>Copy link</span>
						</button>

						{(isOwner || canEditBoard) && (
							<button
								className="popover_item"
								tabIndex={0}
								onClick={() => {
									onDelete();
									popoverRef.current.closePopover();
								}}
							>
								<span className="img-sm">
									<img src={deleteIcon} alt={"delete"} />
								</span>
								<span>Delete post</span>
							</button>
						)}

						<button
							className="popover_item"
							tabIndex={0}
							onClick={(e) => {
								e.stopPropagation();
								onReport();
								popoverRef.current.closePopover();
							}}
						>
							<span className="img-sm">
								<img src={reportIcon} alt={"report"} />
							</span>
							<span>Report post</span>
						</button>

						{/* {!isOwner && (
                            <button
                                className="popover_item"
                                tabIndex={0}
                                onClick={() => {
                                    alert("Report this post");
                                    popoverRef.current.closePopover();
                                }}
                            >
                                <span className="img-sm">
                                    <img src={warningIcon} alt={"report"} />
                                </span>
                                <span>Report this post</span>
                            </button>
                        )} */}
					</div>
				}
			>
				<button className={styles.more_button}>
					<DotsIcon fill={whiteButton ? "#FFF" : "#000"} />
				</button>
			</Popover>
		</>
	);
};

export default BoardPostActions;
