import { joiResolver } from "@hookform/resolvers/joi";
import routes from "components/Routing/routing-keys";
import PromptModal from "components/Utils/Prompt/PromptModal";
import useApi from "hooks/useApi";
import useCompanyId from "hooks/useCompanyId";
import useCurrencies from "hooks/useCurrencies";
import useFetchLanguages from "hooks/useFetchLanguages";
import useFetchLocation from "hooks/useFetchLocation";
import useMutate from "hooks/useMutate";
import { Calendar } from "primereact/calendar";
import { languagesKeys } from "queryKeys/languages-key-factory";
import { locationKeys } from "queryKeys/location-key-factory";
import { skillsKeys } from "queryKeys/skills-key-factory";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import jobApi from "../../../api/job";
import skillsApi from "../../../api/skills";
import MapItem from "../../Profile/ProfileEdit/Contacts/Address/MapItem";
import {
	employementTypes,
	experienceLvlTypes,
	frequencyTypes,
	workLocation as workLocationTypes,
} from "../../Utils/General";
import MapSelect from "../../Utils/MapSelect/MapSelect";
import LoadingPage from "../../Utils/SubComs/CustomLoader/LoadingPage";
import { RadioButton } from "primereact/radiobutton";
import CustomSelect from "../../Utils/SubComs/CustomSelect/CustomSelect";
import DayPicker from "../../Utils/SubComs/DayPicker/DayPicker";
import InfiniteSearchInput from "../../Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import MultiSelectInput from "../../Utils/SubComs/Inputs/MultiSelectInput/MultiSelectInput";
import {
	defaultFormatter,
	languageFormatter,
	locationFormatter,
	unduplicateValue,
} from "../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import TextInput from "../../Utils/SubComs/Inputs/TextInput/TextInput";
import jobSchema from "./job-schema";
import styles from "./JobEntry.module.css";
import { useCustomQuery } from "hooks/useCustomQuery";
import { jobsKeys } from "queryKeys/jobs-key-factory";
import useEthnicities from "hooks/useEthnicities";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";
import TextArea from "components/Utils/SubComs/Inputs/TextArea/TextArea";
import NewMultiCheckbox from "components/Utils/NewMultiCheckBox/NewMultiCheckBox";
import Switcher from "components/Utils/SubComs/Switcher/Switcher";
import NewGaawkModal from "components/Utils/NewGaawkModal/NewGaawkModal";

const PAGE_SIZE = 20;
const defaultErrorMessage = "Something Wrong Happened";

const JobEntry = ({ jobType: type = "Long_Term", project: projectObject }) => {
	const navigate = useNavigate();
	const { jobId } = useParams();
	const { pathname } = useLocation();
	const editMode = useMemo(() => pathname === routes.jobEdit(jobId), [jobId, pathname]);

	useEffect(() => {
		if (editMode) {
			//TODO
		} else {
			trackEvent(eventsDictionary.JOB.CREATE, { jobType: type });
		}
	}, [editMode]);

	const [jobType, setJobType] = useState(type);
	const [project, setProject] = useState(projectObject);

	const handleFailedRequest = () => {
		setShouldBlockSave(true);
	};

	const handleRedirect = ({ data }) => {
		const { jobType, uuid: jobId, project } = data;

		navigate(
			`${routes.jobs}?tab=${jobType === "Casting_Call" ? "casting" : jobType.toLowerCase()}${
				project ? `&projectId=${project.uuid}` : ""
			}&jobId=${jobId}`,
			{ replace: true }
		);
	};

	//* ========= JOB API =================

	const {
		action: { mutate: createJob, isLoading: createJobLoading },
	} = useMutate(jobApi.createJob, handleRedirect, handleFailedRequest, {
		meta: {
			errorMessage: defaultErrorMessage,
		},
	});

	const {
		action: { mutate: updateJob, isLoading: updateJobLoading },
	} = useMutate(jobApi.updateJob, handleRedirect, handleFailedRequest, {
		meta: {
			errorMessage: defaultErrorMessage,
		},
	});

	//* ========= CASTING API =============

	const {
		action: { mutate: createCasting, isLoading: createCastingLoading },
	} = useMutate(jobApi.createCasting, handleRedirect, handleFailedRequest, {
		meta: {
			errorMessage: defaultErrorMessage,
		},
	});

	const {
		action: { mutate: updateCasting, isLoading: updateCastingLoading },
	} = useMutate(jobApi.updateCasting, handleRedirect, handleFailedRequest, {
		meta: {
			errorMessage: defaultErrorMessage,
		},
	});

	//* ========= FREELANCE API ===========

	const {
		action: { mutate: createFreelance, isLoading: createFreelanceLoading },
	} = useMutate(jobApi.createFreelance, handleRedirect, handleFailedRequest, {
		meta: {
			errorMessage: defaultErrorMessage,
		},
	});

	const {
		action: { mutate: updateFreelance, isLoading: updateFreelanceLoading },
	} = useMutate(jobApi.updateFreelance, handleRedirect, handleFailedRequest, {
		meta: {
			errorMessage: defaultErrorMessage,
		},
	});

	// * ==================================

	const companyId = useCompanyId();

	const [locationModal, setLocationModal] = useState(false);

	const [shouldBlockSave, setShouldBlockSave] = useState(true);
	// const [redirect, setRedirect] = useState("");

	const [mapLocation, setMapLocation] = useState("");

	const [selectedLocation, setSelectedLocation] = useState("");

	const { data: currencies, isLoading: isLoadingCurrencies } = useCurrencies();

	const handleMapLocation = (location) => {
		setMapLocation(location);
	};

	const handleSelectedLocation = () => {
		setValue("address", mapLocation.address);
		setSelectedLocation(mapLocation);
		setLocationModal(false);
	};

	const handleRemoveLocation = () => {
		setSelectedLocation("");
	};

	const getJobByIdApi = useApi(jobApi.getJobById, true, true);

	const fetchJob = async ({ queryKey }) => {
		const [_, __, jobId] = queryKey;
		const response = await getJobByIdApi.request(jobId);
		return response.data;
	};

	const { data, isSuccess, isLoading, fetchStatus, error } = useCustomQuery({
		queryKey: jobsKeys.detail(jobId),
		queryFn: fetchJob,
		enabled: editMode,
	});

	useEffect(() => {
		if (isSuccess) {
			const {
				name,
				longTermJob: { workingLocation, type, experience } = {},
				castingCallDTO: {
					dateTime,
					gender,
					languages = [],
					maxAge,
					minAge,
					ethnicity,
				} = {},
				freelanceJob: {
					startDate,
					endDate,
					experience: freelanceExp,
					workingLocation: freelanceWorkingLocation,
				} = {},
				closed,
				description,
				requirements,
				skills,
				location,
				compensation,
				currency,
				frequency,
				jobType,
				project: projectObject,
				minifiedCompany,
			} = data;

			// * IF NOT THE OWNER OF THE JOB, WILL THROW ERROR
			if (companyId !== minifiedCompany.uuid) {
				throw new Response("", {
					statusText: "You are not allowed to edit this job",
					status: 403,
				});
			}

			setJobType(jobType);

			if (projectObject) setProject(projectObject);

			if (location)
				setSelectedLocation({
					address: location?.title,
					city: location.locationDTO?.name,
					country: location.locationDTO?.countryDTO?.name,
					countryCode: location.locationDTO?.countryDTO?.code,
					lat: location.latitude,
					lng: location.longitude,
				});

			reset({
				title: name,
				...(type && {
					employementType: employementTypes.find((item) => item.value === type),
				}),
				...((workingLocation || freelanceWorkingLocation) && {
					location: workLocationTypes.find(
						(location) =>
							location.value === workingLocation ||
							location.value === freelanceWorkingLocation
					),
				}),
				...((experience || freelanceExp) && {
					experienceNeeded: experienceLvlTypes.find(
						(exp) => exp.value === experience || exp.value === freelanceExp
					),
				}),
				...(gender && { gender: gender.toLowerCase() }),
				...(minAge > 0 && { minAge }),
				...(maxAge > 0 && { maxAge }),
				...(dateTime > 0 && {
					date: new Date(dateTime),
					// time: format(new Date(dateTime), "h:mm aaa"),
				}),
				...(startDate > 0 && {
					startDate: new Date(startDate),
				}),
				...(endDate > 0 && {
					endDate: new Date(endDate),
				}),
				...(languages.length > 0 && {
					languages: languages.map((language) => ({
						label: language.name,
						value: language.code,
					})),
				}),
				...(languages.length === 0 &&
					jobType === "Casting_Call" && {
						languages: [],
					}),
				...(ethnicity && {
					ethnicity: {
						label: ethnicity.name,
						value: ethnicity.uuid,
					},
				}),
				status: !closed,
				description,
				requirements,
				skills: skills.map((skill) => defaultFormatter(skill)),
				...(compensation > 0 && { compensation }),
				...(currency && { currency: defaultFormatter(currency) }),
				...(frequency !== "N_A" && {
					frequency: frequencyTypes.find((item) => item.value === frequency),
				}),
				...(location?.title && {
					address: location.title,
				}),
				...(location?.locationDTO && {
					city: {
						label: `${location.locationDTO.name}, ${location.locationDTO.countryDTO.name}`,
						value: location.locationDTO.id,
					},
				}),
			});
		}
	}, [data, isSuccess]);

	const excludedEmployement = ["SELF_EMPLOYED", "FREELANCE", "APPRENTICESHIP", "CASTING_CALLS"];
	const updatedTypes = employementTypes.filter(
		(item) => !excludedEmployement.includes(item.value)
	);

	const handleRemoveSkill = (skillIndex) => {
		const updatedSkills = skillsWatcher.filter((_, index) => index !== skillIndex);
		setValue("skills", updatedSkills);
	};

	const {
		register,
		setValue,
		formState: { errors, isDirty },
		watch,
		handleSubmit,
		control,
		reset,
	} = useForm({
		resolver: joiResolver(jobSchema(jobType)),
		mode: "onSubmit",
		defaultValues: {
			title: "",
			...(jobType !== "Casting_Call" && {
				location: {
					label: "On Site",
					value: "OFFICE",
				},
				experienceNeeded: {
					label: "Entry-level",
					value: "ENTRY_LEVEL",
				},
			}),
			...(jobType === "Casting_Call" && {
				minAge: "",
				maxAge: "",
				gender: "",
				ethnicity: null,
				languages: [],
				date: null,
				// time: null,
			}),
			...(jobType === "Long_Term" && {
				employementType: {
					label: "Full Time",
					value: "FULL_TIME",
				},
			}),
			...(jobType === "Freelance" && {
				startDate: null,
				endDate: null,
			}),
			status: true,
			description: "",
			requirements: "",
			skills: [],
			compensation: "",
			currency: null,
			frequency: null,
			city: null,
			address: "",
		},
	});

	const statusWatcher = watch("status");
	const skillsWatcher = watch("skills");
	const currencyWatcher = watch("currency");
	const frequencyWatcher = watch("frequency");
	const languagesWatcher = watch("languages");
	const cityWatcher = watch("city");

	const getSkills = useApi(skillsApi.searchSkills, true, true);

	const fetchSkills = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, searchInput] = queryKey;

		const response = await getSkills.request(pageParam, PAGE_SIZE, searchInput);
		return response.data;
	};

	const handleSave = (data) => {
		const {
			compensation,
			currency,
			description,
			location,
			employementType,
			experienceNeeded,
			frequency,
			requirements,
			skills,
			status: active,
			title,
			gender,
			languages,
			date,
			// time,
			minAge,
			maxAge,
			ethnicity,
			startDate,
			endDate,
			address,
			city,
		} = data;

		//TODO >> check si ca break pas
		// if (date) {
		// 	if (time) {
		// 		const [hours, minutes] = time.split(":");
		// 		date.setHours(hours, minutes);
		// 	} else {
		// 		date.setHours(0, 0);
		// 	}
		// }

		const body = {
			title,
			active,
			...(project && { projectId: project.uuid }),
			...(city && {
				locationId: city.value,
			}),
			...(address && {
				address,
			}),
			...(selectedLocation &&
				cityWatcher &&
				selectedLocation.lat !== 0 &&
				selectedLocation.lng !== 0 && {
					latitude: selectedLocation.lat,
					longitude: selectedLocation.lng,
				}),
			...(compensation > 0 && { compensation }),
			...(currency && { currencyId: currency.value }),
			...(frequency && { frequency: frequency.value }),
			...(description && { description }),
			...(requirements && { requirements }),
			...(jobType !== "Casting_Call" && {
				location: location.value,
				experienceNeeded: experienceNeeded.value,
				// ...(experienceNeeded > 0 && { experienceNeeded }), //TODO >> need to be replaced
			}),
			...(jobType === "Long_Term" && {
				type: employementType.value,
			}),
			...(jobType === "Freelance" && {
				...(startDate && { startDate: startDate.getTime() }),
				...(endDate && { endDate: endDate.getTime() }),
			}),
			...(jobType === "Casting_Call" && {
				gender: gender.toUpperCase(),
				...(date && { dateTime: date.getTime() }),
				...(languages.length > 0 && {
					languages: languages.map((language) => language.value),
				}),
				...(ethnicity && {
					ethnicityId: ethnicity.value,
				}),
				...(minAge > 0 && {
					minAge,
				}),
				...(maxAge > 0 && {
					maxAge,
				}),
			}),
			...(skills.length > 0 && {
				skills: skills.map((skill) => skill.value),
			}),
		};

		if (jobType === "Casting_Call") {
			if (editMode) {
				updateCasting({ body, jobId });
			} else {
				createCasting({ object: body });
			}
		} else if (jobType === "Freelance") {
			if (editMode) {
				updateFreelance({ body, jobId });
			} else {
				createFreelance({ object: body });
			}
		} else {
			if (editMode) {
				updateJob({ body, jobId });
			} else {
				createJob({ object: body });
			}
		}

		setShouldBlockSave(false);
	};

	// !============================= LANGUAGES =============================

	const fetchLanguages = useFetchLanguages();

	const handleRemoveLanguage = (languageIndex) => {
		const updatedLanguages = [...languagesWatcher].filter(
			(_, index) => index !== languageIndex
		);
		setValue("languages", updatedLanguages);
	};

	// !============================= ETHNICITIES =============================

	const { data: ethnicities } = useEthnicities();

	// !=======================================================================

	const fetchLocation = useFetchLocation(true, PAGE_SIZE);

	// if (!companyId) {
	// 	//* 1 - redirecting if you're not a company (should not be able to edit job as a user)
	// 	//* 2 - Knowing if you're the owner of the job and allow editing >> check performed in the useEffect
	// 	return <Navigate to={jobId ? routes.job(jobId) : routes.jobs} />;
	// }

	if (error) navigate(routes.jobs, { replace: true });

	if (isLoading && fetchStatus !== "idle") return <LoadingPage />;

	return (
		<form
			className={styles.job_form}
			onSubmit={handleSubmit((data) => handleSave(data))}
			noValidate
		>
			{project && (
				<div className={styles.form_input_wrapper}>
					<label>
						Project
						<span className="required">*</span>
					</label>

					<TextInput value={project.title} disabled={true} />
				</div>
			)}
			<div className={styles.form_input_wrapper}>
				<label>
					{jobType === "Casting_Call" ? "Casting Call Role Name" : "Title"}
					<span className="required">*</span>
				</label>

				<TextInput
					{...register("title")}
					error={!!errors.title}
					placeholder={jobType === "Casting_Call" ? "Enter Role Name" : "Enter Job Title"}
				/>
				{errors?.title?.message && (
					<p className={styles.error_message}>{errors?.title?.message}</p>
				)}
			</div>

			{jobType === "Long_Term" && (
				<div className={styles.form_input_wrapper}>
					<NewMultiCheckbox
						title={"Job Type"}
						options={updatedTypes}
						type={"radio"}
						control={control}
						name="employementType"
						// perRow={"3, auto"}
					/>
				</div>
			)}

			{jobType !== "Casting_Call" && (
				<div className={styles.form_input_wrapper}>
					<NewMultiCheckbox
						title={"Working Setup"}
						options={workLocationTypes}
						type={"radio"}
						control={control}
						name="location"
						// perRow={"3, auto"}
					/>
				</div>
			)}

			{jobType !== "Casting_Call" && (
				<div className={styles.form_input_wrapper}>
					<NewMultiCheckbox
						title={"Experience Needed"}
						options={experienceLvlTypes}
						type={"radio"}
						control={control}
						name="experienceNeeded"
						// perRow={"3, auto"}
					/>
				</div>
			)}

			{/* {jobType !== "Casting_Call" && (
				<div className={styles.form_input_wrapper}>
					<label>Experience Needed</label>

					<TextInput
						{...register("experienceNeeded")}
						placeholder="Enter number of years"
						type="number"
						error={errors?.experienceNeeded}
					/>
					{errors?.experienceNeeded?.message && (
						<p className={styles.error_message}>{errors?.experienceNeeded?.message}</p>
					)}
				</div>
			)} */}

			<div className={styles.react_switch}>
				<Controller
					name="status"
					control={control}
					render={({ field }) => (
						<label>
							<Switcher {...field} />
							<span>
								{jobType === "Casting_Call" ? "Casting" : "Job"} Status:{" "}
								<b>{statusWatcher ? "Open" : "Closed"}</b>
							</span>
						</label>
					)}
				/>
			</div>

			{jobType === "Freelance" && (
				<div className={styles.inline_input}>
					<div className={styles.item}>
						<label>Start Date</label>
						<DayPicker
							className={"form-start-date"}
							control={control}
							name="startDate"
							isClearable={true}
						/>
					</div>
					<div className={styles.item}>
						<label>End Date</label>
						<DayPicker
							className={`form-end-date ${!!errors.endDate ? "error" : undefined}`}
							control={control}
							name="endDate"
							isClearable={true}
						/>
						{errors?.endDate?.message && (
							<p className={styles.error_message}>{errors?.endDate?.message}</p>
						)}
					</div>
				</div>
			)}

			{jobType === "Casting_Call" && (
				<>
					<div className={styles.form_input_wrapper}>
						<label>
							Gender
							<span className="required">*</span>
						</label>
						<div className={`${styles.inline_input} ${styles.gender_wrapper}`}>
							<Controller
								name={"gender"}
								control={control}
								render={({ field: { onChange, value } }) => (
									<>
										<div className={styles.gender_item}>
											<RadioButton
												checked={value === "female"}
												onChange={onChange}
												inputId={"female"}
												value={"female"}
											/>
											<label htmlFor={"female"}>Female</label>
										</div>

										<div className={styles.gender_item}>
											<RadioButton
												checked={value === "male"}
												onChange={onChange}
												inputId={"male"}
												value={"male"}
											/>
											<label htmlFor={"male"}>Male</label>
										</div>
									</>
								)}
							/>
						</div>
						{errors?.gender?.message && (
							<p className={styles.error_message}>{errors?.gender?.message}</p>
						)}
					</div>

					<div className={styles.form_input_wrapper}>
						<label>Age Range</label>
						<div className={`${styles.inline_input} ${styles.age_wrapper}`}>
							<div className={styles.item}>
								<TextInput
									{...register("minAge")}
									placeholder="Minimum"
									type="number"
								/>
							</div>
							<div className={styles.item}>
								<TextInput
									{...register("maxAge")}
									placeholder="Maximum"
									type="number"
								/>
							</div>
						</div>
					</div>

					<div className={styles.text_input}>
						<label>Ethnicity</label>
						<CustomSelect
							options={ethnicities?.map(defaultFormatter)}
							height="35px"
							isClearable={true}
							control={control}
							name={"ethnicity"}
							className={styles.add_margin}
						/>
					</div>

					<MultiSelectInput
						queryName={languagesKeys.search}
						queryFn={fetchLanguages}
						data={languagesWatcher}
						itemsPerPage={PAGE_SIZE}
						formatter={languageFormatter}
						label="Languages"
						onRemoveItem={handleRemoveLanguage}
						onChange={(language) =>
							setValue("languages", [...languagesWatcher, language])
						}
						unDuplicateFn={(passedData) =>
							unduplicateValue(languagesWatcher, passedData)
						}
						selected={languagesWatcher}
						customStyle={styles.margin_20}
					/>
				</>
			)}

			<Controller
				name={"description"}
				control={control}
				render={({ field }) => (
					<TextArea
						className={styles.textarea_wrapper}
						label={jobType === "Casting_Call" ? "Role Overview" : "Job Description"}
						error={errors.description}
						{...field}
						placeholder={
							jobType === "Casting_Call"
								? "Enter Role Overview"
								: "Enter Job Description"
						}
					/>
				)}
			/>

			{jobType === "Casting_Call" && (
				<div className={styles.form_input_wrapper}>
					<label>Date</label>

					<Controller
						name="date"
						control={control}
						render={({ field }) => (
							<Calendar
								className={styles.timepicker}
								{...field}
								showTime
								hourFormat="12"
								dateFormat={"dd/mm/yy"}
								showButtonBar
								placeholder={"Select..."}
							/>
						)}
					/>
				</div>
			)}

			<Controller
				name={"requirements"}
				control={control}
				render={({ field }) => (
					<TextArea
						className={styles.textarea_wrapper}
						label={`${jobType === "Casting_Call" ? "" : "Job"} Requirements`}
						error={errors.requirements}
						{...field}
						placeholder={`Enter ${
							jobType === "Casting_Call" ? "Role" : "Job"
						} Requirements`}
					/>
				)}
			/>

			<MultiSelectInput
				queryName={skillsKeys.search}
				queryFn={fetchSkills}
				data={skillsWatcher}
				itemsPerPage={PAGE_SIZE}
				formatter={defaultFormatter}
				label="Skills"
				onChange={(skill) => setValue("skills", [...skillsWatcher, skill])}
				onRemoveItem={handleRemoveSkill}
			/>

			<div className={styles.form_input_wrapper}>
				<label>Address</label>

				<TextInput {...register("address")} placeholder="Enter address" />
			</div>

			<InfiniteSearchInput
				queryName={locationKeys.cities}
				queryFn={fetchLocation}
				itemsPerPage={PAGE_SIZE}
				formatter={locationFormatter}
				label={"City & Country"}
				name="city"
				control={control}
				isClearable={true}
				customStyle={styles.margin_20}
				openMenuOnClick={true}
			/>

			{cityWatcher && (
				<MapSelect
					location={selectedLocation}
					onClick={() => setLocationModal(true)}
					onRemove={handleRemoveLocation}
					customStyle={styles.map}
				/>
			)}

			<div className={styles.form_input_wrapper}>
				<label>Compensation</label>

				<TextInput {...register("compensation")} placeholder="Amount" type="number" />
			</div>

			<div className={styles.inline_input}>
				<div className={styles.item}>
					<label>Currency</label>
					<CustomSelect
						isLoading={isLoadingCurrencies}
						options={currencies?.map((currency) => ({
							label: currency.name,
							value: currency.uuid,
						}))}
						placeholder="Select"
						height="35px"
						isClearable={true}
						onChange={(currency) => setValue("currency", currency)}
						value={currencyWatcher}
					/>
				</div>
				<div className={styles.item}>
					<label>Payment Frequency</label>
					<CustomSelect
						options={frequencyTypes}
						placeholder="Select"
						height="35px"
						isClearable={true}
						onChange={(frequency) => setValue("frequency", frequency)}
						value={frequencyWatcher}
					/>
				</div>
			</div>

			<div className={styles.button_container}>
				<GaawkButton
					type={"submit"}
					text={editMode ? "Save" : "Create"}
					isLoading={
						createJobLoading ||
						updateJobLoading ||
						createCastingLoading ||
						updateCastingLoading ||
						createFreelanceLoading ||
						updateFreelanceLoading
					}
				/>
			</div>

			<PromptModal when={isDirty && shouldBlockSave} />

			{locationModal && (
				<NewGaawkModal
					visible={locationModal}
					onHide={() => setLocationModal(false)}
					title={"Select Job Location"}
					children={
						<div className={styles.map_container}>
							{/* <MapItem
                                    onLocation={handleMapLocation}
                                    locationData={
                                        selectedLocation || mapLocation
                                    }
                                /> */}
							<MapItem
								onLocation={handleMapLocation}
								locationData={selectedLocation || mapLocation}
							/>

							{/* <LoadingSpinner visible={!mapLocation} /> */}

							{mapLocation && (
								<div className={styles.button_container}>
									<GaawkButton
										className={styles.save_btn}
										text="Select"
										onClick={handleSelectedLocation}
									/>
								</div>
							)}
						</div>
					}
				/>
			)}
		</form>
	);
};

export default JobEntry;
