import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import routes from "components/Routing/routing-keys";
import Blocked from "components/Settings/Blocked/Blocked";
import DeleteAccount from "components/Settings/DeleteAccount/DeleteAccount";
import Favorites from "components/Settings/Favorites/Favorites";
import FindFriends from "components/Settings/FindFriends/FindFriends";
import ManageAccount from "components/Settings/ManageAccount/ManageAccount";
import PasswordChange from "components/Settings/PasswordChange";
import PhoneChange from "components/Settings/PhoneChange";
import EmailChange from "components/Settings/EmailChange";
import Section from "components/Settings/SettingsComponents/Section";
import SettingsMenu from "components/Settings/SettingsComponents/SettingsMenu";
import Visibility from "components/Settings/Visibility/Visibility";
import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
import ContextHeader from "components/Utils/SubComs/ContextHeader/ContextHeader";
import GaawkLink from "components/Utils/SubComs/GaawkLink/GaawkLink";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";
import styles from "./SettingsView.module.scss";
import useCurrentUser from "hooks/useCurrentUser";
import Plans from "components/Plans/Plans";

const SettingsView = () => {
	const { pathname } = useLocation();
	const { type } = useCurrentUser();
	const isMobile = useSelector((state) => state.ui.isMobile);

	const [hideLeftSide, setHideLeftSide] = useState(
		pathname !== routes.settings
	);

	useEffect(() => {
		setHideLeftSide(pathname !== routes.settings);
	}, [pathname]);

	useEffect(() => {
		if (pathname === routes.settings)
			trackEvent(eventsDictionary.SETTINGS.LANDING);
	}, []);

	// Define restricted routes for company users
	const restrictedRoutes = [
		routes.settingsAccount,
		routes.passwordChange,
		routes.phoneChange,
		routes.emailChange,
		routes.settingsSubscription,
		routes.settingsVisibility,
	];

	// Check if current route is restricted for company users
	if (type === "COMPANY" && restrictedRoutes.includes(pathname)) {
		return <Navigate to={routes.settings} />;
	}

	let component;
	let headerTitle = "";

	switch (pathname) {
		case "/settings/account":
			headerTitle = "Manage Account";
			component = <ManageAccount />;
			break;

		case "/settings/account/change-password":
			headerTitle = "Change Password";
			component = <PasswordChange />;
			break;

		case "/settings/account/change-phone":
			headerTitle = "Change Phone";
			component = <PhoneChange />;
			break;

		case "/settings/account/change-email":
			headerTitle = "Change Email";
			component = <EmailChange />;
			break;

		case "/settings/friends":
			headerTitle = "Find Your Friends / Contacts";
			component = <FindFriends />;
			break;

		case "/settings/subscription":
			headerTitle = "Manage Subscription";
			component = <Plans />;
			break;

		case "/settings/delete":
			headerTitle = `Delete ${
				type === "COMPANY" ? "Company" : "Account"
			}`;
			component = <DeleteAccount />;
			break;

		case "/settings/visibility":
			headerTitle = "Visibility";
			component = <Visibility />;
			break;

		case "/settings/favorites":
			headerTitle = "Favorites";
			component = <Favorites />;
			break;

		case "/settings/blocked":
			headerTitle = "Block List";
			component = <Blocked />;
			break;

		case "/settings":
			headerTitle = isMobile
				? "Settings"
				: type === "USER"
				? "Manage Account"
				: "Find Your Friends / Contacts";
			component = type === "USER" ? <ManageAccount /> : <FindFriends />;
			break;

		default:
			throw new Response("", {
				status: 404,
			});
	}

	return (
		<DefaultPage
			hideLeftSide={hideLeftSide}
			hideRightSide={!hideLeftSide}
			hybridNavigation
			enableCollapsedNavBar
			headerRightContent={<ContextHeader title={headerTitle} />}
			leftSideChildren={
				<div className={styles.settings_menu_container}>
					<SettingsMenu pathname={pathname} />
					<div className={styles.settings_menu_footer}>
						<GaawkLink to={routes.faq} target={"_blank"}>
							FAQ
						</GaawkLink>
						<GaawkLink to={routes.privacy} target={"_blank"}>
							Privacy Policy
						</GaawkLink>
						<GaawkLink to={routes.help} target={"_blank"}>
							Help &amp; Support
						</GaawkLink>
					</div>
				</div>
			}
			rightSideChildren={<Section>{component}</Section>}
			rightSideBgColor={"#FFF"}
		/>
	);
};

export default SettingsView;
