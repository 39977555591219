import { useMemo } from "react";
import styles from "./TabMenu.module.scss";
import { TabView, TabPanel } from "primereact/tabview";
import { classNames } from "primereact/utils";

const TabMenu = ({
	menuItems,
	selectedTab,
	onSelectedTabChanged,
	customStyle,
	isLoading = false,
	className,
}) => {
	const menu = useMemo(
		() =>
			Object.entries(menuItems).map(([key, value]) => (
				<TabPanel
					key={key}
					header={value}
					headerClassName={classNames(customStyle, {
						[styles.selected]:
							selectedTab.toLowerCase() === key.toLowerCase(),
					})}
					disabled={isLoading}
				/>
			)),
		[menuItems, selectedTab, isLoading, customStyle]
	);

	return (
		<div className={styles.tabs_wrapper}>
			<TabView
				activeIndex={Object.keys(menuItems).findIndex(
					(key) => key.toLowerCase() === selectedTab.toLowerCase()
				)}
				onTabChange={(e) =>
					onSelectedTabChanged(Object.keys(menuItems)[e.index])
				}
				className={classNames(styles.scroll_container, className)}
				pt={{
					panelContainer: {
						style: {
							display: "none",
						},
					},
					inkbar: {
						style: {
							backgroundColor: "#000",
						},
					},
				}}
			>
				{menu}
			</TabView>
		</div>
	);
};

export default TabMenu;
