import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import servicesApi from "api/services";
import ResourceSearchView from "components/Resources/ResourceSearchView/ResourceSearchView";
import ServiceCard from "components/Resources/ServiceCard/ServiceCard";
import routes from "components/Routing/routing-keys";
import StorageCard from "components/Storage/StorageComponents/StorageCard";
import RoundButton from "components/Utils/Button/RoundButton";
import {
	getStorageWarningText,
	showStorageWarning,
} from "components/Utils/General";
import ServiceCardLoader from "components/Utils/SubComs/CustomLoader/ServiceCardLoader";
import useApi from "hooks/useApi";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import useVaultStorage from "hooks/useVaultStorage";
import { servicesKeys } from "queryKeys/services-key-factory";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setModal } from "../../../store/slices/service";
import SearchInput from "../../Utils/SubComs/Inputs/SearchInput/SearchInput";
import ServiceEntry from "./ServiceAdd/ServiceEntry";
import styles from "./ServicesTab.module.scss";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import GaawkButton from "components/Utils/Button/GaawkButton";

const PAGE_SIZE = 20;

const ServicesTab = ({ canEdit, userInfo }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { data, usedSpaceInPercentage } = useVaultStorage();

	const {
		usedServicesCount,
		currentPlan: { services: servicesAdCount } = {},
	} = data;

	const {
		uuid: userId,
		hasServices, //! this can't be used because user profile is not invalidated and getting fresh data !
		type,
		firstName,
		lastName,
		name: companyName,
	} = userInfo;
	const name = type === "USER" ? `${firstName} ${lastName}` : companyName;

	const [searchInput, setSearchInput] = useState("");

	// !-----------

	const getServicesApi = useApi(servicesApi.getServices, true, true);

	const fetchServicesList = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, userId, parentId] = queryKey;
		const response = await getServicesApi.request(
			userId,
			pageParam,
			PAGE_SIZE,
			0,
			parentId
		);
		return response.data;
	};

	const { items, viewRef, isFetchingNextPage, isFetching } =
		useInfiniteScroll({
			queryKey: servicesKeys.servicesList(userId, ""),
			queryFn: fetchServicesList,
		});

	const servicesList = useMemo(() => {
		return items?.map((service, index) => {
			const isLastItem = index === items.length - 1;

			return (
				<Fragment key={service.uuid}>
					<ServiceCard
						showOwnerThumbnail={false}
						ref={isLastItem ? viewRef : null}
						data={service}
						onClick={() =>
							navigate(routes.serviceTag(service.tag), {
								state: { from: "/profile" },
							})
						}
						className={styles.custom_card}
					/>

					{isLastItem && isFetchingNextPage && (
						<>
							<ServiceCardLoader className={styles.custom_card} />
							<ServiceCardLoader className={styles.custom_card} />
						</>
					)}
				</Fragment>
			);
		});
	}, [items, isFetchingNextPage, viewRef]);

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.SERVICES_TAB);
	}, []);

	return (
		<>
			<div className={styles.service_tab_container}>
				<div className={styles.service_title_wrapper}>
					<h3>Service Categories</h3>
					{canEdit && (
						<RoundButton
							icon={"add"}
							onClick={() => dispatch(setModal(true))}
							disabled={usedServicesCount >= servicesAdCount}
						/>
					)}
				</div>

				{canEdit && showStorageWarning(usedSpaceInPercentage, data) && (
					<div className={styles.warning_wrapper}>
						<StorageCard
							text={getStorageWarningText(
								"productsAndServices",
								usedSpaceInPercentage,
								data
							)}
						/>
					</div>
				)}

				{hasServices && (
					<SearchInput
						onChange={({ target }) => {
							const query = target.value;
							setSearchInput(query);
						}}
						value={searchInput}
						showIcons={true}
						onClearClicked={() => setSearchInput("")}
						border={false}
						placeholder={`Search resources by ${name}`}
					/>
				)}

				{!searchInput && items.length > 0 && (
					<div className={styles.services_wrapper}>
						<div className={styles.services_container}>
							{isFetching && (
								<>
									<ServiceCardLoader
										className={styles.custom_card}
									/>
									<ServiceCardLoader
										className={styles.custom_card}
									/>
								</>
							)}
							{servicesList}
						</div>
					</div>
				)}

				<NoResults
					visible={!hasServices}
					title={"Nothing Here Yet!"}
					text="There are no services here"
					button={
						canEdit && (
							<GaawkButton
								severity={"secondary"}
								text={"Add Service"}
								onClick={() => dispatch(setModal(true))}
							/>
						)
					}
				/>

				<ResourceSearchView
					searchInput={searchInput}
					requestedProfileId={userId}
					displayOptions={{ services: true, products: false }}
					servicesTitle={""}
					isProfilePage
				/>
			</div>

			<ServiceEntry />
		</>
	);
};

export default ServicesTab;
