import styles from "./NewGaawkModal.module.scss";
import { Dialog } from "primereact/dialog";
import RoundButton from "../Button/RoundButton";
import { useSelector } from "react-redux";

const NewGaawkModal = ({
	title,
	visible,
	onHide,
	children,
	draggable = false,
	showCloseButton = true,
	onBack,
	titleAlignment = "center",
	closeOnEscape = true,
	headerClassName,
	contentClassName,
	className,
	showHeader = true,
	disableMaximize = false,
}) => {
	const useLeftAlignedTitle = titleAlignment === "left" && !onBack;
	const isMobile = useSelector((state) => state.ui.isMobile);

	const headerTemplate = (
		<div
			className={`${styles.modalHeader} ${
				useLeftAlignedTitle ? styles.leftAlignedLayout : undefined
			}`}
		>
			{onBack && (
				<div className={styles.backButton} aria-label="Go back">
					<RoundButton icon="back" onClick={onBack} />
				</div>
			)}

			<div className={styles.titleContainer}>
				<h4>{title}</h4>
			</div>

			{showCloseButton && (
				<div className={styles.closeButton} aria-label="Close">
					<RoundButton icon="close" onClick={onHide} />
				</div>
			)}
		</div>
	);

	return (
		<Dialog
			showHeader={showHeader}
			header={headerTemplate}
			className={className}
			position={!disableMaximize && isMobile ? "bottom" : "center"}
			maximized={!disableMaximize && isMobile}
			onMaximize={(e) => console.log("on maximize", e)}
			pt={{
				root: {
					style: {
						width: "500px",
						fontSize: "14px",
						borderRadius: "8px",
						margin: isMobile ? "0px" : undefined,
					},
				},
				headerTitle: {
					style: {
						color: "#000",
						fontSize: "14px",
					},
				},
				closeButton: {
					style: {
						display: "none",
					},
				},
				header: {
					style: {
						borderRadius: isMobile ? "0" : "8px 8px 0 0",
					},
				},
				content: {
					style: {
						display: "flex",
						flexDirection: "column",
						color: "#000",
						borderRadius: disableMaximize
							? "8px"
							: isMobile
							? "0"
							: showHeader
							? "0 0 8px 8px"
							: "8px",
						padding: showHeader ? undefined : "2rem 1.5rem",
					},
				},
			}}
			visible={visible}
			onHide={onHide}
			draggable={draggable}
			resizable={false}
			closeOnEscape={closeOnEscape}
			contentClassName={contentClassName}
			headerClassName={headerClassName}
		>
			{children}
		</Dialog>
	);
};

export default NewGaawkModal;
