import { useState, useRef, useEffect } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { ReactComponent as ArrowIcon } from "images/arrow-icon.svg";
import styles from "./ScrollableContainer.module.scss";

const SCROLL_AMOUNT = 650;
const SCROLL_THRESHOLD = 1; // Threshold to handle floating-point precision

const ScrollableContainer = ({
	children,
	hasNextPage = true,
	scrollAmount = SCROLL_AMOUNT,
	hideScrollbars = true,
	className = "",
	isSingleLine = false,
}) => {
	const scrollContainerRef = useRef(null);
	const [scrollValue, setScrollValue] = useState(0);
	const [maxScroll, setMaxScroll] = useState(0);

	const handleScroll = (direction) => {
		if (scrollContainerRef.current) {
			const container = scrollContainerRef.current.getElement();
			const scrollAmount =
				direction === "right" ? SCROLL_AMOUNT : -SCROLL_AMOUNT;
			container.scrollBy({ left: scrollAmount, behavior: "smooth" });
		}
	};

	// Update scroll value when user scrolls
	useEffect(() => {
		const updateScrollValue = () => {
			if (scrollContainerRef.current) {
				const container = scrollContainerRef.current.getElement();
				setScrollValue(container.scrollLeft);

				// Update maxScroll value
				const maxScrollWidth =
					container.scrollWidth - container.clientWidth;
				setMaxScroll(maxScrollWidth);
			}
		};

		const container = scrollContainerRef.current?.getElement();

		if (container) {
			container.addEventListener("scroll", updateScrollValue);

			// Set initial maxScroll value
			setMaxScroll(container.scrollWidth - container.clientWidth);

			return () => {
				container.removeEventListener("scroll", updateScrollValue);
			};
		}
	}, [scrollContainerRef.current, children]);

	const showLeftArrow = scrollValue > 0;

	const isContainerScrollable =
		scrollContainerRef.current?.getElement()?.scrollWidth >
		scrollContainerRef.current?.getElement()?.clientWidth;

	const showRightArrow =
		hasNextPage ||
		(maxScroll > 0 &&
			Math.abs(maxScroll - scrollValue) > SCROLL_THRESHOLD &&
			isContainerScrollable);

	return (
		<div className={`${styles.scroll_container_wrapper} ${className}`}>
			<div
				className={`${styles.gradient_overlay} ${styles.left} ${
					!showLeftArrow ? styles.hidden : ""
				}`}
			/>
			<button
				className={`${styles.scroll_button} ${styles.left} ${
					!showLeftArrow ? styles.hidden : ""
				}`}
				onClick={() => handleScroll("left")}
			>
				<ArrowIcon />
			</button>

			<ScrollContainer
				ref={scrollContainerRef}
				className={`${styles.scroll_container} ${
					isSingleLine ? styles.single_line : undefined
				}`}
				hideScrollbars={hideScrollbars}
			>
				{children}
			</ScrollContainer>

			<button
				className={`${styles.scroll_button} ${styles.right} ${
					!showRightArrow ? styles.hidden : ""
				}`}
				onClick={() => handleScroll("right")}
			>
				<ArrowIcon />
			</button>
			<div
				className={`${styles.gradient_overlay} ${styles.right} ${
					!showRightArrow ? styles.hidden : ""
				}`}
			/>
		</div>
	);
};

export default ScrollableContainer;
