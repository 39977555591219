import {
	capitalize,
	employementTypes,
	experienceLvlTypes,
	getWorkingLocation,
} from "components/Utils/General";
import styles from "./JobInfo.module.scss";

const JobInfo = ({ jobType, jobData }) => {
	const displayInfo = () => {
		switch (true) {
			case jobType === "Long_Term" || jobType === "Freelance":
				const { experience, type, workingLocation } = jobData;

				return (
					<>
						{jobType === "Long_Term" && (
							<div className={styles.job_detail_item}>
								<p>Job Type:</p>
								<p>{employementTypes.find((item) => item.value === type).label}</p>
							</div>
						)}

						<div className={styles.job_detail_item}>
							<p>Working:</p>
							<p>{getWorkingLocation(workingLocation)}</p>
						</div>

						<div className={styles.job_detail_item}>
							<p>Experience:</p>
							<p>
								{experienceLvlTypes.find((exp) => exp.value === experience)?.label}
							</p>
						</div>
					</>
				);

			case jobType === "Casting_Call":
				const {
					ethnicity: { name: ethnicityName } = {},
					gender,
					languages,
					minAge,
					maxAge,
				} = jobData;

				return (
					<>
						<div className={styles.job_detail_item}>
							<p>Gender:</p>
							<p>{capitalize(gender)}</p>
						</div>

						{(minAge > 0 || maxAge > 0) && (
							<div className={styles.job_detail_item}>
								<p>Age:</p>
								<p>
									{minAge > 0 && <span>{minAge}</span>}
									{maxAge > 0 && <span>{maxAge}</span>}
								</p>
							</div>
						)}

						{ethnicityName && (
							<div className={styles.job_detail_item}>
								<p>Ethnicity:</p>
								<p>{ethnicityName}</p>
							</div>
						)}

						{languages.length > 0 && (
							<div className={styles.job_detail_item}>
								<p>Languages:</p>
								<p>
									{languages.map((lang) => (
										<span key={lang.code}>{lang.name}</span>
									))}
								</p>
							</div>
						)}
					</>
				);

			default:
				break;
		}
	};

	return <div className={styles.job_details}>{displayInfo()}</div>;
};

export default JobInfo;
