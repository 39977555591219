import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import GaawkButton from "components/Utils/Button/GaawkButton";
import NewGaawkModal from "components/Utils/NewGaawkModal/NewGaawkModal";
import ContactLoader from "components/Utils/SubComs/CustomLoader/ContactLoader";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import TagContainer from "components/Utils/SubComs/Tags/TagContainer";
import { ReactComponent as ServiceIcon } from "images/services.svg";
import { useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import servicesApi from "../../api/services";
import useApi from "../../hooks/useApi";
import useCompanyId from "../../hooks/useCompanyId";
import useDebounce from "../../hooks/useDebounce";
import SearchInput from "../Utils/SubComs/Inputs/SearchInput/SearchInput";
import Tag from "../Utils/SubComs/Tags/Tag";
import ProfilePic from "./ProfilePic";
import styles from "./ServicesTagModal.module.scss";

const itemsPerPage = 50;

//* IMPROVEMENT >> need to use useinfinitescroll custom hook

const ServicesTagModal = ({ show, handleClose, onConfirm, currentTags }) => {
	const queryClient = useQueryClient();

	const searchServicesApi = useApi(servicesApi.searchServices, true, true);

	const companyId = useCompanyId();

	const { uuid: profileId } = useSelector((state) => {
		if (companyId) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	const { ref: viewRef, inView } = useInView();

	const [searchInput, setSearchInput] = useState("");
	const debouncedSearch = useDebounce(searchInput);

	const handleSearchInputChange = ({ target }) => {
		const query = target.value;
		setSearchInput(query);
	};

	const fetchSearch = async ({ pageParam = 0, signal }) => {
		const response = await searchServicesApi.request(
			pageParam,
			itemsPerPage,
			debouncedSearch,
			profileId
		);
		return response.data;
	};

	const { data, hasNextPage, fetchNextPage, isLoading, isFetching } = useInfiniteQuery({
		queryKey: ["searchServicesTag", debouncedSearch], //TODO >> need to move this to query key files
		queryFn: fetchSearch,
		getNextPageParam: (lastPage, pages) => {
			const nextPage = lastPage.length === itemsPerPage ? pages.length : undefined;
			return nextPage;
		},

		enabled: show,
		staleTime: 0,
		cacheTime: 0,
	});

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, fetchNextPage, isFetching]);

	// console.log("%c THE DATA >>", "color: yellow; font-weight: bolder;", data);

	const [selectedServices, setSelectedServices] = useState([]);

	useEffect(() => {
		// if (currentTags) {
		setSelectedServices(currentTags ? currentTags : []);
		// }
	}, [currentTags]);

	const handleRemoveService = (service) => {
		setSelectedServices((prevState) => prevState.filter((item) => item.uuid !== service.uuid));
		queryClient.invalidateQueries({
			queryKey: ["searchServicesTag", debouncedSearch], //TODO >> need to move this to query key files
		});
	};

	const selectedTagsList = useMemo(
		() =>
			selectedServices.map((service) => (
				<Tag
					key={service.uuid}
					itemName={service.name}
					onRemove={() => handleRemoveService(service)}
				/>
			)),
		[selectedServices]
	);

	const formattedSearchServices = useMemo(
		() =>
			data?.pages?.map((page) =>
				page
					.filter(
						(service) =>
							!selectedServices
								.map((selectedService) => selectedService.uuid)
								.includes(service.uuid)
					)
					.map((service, i) => (
						<button
							key={i}
							ref={page.length === i + 1 ? viewRef : null}
							className={styles.service_item_wrapper}
							onClick={() => handleSelectedTag(service)}
						>
							<div className={styles.top}>
								<ProfilePic
									type={service.minifiedProfile.type}
									thumbnail={service.minifiedProfile?.profileImage?.image}
								/>
								<div className={styles.service_name}>
									<h4>+{service.name}</h4>
									<span>{service.tag}</span>
								</div>
							</div>

							{service.parents && Object.keys(service.parents).length > 0 && (
								<div className={styles.breadcrumb_wrapper}>
									<span>
										{/* <img src={servicesIcon} alt="" />{" "} */}
										<ServiceIcon width={20} fill={"#000"} />
										{`+${service.parents?.p3?.name}`}
									</span>

									{service.parents.p2 && (
										<>
											{` > `}
											<span>
												{/* <img
														src={servicesIcon}
														alt=""
													/>{" "} */}
												<ServiceIcon width={20} fill={"#000"} />
												{`+${service.parents.p2.name}`}
											</span>
										</>
									)}
								</div>
							)}
							<div className={styles.border_bottom}></div>
						</button>
					))
			),
		[data, selectedServices]
	);

	const handleSelectedTag = (service) => {
		setSelectedServices((prevState) => [...prevState, service]);
		queryClient.invalidateQueries({
			queryKey: ["searchServicesTag", debouncedSearch], //TODO >> need to move this to query key files
		});
	};

	const handleCloseModal = () => {
		handleClose();
		setSearchInput("");
		queryClient.removeQueries({
			queryKey: ["searchServicesTag", debouncedSearch], //TODO >> need to move this to query key files
		});
		setSelectedServices([]);
	};

	const handleConfirm = () => {
		onConfirm(selectedServices);
		handleCloseModal();
	};

	return (
		<NewGaawkModal
			visible={show}
			onHide={handleCloseModal}
			title={"Services Tags"}
			children={
				<>
					{isLoading ? (
						<>
							<ContactLoader />
							<ContactLoader />
							<ContactLoader />
						</>
					) : (
						<>
							<SearchInput
								onChange={handleSearchInputChange}
								value={searchInput}
								showIcons={true}
								onClearClicked={() => setSearchInput("")}
								border={false}
								placeholder={"Search services"}
							/>

							{selectedServices.length > 0 && (
								<TagContainer
									customStyle={styles.tags_container}
									scrollToBottomEnabled={true}
									items={selectedTagsList}
								/>
							)}

							<NoResults
								visible={data?.pages?.flat()?.length === 0}
								text={
									debouncedSearch
										? "No results related to your search"
										: "No results"
								}
							/>

							<div className={styles.services_items_container}>
								{formattedSearchServices}
							</div>

							<GaawkButton onClick={handleConfirm} text="Done" />
						</>
					)}
				</>
			}
		/>
	);
};

export default ServicesTagModal;
