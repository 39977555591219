import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import styles from "./ProductMediaSlider.module.scss";

// Import Swiper styles
// import "swiper/css";
// import "swiper/css/free-mode";
// import "swiper/css/navigation";
// import "swiper/css/thumbs";
import { classNames } from "primereact/utils";

const ProductMediaSlider = ({ files = [], onMainImageClick, className }) => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [thumbsSwiper, setThumbsSwiper] = useState(null);

	if (!files || files.length === 0) {
		return (
			<div className={styles.noImages}>No product images available</div>
		);
	}

	const handleThumbClick = (index) => {
		setActiveIndex(index);
	};

	const handleMainImageClick = () => {
		if (onMainImageClick) {
			onMainImageClick(activeIndex);
		}
	};

	return (
		<div className={classNames(styles.productMediaSlider, className)}>
			{/* Main image */}
			<div className={styles.mainImageContainer}>
				<img
					src={files[activeIndex]?.url}
					alt={files[activeIndex]?.alt || "Product image"}
					className={styles.mainImage}
					onClick={handleMainImageClick}
				/>
			</div>

			{/* Thumbnails slider */}
			{files.length > 1 && (
				<div className={styles.thumbnailsContainer}>
					<Swiper
						onSwiper={setThumbsSwiper}
						spaceBetween={7}
						slidesPerView={"auto"}
						freeMode={true}
						watchSlidesProgress={true}
						modules={[FreeMode, Navigation, Thumbs]}
						className={styles.thumbnailsSwiper}
					>
						{files.map((file, index) => (
							<SwiperSlide
								key={index}
								className={styles.thumbnailSlide}
							>
								<div
									className={`${styles.thumbnail} ${
										activeIndex === index
											? styles.active
											: ""
									}`}
									onClick={() => handleThumbClick(index)}
								>
									<img
										src={file.url}
										alt={
											file.alt || `Thumbnail ${index + 1}`
										}
									/>
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			)}
		</div>
	);
};

export default ProductMediaSlider;
