import { joiResolver } from "@hookform/resolvers/joi";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styles from "../ServiceAdd.module.css";
import { useTag } from "../hooks/useTag";
import { secondStep } from "../schema";
import {
	defaultFormatter,
	locationFormatter,
} from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import {
	setLocationModal,
	setStep,
	setSuggModal,
	updateStep2,
	setModal,
	setService,
	clear,
} from "store/slices/service";
import ServiceSuggestionModal from "../../ServiceSuggestionModal/ServiceSuggestionModal";
// import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
// import InfiniteSearchInput from "components/Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import CustomSelect from "components/Utils/SubComs/CustomSelect/CustomSelect";
import MultiSelectInput from "components/Utils/SubComs/Inputs/MultiSelectInput/MultiSelectInput";
import useFetchLocation from "hooks/useFetchLocation";
import { useGetUserLocationsList } from "hooks/useGetUserLocationList";
import infoIcon from "images/icon-info-gray.png";
import { classNames } from "primereact/utils";
import UserLocationsModal from "../UserLocationsModal";
import { locationKeys } from "queryKeys/location-key-factory";
import { maxPostLength } from "components/Utils/General";
import TextArea from "components/Utils/SubComs/Inputs/TextArea/TextArea";
import GaawkButton from "components/Utils/Button/GaawkButton";
import { Checkbox } from "primereact/checkbox";
import { useQueryClient } from "@tanstack/react-query";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import useMutate from "hooks/useMutate";
import servicesApi from "api/services";
import { servicesKeys } from "queryKeys/services-key-factory";
import { vaultKeys } from "queryKeys/vault-key-factory";
import { profileKeys } from "queryKeys/profile-key-factory";
import useUrlPreview from "hooks/useUrlPreview";
import FileInput from "components/Utils/SubComs/Inputs/FileInput/FileInput";
import { gaawkServicesKeys } from "queryKeys/gaawk-service-key-factory";
import gaawkServiceApi from "api/gaawk-service";
import useApi from "hooks/useApi";
import useDebounce from "hooks/useDebounce";
import useInfiniteScroll from "hooks/useInfiniteScroll";

const itemsPerPage = 20;
const sizeLimit = 500;

const Stage2 = () => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	// const { modalShow } = useSelector((state) => state.location);

	const { step1, step2, service, suggModal, locationModal } = useSelector(
		(state) => state.service
	);
	console.log("🚀 ~ step1 >>", step1);
	console.log("🚀 ~ step2 >>", step2);

	//*  when editing, will revert back to existing locations if unchecking box

	const originalLocationsRef = useRef([]);

	useEffect(() => {
		if (service) {
			setValue("locationIds", originalLocationsRef?.current);
		}
	}, [service]);

	// ! ---- create update service ------

	const {
		action: { mutate: createUpdateService, isLoading },
	} = useMutate(
		service ? servicesApi.updateService : servicesApi.addService,
		(response) => {
			const { level, parents, tag } = response.data;

			if (service) {
				// queryClient.invalidateQueries(servicesKeys.detail(serviceId));
				queryClient.invalidateQueries(servicesKeys.detailByTag(tag));
				trackEvent(eventsDictionary.SERVICE.EDIT_SERVICE_DONE);
			} else {
				trackEvent(eventsDictionary.SERVICE.ADD_SERVICE_DONE);
			}

			queryClient.invalidateQueries(servicesKeys.lists()); //TRYING THIS INSTEAD OF INVALIDATING PER LEVEL

			if (level === 1) {
				// queryClient.invalidateQueries(
				// 	servicesKeys.serviceLvl(1, userId, "", false)
				// );
				queryClient.invalidateQueries(servicesKeys.searchList());
			}
			// else if (level === 2) {
			// 	queryClient.invalidateQueries(
			// 		servicesKeys.serviceLvl(2, userId, parents.p3.uuid, false)
			// 	);
			// } else if (level === 3) {
			// 	queryClient.invalidateQueries(
			// 		servicesKeys.serviceLvl(3, userId, parents.p2.uuid, false)
			// 	);
			// }
			dispatch(clear());
			dispatch(setModal(false));
			dispatch(setStep(1));
			dispatch(setService(undefined));

			queryClient.invalidateQueries(vaultKeys.storage());
			queryClient.invalidateQueries(profileKeys.details()); //  invalidate user profile to get the correct services/products count
		},
		undefined,
		{
			meta: {
				successMessage: `${
					service ? "Edit saved" : "Service has been created"
				}`,
			},
		}
	);

	//* commented below since removed user locations list for now (to be replaced with above useEffect once reimplemented)
	// const [myLocationCheckbox, setMyLocationCheckbox] = useState(false);

	// const { profileLocationLists } =
	// 	useGetUserLocationsList(myLocationCheckbox);

	// useEffect(() => {
	// 	if (myLocationCheckbox) {
	// 		if (profileLocationLists && profileLocationLists?.length > 0) {
	// 			setValue(
	// 				"locationIds",
	// 				profileLocationLists?.map((item) => locationFormatter(item))
	// 			);
	// 		} else {
	// 			setValue("locationIds", []);
	// 		}
	// 	} else if (!myLocationCheckbox && service) {
	// 		setValue("locationIds", originalLocationsRef?.current);
	// 	}
	// }, [profileLocationLists, myLocationCheckbox, service]);

	// *============================================

	// const {
	// 	title: name,
	// 	tag,
	// 	description,
	// 	gaawkLvl1,
	// 	gaawkLvl2,
	// 	gaawkLvl3,
	// 	tagAlbumMedia = true,
	// 	tagProducts = true,
	// 	locationIds,
	// 	worldWide,
	// } = step2 || {};

	// console.log("🚀 ~ service >>", service);
	// console.log("🚀 ~ step2 >>", step2);

	// const createDefaultValues = () => ({
	// 	name: step2 ? name : "",
	// 	tag: step2 ? tag : "",
	// 	description: step2 ? description : "",
	// 	gaawkLvl1: step2 ? gaawkLvl1 : null,
	// 	gaawkLvl2: step2 ? gaawkLvl2 : null,
	// 	gaawkLvl3: step2 ? gaawkLvl3 : null,
	// 	tagAlbumMedia: step2 ? tagAlbumMedia : true,
	// 	tagProducts: step2 ? tagProducts : true,
	// 	worldWide: step2 ? worldWide : false,
	// 	locationIds: step2 ? locationIds : [],
	// });

	const {
		register,
		formState: { errors },
		handleSubmit,
		watch,
		setValue,
		control,
		reset,
		clearErrors,
	} = useForm({
		resolver: joiResolver(secondStep),
		defaultValues: {
			name: "",
			tag: "",
			description: "",
			gaawkLvl1: null,
			// gaawkLvl2: null,
			// gaawkLvl3: null,
			tagAlbumMedia: true,
			tagProducts: true,
			worldWide: false,
			locationIds: [],
			image: [],
		},
	});

	// console.log(
	// 	"%c 🚫 ERRORS 🚫 >>",
	// 	"color: red; font-weight: bolder;",
	// 	errors
	// );

	const nameWatcher = watch("name");
	const locationsWatcher = watch("locationIds");
	const worldWideWatcher = watch("worldWide");
	// const gaawkLvl1Watcher = watch("gaawkLvl1");
	// const gaawkLvl2Watcher = watch("gaawkLvl2");
	const imageWatcher = watch("image");

	// console.log("tagAlbumMedia", watch("tagAlbumMedia"));
	// console.log("tagProducts", watch("tagProducts"));
	// console.log("🚀 ~ locationsWatcher >>", locationsWatcher);
	// console.log("====");
	// console.log("🚀 ~ worldWideWatcher >>", worldWideWatcher);

	useTag(nameWatcher, setValue, step2, service);

	useEffect(() => {
		if (step2) {
			const {
				title: name,
				locationIds,
				...rest
				// tag,
				// description,
				// gaawkLvl1,
				// gaawkLvl2,
				// gaawkLvl3,
				// tagAlbumMedia = true,
				// tagProducts = true,
				// worldWide,
			} = step2 || {};

			reset({
				name,
				locationIds,
				...rest,
				// tag,
				// description,
				// gaawkLvl1,
				// gaawkLvl2,
				// gaawkLvl3,
				// tagAlbumMedia,
				// tagProducts,
				// worldWide,
				// locationIds,
			});

			originalLocationsRef.current = locationIds;
		} else if (service) {
			const {
				name,
				tag,
				description,
				gaawkService,
				tagMedia: tagAlbumMedia,
				tagProducts,
				locations,
				worldWide,
				image,
			} = service || {};

			originalLocationsRef.current =
				locations?.map((item) => locationFormatter(item)) || [];

			reset({
				name,
				tag,
				description,
				locationIds: locations?.map((item) => locationFormatter(item)),
				gaawkLvl1: {
					label: gaawkService.serviceL1.name,
					value: gaawkService.serviceL1.uuid,
				},
				// ...(gaawkService.serviceL2 && {
				// 	gaawkLvl2: {
				// 		label: gaawkService.serviceL2.name,
				// 		value: gaawkService.serviceL2.uuid,
				// 	},
				// }),
				// ...(gaawkService.serviceL3 && {
				// 	gaawkLvl3: {
				// 		label: gaawkService.serviceL3.name,
				// 		value: gaawkService.serviceL3.uuid,
				// 	},
				// }),
				tagAlbumMedia,
				tagProducts,
				worldWide,
				image: image ? [image?.file] : [],
			});
		}
	}, [service, step2]);

	const handleSave = (data) => {
		// const { name: title, ...rest } = data;

		// dispatch(
		// 	updateStep2({
		// 		title,
		// 		...rest,
		// 	})
		// );
		// dispatch(setStep(3));

		const { level, parentId } = step1 || {};
		const {
			name,
			description,
			tag,
			gaawkLvl1,
			// gaawkLvl2,
			// gaawkLvl3,
			tagAlbumMedia,
			tagProducts,
			locationIds,
			worldWide,
			image,
		} = data;

		// const { headerStyle, image, imageSize, sameImageMobile, mobileImage } =
		// 	data;

		const formData = new FormData();

		// //* STEP1
		if (service) {
			formData.append("level", service.level);
			if (service.level === 3)
				formData.append("parentId", service.parents.p2.uuid);
			if (service.level === 2)
				formData.append("parentId", service.parents.p3.uuid);
		} else {
			formData.append("level", level);
			formData.append("parentId", parentId);
		}

		// //* STEP2
		formData.append("title", name);
		formData.append("tag", tag);
		formData.append("description", description);
		formData.append("worldWide", worldWide);
		formData.append(
			"locationIds",
			locationIds.map((location) => location.value)
		);
		// if (gaawkLvl3) {
		// 	formData.append("gaawkServiceId", gaawkLvl3.value);
		// } else if (gaawkLvl2) {
		// 	formData.append("gaawkServiceId", gaawkLvl2.value);
		// } else {
		// 	formData.append("gaawkServiceId", gaawkLvl1.value);
		// }

		if (gaawkLvl1) {
			formData.append("gaawkServiceId", gaawkLvl1.value);
		}

		formData.append("tagAlbumMedia", tagAlbumMedia);
		formData.append("tagProducts", tagProducts);
		if (image[0] instanceof Blob) {
			formData.append("serviceImage", image[0], image[0].fileName);
		}

		// //* STEP3
		// formData.append("headerStyle", headerStyle);
		// if (headerStyle !== "TEXT") {
		// 	formData.append("imageSize", imageSize);

		// 	if (image[0] instanceof Blob) {
		// 		formData.append("serviceImage", image[0], image[0].fileName);
		// 	}

		// 	formData.append("differentMobileImage", !sameImageMobile);

		// 	if (!sameImageMobile) {
		// 		if (mobileImage[0] instanceof Blob) {
		// 			formData.append(
		// 				"serviceMobileImage",
		// 				mobileImage[0],
		// 				mobileImage[0].fileName
		// 			);
		// 		}
		// 	}
		// } else {
		// 	formData.append("imageSize", "N_A");
		// }

		// //! EDIT
		if (service) {
			formData.append("serviceId", service?.uuid);
		}

		createUpdateService(formData);
	};

	//! Location handlers =========

	const fetchLocation = useFetchLocation(true, itemsPerPage);

	const handleLocation = (location) => {
		if (errors?.locationIds) clearErrors("locationIds");
		setValue("locationIds", [...locationsWatcher, location]);
	};

	const handleRemoveLocation = (locationIndex) => {
		const updatedLocations = locationsWatcher.filter(
			(_, index) => index !== locationIndex
		);

		setValue("locationIds", updatedLocations);
	};

	// !=========================

	const [query, setQuery] = useState("");
	const debouncedQuery = useDebounce(query);
	const handleKeyUp = (e) => {
		if (e.key !== "ArrowUp" && e.key !== "ArrowDown") {
			setQuery(e.target.value);
		}
	};

	const searchGaawkServiceApi = useApi(
		gaawkServiceApi.searchGaawkService,
		true,
		true
	);

	const fetchGaawkServices = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, __, searchInput] = queryKey;

		const response = await searchGaawkServiceApi.request(
			pageParam,
			itemsPerPage,
			searchInput
		);
		return response.data;
	};

	const {
		items: gaawkServices,
		hasNextPage,
		loadMore,
		isFetching,
	} = useInfiniteScroll({
		queryKey: gaawkServicesKeys.search(debouncedQuery),
		queryFn: fetchGaawkServices,
		pageSize: itemsPerPage,
	});

	const gaawkServicesList = useMemo(() => {
		return gaawkServices.map((service) => {
			const lastService =
				service.serviceL3 || service.serviceL2 || service.serviceL1;

			return {
				label: lastService.name,
				value: lastService.uuid,
			};
		});
	}, [gaawkServices]);

	// !----- revoking image preview -----

	const [tempImage, setTempImage] = useState([]);

	const { objectUrls, setObjectUrls, cleanupObjectUrls } = useUrlPreview();

	useEffect(() => {
		// Create object URLs when postMedia changes

		if (
			imageWatcher?.[0] &&
			imageWatcher[0] instanceof Blob &&
			!(imageWatcher[0] instanceof File)
		) {
			setObjectUrls([URL.createObjectURL(imageWatcher[0])]);
		}

		// // Cleanup function
		return () => {
			cleanupObjectUrls();
		};
	}, [imageWatcher]);

	return (
		<>
			{!suggModal && !locationModal ? (
				<form
					className={styles.form}
					onSubmit={handleSubmit((data) => handleSave(data))}
					noValidate
				>
					<div>
						<div className={styles.form_input_wrapper}>
							<label>
								Service Name<span className="required">*</span>
							</label>

							<div
								className={`${styles.input_wrapper} ${styles.left}`}
							>
								<span>+</span>
								<TextInput
									{...register("name")}
									error={!!errors.name}
									placeholder="Service Name"
									disabled={service}
								/>
							</div>

							{errors?.name?.message && (
								<p className={styles.error_message}>
									{errors?.name?.message}
								</p>
							)}
						</div>

						<span
							className={`${styles.info_text} ${styles.below_input}`}
						>
							{service
								? "Service name can not be changed."
								: "Choose your service name carefully. Once saved, it can not be changed."}
						</span>

						<div className={styles.form_input_wrapper}>
							<label>Service Tag</label>

							<TextInput
								{...register("tag")}
								disabled={true}
								disabledText="Auto Generated Link"
							/>
						</div>

						{!service && (
							<span
								className={`${styles.info_text} ${styles.below_input}`}
							>
								Service Tag gets generated automatically
							</span>
						)}

						<Controller
							name={"description"}
							control={control}
							render={({ field }) => (
								<TextArea
									required={true}
									className={styles.textarea_wrapper}
									label="Description"
									error={errors.description}
									{...field}
								/>
							)}
						/>

						<div className={styles.form_input_wrapper}>
							<label>
								Service Image{" "}
								<span className="required">*</span>
							</label>

							<p className={styles.info_text}>
								This image will be visible for representation of
								your service.
							</p>

							{imageWatcher?.length > 0 &&
								((imageWatcher[0] instanceof Blob &&
									!(imageWatcher[0] instanceof File)) ||
									imageWatcher?.[0].url) && (
									<div className={styles.thumb}>
										<div
											className={styles.thumb_inner}
											style={{
												aspectRatio: 1,
											}}
										>
											{/* <button
    											className={styles.close_button}
    											onClick={() =>
    												setValue("image", [])
    											}
    										>
    											<img
    												src={deleteIcon}
    												alt={"delete"}
    											/>
    										</button> */}

											<img
												src={
													imageWatcher[0] instanceof
														Blob &&
													!(
														imageWatcher[0] instanceof
														File
													)
														? objectUrls[0]
														: imageWatcher[0]?.url
												}
												alt=""
											/>
										</div>
									</div>
								)}

							<FileInput
								control={control}
								error={errors.image}
								// title={"Service Image"}
								required={true}
								name="image"
								// loadedFile={imageWatcher}
								loadedFile={tempImage}
								onCrop={(cropped) => {
									setValue("image", [cropped]);
								}}
								onChange={(value) => {
									//TODO >> this should have a temp image same as in profile edit
									setTempImage([value.target.files[0]]);
									// setValue("image", [value.target.files[0]], {
									// 	shouldDirty: true,
									// });
								}}
								showThumbails={false}
							/>
						</div>
					</div>

					<div>
						<div className={styles.radio_button_wrapper}>
							<Controller
								name={"worldWide"}
								control={control}
								render={({ field: { onChange, value } }) => (
									<>
										<Checkbox
											checked={value}
											onChange={(e) => {
												onChange(e.target.checked);
												// setMyLocationCheckbox(false); //*commented since removed user locations list for now
												clearErrors("locationIds");
											}}
											inputId={"worldWide"}
										/>
										<label htmlFor={"worldWide"}>
											<h4>Worldwide</h4>
											<p className={styles.info_text}>
												This service is offered
												worldwide
											</p>
										</label>
									</>
								)}
							/>
						</div>

						<MultiSelectInput
							queryName={locationKeys.cities}
							queryFn={fetchLocation}
							data={locationsWatcher}
							itemsPerPage={itemsPerPage}
							formatter={locationFormatter}
							label="Service Locations"
							required={!worldWideWatcher}
							limit={sizeLimit}
							onChange={handleLocation}
							onRemoveItem={handleRemoveLocation}
							infoText={`You can add up to ${sizeLimit} locations where you want to work.`}
							error={errors?.locationIds}
							wrapperClassName={styles.mb_0}
							customDisabled={worldWideWatcher}
						/>

						{/* //* below commented since removed user locations list for now */}
						{/* <div
							className={classNames(styles.radio_button_wrapper, {
								[styles.disabled]: worldWideWatcher,
							})}
						>
							<CustomCheckbox
								checked={
									myLocationCheckbox && !worldWideWatcher
								}
								onChange={(e) =>
									setMyLocationCheckbox(e.target.checked)
								}
								id="myLocationCheckbox"
								customBackgroundColor={"gaawk"}
								marginRight="0px"
								isBorder={true}
								disabled={worldWideWatcher}
							/>

							<label htmlFor="myLocationCheckbox">
								Use my location list
							</label>

							<button
								type="button"
								disabled={worldWideWatcher}
								onClick={() => dispatch(setLocationModal(true))}
							>
								Edit List
							</button>
						</div> */}
					</div>

					<div>
						<div className={styles.form_input_wrapper}>
							<label>Tag gaawk category</label>

							<div className={styles.info_text}>
								Link your service with one of our gaawk tags to
								enhance visibility and improve discovery for
								users.
							</div>

							<div onKeyUp={handleKeyUp}>
								<CustomSelect
									placeholder="Select Service"
									options={gaawkServicesList}
									height="35px"
									error={!!errors.gaawkLvl1}
									control={control}
									name="gaawkLvl1"
									// onChange={() => setValue("gaawkLvl2", null)}
									isLoading={isFetching}
									onMenuScrollToBottom={() => {
										if (hasNextPage && !isFetching)
											loadMore();
									}}
								/>
							</div>
							{errors?.gaawkLvl1?.message && (
								<p className={styles.error_message}>
									{errors?.gaawkLvl1?.message}
								</p>
							)}
						</div>

						{/* {gaawkLvl1Watcher && (
							<div className={styles.form_input_wrapper}>
								<label>Level Two Services</label>

								<div onKeyUp={handleKeyUpLvl2}>
									<CustomSelect
										placeholder="Select Service"
										options={gaawkServicesLvl2}
										height="35px"
										error={!!errors.gaawkLvl2}
										control={control}
										name="gaawkLvl2"
										isLoading={isFetchingLvl2}
										onChange={() =>
											setValue("gaawkLvl3", null)
										}
										onMenuScrollToBottom={() => {
											if (
												hasNextPageLvl2 &&
												!isFetchingLvl2
											)
												fetchNextPageLvl2();
										}}
										isClearable={true}
									/>
								</div>
								{errors?.gaawkLvl2?.message && (
									<p className={styles.error_message}>
										{errors?.gaawkLvl2?.message}
									</p>
								)}
							</div>
						)} */}

						{/* {gaawkLvl2Watcher && (
							<div className={styles.form_input_wrapper}>
								<label>Level Three Services</label>

								<div onKeyUp={handleKeyUpLvl3}>
									<CustomSelect
										placeholder="Select Service"
										options={gaawkServicesLvl3}
										height="35px"
										error={!!errors.gaawkLvl3}
										control={control}
										name="gaawkLvl3"
										isLoading={isFetchingLvl3}
										onMenuScrollToBottom={() => {
											if (
												hasNextPageLvl3 &&
												!isFetchingLvl3
											)
												fetchNextPageLvl3();
										}}
										isClearable={true}
									/>
								</div>
								{errors?.gaawkLvl3?.message && (
									<p className={styles.error_message}>
										{errors?.gaawkLvl3?.message}
									</p>
								)}
							</div>
						)} */}

						<button
							type="button"
							className={styles.suggestion_btn}
							onClick={() => {
								dispatch(setSuggModal(true));
							}}
						>
							Can’t find it?{" "}
							<span>Suggest a missing category</span>
						</button>
					</div>

					<div>
						<div className={styles.section_title_wrapper}>
							<h3 className={styles.section_title}>
								Display Options
								<span className="required">*</span>
							</h3>
							<button
								type={"button"}
								onClick={() => alert("show info modal")}
							>
								<img src={infoIcon} alt="" />
							</button>
						</div>
						<span className={styles.info_text}>
							Display tagged items with this service?
						</span>
						<div className={styles.row}>
							<div className={styles.radio_button_wrapper}>
								<Controller
									name={"tagAlbumMedia"}
									control={control}
									render={({
										field: { onChange, value },
									}) => (
										<>
											<Checkbox
												checked={value}
												onChange={onChange}
												inputId={"tagAlbumMedia"}
											/>
											<label htmlFor={"tagAlbumMedia"}>
												<h4>Media</h4>
												<p className={styles.info_text}>
													Tagged items from your
													gallery.
												</p>
											</label>
										</>
									)}
								/>
							</div>

							<div className={styles.radio_button_wrapper}>
								<Controller
									name={"tagProducts"}
									control={control}
									render={({
										field: { onChange, value },
									}) => (
										<>
											<Checkbox
												checked={value}
												onChange={onChange}
												inputId={"tagProducts"}
											/>
											<label htmlFor={"tagProducts"}>
												<h4>Products</h4>
												<p className={styles.info_text}>
													Tagged items from your
													products.
												</p>
											</label>
										</>
									)}
								/>
							</div>
						</div>
					</div>

					<div className={styles.button_container}>
						{/* <GaawkButton type={"submit"} text={"Next"} /> */}
						<GaawkButton
							type={"submit"}
							text={`${service ? "Update" : "Create"} Service`}
							// disabled={addServiceApi.loading || updateServiceApi.loading}
							isLoading={isLoading}
						/>
					</div>
				</form>
			) : locationModal ? (
				<UserLocationsModal
					onSave={() => dispatch(setLocationModal(false))}
				/>
			) : (
				<ServiceSuggestionModal />
			)}
		</>
	);
};

export default Stage2;
