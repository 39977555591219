export const gaawkServicesKeys = {
	all: ["gaawkServices"],

	// withProductsOrServices: () => [
	// 	...gaawkServicesKeys.all,
	// 	"withProductsOrServices",
	// ],
	gaawkCategories: (q, serviceLevels, withProductsOrServices = false) => [
		...gaawkServicesKeys.all,
		"gaawkCategories",
		q,
		serviceLevels,
		withProductsOrServices,
	],

	search: (query = "") => [...gaawkServicesKeys.all, "search", query],
};
