import { useState, useRef, useEffect } from "react";
import styles from "./EmailChange.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { InputOtp } from "primereact/inputotp";
import GaawkButton from "components/Utils/Button/GaawkButton";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import useMutate from "hooks/useMutate";
import profileApi from "api/profile";
import { otpUpdated, resetOtp, startTimer } from "store/slices/otp";
// import { userLoginUpdated } from "store/slices/user";
// import client from "api/client";
// import { logout } from "components/Utils/General";
import useLogout from "hooks/useLogout";

// Form validation schema for new email
const emailChangeSchema = Joi.object({
	newEmail: Joi.string()
		.email({ tlds: { allow: false } })
		.required()
		.messages({
			"string.email": "Please enter a valid email address",
			"string.empty": "Email is required",
		}),
	confirmEmail: Joi.string().valid(Joi.ref("newEmail")).required().messages({
		"any.only": "Emails do not match",
		"string.empty": "Confirmation email is required",
	}),
});

const EmailChange = () => {
	const dispatch = useDispatch();
	const { email } = useSelector((state) => state.user.userLogin); // current user email
	const { code, expireAt, sent: isSent } = useSelector((state) => state.otp);

	// For managing steps
	const [currentStep, setCurrentStep] = useState(1);
	const [newEmail, setNewEmail] = useState("");
	const [hasError, setHasError] = useState(false);
	const [otp, setOtp] = useState("");

	// Improved timer state management
	const [remainingSeconds, setRemainingSeconds] = useState(0);
	const [canResend, setCanResend] = useState(false);
	const intervalId = useRef(null);
	const hasTimer = useRef(false);

	// Form validation
	const {
		register,
		formState: { errors },
		handleSubmit,
		setError,
	} = useForm({
		resolver: joiResolver(emailChangeSchema),
		mode: "onChange",
		defaultValues: {
			newEmail: "",
			confirmEmail: "",
		},
	});

	// API call to send OTP to current email
	const {
		action: { mutate: getOtp },
	} = useMutate(profileApi.sendOtp, (response) => {
		dispatch(otpUpdated(response.data));
	});

	// API call to update email
	// const {
	// 	action: { mutate: updateEmail },
	// } = useMutate(
	// 	(email) => {
	// 		return client.put(`/profile/user/login/email?email=${email}`);
	// 	},
	// 	() => {
	// 		// After successful email update, move to step 3 to verify new email
	// 		setCurrentStep(3);
	// 		// Send OTP to the new email for verification
	// 		sendOtp(newEmail);
	// 	}
	// );

	const { handleLogout } = useLogout();

	// API call to verify email
	const {
		action: { mutate: updateLoginEmail },
	} = useMutate(profileApi.updateLoginEmail, (response) => {
		// Email change completed successfully
		// dispatch(userLoginUpdated(response.data));
		handleLogout();
		//TODO >> should track the event with amplitude as an email change
	});

	const sendOtp = (emailToUse = "") => {
		// If no email is provided, use the current user email
		// Otherwise use the provided email (for step 3)
		const emailToSend = emailToUse || email;
		getOtp({ email: emailToSend });

		// Start the timer through Redux
		dispatch(startTimer(new Date().getTime()));
		setCanResend(false);
	};

	const handleOTPChange = (value) => {
		setOtp(value);
		setHasError(false);
	};

	// Calculate and update remaining time
	useEffect(() => {
		// Clear any existing timer first
		if (intervalId.current) {
			clearInterval(intervalId.current);
			intervalId.current = null;
		}

		// Check if we have a valid expiry time
		if (expireAt) {
			// Initial calculation of remaining time
			const calculateRemainingTime = () => {
				const now = new Date().getTime();
				const remaining = Math.max(
					0,
					Math.ceil((expireAt - now) / 1000)
				);
				setRemainingSeconds(remaining);
				setCanResend(remaining <= 0);

				// Update our ref to track timer status
				hasTimer.current = remaining > 0;

				// Clear timer when it reaches zero
				if (remaining <= 0 && intervalId.current) {
					clearInterval(intervalId.current);
					intervalId.current = null;
				}
			};

			// Calculate once immediately
			calculateRemainingTime();

			// Then set up interval
			intervalId.current = setInterval(calculateRemainingTime, 1000);
		} else {
			// No expiry time set, enable resend
			setRemainingSeconds(0);
			setCanResend(true);
			hasTimer.current = false;
		}

		// Cleanup on unmount or when dependencies change
		return () => {
			if (intervalId.current) {
				clearInterval(intervalId.current);
				intervalId.current = null;
			}
		};
	}, [expireAt]);

	// Initial OTP sending when component mounts or steps change
	useEffect(() => {
		// Send OTP when first landing on step 1
		if (currentStep === 1 && !isSent && !hasTimer.current) {
			sendOtp();
		}
	}, [currentStep, isSent]);

	// Reset OTP state when changing to step 2
	useEffect(() => {
		if (currentStep === 2) {
			dispatch(resetOtp());
		}
	}, [dispatch, currentStep]);

	// Handle component unmount cleanup
	useEffect(() => {
		return () => {
			// If we're unmounting and there's no time remaining, reset the OTP state
			if (!hasTimer.current && expireAt) {
				dispatch(resetOtp());
			}
			// If there is time remaining, we keep the timer state so user can come back
		};
	}, [dispatch, expireAt]);

	// Step 1: Verify current email with OTP
	const checkOtp = () => {
		if (otp !== code) {
			setHasError(true);
		} else {
			setHasError(false);
			// Move to step 2 if OTP is correct
			if (currentStep === 1) {
				setCurrentStep(2);
				setOtp("");
			} else if (currentStep === 3) {
				// Final step - verify the new email
				updateLoginEmail(newEmail);
			}
		}
	};

	// Step 2: Submit new email
	const onSubmitNewEmail = (data) => {
		if (data.newEmail === email) {
			setError("newEmail", {
				type: "manual",
				message: "You cannot update to the same email",
			});
			return;
		}
		setNewEmail(data.newEmail);
		setCurrentStep(3);
		sendOtp(data.newEmail);
	};

	const resendOTP = () => {
		if (!canResend) return;

		if (currentStep === 1) {
			sendOtp();
		} else if (currentStep === 3) {
			sendOtp(newEmail);
		}
	};

	const renderResendButton = () => {
		const text = !canResend
			? `Send code again (wait 0:${remainingSeconds
					.toString()
					.padStart(2, "0")})`
			: "Resend code";

		return (
			<GaawkButton
				severity={"tertiary"}
				disabled={!canResend}
				onClick={resendOTP}
				text={text}
			/>
		);
	};

	return (
		<div className={styles.container}>
			{/* Step 1: Verify current email */}
			{currentStep === 1 && (
				<div className={styles.step}>
					<h3>Confirm your account</h3>
					<div className={styles.info}>
						{`We have sent an email with a code to ${email}. Please enter the 6 digit code from the email below`}
					</div>

					<div className={styles.otp_container}>
						<InputOtp
							value={otp}
							onChange={(e) => handleOTPChange(e.value)}
							length={6}
						/>
					</div>

					{hasError && (
						<p className={styles.otp_error}>
							Please check your emails for a code and try entering
							it again.
						</p>
					)}

					<div className={styles.button_container}>
						{renderResendButton()}
						<GaawkButton text="Confirm" onClick={checkOtp} />
					</div>
				</div>
			)}

			{/* Step 2: Enter new email */}
			{currentStep === 2 && (
				<div className={styles.step}>
					<h3>Enter an email to link to your account</h3>
					<p className={styles.info}>
						You'll receive an OTP to verify it. This will be your
						primary email for the next login:
					</p>

					<form
						onSubmit={handleSubmit(onSubmitNewEmail)}
						className={styles.form}
					>
						<div className={styles.input_group}>
							<label>
								New E-mail<span className="required">*</span>
							</label>
							<TextInput
								{...register("newEmail")}
								placeholder="Enter new E-mail"
								error={!!errors.newEmail}
							/>
							{errors.newEmail && (
								<p className={styles.error_text}>
									{errors.newEmail.message}
								</p>
							)}
						</div>

						<div className={styles.input_group}>
							<label>
								Re-enter E-mail
								<span className="required">*</span>
							</label>
							<TextInput
								{...register("confirmEmail")}
								placeholder="Enter new E-mail again"
								error={!!errors.confirmEmail}
							/>
							{errors.confirmEmail && (
								<p className={styles.error_text}>
									{errors.confirmEmail.message}
								</p>
							)}
						</div>

						<GaawkButton type="submit" text="Change E-Mail" />
					</form>
				</div>
			)}

			{/* Step 3: Verify new email */}
			{currentStep === 3 && (
				<div className={styles.step}>
					<h3>Confirm your new email</h3>
					<div className={styles.info}>
						{`We have sent an E-mail with a code to ${newEmail}. Please enter the 6 digit code from the E-mail below`}
					</div>

					<div className={styles.otp_container}>
						<InputOtp
							value={otp}
							onChange={(e) => handleOTPChange(e.value)}
							length={6}
						/>
					</div>

					{hasError && (
						<p className={styles.otp_error}>
							Please check your emails for a code and try entering
							it again.
						</p>
					)}

					<div className={styles.button_container}>
						{renderResendButton()}
						<GaawkButton text="Confirm" onClick={checkOtp} />
					</div>
				</div>
			)}
		</div>
	);
};

export default EmailChange;
