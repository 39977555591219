import NotificationPrompt from "components/Utils/NotificationPrompt/NotificationPrompt";
import SocketSubscription from "components/Chat/SocketSubscription";
// import { isSupported } from "firebase/messaging";
// import { useEffect, useState } from "react";
// import SocketSubscription from "./components/Chat/SocketSubscription";

const ProtectedLayout = ({ children }) => {
	// const [isBrowserSupported, setIsBrowserSupported] = useState(undefined);
	// console.log("🚀 ~ isSupported >>", isBrowserSupported);

	// useEffect(() => {
	// 	isSupported().then((resolve) => setIsBrowserSupported(resolve));
	// }, []);

	return (
		<>
			<SocketSubscription />
			{/* // "Notification" in window  >> performs a check to si if Notification are supported. currently not supported in ios Safari*/}
			{/* {"Notification" in window && <NotificationPrompt />} //* firebase commented  */}
			{/* {isBrowserSupported && <NotificationPrompt />} */}
			{children}
		</>
	);
};

export default ProtectedLayout;
