import { useQuery } from "@tanstack/react-query";
import { formatFiltersValues } from "components/Utils/General";
import { Checkbox } from "primereact/checkbox";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import useApi from "hooks/useApi";
import useGetQueryParam from "hooks/useGetQueryParam";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { filterKeys } from "queryKeys/filters-key-factory";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetCompaniesFilters, updateCompaniesFilters } from "store/slices/filters";
import searchApi from "../../../api/search";
import {
	defaultFormatter,
	specialtyFormatter,
} from "../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import ConnectionFilter from "./ConnectionFilter";
import styles from "./PeopleFilter.module.scss";
import useFilterAndSort from "./useFilterAndSort";
import GaawkButton from "components/Utils/Button/GaawkButton";
import NewMultiCheckbox from "components/Utils/NewMultiCheckBox/NewMultiCheckBox";

const FILTERS_PAGE_SIZE = 100;

const CompaniesFilter = forwardRef(({ userCoordinates }, ref) => {
	const { paramValue: inputValue } = useGetQueryParam("q");

	const dispatch = useDispatch();

	const companiesFiltersObject = useSelector((state) => state.filters.companiesFilters);

	const storedCompaniesFilters = {
		...companiesFiltersObject,
		q: inputValue,
		locationIdForSort: userCoordinates.id,
	};

	useEffect(() => {
		return () => {
			//CLEARS COMPANIES SEARCH FILTERS WHEN COMPONENT UNMOUNTS
			dispatch(resetCompaniesFilters());
		};
	}, []);

	// ! ========= FETCHING OF DEFAULT COMPANIES FILTERS ============

	const companiesFiltersApi = useApi(searchApi.companiesFilters, true, true);

	const fetchCompaniesFilters = async ({ queryKey }) => {
		const [_, __, filters] = queryKey;
		const response = await companiesFiltersApi.request(formatFiltersValues(filters));
		return response.data;
	};

	const {
		data: companiesFilters,
		// isLoading, //TODO >> loading spinner for 1st render
		isFetching,
		// isError,
	} = useQuery({
		queryKey: filterKeys.companies(storedCompaniesFilters),
		queryFn: fetchCompaniesFilters,
	});

	// ! =====================================================

	const getCompaniesDynamicFilterApi = useApi(searchApi.companiesDynamicFilters, true, true);

	const fetchDynamicFilters = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, type, filters] = queryKey;
		const response = await getCompaniesDynamicFilterApi.request(
			type,
			pageParam,
			FILTERS_PAGE_SIZE,
			formatFiltersValues(filters)
		);
		return response.data;
	};

	const {
		items: dynamicIndustries,
		hasNextPage: hasNextPageIndustries,
		refetch: refetchIndustries,
		loadMore: loadMoreIndustries,
	} = useInfiniteScroll({
		queryKey: filterKeys.dynamicCompaniesFilter("industries", storedCompaniesFilters),
		queryFn: fetchDynamicFilters,
		pageSize: FILTERS_PAGE_SIZE,
		enabled: false,
	});

	const {
		items: dynamicSpecialties,
		hasNextPage: hasNextPageSpecialties,
		refetch: refetchSpecialties,
		loadMore: loadMoreSpecialties,
	} = useInfiniteScroll({
		queryKey: filterKeys.dynamicCompaniesFilter("specialties", storedCompaniesFilters),
		queryFn: fetchDynamicFilters,
		pageSize: FILTERS_PAGE_SIZE,
		enabled: false,
	});

	const { industries: industriesList = [], specialties: specialtiesList = [] } =
		companiesFilters || {};

	const {
		industryIds = [],
		specialties = [],
		agency = false,
		inMyCircle = false,
		inTheirCircle = false,
	} = storedCompaniesFilters || {};

	const filteredIndustries = useFilterAndSort(
		industryIds,
		dynamicIndustries,
		industriesList,
		dynamicIndustries.length > 0
	);

	const filteredSpecialties = useFilterAndSort(
		specialties,
		dynamicSpecialties,
		specialtiesList,
		dynamicSpecialties.length > 0,
		"value",
		false,
		true
	);

	const industriesRef = useRef();
	const specialtiesRef = useRef();

	useImperativeHandle(ref, () => ({
		updateDynamicFilters(key, tag) {
			switch (key) {
				case "industryIds":
					industriesRef.current.removeItem(tag);
					break;
				case "specialties":
					specialtiesRef.current.removeItem(tag);
					break;
				default:
					break;
			}
		},
	}));

	if (isFetching) return <LoadingSpinner customStyle={styles.loading_spinner} />;

	return (
		<div className={styles.container}>
			{industriesList?.length > 0 && (
				<div className={styles.field_wrapper}>
					<NewMultiCheckbox
						ref={industriesRef}
						title={"Industries"}
						options={filteredIndustries.map((item) => defaultFormatter(item))}
						onSelect={(industries) => {
							dispatch(
								updateCompaniesFilters({
									industryIds: industries,
								})
							);
						}}
						selected={industryIds}
						perRow="2, 150px"
						// customStyle={styles.multicheckbox_container}
					/>

					{((dynamicIndustries?.length === 0 && industriesList.length === 4) ||
						(dynamicIndustries?.length > 0 && hasNextPageIndustries)) && (
						<SeeMore
							onClick={
								dynamicIndustries?.length === 0
									? refetchIndustries
									: loadMoreIndustries
							}
							className={styles.margin_top}
						/>
					)}
				</div>
			)}

			{specialtiesList?.length > 0 && (
				<div className={styles.field_wrapper}>
					<NewMultiCheckbox
						ref={specialtiesRef}
						title={"Specialties"}
						options={filteredSpecialties.map((item) => specialtyFormatter(item))}
						onSelect={(specialties) => {
							dispatch(
								updateCompaniesFilters({
									specialties,
								})
							);
						}}
						selected={specialties}
						perRow="2, 150px"
						// customStyle={styles.multicheckbox_container}
					/>

					{((dynamicSpecialties?.length === 0 && specialtiesList.length === 4) ||
						(dynamicSpecialties?.length > 0 && hasNextPageSpecialties)) && (
						<SeeMore
							onClick={
								dynamicSpecialties?.length === 0
									? refetchSpecialties
									: loadMoreSpecialties
							}
							className={styles.margin_top}
						/>
					)}
				</div>
			)}

			<div className={styles.agency_wrapper}>
				<h3>Talent Agencies</h3>
				<div className={styles.agency_checkbox}>
					<Checkbox
						checked={agency}
						onChange={() =>
							dispatch(
								updateCompaniesFilters({
									agency: !agency,
								})
							)
						}
						name={"agency"}
						inputId={"agency"}
					/>
					<label htmlFor={"agency"}>Show only Talent Agencies</label>
				</div>
			</div>

			<ConnectionFilter
				inMyCircle={inMyCircle}
				inTheirCircle={inTheirCircle}
				onMyWorkChange={(value) =>
					dispatch(
						updateCompaniesFilters({
							inMyCircle: value,
						})
					)
				}
				onTheirWorkChange={(value) =>
					dispatch(
						updateCompaniesFilters({
							inTheirCircle: value,
						})
					)
				}
			/>

			<div className={styles.button_container}>
				<GaawkButton
					severity={"tertiary"}
					text={"Clear Filters"}
					className={styles.btn}
					onClick={() => dispatch(resetCompaniesFilters())}
				/>
			</div>
		</div>
	);
});

export default CompaniesFilter;
