import { useMutation } from "@tanstack/react-query";
import profileApi from "api/profile";
import GroupMember from "components/Chat/GroupMember";
import { getLocationType, getVideoFrame, maxPostLength } from "components/Utils/General";
import UserListModal from "components/Utils/UserListModal/UserListModal";
import useLocationChange from "hooks/useLocationChange";
import useMutate from "hooks/useMutate";
import useUrlPreview from "hooks/useUrlPreview";
import { ReactComponent as AddIcon } from "images/add-icon.svg";
import closeIcon from "images/close-icon-black-20-x-20.svg";
import Close from "images/cross-white-14-x-14.svg";
import editIcon from "images/edit-icon-20-x-20-black.svg";
import publicIcon from "images/public_icon.svg";
import { profileKeys } from "queryKeys/profile-key-factory";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updatePostModal } from "store/slices/ui";
import postApi from "../../api/post";
import WarningModal from "../Utils/GaawkModal/WarningModal";
import CustomMentions from "../Utils/Mentions/CustomMentions";
import StaticMap from "../Utils/StaticMap/StaticMap";
import TextInput from "../Utils/SubComs/Inputs/TextInput/TextInput";
import LoadingBar from "../Utils/SubComs/LoadingBar/LoadingBar";
import VisibilitySelector from "../Utils/Visibility/VisibilitySelector";
import PostDocuments from "./Components/PostDocuments";
import styles from "./CreatePost.module.css";
import EditMediaModal from "./EditMediaModal";
import EmotionModal from "./EmotionModal";
import LocationModal from "./LocationModal";
import MediaList from "./MediaList";
import PostButtons from "./PostButtons";
import PostHeader from "./PostHeader";
import PostItem from "./PostItem";
import PostMedia from "./PostMedia";
import ProfilePic from "./ProfilePic";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";
import NewGaawkModal from "components/Utils/NewGaawkModal/NewGaawkModal";

const FILES_LIMIT = 10;
const itemsPerPage = 20;

const uploadSingleFile = async ({ media, parentPostId, board = undefined, onUploadProgress }) => {
	const formData = new FormData();
	if (board) {
		formData.append("boardId", board.uuid);
		formData.append("title", board.title);
	}
	formData.append("parentPostId", parentPostId);
	formData.append(
		"multipartFile",
		media.multipartFile,
		media.multipartFile instanceof Blob ? media.multipartFile.fileName : undefined
	);
	formData.append("caption", media.caption);
	if (media.date) formData.append("date", media.date);
	if (media.location) {
		formData.append("location.latitude", media.location.latitude);
		formData.append("location.longitude", media.location.longitude);
		formData.append("location.type", media.location.type);
		formData.append("location.title", media.location.title);
		formData.append("location.placeId", media.location.placeId);
	}
	if (media.taggedIds) {
		formData.append(
			"taggedIds",
			media.taggedIds.map((profile) => profile.uuid)
		);
	}
	if (media.services?.length > 0) {
		formData.append(
			"serviceIds",
			media.services.map((service) => service.uuid)
		);
	}

	const response = await postApi.addMedia(formData, onUploadProgress);

	if (!response.ok) {
		throw response.data;
	}

	return response.data;
};

const CreatePost = forwardRef(
	({ profileImg, name, type, onCreate, isFeed = false, board = undefined }, ref) => {
		// const addPostApi = useApi(postApi.addPost);
		// const addMediaApi = useApi(postApi.addMedia);
		// const updatePostStatusApi = useApi(postApi.updatePostStatus);

		const titleRef = useRef(null);

		const thumbnail = profileImg?.framedImageURL
			? profileImg.framedImageURL
			: profileImg?.originalImageURL
			? profileImg.originalImageURL
			: undefined;

		const [showCreatePost, setShowCreatePost] = useState(false);
		const [showSharePost, setShowSharePost] = useState(false);
		const [showEmotionModal, setShowEmotionModal] = useState(false);
		const [showLocationModal, setShowLocationModal] = useState(false);
		const [showPhotosVideos, setShowPhotosVideos] = useState(false);
		const [showWarning, setShowWarning] = useState(false);

		const [postMedia, setPostMedia] = useState([]);
		const [pdfPosts, setPdfPosts] = useState([]);

		const hasRun = useRef(false);

		useEffect(() => {
			if (showCreatePost && !hasRun.current) {
				trackEvent(eventsDictionary.POST.CREATE);

				hasRun.current = true;
			}
		}, [showCreatePost]);

		//* can be used if need to disable files buttons if more than ${FILES_LIMIT} files
		// const [filesError, setFilesError] = useState(false);

		// useEffect(() => {
		// 	if (postMedia.length + pdfPosts.length > FILES_LIMIT) {
		// 		alert("CAN'T UPLOAD MORE THAN 10 FILES");
		// 		setFilesError(true);
		// 	}
		// }, [postMedia, pdfPosts]);

		// const [counter, setCounter] = useState(0);

		// const [showProgression, setShowProgression] = useState(false);

		const [post, setPost] = useState({
			emotion: null,
			filesIncluded: false,
			location: null,
			postText: "",
			taggedProfiles: [],
			postVisibility: board ? "PUBLIC" : "WORK_CIRCLE_FRIENDS",
			boardId: board?.uuid,
			title: "",
		});

		const enablePosting = useMemo(() => {
			if (board) {
				if (post.title && post.postText) return true;
				else return false;
			} else {
				if (post.postText || pdfPosts.length > 0 || postMedia.length > 0) return true;
				else return false;
			}
		}, [board, pdfPosts, post.postText, post.title, postMedia]);

		const [sharedPost, setSharedPost] = useState("");

		const { postModal } = useSelector((state) => state.ui);

		useEffect(() => {
			setShowCreatePost(postModal);
		}, [postModal]);

		const dispatch = useDispatch();
		const didMountRef = useRef(false);

		useEffect(() => {
			if (didMountRef.current && !showCreatePost) {
				dispatch(updatePostModal(false));
			}
			didMountRef.current = true;
		}, [dispatch, showCreatePost]);

		useLocationChange(() => dispatch(updatePostModal(false)));

		useEffect(() => {
			if (showCreatePost) titleRef.current?.focus();
		}, [showCreatePost]);

		useImperativeHandle(ref, () => ({
			sharePost(post) {
				setSharedPost(post);
				setPost((prevState) => ({
					...prevState,
					sharedPostId: post.uuid,
				}));
				setShowSharePost(true);
			},
			showCreateModal() {
				setShowCreatePost(true);
			},
		}));

		useEffect(() => {
			setPost((prevState) => ({
				...prevState,
				filesIncluded: postMedia.length > 0 || pdfPosts.length > 0,
			}));
		}, [postMedia.length, pdfPosts.length]);

		const handlePostTextChange = (text) => {
			setPost((post) => ({
				...post,
				postText: text,
			}));
		};

		const onEmojiClick = (event, emojiObject) => {
			setPost((post) => ({
				...post,
				postText: post.postText + emojiObject.emoji,
			}));
		};

		/**
		 * Discard post creation....
		 */
		const handleWarningDiscard = () => {
			setShowWarning(false);
			setShowCreatePost(false);
			setShowSharePost(false);
			resetPost();
		};

		// !=== HANDLE SHARE POST =====

		const [overallProgress, setOverallProgress] = useState(0);

		const handlePostSuccess = () => {
			trackEvent(eventsDictionary.POST.CREATE_DONE);
			resetPost();
			onCreate();
		};

		const {
			action: { mutate: updatePostStatus },
		} = useMutate(postApi.updatePostStatus, handlePostSuccess);

		const uploadMutation = useMutation(uploadSingleFile);

		const {
			action: { mutate: createPost },
		} = useMutate(postApi.addPost, async (response) => {
			if (post.filesIncluded) {
				setOverallProgress(0);

				const filesToUpload = [...postMedia, ...pdfPosts];

				try {
					for (const [i, media] of filesToUpload.entries()) {
						await uploadMutation.mutateAsync({
							media,
							parentPostId: response.data.uuid,
							board: board ? { ...board, title: post.title } : undefined,
							onUploadProgress: (progress) => {
								const baseProgress = (i / filesToUpload.length) * 100;
								const currentFileProgress =
									(progress / 100) * (100 / filesToUpload.length);
								setOverallProgress(Math.round(baseProgress + currentFileProgress));
							},
						});
					}

					// after all files are sent call updatePostStatusApi
					// and then in the "onSuccess" callback of updatePostStatusApi, call resetPost() and onCreate()
					// (below call will fire even if all files are not successfully sent, need to use Promise.all in order to fire the call ONLY if all files are successfully sent)
					updatePostStatus(response.data.uuid);
				} catch (error) {
					console.error("Upload failed:", error);
				}
			} else {
				handlePostSuccess();
			}
		});

		const handleSharePost = () => {
			const { boardId, ...rest } = { ...post }; //TODO >> why creating a copy of the post ?
			// passing the post without the boardId (otherwise it's shared in the board)

			createPost({ rest });
			setShowSharePost(false);
		};

		/**
		 * Handle post button
		 */
		const handlePostButton = () => {
			const postCopy = { ...post };
			delete postCopy.emotion;
			delete postCopy.taggedProfiles;
			if (post.emotion) postCopy["emotionId"] = post.emotion.uuid;
			if (post.taggedProfiles.length > 0) {
				postCopy["taggedProfiles"] = post.taggedProfiles.map((profile) => profile.uuid);
			}

			createPost({ postCopy });
			setShowCreatePost(false);
		};

		const resetPost = () => {
			setPost({
				emotion: null,
				filesIncluded: false,
				location: null,
				postText: "",
				postVisibility: board ? "PUBLIC" : "WORK_CIRCLE_FRIENDS",
				taggedProfiles: [],
				boardId: board?.uuid,
			});

			if (postMedia.length > 0 || pdfPosts.length > 0) {
				setPostMedia([]);
				setPdfPosts([]);
			}
		};

		/**
		 * Visibility
		 */
		const handlePostVisibilityChange = (value) => () => {
			setPost((prevState) => ({
				...prevState,
				postVisibility: value,
			}));
		};

		/**
		 * Tags
		 */

		/**
		 * Emotion handlers...
		 */

		const handleShowEmotionModal = () => {
			setShowCreatePost(false);
			setShowEmotionModal(true);
		};

		const handleEmotionClicked = (emotion) => {
			setPost((prevState) => ({
				...prevState,
				emotion,
			}));
		};

		const handleRemoveEmotion = (e) => {
			e.stopPropagation();
			setPost((prevState) => ({
				...prevState,
				emotion: null,
			}));
		};

		const handleHideEmotionModal = () => {
			setShowEmotionModal(false);
			setShowCreatePost(true);
		};

		/**
		 * Location handlers...
		 */
		const handleShowLocationModal = () => {
			setShowCreatePost(false);
			setShowLocationModal(true);
		};

		const handleClearLocation = () => {
			setPost((prevState) => ({ ...prevState, location: null }));
		};

		const handleLocationClicked = (place) => {
			setPost((prevState) => ({
				...prevState,
				location: {
					latitude: place.geometry.location.lat,
					longitude: place.geometry.location.lng,
					title: place.name,
					placeId: place.place_id,
					type: getLocationType(place.types),
				},
			}));

			handleCloseLocationModal();
		};

		const handleCloseLocationModal = () => {
			setShowLocationModal(false);
			setShowCreatePost(true);
		};

		/**
		 * File uploader
		 */

		const allowFiles = (filesAmount) => {
			if (filesAmount + pdfPosts.length + postMedia.length > FILES_LIMIT) return false;
			return true;
		};

		const triggerFilesErrorLimit = () =>
			toast.error(`You can only upload up to ${FILES_LIMIT} items`);

		const handleAppendFileChange = ({ target }) => {
			const files = target.files;
			const fileData = [];

			if (files.length > 0) {
				if (allowFiles(files.length)) {
					for (let i = 0; i < files.length; i++) {
						fileData.push({
							caption: "",
							multipartFile: files[i],
							postId: "",
							type: files[i].type.includes("video")
								? "VIDEO"
								: files[i].type.includes("image")
								? "IMAGE"
								: "",
							taggedIds: [],
							newFile: true,
						});
					}
					setPostMedia((prevState) => [...prevState, ...fileData]);
				} else {
					triggerFilesErrorLimit();
				}
			}

			//TODO >> run a FN that checks the files count and allows or not set in postMedias

			// if (!showCreatePost) {
			// 	setShowCreatePost(true);
			// }
		};

		const handlePdfChange = ({ target }) => {
			const files = target.files;
			const fileData = [];

			if (files.length > 0) {
				if (allowFiles(files.length)) {
					for (let i = 0; i < files.length; i++) {
						fileData.push({
							caption: "",
							multipartFile: files[i],
							postId: "",
							type: "PDF",
							taggedIds: [],
							newFile: true,
						});
					}

					setPdfPosts((prevState) => [...prevState, ...fileData]);
				} else {
					triggerFilesErrorLimit();
				}
			}
		};

		const handleShowPhotoVideoModal = () => {
			setShowCreatePost(false);
			setShowPhotosVideos(true);
		};

		const handleHidePhotoVideoModal = () => {
			setShowPhotosVideos(false);
			setShowCreatePost(true);
		};

		const handleRemovePDFItem = (item, index) => {
			const pdfPostsCopy = [...pdfPosts];
			pdfPostsCopy.splice(index, 1);
			setPdfPosts(pdfPostsCopy);
		};

		useEffect(() => {
			// Create object URLs when postMedia changes
			// const newObjectUrls = postMedia.map((media) =>
			// 	URL.createObjectURL(media.multipartFile)
			// );
			// setObjectUrls(newObjectUrls);

			const loadItems = async () => {
				const promises = postMedia.map(async (media) => {
					if (media.multipartFile.type.startsWith("image")) {
						return URL.createObjectURL(media.multipartFile);
					} else if (media.multipartFile.type.startsWith("video")) {
						const videoFirstFrame = await getVideoFrame(media.multipartFile);
						return videoFirstFrame;
					}
					// else {
					// 	return getFileIcon(media, media.name).icon;
					// }
				});

				const resolvedUrls = await Promise.all(promises);
				setObjectUrls(resolvedUrls);
			};

			loadItems();

			// Cleanup function
			return () => {
				// newObjectUrls.forEach((url) => URL.revokeObjectURL(url));
				cleanupObjectUrls();
			};
		}, [postMedia]);

		const handleRemoveMediaItem = (index) => () => {
			setPostMedia((prevMedia) => {
				const newMedia = [...prevMedia];
				newMedia.splice(index, 1);
				return newMedia;
			});

			// setObjectUrls((prevUrls) => {
			// 	const newUrls = [...prevUrls];
			// 	URL.revokeObjectURL(newUrls[index]);
			// 	newUrls.splice(index, 1);
			// 	return newUrls;
			// });

			if (postMedia.length - 1 === 0) handleHidePhotoVideoModal();
		};

		const handleRemoveAllMedia = () => {
			setPostMedia([]);
		};

		/**
		 * mappers
		 */

		// media
		const [showEditMedia, setShowEditMedia] = useState(false);
		const [editItem, setEditItem] = useState("");
		const [editItemIndex, setEditItemIndex] = useState("");

		const handleShowEditModal = (media, index) => {
			setEditItem(media);
			setEditItemIndex(index);
			setShowPhotosVideos(false);
			setShowEditMedia(true);
		};

		const handleHideEditMedia = () => {
			setShowEditMedia(false);
			setShowPhotosVideos(true);
		};

		const handleConfirmEdit = (newItem) => {
			const postMediaCopy = [...postMedia];
			postMediaCopy[editItemIndex] = newItem;
			setPostMedia(postMediaCopy);
		};

		const { objectUrls, setObjectUrls, cleanupObjectUrls } = useUrlPreview();

		const mediaList = useMemo(
			() =>
				postMedia.map((media, index) => (
					<MediaList
						key={index}
						mediaType={media.type}
						url={objectUrls[index]}
						fileName={media.multipartFile.name}
						text={media.caption}
						onClick={handleRemoveMediaItem(index)}
						onEdit={() => handleShowEditModal(media, index)}
					/>
				)),
			[postMedia, objectUrls]
		);

		// !=========== TAGS HANDLER ==================
		const [showTagModal, setShowTagModal] = useState(false);
		const [postTags, setPostTags] = useState([]);

		const handleShowTagModal = () => {
			setShowCreatePost(false);
			setShowTagModal(true);
			setPostTags(post.taggedProfiles || []);
		};

		const handleHideTagModal = () => {
			setShowTagModal(false);
			setShowCreatePost(true);
			setPostTags([]);
		};

		const fetchTags = async ({ pageParam = 0, signal, queryKey }) => {
			const [_, __, query] = queryKey;
			const response = await profileApi.searchTags(pageParam, itemsPerPage, query);
			return response.data;
		};

		const handleConfirmTags = () => {
			setPost((prevState) => ({
				...prevState,
				taggedProfiles: postTags,
			}));
			handleHideTagModal();
		};

		const handleRemoveTag = (tag) => {
			setPostTags((prevTags) => prevTags.filter((t) => t.uuid !== tag.uuid));
		};

		const renderTag = ({ user: tag, ref }) => {
			const isSelected = postTags.some((t) => t.uuid === tag.uuid);

			return (
				<GroupMember
					ref={ref}
					key={tag.uuid}
					redirect={false}
					onClick={() => {
						if (!isSelected) {
							setPostTags((prevState) => [...prevState, tag]);
						}
					}}
					disabled={isSelected}
					data={tag}
				/>
			);
		};
		// !============================================

		// const NewPostLoading = () => {
		// 	return (
		// 		<div>
		// 			<div>{`${counter} / ${[...postMedia, ...pdfPosts].length}`}</div>
		// 			<LoadingBar progress={(100 * counter) / [...postMedia, ...pdfPosts].length} />
		// 		</div>
		// 	);
		// };

		return (
			<>
				{/* {showProgression && <NewPostLoading />} */}

				{uploadMutation.isLoading && post.filesIncluded && (
					<LoadingBar progress={overallProgress} />
				)}

				<div
					className={`${styles.padd_lr_10} ${
						!isFeed ? styles.hide_post_controls : undefined
					}`}
				>
					<div className={styles.create_post_section}>
						{/* <div className={styles.create_post_profile_img}>
							<button
								className={`${styles.profile_img} ${
									type === "COMPANY"
										? styles.company
										: undefined
								}`}
							>
								<ProfilePic
									w={"100%"}
									h={"100%"}
									type={type}
									thumbnail={thumbnail}
									noMarginRight={true}
									name={name}
									enableName={false}
								/>


							</button>
						</div> */}
						<button
							className={`${styles.profile_img} ${
								type === "COMPANY" ? styles.company : undefined
							}`}
						>
							<ProfilePic
								w={"100%"}
								h={"100%"}
								type={type}
								thumbnail={thumbnail}
								noMarginRight={true}
								name={name}
								enableName={false}
							/>
						</button>

						<div className={styles.create_post_text_reaction_section}>
							<div
								className={styles.create_post_input}
								onClick={() => setShowCreatePost(true)}
								role="button"
								tabIndex={0}
							>
								<label>What's on your mind?</label>
								<AddIcon fill="#000" width={18} height={18} />
							</div>

							{/* <PostButtons
								onChange={handleAppendFileChange}
								onEmotion={handleShowEmotionModal}
								onLocation={handleShowLocationModal}
								onTag={handleShowTagModal}
							/> */}
						</div>
					</div>
				</div>

				<NewGaawkModal
					visible={showCreatePost}
					onHide={() => setShowWarning(true)}
					title={board ? "Post to Board" : "Create a Post"}
					children={
						<>
							<div className={styles.user_wrapper}>
								<PostHeader
									name={name}
									img={board ? board.feedImage.file.customName : thumbnail}
									emotion={post.emotion}
									location={post.location}
									tags={post.taggedProfiles}
									createMode={true}
									postTime={null}
									visibility={null}
									type={type}
									postType={board ? "BOARD" : undefined}
									boardName={board ? board.name : undefined}
									icon={board ? board.owner?.profileImage?.image : undefined}
									// url={owner.url}
									onTagClicked={(e) => {
										e.stopPropagation();
										handleShowTagModal();
									}}
									onOtherTagsClicked={(e) => {
										e.stopPropagation();
										handleShowTagModal();
									}}
								/>
							</div>

							{board && (
								<div className={styles.title_input}>
									<label>
										Title
										<span className="required">*</span>
									</label>
									<TextInput
										ref={titleRef}
										onChange={({ target }) =>
											setPost((prevState) => ({
												...prevState,
												title: target.value,
											}))
										}
										value={post.title}
										placeholder="Enter title"
										cursor="text"
									/>
								</div>
							)}

							<div className={styles.create_body_wrapper}>
								<div className={styles.post_text_container}>
									<CustomMentions
										maxLength={maxPostLength}
										placeholder={"What's on your mind?"}
										value={post.postText}
										onChange={handlePostTextChange}
										onEmojiSelected={onEmojiClick}
										fixedHeight={true}
										h={177}
									/>
								</div>
								{post.location && postMedia.length <= 0 && (
									<div className={styles.post_location_map_container}>
										<StaticMap
											lat={post.location.latitude}
											lng={post.location.longitude}
											title={post.location.title}
										/>

										<button
											className={styles.location_clear_button}
											onClick={handleClearLocation}
										>
											<img src={Close} alt={"dismiss"} />
										</button>
									</div>
								)}
								{postMedia.length > 0 && (
									<div className={styles.create_post_media_container}>
										<PostMedia
											mediaList={postMedia}
											viewer={true}
											multipartViewer={true} //TODO >> to be deleted ? already have 'media.newFile'
										/>
										<div className={styles.create_post_media_controllers}>
											<button
												className={styles.edit_post_media_button}
												onClick={handleShowPhotoVideoModal}
											>
												<img src={editIcon} alt={"edit"} />
												<label>Edit</label>
											</button>

											<button
												className={styles.remove_post_media_button}
												onClick={handleRemoveAllMedia}
											>
												<img src={closeIcon} alt={"close"} />
											</button>
										</div>
									</div>
								)}
								{pdfPosts.length > 0 && (
									<PostDocuments
										items={pdfPosts}
										onDelete={handleRemovePDFItem}
										canEdit={true}
									/>
								)}
								<div className={styles.info}>
									You can only upload up to 10 items{" "}
								</div>
								<PostButtons
									onChange={handleAppendFileChange}
									onChangePdf={handlePdfChange}
									onEmotion={handleShowEmotionModal}
									onLocation={handleShowLocationModal}
									onTag={handleShowTagModal}
								/>
								<div className={styles.post_visibility_row}>
									{board ? (
										<div className={styles.board_visibility}>
											<img src={publicIcon} alt="" />
											Posting to boards is always public.
										</div>
									) : (
										<>
											<label>Post to:</label>

											<div className={styles.visibility_wrapper}>
												<VisibilitySelector
													value={post.postVisibility}
													onValueChange={handlePostVisibilityChange}
												/>
											</div>
										</>
									)}
								</div>

								<div className={styles.post_button_wrapper}>
									<GaawkButton
										onClick={handlePostButton}
										disabled={!enablePosting}
										text={"Post"}
									/>
								</div>
							</div>
						</>
					}
				/>

				<NewGaawkModal
					visible={showSharePost}
					onHide={() => setShowWarning(true)}
					title={"Share a Post"}
					children={
						<>
							<div className={styles.user_wrapper}>
								<PostHeader
									name={name}
									img={thumbnail}
									emotion={post.emotion}
									location={post.location}
									tags={post.taggedProfiles}
									createMode={true}
									postTime={null}
									visibility={null}
									type={type}
								/>
							</div>

							<div className={styles.create_body_wrapper}>
								<div className={styles.post_text_container}>
									<CustomMentions
										maxLength={maxPostLength}
										placeholder={"What's on your mind?"}
										value={post && post.postText}
										onChange={handlePostTextChange}
										onEmojiSelected={onEmojiClick}
										fixedHeight={true}
										h={177}
									/>
								</div>

								<div className={styles.post_visibility_row}>
									<label>Post to:</label>

									<div className={styles.visibility_wrapper}>
										<VisibilitySelector
											value={post.postVisibility}
											onValueChange={handlePostVisibilityChange}
										/>
									</div>
								</div>

								<PostItem
									post={sharedPost}
									inShareModal={true}
									isSharePost={true}
								/>

								<div className={styles.post_button_wrapper}>
									<GaawkButton onClick={handleSharePost} text={"Share"} />
								</div>
							</div>
						</>
					}
				/>

				<UserListModal
					show={showTagModal}
					onBack={handleHideTagModal}
					showCloseButton={false}
					title="Tag People / Companies"
					fetchUsers={fetchTags}
					renderUser={renderTag}
					hasSearch={true}
					queryKey={(query) => profileKeys.searchTags(query)}
					showSelectedContainer={true}
					selectedUsers={postTags}
					onConfirm={handleConfirmTags}
					onRemove={handleRemoveTag}
					pageSize={itemsPerPage}
				/>

				<EmotionModal
					emotionId={post.emotion && post.emotion.uuid}
					show={showEmotionModal}
					onClose={handleHideEmotionModal}
					onSelect={handleEmotionClicked}
					onRemoveEmotion={handleRemoveEmotion}
				/>

				<LocationModal
					show={showLocationModal}
					onClose={handleCloseLocationModal}
					onLocationClicked={handleLocationClicked}
					activeLocation={post.location}
					onRemove={handleClearLocation}
				/>

				<NewGaawkModal
					visible={showPhotosVideos}
					onHide={handleHidePhotoVideoModal}
					title={"Photos / Videos"}
					children={<>{mediaList}</>}
				/>

				<EditMediaModal
					show={showEditMedia}
					onClose={handleHideEditMedia}
					item={editItem}
					onConfirm={handleConfirmEdit}
					onToggle={(bool) => setShowEditMedia(bool)}
					onReset={() => setEditItem("")}
				/>

				<WarningModal
					show={showWarning}
					headerText="Are you sure you want to discard your changes?"
					warningText="This action cannot be undone."
					cancelButtonText={"Cancel"}
					onCancelButtonClicked={() => setShowWarning(false)}
					submitButtonText={"DISCARD"}
					onSubmitButtonClicked={handleWarningDiscard}
				/>
			</>
		);
	}
);

export default CreatePost;
