import { format } from "date-fns";
import { classNames } from "primereact/utils";
import { useSelector } from "react-redux";
import JobActions from "../JobActions/JobActions";
import styles from "./CompanyJobItem.module.scss";
import useCurrentUser from "hooks/useCurrentUser";

const CompanyJobItem = ({ job, onClick, isActive = false, disableActions }) => {
	const isMobile = useSelector((state) => state.ui.isMobile);

	const { name, insertionTime, newApplicants, closed, minifiedCompany } = job;

	const { uuid } = useCurrentUser();

	return (
		<>
			<div
				role={"button"}
				tabIndex={0}
				className={classNames(styles.container, {
					[styles.closed]: closed,
					[styles.active]: isActive,
				})}
				onClick={onClick}
			>
				<div className={styles.job_details}>
					<h3>{name}</h3>
					<p>
						<span>Posted on:</span> {format(new Date(insertionTime), "dd/MM/yyyy")}
					</p>
				</div>
				<div className={styles.job_controls}>
					{newApplicants > 0 && minifiedCompany.uuid === uuid && (
						<div className={styles.new_applicants}>{newApplicants}</div>
					)}

					{isMobile && !disableActions && <JobActions job={job} />}
				</div>
			</div>
		</>
	);
};

export default CompanyJobItem;
