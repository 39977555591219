import Popover from "components/Utils/Popover/Popover";
import useDirectMessage from "hooks/useDirectMessage";
import { useRef, useState } from "react";
import styles from "./ConnectionActions.module.scss";
import addFriendIcon from "images/add-friend-40-x-35.svg";
import blockBlackIcon from "images/connection-icons/block.svg";
import favoriteIcon from "images/connection-icons/favorite.svg";
import friendsIcon from "images/connection-icons/friends.svg";
import downloadIcon from "images/download-black.svg";
import followIcon from "images/follow-icon.svg";
import unfavoriteIcon from "images/icon-metro-star-full.svg";
import pendingIcon from "images/pending-friend-icon.svg";
import reportIcon from "images/report-black-icon.svg";
import workIcon from "images/work-circle-black.svg";
import connectionApi from "api/connection";
import profileApi from "api/profile";
import useMutate from "hooks/useMutate";
import { ReactComponent as FriendIcon } from "images/connection-icons/friends.svg";
import { ReactComponent as WorkIcon } from "images/connection-icons/work.svg";
import { ReactComponent as DotsIcon } from "images/dots.svg";
import ConnectionModal from "./ConnectionModal";
import { useQueryClient } from "@tanstack/react-query";
import ReportModal from "components/Utils/ReportModal/ReportModal";
import useCurrentUser from "hooks/useCurrentUser";
import { ReactComponent as PendingIcon } from "images/pending.svg";
import { profileKeys } from "queryKeys/profile-key-factory";
import RoundButton from "components/Utils/Button/RoundButton";
import GaawkButton from "components/Utils/Button/GaawkButton";
import { classNames } from "primereact/utils";

const ConnectionActions = ({ canEdit, onClickEdit, userInfo, pathname }) => {
	const {
		type,
		connection,
		uuid,
		firstName = "",
		lastName = "",
		name: companyName,
	} = userInfo || {};

	const isCompany = type === "COMPANY";

	const { type: currentUserType } = useCurrentUser();
	const isCurrentUserACompany = currentUserType === "COMPANY";

	const reportRef = useRef();

	const { handleDirectMessage } = useDirectMessage();

	const [showConnectionModal, setShowConnectionModal] = useState(false);
	const [modalAction, setModalAction] = useState("");

	const popoverRef = useRef(null);

	const queryClient = useQueryClient();

	const invalidateProfile = () => {
		queryClient.invalidateQueries(profileKeys.details());
	};

	// ! ========== modal connection handlers ===============

	const {
		action: { mutate: addWorkCircle },
	} = useMutate(connectionApi.addWorkCircle, invalidateProfile);

	const {
		action: { mutate: removeWorkCircle },
	} = useMutate(connectionApi.removeWorkCircle, invalidateProfile);

	const {
		action: { mutate: follow },
	} = useMutate(connectionApi.follow, invalidateProfile);

	const {
		action: { mutate: unfollow },
	} = useMutate(connectionApi.unfollow, invalidateProfile);

	const {
		action: { mutate: sendFriendRequest },
	} = useMutate(connectionApi.sendFriendRequest, invalidateProfile);

	const {
		action: { mutate: revokeFriendRequest },
	} = useMutate(connectionApi.revokeFriendRequest, invalidateProfile);

	const {
		action: { mutate: deleteFriend },
	} = useMutate(connectionApi.deleteFriend, invalidateProfile);

	const {
		action: { mutate: declineFriend },
	} = useMutate(connectionApi.declineFriend, invalidateProfile);

	const {
		action: { mutate: acceptFriend },
	} = useMutate(connectionApi.acceptFriend, invalidateProfile);

	const {
		action: { mutate: block },
	} = useMutate(profileApi.block, invalidateProfile);

	const {
		action: { mutate: unblock },
	} = useMutate(profileApi.unblock, invalidateProfile);

	const {
		action: { mutate: favorite },
	} = useMutate(profileApi.addFavorite, invalidateProfile);

	const {
		action: { mutate: unfavorite },
	} = useMutate(profileApi.removeFavorite, invalidateProfile);

	const handleModalAction = (action) => () => {
		popoverRef.current?.closePopover();
		setModalAction(action);
		setShowConnectionModal(true);
	};

	const handleModalConfirm = (action) => {
		switch (action) {
			case "ADD_WORK":
				addWorkCircle(uuid);
				break;
			case "REMOVE_WORK":
				removeWorkCircle(uuid);
				break;
			case "ADD_FRIEND":
				sendFriendRequest(uuid);
				break;
			case "REVOKE_FRIEND":
				revokeFriendRequest(uuid);
				break;
			case "ACCEPT_FRIEND":
				acceptFriend(uuid);
				break;
			case "DECLINE_FRIEND":
				declineFriend(uuid);
				break;
			case "REMOVE_FRIEND":
				deleteFriend(uuid);
				break;
			case "FOLLOW":
				follow(uuid);
				break;
			case "UNFOLLOW":
				unfollow(uuid);
				break;
			case "BLOCK":
				block(uuid);
				break;
			case "UNBLOCK":
				unblock(uuid);
				break;
			default:
				break;
		}
		setShowConnectionModal(false);
	};

	return (
		<>
			{canEdit ? (
				<div className={styles.container}>
					<RoundButton icon="edit" onClick={onClickEdit} />
				</div>
			) : connection?.blocking ? (
				<div className={classNames(styles.container, styles.block_btn)}>
					<GaawkButton
						className={styles.btn}
						text={"Unblock"}
						onClick={handleModalAction("UNBLOCK")}
						severity={"black"}
					/>
				</div>
			) : connection?.blocked ? (
				<div className={styles.container}>
					<div className={styles.no_access}>No Access</div>
				</div>
			) : (
				connection && (
					<div className={styles.container}>
						{!isCurrentUserACompany &&
							!isCompany &&
							connection.friendStatus === "APPROVED" && (
								<GaawkButton
									className={styles.btn}
									severity={"black-secondary"}
									onClick={handleModalAction("REMOVE_FRIEND")}
									text={
										<span className={styles.btn_label}>
											<FriendIcon className={styles.icon} /> Friends
										</span>
									}
								/>
							)}

						{!isCurrentUserACompany &&
							!isCompany &&
							connection.friendStatus === "SENT" && (
								<GaawkButton
									className={styles.btn}
									severity={"black-secondary"}
									onClick={handleModalAction("REVOKE_FRIEND")}
									text={
										<span className={styles.btn_label}>
											<PendingIcon className={styles.icon} /> Requested
										</span>
									}
								/>
							)}

						{!isCurrentUserACompany &&
							!isCompany &&
							connection.friendStatus === "PENDING" && (
								<GaawkButton
									className={styles.btn}
									severity={"black-secondary"}
									text={"Respond"}
									onClick={handleModalAction("ACCEPT_FRIEND")}
								/>
							)}

						{!isCurrentUserACompany &&
							!isCompany &&
							connection.friendStatus === "N_A" && (
								<GaawkButton
									className={styles.btn}
									severity={"black"}
									text={"Add Friend"}
									onClick={handleModalAction("ADD_FRIEND")}
								/>
							)}

						{connection.inMyWorkCircle ? (
							<GaawkButton
								className={styles.btn}
								severity={"black-secondary"}
								text={
									<span className={styles.btn_label}>
										<WorkIcon className={styles.icon} /> In Circle
									</span>
								}
								onClick={handleModalAction("REMOVE_WORK")}
							/>
						) : (
							<GaawkButton
								className={styles.btn}
								severity={"black"}
								text={"Add Work"}
								onClick={handleModalAction("ADD_WORK")}
							/>
						)}

						{pathname !== "/chat" && !canEdit && (
							<GaawkButton
								className={styles.btn}
								severity={"black-secondary"}
								text={"Message"}
								onClick={() => handleDirectMessage(userInfo)}
							/>
						)}

						<Popover
							ref={popoverRef}
							render={
								<div className="popover_container">
									{!isCurrentUserACompany &&
										!isCompany &&
										connection.friendStatus === "N_A" && (
											<button
												className={`popover_item`}
												onClick={handleModalAction("ADD_FRIEND")}
											>
												<span>
													<img src={friendsIcon} alt="add friend" />
												</span>
												<span>Add Friend</span>
											</button>
										)}

									{!isCurrentUserACompany &&
										!isCompany &&
										connection.friendStatus === "SENT" && (
											<button
												className={`popover_item`}
												onClick={handleModalAction("REVOKE_FRIEND")}
											>
												<span>
													<img src={pendingIcon} alt="revoke friend" />
												</span>
												<span>Revoke friend request</span>
											</button>
										)}

									{!isCurrentUserACompany &&
										!isCompany &&
										connection.friendStatus === "PENDING" && (
											<button
												className={`popover_item`}
												onClick={handleModalAction("ACCEPT_FRIEND")}
											>
												<span>
													<img src={pendingIcon} alt="pending friend" />
												</span>
												<span>Accept/Decline Friend</span>
											</button>
										)}

									{!isCurrentUserACompany &&
										!isCompany &&
										connection.friendStatus === "APPROVED" && (
											<button
												className={`popover_item`}
												onClick={handleModalAction("REMOVE_FRIEND")}
											>
												<span>
													<img src={addFriendIcon} alt="remove friend" />
												</span>
												<span>Remove Friend</span>
											</button>
										)}

									{connection.inMyWorkCircle ? (
										<button
											className={`popover_item`}
											onClick={handleModalAction("REMOVE_WORK")}
										>
											<span>
												<img src={workIcon} alt="remove from work circle" />
											</span>
											<span>Remove from work circle</span>
										</button>
									) : (
										<button
											className={`popover_item`}
											onClick={handleModalAction("ADD_WORK")}
										>
											<span>
												<img src={workIcon} alt="add to work circle" />
											</span>
											<span>Add to work circle</span>
										</button>
									)}

									{connection.following ? (
										<button
											className={`popover_item`}
											onClick={handleModalAction("UNFOLLOW")}
										>
											<span>
												<img src={followIcon} alt="Unfollow" />
											</span>
											<span>Unfollow</span>
										</button>
									) : (
										<button
											className={`popover_item`}
											onClick={handleModalAction("FOLLOW")}
										>
											<span>
												<img src={followIcon} alt="Follow" />
											</span>
											<span>Follow</span>
										</button>
									)}

									{connection.favorite ? (
										<button
											className={`popover_item`}
											onClick={() => {
												// unfavoriteApi.request(
												// 	uuid
												// );
												unfavorite(uuid);
												popoverRef.current.closePopover();
											}}
										>
											<span>
												<img src={unfavoriteIcon} alt="Unfavorite" />
											</span>
											<span>Unfavorite</span>
										</button>
									) : (
										<button
											className={`popover_item`}
											onClick={() => {
												// favoriteApi.request(
												// 	uuid
												// );
												favorite(uuid);
												popoverRef.current.closePopover();
											}}
										>
											<span>
												<img src={favoriteIcon} alt="Favorite" />
											</span>
											<span>Favorite</span>
										</button>
									)}

									<button
										className={`popover_item`}
										onClick={() => {
											alert("download profile as PDF");
											popoverRef.current.closePopover();
										}}
									>
										<span>
											<img src={downloadIcon} alt="Download" />
										</span>
										<span>Download Profile as PDF</span>
									</button>

									<button
										className={`popover_item`}
										onClick={handleModalAction("BLOCK")}
									>
										<span>
											<img src={blockBlackIcon} alt="Block" />
										</span>
										<span>Block</span>
									</button>

									<button
										className={`popover_item`}
										onClick={() => {
											reportRef.current.showReportModal(uuid);
											popoverRef.current.closePopover();
										}}
									>
										<span>
											<img src={reportIcon} alt="Report" />
										</span>
										<span>Report</span>
									</button>
								</div>
							}
						>
							<GaawkButton
								className={styles.more_btn}
								severity={"black-secondary"}
								text={
									<span className={styles.btn_label}>
										<DotsIcon className={styles.icon} />
									</span>
								}
							/>
						</Popover>
					</div>
				)
			)}

			<ConnectionModal
				show={showConnectionModal}
				profileImage={
					userInfo?.profileImage ? userInfo?.profileImage.originalImageURL : undefined
				}
				name={isCompany ? companyName : `${firstName} ${lastName}`}
				action={modalAction}
				onConfirm={handleModalConfirm}
				onClose={() => setShowConnectionModal(false)}
			/>

			<ReportModal ref={reportRef} category="profile" />
		</>
	);
};

export default ConnectionActions;
