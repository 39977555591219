import routes from "components/Routing/routing-keys";
import useMutate from "hooks/useMutate";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import profileApi from "../../api/profile";
import styles from "./BecomeAgency.module.css";
import CompanyItem from "./CompanyItem";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";
import { Checkbox } from "primereact/checkbox";
import NewGaawkModal from "components/Utils/NewGaawkModal/NewGaawkModal";

const BecomeAgency = ({ companyProfile }) => {
	const navigate = useNavigate();

	const { agency: isAgency, locationDTO } = companyProfile || {};

	const [isChecked, setIsChecked] = useState(false);
	const [isDeleteChecked, setIsDeleteChecked] = useState(false);
	const [confirmationModal, setConfirmationModal] = useState(false);

	const {
		action: { mutate: becomeAgency },
	} = useMutate(profileApi.becomeAgency, () => {
		trackEvent(eventsDictionary.AGENCY.BECOME_DONE);
		setConfirmationModal(true);
	});
	const {
		action: { mutate: deleteAgency },
	} = useMutate(profileApi.deleteAgency, () => {
		trackEvent(eventsDictionary.AGENCY.DELETE_DONE);
		toast.success("We're sorry to see you go");
		navigate(routes.profile());
	});

	useEffect(() => {
		if (isAgency) {
			trackEvent(eventsDictionary.AGENCY.BECOME_INITIATE);
		} else {
			trackEvent(eventsDictionary.AGENCY.DELETE_INITIATE);
		}
	}, []);

	return (
		<>
			<div className={styles.company_item}>
				<CompanyItem
					item={companyProfile}
					subdata={`${locationDTO.name}, ${locationDTO.countryDTO.name}`}
				/>
			</div>
			<div className={styles.container}>
				{isAgency ? (
					<>
						<p>
							You will no longer have a talent tab on your profile page and users will
							no longer be able to send you the request for the special profiles to be
							listed on your page.
						</p>

						<div className={styles.terms_wrapper}>
							<Checkbox
								checked={isDeleteChecked}
								onChange={() => setIsDeleteChecked((prevState) => !prevState)}
								name={"terms"}
								inputId={"terms"}
							/>

							<label htmlFor={"terms"}>
								I understand that cancelling my subscription will result in the loss
								of my data, and I will no longer have access to it.
							</label>
						</div>
						<div className={styles.button_container}>
							<GaawkButton
								severity={"danger"}
								text={"Confirm Cancellation"}
								onClick={() => deleteAgency()}
								disabled={!isDeleteChecked}
							/>
						</div>
					</>
				) : (
					<>
						<p>
							By becoming an agency you can represent and manage talents, users with
							their talent profiles can send you requests to be on your talent page.
							You can enjoy 6 months of free subscription.
						</p>

						<div className={styles.terms_wrapper}>
							<Checkbox
								checked={isChecked}
								onChange={() => setIsChecked((prevState) => !prevState)}
								name={"terms"}
								inputId={"terms"}
							/>

							<label htmlFor={"terms"}>
								I understand that my company will be represented as agency
								throughout gaawk and I will accept requests for talent profiles to
								be listed on my page.
							</label>
						</div>
						<div className={styles.button_container}>
							<GaawkButton
								text={"Confirm"}
								onClick={becomeAgency}
								disabled={!isChecked}
							/>
						</div>

						{/* {showPayment && (
							<>
								<div className={styles.payment_container}>
									ADD PAYMENT METHODS HERE
								</div>
								<div className={styles.button_container}>
									<GaawkButton
										text={"Pay"}
										onClick={handlePayment}
										disabled={becomeAgencyApi.loading}
									/>
								</div>
							</>
						)} */}
					</>
				)}
			</div>

			<NewGaawkModal
				visible={confirmationModal}
				onHide={() => {
					setConfirmationModal(false);
					navigate(routes.profile());
				}}
				title={"Become an Agency"}
				children={
					<>
						<div className={styles.congrats_message}>
							<h2>CONGRATULATIONS!</h2>
							<h4>Your company is now an agency.</h4>
						</div>
						<div className={styles.company_wrapper}>
							<CompanyItem
								item={companyProfile}
								subdata={`${companyProfile?.locationDTO?.name}, ${companyProfile?.locationDTO?.countryDTO?.name}`}
							/>
						</div>
						<div className={styles.info}>
							Congratulations! You can now represent and manage talents, people with
							their talent profiles can send you requests to be on your talent page.
						</div>
						<div className={styles.button_container}>
							<GaawkButton
								text={"Go To Your Agency"}
								onClick={() => navigate(routes.profile())}
							/>
						</div>
					</>
				}
			/>
		</>
	);
};

export default BecomeAgency;
