import styles from "./NoResults.module.css";
// import desertIcon from "images/desert.svg";
// import emptyBox from "images/empty-box.svg";
import { ReactComponent as DesertIcon } from "images/empty-states/desert.svg";

const NoResults = ({
	visible,
	text,
	title = "OOPS!",
	className,
	style,
	icon = "desert",
	// image = emptyBox,
	// imageStyle,
	button,
	noPadding = false,
	// isSearch = false,
}) => {
	let iconType;

	switch (icon) {
		case "desert":
			iconType = <DesertIcon />;
			break;
		// case "box":
		// 	iconType = <DeleteIcon />;
		// 	break;
		// case "user-blocked":
		// 	iconType = <AddIcon />;
		// 	break;
		// case "blocked":
		// 	iconType = <AddIcon />;
		// 	break;
		// case "congrats":
		// 	iconType = <AddIcon />;
		// 	break;

		default:
			break;
	}

	if (!visible) return null;
	return (
		<div
			className={`${styles.container} ${className} ${
				noPadding ? styles.no_padding : undefined
			}`}
			style={style}
		>
			{/* {image && !isSearch && (
				<img
					src={image}
					alt="Empty"
					className={`${imageStyle ? imageStyle : styles.empty_image}`}
				/>
			)} */}

			{/* {isSearch && ( */}
			{/* <div className={styles.no_results_container}> */}
			{iconType}
			<p className={styles.title}>{title}</p>
			{/* </div> */}
			{/* )} */}

			<p className={styles.text}>{text}</p>

			{button && button}
		</div>
	);
};

export default NoResults;
