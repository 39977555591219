import BackButton from "../../../components/Utils/Button/BackButton";
import DefaultPage from "../../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import NewGaawkModal from "../NewGaawkModal/NewGaawkModal";
import styles from "./Filter.module.css";
import { useNavigate } from "react-router-dom";

const Filter = ({
	title,
	leftSideChildren,
	rightSideChildren,
	isMobile,
	onCloseModal,
	hiddenMode,
	headerButton,
}) => {
	const navigate = useNavigate();

	return (
		<>
			<DefaultPage
				headerRightContent={
					<>
						<div className={styles.header}>
							<BackButton onBack={() => navigate(-1)} />
							{title}
						</div>
						{headerButton}
					</>
				}
				leftSideChildren={
					!isMobile && <div style={{ padding: "20px" }}>{leftSideChildren}</div>
				}
				rightSideChildren={rightSideChildren}
				rightSideBgColor={"#FFF"}
			/>

			<NewGaawkModal
				visible={isMobile && !hiddenMode}
				onHide={onCloseModal}
				title={title}
				children={leftSideChildren}
			/>
		</>
	);
};

export default Filter;
