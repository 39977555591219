import ProfilePic from "components/Home/ProfilePic";
import styles from "./ProfileSwitchItem.module.scss";
import { pluralize } from "components/Utils/General";
import { RadioButton } from "primereact/radiobutton";

const ProfileSwitchItem = ({ data, isActive, onClick }) => {
	const { messageCount, notificationCount } = data;

	const messageDTO = `${messageCount} ${pluralize(messageCount, "Message")}`;

	const notificationDTO = `${notificationCount} ${pluralize(notificationCount, "Notification")}`;

	let subData = "";

	switch (true) {
		case messageCount > 0 && notificationCount > 0:
			subData = `${messageDTO}, ${notificationDTO}`;
			break;

		case messageCount > 0 && notificationCount === 0:
			subData = messageDTO;
			break;

		case messageCount === 0 && notificationCount > 0:
			subData = notificationDTO;
			break;

		default:
			subData = "";
			break;
	}

	return (
		<button className={styles.profile_item} onClick={onClick}>
			<ProfilePic
				type={data.type}
				w={40}
				h={40}
				name={data.name || `${data.firstName} ${data.lastName}`}
				thumbnail={data?.profileImage?.image || data?.profileImage?.originalImageURL}
				subdata={subData}
				subdataStyle={styles.subdata}
				// fullWidth={true}
				className={styles.custom_style}
			/>

			<RadioButton readOnly checked={isActive} />
		</button>
	);
};

export default ProfileSwitchItem;
