// import { useQueryClient } from "@tanstack/react-query";
// import jobApi from "api/job";
import CompanyItem from "components/Companies/CompanyItem";
import routes from "components/Routing/routing-keys";
import { capitalize, companySizeList } from "components/Utils/General";
// import BackButton from "components/Utils/Button/BackButton";
// import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
// import LoadingPage from "components/Utils/SubComs/CustomLoader/LoadingPage";
import Tag from "components/Utils/SubComs/Tags/Tag";
import { format } from "date-fns";
import useCurrentUser from "hooks/useCurrentUser";
// import useJob from "hooks/useJob";
// import useMutate from "hooks/useMutate";
// import { jobsKeys } from "queryKeys/jobs-key-factory";
import { useMemo, useState } from "react";
import {
	useNavigate,
	// useParams
} from "react-router-dom";
import CommonConnections from "../JobsComponents/CommonConnections";
import JobDates from "../JobsComponents/JobDates/JobDates";
import JobInfo from "../JobsComponents/JobInfo/JobInfo";
import JobInfoItem from "../JobsComponents/JobInfoItem/JobInfoItem";
import JobLocation from "../JobsComponents/JobLocation";
import JobStatus from "../JobsComponents/JobStatus/JobStatus";
import styles from "./JobDetails.module.scss";
// import useApi from "hooks/useApi";
// import JobCard from "../JobsComponents/JobCard/JobCard";
import ProjectCard from "../JobsComponents/ProjectCard/ProjectCard";
// import JobActions from "../JobsComponents/JobActions/JobActions";
import { ReactComponent as TickIcon } from "images/tick.svg";
import { ReactComponent as MinusIcon } from "images/minus.svg";
import FormatSpecialText from "components/Utils/SubComs/FormatSpecialText/FormatSpecialText";
// import JobLoader from "components/Utils/SubComs/CustomLoader/JobLoader";
// import { trackEvent } from "analytics/amplitude-config";
// import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";
// import RoundButton from "components/Utils/Button/RoundButton";
// import NavBar from "components/Utils/SubComs/NavBar/NavBar";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
// import { projectKeys } from "queryKeys/projects-key-factory";
import useUpdateJobStatus from "hooks/useUpdateJobStatus";
import ApplyModal from "../ApplyModal/ApplyModal";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import { useQueryClient } from "@tanstack/react-query";
import { jobsKeys } from "queryKeys/jobs-key-factory";
// import useInfiniteScroll from "hooks/useInfiniteScroll";

// const itemsPerPage = 20;

const JobDetails = ({ job, isLoading, onProjectClick, onApply }) => {
	const navigate = useNavigate();
	const { uuid, type } = useCurrentUser();

	const [showApplyModal, setShowApplyModal] = useState(false);

	// const [invalidate, setInvalidate] = useState("");

	const {
		uuid: jobId,
		closed,
		insertionTime,
		description,
		// name,
		minifiedCompany,
		minifiedCompany: {
			uuid: companyId,
			locationDTO,
			size,
			bio,
			// industry //TODO >> not coming from backend at the moment
		} = {},
		jobType,
		requirements,
		castingCallDTO,
		freelanceJob,
		longTermJob,
		skills,
		location,
		commonConnections,
		commonConnectionsCount,
		compensation,
		currency,
		frequency,
		project,
		seenTime,
		appliedTime,
	} = job || {};

	const jobData = castingCallDTO || freelanceJob || longTermJob;

	const isJobOwner = uuid === companyId;

	// const queryClient = useQueryClient();

	// !------- toggle job status ---------

	const toggleStatus = useUpdateJobStatus(job);

	// !----------- skills list -----------

	const skillsList = useMemo(
		() => skills?.map((tag) => <Tag key={tag.uuid} itemName={tag.name} />),
		[skills]
	);

	// !------- show date condition -------
	const showDateBlock =
		(jobType === "Freelance" && freelanceJob.startDate > 0 && freelanceJob.endDate > 0) ||
		(jobType === "Casting_Call" && castingCallDTO.dateTime > 0);

	// !------- Other company's jobs -------

	// const getJobsApi = useApi(jobApi.getJobByCompanyId, true, true);

	// const fetchJobs = async ({ pageParam = 0, signal, queryKey }) => {
	// 	// eslint-disable-next-line no-unused-vars
	// 	const [_, __, companyId, jobType, projectId, closed, excludeId] = queryKey;

	// 	const response = await getJobsApi.request(
	// 		companyId,
	// 		pageParam,
	// 		itemsPerPage,
	// 		jobType,
	// 		closed,
	// 		excludeId,
	// 		projectId
	// 	);
	// 	return response.data;
	// };

	// const {
	// 	items: otherJobs,
	// 	viewRef,
	// 	isFetchingNextPage,
	// 	isLoading: isLoadingOtherJobs,
	// 	fetchStatus,
	// } = useInfiniteScroll({
	// 	queryKey: jobsKeys.jobsByCompany(companyId, "", false, jobId), //* exclude current job from other fetched jobs from the same company
	// 	queryFn: fetchJobs,
	// 	pageSize: itemsPerPage,
	// 	enabled: !!job,
	// });

	// const otherJobsList = useMemo(
	// 	() =>
	// 		otherJobs?.map((job, index) => {
	// 			const isLastItem = index === otherJobs.length - 1;
	// 			return (
	// <div key={job.uuid} ref={isLastItem ? viewRef : null}>
	// 	<JobCard
	// 		data={job}
	// 		onToggleSave={() => {
	// 			setInvalidate("other");
	// 			toggleSave({
	// 				jobId: job.uuid,
	// 				state: !job.savedJob,
	// 			});
	// 		}}
	// 	/>

	// 	{isLastItem && isFetchingNextPage && (
	// 		<div className={styles.job_loader}>
	// 			<JobLoader />
	// 		</div>
	// 	)}
	// </div>
	// 			);
	// 		}),
	// 	[otherJobs, viewRef]
	// );

	// !------- similar  jobs -------

	// const getSimilarJobsApi = useApi(jobApi.getSimilarJobs, true, true);

	// const fetchSimilarJobs = async ({ pageParam = 0, signal, queryKey }) => {
	// 	// eslint-disable-next-line no-unused-vars
	// 	const [_, __, ___, jobId] = queryKey;

	// 	const response = await getSimilarJobsApi.request(jobId, pageParam, itemsPerPage);
	// 	return response.data;
	// };

	// const {
	// 	items: similarJobs,
	// 	viewRef: viewRefSimilar,
	// 	isFetchingNextPage: isFetchingSimilarJobs,
	// 	isLoading: isLoadingSimilarJobs,
	// 	fetchStatus: fetchStatusSimilarJobs,
	// } = useInfiniteScroll({
	// 	queryKey: jobsKeys.similarJobs(jobId),
	// 	queryFn: fetchSimilarJobs,
	// 	pageSize: itemsPerPage,
	// 	enabled: !!job,
	// });

	// const similarJobsList = useMemo(
	// 	() =>
	// 		similarJobs?.map((job, index) => {
	// 			const isLastItem = index === similarJobs.length - 1;
	// 			return (
	// 				<div key={job.uuid} ref={isLastItem ? viewRefSimilar : null}>
	// 					<JobCard
	// 						data={job}
	// 						onToggleSave={() => {
	// 							setInvalidate("similar");
	// 							toggleSave({
	// 								jobId: job.uuid,
	// 								state: !job.savedJob,
	// 							});
	// 						}}
	// 					/>
	// 					{isLastItem && isFetchingSimilarJobs && (
	// 						<div className={styles.job_loader}>
	// 							<JobLoader />
	// 						</div>
	// 					)}
	// 				</div>
	// 			);
	// 		}),
	// 	[similarJobs, viewRefSimilar]
	// );

	//  !=============

	// const {
	// 	action: { mutate: toggleSave },
	// } = useMutate(jobApi.toggleSaveJob, () => {
	// 	if (invalidate === "similar") {
	// 		queryClient.invalidateQueries(jobsKeys.similarJobs(jobId));
	// 	} else {
	// 		queryClient.invalidateQueries(jobsKeys.jobsByCompany(companyId, "", false, jobId));
	// 	}
	// 	setInvalidate("");
	// });

	// return <LoadingSpinner customStyle={styles.full_height} />;

	const queryClient = useQueryClient();

	const handleApplication = () => {
		queryClient.invalidateQueries(jobsKeys.detail(jobId));
		onApply && onApply();
	};

	return isLoading ? (
		<LoadingSpinner customStyle={styles.full_height} />
	) : (
		<>
			<div className={styles.container}>
				<JobStatus
					canEdit={isJobOwner}
					active={!closed}
					onToggleStatus={() =>
						toggleStatus({
							jobId,
							status: closed,
						})
					}
				/>
				<h3 className={styles.job_title}>{job.name}</h3>
				<div className={styles.company_wrapper}>
					<CompanyItem
						item={minifiedCompany}
						nameClassName={styles.company_name}
						size={24}
						// subdata={`${locationDTO.name}, ${locationDTO.countryDTO.name}`}
					/>
					{/* {isJobOwner && (
    					<RoundButton icon="edit" onClick={() => navigate(routes.jobEdit(jobId))} />
    				)} */}
				</div>
				<JobInfo jobType={jobType} jobData={jobData} />
				<div className={styles.date_container}>
					<p className={styles.important_info}>
						Posted on <span>{format(new Date(insertionTime), "dd/MM/yyyy")}</span>
					</p>
					<hr />
				</div>
				<div className={styles.other_info_container}>
					{jobType !== "Casting_Call" && (
						<div className={styles.button_container}>
							{isJobOwner ? (
								<GaawkButton
									severity={"secondary"}
									text={"View Applicants"}
									onClick={() => navigate(routes.jobApplicants(jobId))}
									className={styles.cta_btn}
								/>
							) : appliedTime > 0 ? (
								<div className={styles.application_info}>
									<div>
										<TickIcon width={12} /> Applied on:{" "}
										{format(new Date(appliedTime), "dd/MM/yyyy")}
									</div>
									<div>
										{seenTime > 0 ? (
											<TickIcon width={12} />
										) : (
											<MinusIcon width={12} />
										)}
										Application viewed:{" "}
										{seenTime > 0
											? format(new Date(seenTime), "dd/MM/yyyy")
											: "-"}
									</div>
								</div>
							) : (
								type === "USER" && (
									<GaawkButton
										text={"Apply"}
										onClick={() => {
											setShowApplyModal(true);
											trackEvent(eventsDictionary.JOB.APPLY);
										}}
										className={styles.apply_btn}
									/>
								)
							)}
						</div>
					)}

					{project && <ProjectCard project={project} onClick={onProjectClick} />}

					{showDateBlock && (
						<JobInfoItem
							title={"Job Dates"}
							content={
								jobType === "Freelance" ? (
									<JobDates
										startDate={freelanceJob.startDate}
										endDate={freelanceJob.endDate}
									/>
								) : (
									<p className={styles.important_info}>
										On:{" "}
										<span>
											{format(
												new Date(castingCallDTO.dateTime),
												"dd/MM/yyyy"
											)}
										</span>{" "}
										- Starting:{" "}
										<span>
											{format(new Date(castingCallDTO.dateTime), "h:mm aaa")}
										</span>
									</p>
								)
							}
						/>
					)}

					{description && (
						<JobInfoItem
							title={"Job Description"}
							content={<FormatSpecialText text={description} />}
						/>
					)}

					{requirements && (
						<JobInfoItem
							title={"Requirements"}
							content={<FormatSpecialText text={requirements} />}
						/>
					)}

					{skills.length > 0 && (
						<JobInfoItem
							title={"Skills"}
							content={<div className={styles.tags_container}>{skillsList}</div>}
						/>
					)}

					<JobLocation location={location} />

					{compensation > 0 && (
						<JobInfoItem
							title={"We offer"}
							content={
								<p className={styles.important_info}>
									Salary:{" "}
									<span>
										{`${currency.key} ${compensation} ${capitalize(frequency)}`}
									</span>
								</p>
							}
						/>
					)}

					<JobInfoItem
						title={"About the company"}
						content={
							<>
								<CompanyItem
									item={minifiedCompany}
									subdata={`${locationDTO.name}, ${locationDTO.countryDTO.name}`}
								/>
								<div className={styles.company_stats}>
									{/* <p>Industry: </p> */}
									<p>
										Company Size:{" "}
										{companySizeList.find((item) => item.value === size).label}
									</p>
								</div>
								<p>{bio}</p>
							</>
						}
					/>

					{commonConnections?.length > 0 && !isJobOwner && (
						<CommonConnections
							count={commonConnectionsCount}
							data={commonConnections}
							company={minifiedCompany}
						/>
					)}

					{/* {otherJobs.length > 0 && (
    					<JobInfoItem
    						title={"Other jobs by this company"}
    						content={
    							<div className={styles.jobs_wrapper}>
    								{otherJobsList}

    								{isLoadingOtherJobs && fetchStatus !== "idle" && (
    									<div>
    										<JobLoader />
    									</div>
    								)}
    							</div>
    						}
    					/>
    				)}

    				{similarJobs.length > 0 && (
    					<JobInfoItem
    						title={"Similar Jobs"}
    						content={
    							<div className={styles.jobs_wrapper}>
    								{similarJobsList}

    								{isLoadingSimilarJobs && fetchStatusSimilarJobs !== "idle" && (
    									<div>
    										<JobLoader />
    									</div>
    								)}
    							</div>
    						}
    					/>
    				)} */}
				</div>
			</div>

			{showApplyModal && (
				<ApplyModal
					onClose={() => setShowApplyModal(false)}
					jobId={jobId}
					onApply={handleApplication}
				/>
			)}
		</>
	);

	// return (
	// 	<DefaultPage
	// 		headerRightContent={
	// 			<>
	// 				<div className={styles.header}>
	// 					<BackButton onBack={() => navigate(-1)} />
	// 					<p>{name}</p>
	// 				</div>

	// 				{!isFetching && <JobActions job={job} />}
	// 			</>
	// 		}
	// 		leftSideChildren={<NavBar />}
	// 		rightSideChildren={
	// 			isFetching ? (
	// 				<LoadingPage />
	// 			) : (
	// 				<div className={styles.container}>
	// 					<JobStatus
	// 						canEdit={isJobOwner}
	// 						active={!closed}
	// 						onToggleStatus={() =>
	// 							toggleStatus({
	// 								jobId,
	// 								status: closed,
	// 							})
	// 						}
	// 					/>

	// 					<div className={styles.company_wrapper}>
	// 						<CompanyItem
	// 							item={minifiedCompany}
	// 							subdata={`${locationDTO.name}, ${locationDTO.countryDTO.name}`}
	// 						/>
	// 						{isJobOwner && (
	// 							<RoundButton
	// 								icon="edit"
	// 								onClick={() => navigate(routes.jobEdit(jobId))}
	// 							/>
	// 						)}
	// 					</div>

	// 					<JobInfo jobType={jobType} jobData={jobData} />

	// 					<div className={styles.date_container}>
	// 						<p className={styles.important_info}>
	// 							Posted on{" "}
	// 							<span>{format(new Date(insertionTime), "dd/MM/yyyy")}</span>
	// 						</p>
	// 						<hr />
	// 					</div>

	// 					<div className={styles.other_info_container}>
	// 						{jobType !== "Casting_Call" && (
	// 							<div className={styles.button_container}>
	// 								{isJobOwner ? (
	// 									<GaawkButton
	// 										severity={"secondary"}
	// 										text={"View Applicants"}
	// 										onClick={() => navigate(routes.jobApplicants(jobId))}
	// 										className={styles.cta_btn}
	// 									/>
	// 								) : appliedTime > 0 ? (
	// 									<div className={styles.application_info}>
	// 										<div>
	// 											<TickIcon width={12} /> Applied on:{" "}
	// 											{format(new Date(appliedTime), "dd/MM/yyyy")}
	// 										</div>
	// 										<div>
	// 											{seenTime > 0 ? (
	// 												<TickIcon width={12} />
	// 											) : (
	// 												<MinusIcon width={12} />
	// 											)}
	// 											Application viewed:{" "}
	// 											{seenTime > 0
	// 												? format(new Date(seenTime), "dd/MM/yyyy")
	// 												: "-"}
	// 										</div>
	// 									</div>
	// 								) : (
	// 									type === "USER" && (
	// 										<GaawkButton
	// 											text={"Apply"}
	// 											onClick={() => navigate(routes.jobApply(jobId))}
	// 											className={styles.apply_btn}
	// 										/>
	// 									)
	// 								)}
	// 							</div>
	// 						)}

	// 						{project && <ProjectCard project={project} showActions={false} />}

	// 						{showDateBlock && (
	// 							<JobInfoItem
	// 								title={"Job Dates"}
	// 								content={
	// 									jobType === "Freelance" ? (
	// 										<JobDates
	// 											startDate={freelanceJob.startDate}
	// 											endDate={freelanceJob.endDate}
	// 										/>
	// 									) : (
	// 										<p className={styles.important_info}>
	// 											On:{" "}
	// 											<span>
	// 												{format(
	// 													new Date(castingCallDTO.dateTime),
	// 													"dd/MM/yyyy"
	// 												)}
	// 											</span>{" "}
	// 											- Starting:{" "}
	// 											<span>
	// 												{format(
	// 													new Date(castingCallDTO.dateTime),
	// 													"h:mm aaa"
	// 												)}
	// 											</span>
	// 										</p>
	// 									)
	// 								}
	// 							/>
	// 						)}

	// 						{description && (
	// 							<JobInfoItem
	// 								title={"Job Description"}
	// 								content={<FormatSpecialText text={description} />}
	// 							/>
	// 						)}

	// 						{requirements && (
	// 							<JobInfoItem
	// 								title={"Requirements"}
	// 								content={<FormatSpecialText text={requirements} />}
	// 							/>
	// 						)}

	// 						{skills.length > 0 && (
	// 							<JobInfoItem
	// 								title={"Skills"}
	// 								content={
	// 									<div className={styles.tags_container}>{skillsList}</div>
	// 								}
	// 							/>
	// 						)}

	// 						<JobLocation location={location} />

	// 						{compensation > 0 && (
	// 							<JobInfoItem
	// 								title={"We offer"}
	// 								content={
	// 									<p className={styles.important_info}>
	// 										Salary:{" "}
	// 										<span>
	// 											{`${currency.key} ${compensation} ${capitalize(
	// 												frequency
	// 											)}`}
	// 										</span>
	// 									</p>
	// 								}
	// 							/>
	// 						)}

	// 						<JobInfoItem
	// 							title={"About the company"}
	// 							content={
	// 								<>
	// 									<CompanyItem
	// 										item={minifiedCompany}
	// 										subdata={`${locationDTO.name}, ${locationDTO.countryDTO.name}`}
	// 									/>
	// 									<div className={styles.company_stats}>
	// 										{/* <p>Industry: </p> */}
	// 										<p>
	// 											Company Size:{" "}
	// 											{
	// 												companySizeList.find(
	// 													(item) => item.value === size
	// 												).label
	// 											}
	// 										</p>
	// 									</div>
	// 									<p>{bio}</p>
	// 								</>
	// 							}
	// 						/>

	// 						{commonConnections?.length > 0 && (
	// 							<CommonConnections
	// 								count={commonConnectionsCount}
	// 								data={commonConnections}
	// 								company={minifiedCompany}
	// 							/>
	// 						)}

	// 						{otherJobs?.pages?.[0]?.length > 0 && (
	// 							<JobInfoItem
	// 								title={"Other jobs by this company"}
	// 								content={
	// 									<div className={styles.jobs_wrapper}>
	// 										{otherJobsList}

	// 										{isFetchingOtherJobs && (
	// 											<div>
	// 												<JobLoader />
	// 											</div>
	// 										)}
	// 									</div>
	// 								}
	// 							/>
	// 						)}

	// 						{similarJobs?.pages?.[0]?.length > 0 && (
	// 							<JobInfoItem
	// 								title={"Similar Jobs"}
	// 								content={
	// 									<div className={styles.jobs_wrapper}>
	// 										{similarJobsList}

	// 										{isFetchingSimilarJobs && (
	// 											<div>
	// 												<JobLoader />
	// 											</div>
	// 										)}
	// 									</div>
	// 								}
	// 							/>
	// 						)}
	// 					</div>
	// 				</div>
	// 			)
	// 		}
	// 		rightSideContainerColor={"#FFF"}
	// 	/>
	// );
};

export default JobDetails;
