import { useCallback } from "react";
import CreatableSelect from "react-select/creatable";
import plusIcon from "images/plus-icon-dblue-15-x-15.svg";
import { Controller } from "react-hook-form";
import { cssValue } from "components/Utils/General";

const CreateSelect = ({
	options,
	onChange,
	defaultValue,
	value,
	isSearchable = true,
	width = "100% !important",
	height = "100% !important",
	fontSize = "14px",
	handleCreateNew,
	placeholder = "Select...",
	disabled = false,
	isLoading = false,
	onBlur,
	control,
	name,
	error,
	cursor,
	components,
	menuIsOpen,
	onMenuScrollToBottom,
	maxLength = 30,
}) => {
	const handleCreateLabel = useCallback((inputValue) => {
		return (
			<span>
				<img
					src={plusIcon}
					style={{ width: "15px", marginRight: "10px" }}
					alt="Add new item"
				/>
				Add: {inputValue}
			</span>
		);
	}, []);

	const isValidNewOption = (inputValue, selectValue, selectOptions) => {
		if (!inputValue || inputValue.trim().length === 0 || inputValue.length > maxLength) {
			return false;
		}

		// Check if inputValue exactly matches any existing option
		const isExistingOption = selectOptions.some(
			(option) => option.label.toLowerCase() === inputValue.toLowerCase()
		);

		return !isExistingOption;
	};

	const handleInputChange = (inputValue, actionMeta) => {
		if (inputValue.length > maxLength) {
			return inputValue.slice(0, maxLength);
		}
		return inputValue;
	};

	// const [openedMenu, setOpenedMenu] = useState(false);

	// Keeps the menu closed when not typing
	// const inputChangeHandler = (value) => {
	//     let menuIsOpened = false;
	//     if (value) {
	//         menuIsOpened = true;
	//     }
	//     setOpenedMenu(menuIsOpened);
	// };

	return control ? (
		<Controller
			control={control}
			name={name}
			render={({ field }) => (
				<CreatableSelect
					// components={{
					//     DropdownIndicator: () => null,
					//     IndicatorSeparator: () => null,
					// }}
					components={components}
					isSearchable={isSearchable}
					placeholder={placeholder}
					options={options}
					onCreateOption={handleCreateNew}
					formatCreateLabel={handleCreateLabel}
					defaultValue={defaultValue}
					width={width}
					height={height}
					fontSize={fontSize}
					styles={customStyles}
					// menuIsOpen={openedMenu}
					// onInputChange={inputChangeHandler}
					menuIsOpen={menuIsOpen}
					isDisabled={disabled}
					isLoading={isLoading}
					error={error}
					{...field}
					cursor={cursor}
					onMenuScrollToBottom={onMenuScrollToBottom}
					onBlur={(e) => {
						onBlur && onBlur(e);
						field.onBlur(e);
					}}
					// value={value}
					onChange={(e) => {
						onChange && onChange(e);
						field.onChange(e);
					}}
					isValidNewOption={isValidNewOption}
					onInputChange={handleInputChange}
					menuPlacement={"auto"}
				/>
			)}
		/>
	) : (
		<CreatableSelect
			// components={{
			//     DropdownIndicator: () => null,
			//     IndicatorSeparator: () => null,
			// }}
			components={components}
			isSearchable={isSearchable}
			placeholder={placeholder}
			options={options}
			onCreateOption={handleCreateNew}
			formatCreateLabel={handleCreateLabel}
			onChange={onChange}
			defaultValue={defaultValue}
			value={value}
			width={width}
			height={height}
			fontSize={fontSize}
			styles={customStyles}
			// menuIsOpen={openedMenu}
			menuIsOpen={menuIsOpen}
			// onInputChange={inputChangeHandler}
			isDisabled={disabled}
			isLoading={isLoading}
			onBlur={onBlur}
			error={error}
			cursor={cursor}
			onMenuScrollToBottom={onMenuScrollToBottom}
			isValidNewOption={isValidNewOption}
			onInputChange={handleInputChange}
			menuPlacement={"auto"}
		/>
	);
};

const customStyles = {
	container: (provided, state) => ({
		...provided,
		backgroundColor: "white",
		padding: "0 !important",
		borderRadius: "5px",
		height: state.selectProps.height,
	}),
	option: (_, { isFocused, isSelected, selectProps }) => ({
		padding: "7px",
		fontSize: selectProps.fontSize,
		backgroundColor: isSelected ? "#6cc5d1" : isFocused ? "#d4f3f7" : "white",
		cursor: "pointer",
		color: "#000000",
	}),
	control: (provided, { isFocused, isSelected, selectProps }) => ({
		...provided,
		borderRadius: "5px",
		outline:
			(isFocused || isSelected) && !selectProps.error
				? "2px solid #6cc5d1 !important"
				: selectProps.error
				? "2px solid red !important"
				: "1px solid #c4ccd3 !important",
		outlineOffset: "0px",
		borderWidth: "0px",
		boxShadow: "none",
		transition: "none !important",
		// cursor: "pointer !important",
		cursor: selectProps.cursor ? selectProps.cursor : "pointer",
		width: selectProps.width,
		height: selectProps.height,
		minHeight: selectProps.height,
		boxSizing: "border-box !important",

		// justifyContent: "center",
		// alignContent: "center",
		// alignItems: "center",
		// padding: isFocused || isSelected ? "0 !important" : "1 !important",
	}),
	singleValue: (provided, { selectProps }) => ({
		...provided,
		color: "black",
		fontSize: selectProps.fontSize,
	}),

	valueContainer: (provided, { selectProps }) => ({
		...provided,
		fontSize: selectProps.fontSize,
		height: "100%",
		// top: "70%",
		// justifyContent: "center",
		padding: "7px",
		alignContent: "center",
		alignItems: "center",
	}),
	indicatorsContainer: (provided) => ({
		...provided,
		marginRight: "0 !important",
		height: "100%",
	}),
	menu: (provided) => ({
		...provided,
		zIndex: "2",
	}),
	placeholder: (provided) => ({
		...provided,
		color: cssValue("--gray70"),
	}),
};

export default CreateSelect;
