import { joiResolver } from "@hookform/resolvers/joi";
import routes from "components/Routing/routing-keys";
import PromptModal from "components/Utils/Prompt/PromptModal";
import FileInput from "components/Utils/SubComs/Inputs/FileInput/FileInput";
import MultiSelectInput from "components/Utils/SubComs/Inputs/MultiSelectInput/MultiSelectInput";
import useMutate from "hooks/useMutate";
import useUrlPreview from "hooks/useUrlPreview";
import { locationKeys } from "queryKeys/location-key-factory";
import { projectKeys } from "queryKeys/projects-key-factory";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import projectApi from "../../../api/project";
import useApi from "../../../hooks/useApi";
import useCompanyId from "../../../hooks/useCompanyId";
import useFetchLocation from "../../../hooks/useFetchLocation";
import MapItem from "../../Profile/ProfileEdit/Contacts/Address/MapItem";
import MapSelect from "../../Utils/MapSelect/MapSelect";
import LoadingPage from "../../Utils/SubComs/CustomLoader/LoadingPage";
import DayPicker from "../../Utils/SubComs/DayPicker/DayPicker";
import InfiniteSearchInput from "../../Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import {
	defaultFormatter,
	locationFormatter,
} from "../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import TextInput from "../../Utils/SubComs/Inputs/TextInput/TextInput";
import styles from "../JobEntry/JobEntry.module.css";
import projectSchema from "./project-schema";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";
import TextArea from "components/Utils/SubComs/Inputs/TextArea/TextArea";
import Switcher from "components/Utils/SubComs/Switcher/Switcher";
import NewGaawkModal from "components/Utils/NewGaawkModal/NewGaawkModal";

const itemsPerPage = 20;
const itemsLimit = 10;

const ProjectEntry = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { projectId } = useParams();

	const [tempImage, setTempImage] = useState([]);

	const editMode = useMemo(
		() => pathname === `/jobs/project/${projectId}/edit`,
		[pathname, projectId]
	);

	const [isLoading, setIsLoading] = useState(false);

	useMemo(() => {
		if (editMode) {
			setIsLoading(true);
		}
	}, [editMode]);

	const companyId = useCompanyId();

	const getProjectApi = useApi(projectApi.getProject, true);
	const getProjectTagApi = useApi(projectApi.getProjectTags, true, true);

	const fetchProjectTags = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, __, query] = queryKey;

		const response = await getProjectTagApi.request(pageParam, itemsPerPage, query);

		return response.data;
	};

	// const [imageName, setImageName] = useState("");

	useEffect(() => {
		if (editMode) {
			//TODO
		} else {
			trackEvent(eventsDictionary.JOB.CREATE_PROJECT);
		}
	}, [editMode]);

	useEffect(() => {
		//TODO >> change the way of fetching the data (check JobEntry)
		const fetchProjectData = async () => {
			const response = await getProjectApi.request(projectId);

			if (companyId === response.data.owner.uuid) {
				const {
					title,
					active,
					descriptionText,
					startDate,
					endDate,
					image,
					tags,
					location,
				} = response.data;

				if (location)
					setSelectedLocation({
						address: location?.title,
						city: location.locationDTO?.name,
						country: location.locationDTO?.countryDTO?.name,
						countryCode: location.locationDTO?.countryDTO?.code,
						lat: location.latitude,
						lng: location.longitude,
					});

				reset({
					title,
					status: active,
					description: descriptionText,
					...(startDate > 0 && {
						startDate: new Date(startDate),
					}),
					...(endDate > 0 && {
						endDate: new Date(endDate),
					}),
					image: image ? [image.file] : [],
					tags: tags.map((tag) => ({
						label: tag.name,
						value: tag.uuid,
					})),
					...(location?.title && {
						address: location.title,
					}),
					...(location?.locationDTO && {
						city: {
							label: `${location.locationDTO.name}, ${location.locationDTO.countryDTO.name}`,
							value: location.locationDTO.id,
						},
					}),
				});

				setIsLoading(false);
			} else {
				// navigate(`/jobs/project/${projectId}`, { replace: true });
				throw new Response("", {
					statusText: "You are not allowed to edit this project",
					status: 403,
				});
			}
		};

		if (editMode) fetchProjectData();
	}, [editMode, projectId]);

	const {
		register,
		setValue,
		formState: { errors, isDirty },
		watch,
		handleSubmit,
		control,
		reset,
	} = useForm({
		resolver: joiResolver(projectSchema),
		mode: "all",
		defaultValues: {
			title: "",
			startDate: null,
			endDate: null,
			image: [],
			tags: [],
			status: true,
			description: "",
			city: null,
			address: "",
		},
	});

	const tagsWatcher = watch("tags");
	const statusWatcher = watch("status");
	const imageWatcher = watch("image");
	const cityWatcher = watch("city");

	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	// const [cropModal, setCropModal] = useState(false);

	// ! ====== PROJECT TAGS ======

	const handleRemoveTag = (tagIndex) => {
		const updatedTags = tagsWatcher.filter((_, index) => index !== tagIndex);
		setValue("tags", updatedTags);
	};

	const {
		action: { mutate: addTag },
	} = useMutate(projectApi.addProjectTag, (response) =>
		setValue("tags", [...tagsWatcher, defaultFormatter(response.data)])
	);

	const handleSelectedTag = (tag) => {
		//if the tag has the __isNew__ property to true, then create the tag
		// then in the success callback, add it to the list
		if (tag.__isNew__) {
			addTag(tag.value);
		} else {
			setValue("tags", [...tagsWatcher, tag]);
		}
	};
	// const tagsList = useMemo(
	// 	() =>
	// 		tagsWatcher.map((tag, index) => (
	// 			<Tag
	// 				key={tag.value}
	// 				itemName={tag.label}
	// 				onRemove={() => handleRemoveTag(index)}
	// 			/>
	// 		)),
	// 	[tagsWatcher]
	// );
	// ! ====== LOCATION ======

	const [mapLocation, setMapLocation] = useState("");
	const [locationModal, setLocationModal] = useState(false);
	const [selectedLocation, setSelectedLocation] = useState("");

	const handleMapLocation = (location) => {
		setMapLocation(location);
	};

	const handleSelectedLocation = () => {
		setValue("address", mapLocation.address);
		setSelectedLocation(mapLocation);
		setLocationModal(false);
	};

	const handleRemoveLocation = () => {
		setSelectedLocation("");
	};

	// ! ====================

	const {
		action: { mutate: addEditProject, isLoading: isLoadingAddEdit },
	} = useMutate(
		editMode ? projectApi.updateProject : projectApi.addProject,
		(response) => {
			navigate(`${routes.allProjects}?projectId=${response.data.uuid}`, { replace: true });
		},
		() => setShouldBlockSave(true)
	);

	const handleSave = (data) => {
		const { title, tags, status, startDate, endDate, description, image, city, address } = data;

		const formData = new FormData();
		formData.append("active", status);
		formData.append("title", title);
		formData.append("description", description);
		if (tags.length > 0)
			formData.append(
				"tags",
				tags.map((tag) => tag.value)
			);
		if (address) formData.append("address", address);
		if (city) formData.append("locationId", city.value);
		// TODO >> CURRENTLY, WHEN TAG IS ADDED BY USER, IT SHOULD HIT THE API TO CREATE A NEW PROJECT TAG FIRST
		// TODO >> ASK WALEED IF WE CAN DO THE SAME AS SKILLS (ADD STRING WHEN TAG DOESN'T EXIST)
		if (startDate) formData.append("startDate", new Date(startDate).getTime());
		if (endDate) formData.append("endDate", new Date(endDate).getTime());

		// if (image[0] instanceof File) formData.append("image", image[0]);
		if (image[0] instanceof Blob) formData.append("image", image[0], image[0].fileName);

		if (
			selectedLocation &&
			cityWatcher &&
			selectedLocation.lat !== 0 &&
			selectedLocation.lng !== 0
		) {
			formData.append("latitude", selectedLocation.lat);
			formData.append("longitude", selectedLocation.lng);
		}

		if (editMode) formData.append("projectId", projectId);

		setShouldBlockSave(false);
		addEditProject(formData);
	};

	const fetchLocation = useFetchLocation(true, itemsPerPage);

	// !----- revoking image preview -----

	const { objectUrls, setObjectUrls, cleanupObjectUrls } = useUrlPreview();

	useEffect(() => {
		// Create object URLs when postMedia changes

		if (
			imageWatcher?.[0] &&
			imageWatcher[0] instanceof Blob &&
			!(imageWatcher[0] instanceof File)
		) {
			setObjectUrls([URL.createObjectURL(imageWatcher[0])]);
		}

		// // Cleanup function
		return () => {
			cleanupObjectUrls();
		};
	}, [imageWatcher]);

	// !----------------------------------

	if (!companyId) {
		//* 1 - redirecting if you're not a company (should not be able to edit job as a user)
		//* 2 - Knowing if you're the owner of the job and allow editing >> check performed in the useEffect
		// return <Navigate to={projectId ? routes.jobProject(projectId) : routes.jobs} />;
		throw new Response("", {
			statusText: "You are not allowed to edit this project",
			status: 403,
		});
	}

	return (
		<>
			{isLoading ? (
				<LoadingPage />
			) : (
				<form
					className={styles.job_form}
					onSubmit={handleSubmit((data) => handleSave(data))}
					noValidate
				>
					<div className={styles.form_input_wrapper}>
						<label>
							Project Title
							<span className="required">*</span>
						</label>

						<TextInput
							{...register("title")}
							error={!!errors.title}
							placeholder="Enter Project Title"
						/>
						{errors?.title?.message && (
							<p className={styles.error_message}>{errors?.title?.message}</p>
						)}
					</div>
					<div className={styles.inline_input}>
						<div className={styles.item}>
							<label>Start Date</label>

							<DayPicker
								className={"form-start-date"}
								control={control}
								name="startDate"
								isClearable={true}
							/>
						</div>
						<div className={styles.item}>
							<label>End Date</label>
							<DayPicker
								className={`form-end-date ${
									!!errors.endDate ? "error" : undefined
								}`}
								control={control}
								name="endDate"
								isClearable={true}
							/>
							{errors?.endDate?.message && (
								<p className={styles.error_message}>{errors?.endDate?.message}</p>
							)}
						</div>
					</div>
					<div className={styles.form_input_wrapper}>
						<label>Project Image</label>

						{imageWatcher.length > 0 &&
							((imageWatcher[0] instanceof Blob &&
								!(imageWatcher[0] instanceof File)) ||
								imageWatcher?.[0].url) && (
								<div className={styles.thumbs_container}>
									<img
										src={
											imageWatcher[0] instanceof Blob &&
											!(imageWatcher[0] instanceof File)
												? objectUrls[0]
												: imageWatcher[0].url
										}
										alt=""
									/>
								</div>
							)}

						<FileInput
							control={control}
							error={errors.image}
							name="image"
							required={true}
							// loadedFile={imageWatcher}
							loadedFile={tempImage}
							onCrop={(cropped) => {
								setValue("image", [cropped]);
							}}
							cropAspect={2 / 1}
							onChange={(value) => {
								// setValue("image", [value.target.files[0]], {
								// 	shouldDirty: true,
								// });
								setTempImage([value.target.files[0]]);
							}}
							showThumbails={false}
						/>

						{/* <div className={styles.img_option}>
							<button type="button">
								<Controller
									name="image"
									control={control}
									render={() => (
										<input
											type="file"
											accept="image/png, image/jpg, image/jpeg"
											onChange={(val) => {
												val.target.files[0] &&
													setValue(
														"image",
														[val.target.files[0]],
														{ shouldDirty: true }
													);
												setImageName(
													val.target.files[0].name
												);
												val.target.value = "";
												setCropModal(true);
											}}
											tabIndex="-1"
										/>
									)}
								/>
								{imageWatcher.length > 0
									? "REPLACE IMAGE"
									: "+ ADD IMAGE"}
							</button>
						</div> */}
					</div>
					<MultiSelectInput
						creatable={true}
						queryName={projectKeys.projectTags}
						queryFn={fetchProjectTags}
						data={tagsWatcher}
						itemsPerPage={itemsPerPage}
						formatter={defaultFormatter}
						label="Project Tags"
						limit={itemsLimit}
						onChange={handleSelectedTag}
						onRemoveItem={handleRemoveTag}
						infoText={`You can add up to ${itemsLimit} roles which outline your core
                    skill sets.`}
					/>

					<div className={styles.react_switch}>
						<Controller
							name="status"
							control={control}
							render={({ field }) => (
								<label>
									<Switcher {...field} />
									<span>
										Project Status: <b>{statusWatcher ? "Open" : "Closed"}</b>
									</span>
								</label>
							)}
						/>
					</div>

					<Controller
						name={"description"}
						control={control}
						render={({ field }) => (
							<TextArea
								className={styles.textarea_wrapper}
								label="Project Description"
								error={errors.description}
								{...field}
								placeholder={"Enter Project Description"}
							/>
						)}
					/>

					{/* <div className={styles.form_input_wrapper}>
                        <label>Address</label>
                        {selectedLocation && (
                            <div className={styles.address}>
                                {selectedLocation.address}
                            </div>
                        )}
                    </div> */}
					<div className={styles.form_input_wrapper}>
						<label>Address</label>

						<TextInput {...register("address")} placeholder="Enter address" />
					</div>

					<InfiniteSearchInput
						queryName={locationKeys.cities}
						queryFn={fetchLocation}
						itemsPerPage={itemsPerPage}
						formatter={locationFormatter}
						label={"City & Country"}
						name="city"
						control={control}
						isClearable={true}
						customStyle={styles.margin_20}
						openMenuOnClick={true}
					/>
					{cityWatcher && (
						<MapSelect
							location={selectedLocation}
							onClick={() => setLocationModal(true)}
							onRemove={handleRemoveLocation}
							customStyle={styles.map}
						/>
					)}
					<div className={styles.button_container}>
						<GaawkButton
							type={"submit"}
							className={styles.save_btn}
							text={editMode ? "Save" : "Create"}
							isLoading={isLoadingAddEdit}
						/>
					</div>
					<PromptModal when={isDirty && shouldBlockSave} />

					<NewGaawkModal
						visible={locationModal}
						onHide={() => setLocationModal(false)}
						title={"Select Project Location"}
						children={
							<div className={styles.map_container}>
								<MapItem
									onLocation={handleMapLocation}
									locationData={selectedLocation || mapLocation}
								/>

								{mapLocation && (
									<div className={styles.button_container}>
										<GaawkButton
											className={styles.save_btn}
											text="Select"
											onClick={handleSelectedLocation}
										/>
									</div>
								)}
							</div>
						}
					/>
				</form>
			)}
		</>
	);
};

export default ProjectEntry;
