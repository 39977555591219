import React, { useRef } from "react";
import styles from "./FullscreenControls.module.css";
import { ReactComponent as DotsIcon } from "images/dots.svg";
import fullscreenExitIcon from "images/fullscreen-exit.svg";
import Popover from "../Popover/Popover";

const FullscreenControls = ({ actions, onExit }) => {
	const fsRef = useRef();
	const popoverRef = useRef(null);

	return (
		<div className={styles.fullscreen_controls_wrapper} ref={fsRef}>
			<div className={styles.fullscreen_controls}>
				{actions && (
					<Popover
						ref={popoverRef}
						render={<div className="popover_container">{actions}</div>}
					>
						<button>
							<DotsIcon />
						</button>
					</Popover>
				)}
				<img
					src={fullscreenExitIcon}
					onClick={onExit}
					className={styles.fullscreen_exit_icon}
					alt="exit fullscreen"
				/>
			</div>
		</div>
	);
};

export default FullscreenControls;
