export const productsKeys = {
	all: ["products"],

	lists: () => [...productsKeys.all, "listProducts"],

	searchList: () => [...productsKeys.lists(), "search"],

	suggestedList: (productId) => [
		...productsKeys.lists(),
		"suggested",
		productId,
	],

	search: (query, filters = {}) => [
		...productsKeys.searchList(),
		{ ...filters, q: query },
	], //TODO >> to remove when finished refacto
	unCategorized: (userId) => [
		...productsKeys.lists(),
		"unCategorized",
		userId,
	],
	stats: (userId) => [...productsKeys.all, "stats", userId],

	details: () => [...productsKeys.all, "details"],
	detail: (productId) => [...productsKeys.details(), productId],
	productMedia: (productId) => [
		...productsKeys.details(),
		"media",
		productId,
	],
	nearby: (userCoordinates) => [
		...productsKeys.lists(),
		"nearby",
		userCoordinates,
	],
	connections: () => [...productsKeys.lists(), "connections"],

	byGaawkServiceId: (gaawkServiceId, userCoordinates) => [
		...productsKeys.lists(),
		"byGaawkServiceId",
		gaawkServiceId,
		userCoordinates,
	],
};
