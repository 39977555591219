import BackButton from "components/Utils/Button/BackButton";
import styles from "./ContextHeader.module.scss";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";

const ContextHeader = ({ isLoading = false, title, rightSideContent }) => {
	const navigate = useNavigate();
	return (
		<div className={styles.header}>
			<div>
				<BackButton onBack={() => navigate(-1)} />
				{isLoading ? <Skeleton width={150} /> : title}
			</div>
			{rightSideContent}
		</div>
	);
};

export default ContextHeader;
