import styles from "./ProductLoader.module.scss";
import { Skeleton } from "primereact/skeleton";

const ProductLoader = () => {
	return (
		<div className={styles.product_card_skeleton}>
			<Skeleton width={"100%"} height={"87%"} />
			<Skeleton width={"60%"} />
		</div>
	);
};

export default ProductLoader;
