import useApi from "../../../../hooks/useApi";
import productApi from "../../../../api/product";
import { productsKeys } from "queryKeys/products-key-factory";
import { useCustomQuery } from "hooks/useCustomQuery";

export const useProductsStats = (profileId, enabled = true) => {
	const getStatsApi = useApi(productApi.getProductStats, true, true);

	const fetchStats = async ({ queryKey }) => {
		const [, , profileId] = queryKey;
		const response = await getStatsApi.request(profileId);
		return response.data;
	};

	const {
		data: stats,
		isLoading: isLoadingStats,
		isSuccess,
	} = useCustomQuery({
		queryKey: productsKeys.stats(profileId),
		queryFn: fetchStats,
		enabled,
	});
	return { stats, isLoadingStats, isSuccess };
};
