import { projectKeys } from "queryKeys/projects-key-factory";
import useMutate from "./useMutate";
import { useQueryClient } from "@tanstack/react-query";
import projectApi from "api/project";
import { jobsKeys } from "queryKeys/jobs-key-factory";

const useUpdateProjectStatus = (project) => {
	const queryClient = useQueryClient();

	const { uuid: projectId, owner: { uuid: companyId } = {} } = project || {};

	const {
		action: { mutate: toggleProjectStatus },
	} = useMutate(projectApi.updateProjectStatus, () => {
		//* optimistic update for project status
		queryClient.setQueryData(projectKeys.detail(projectId), (oldData) => {
			return {
				...oldData,
				active: !oldData.active,
			};
		});

		queryClient.invalidateQueries(projectKeys.projectsByCompany(companyId));
		queryClient.invalidateQueries(jobsKeys.landing());
	});

	return toggleProjectStatus;
};

export default useUpdateProjectStatus;
