import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
	agencyFilters: {
		locationIds: [],
		types: [],
	},
	talentFilters: {},
	peopleFilters: {
		friends: false,
		inMyCircle: false,
		inTheirCircle: false,
		languages: [],
		roles: [],
		skills: [],
		software: [],
	},
	companiesFilters: {
		inMyCircle: false,
		inTheirCircle: false,
		agency: false,
		industryIds: [],
		specialties: [],
	},
	postsFilters: {
		friends: false,
		inMyCircle: false,
		inTheirCircle: false,
		includeMedia: false,
		fromDate: null,
		toDate: null,
	},
	jobsFilters: {
		jobType: 0, //* 0 all, 1 long term, 2 freelance, 3 casting call
		time: "NONE",
		skillIds: [],
		frequency: "MONTHLY",
		compensation: "",
		currencyId: "",
		castingCallRequest: {
			// date: null, //! removed
			startDate: null, //TODO >> added startDate and endDate
			endDate: null, //TODO >> added startDate and endDate
			// ethnicityId: "", //! removed
			// ethnicityObject: null, //! removed
			ethnicityIds: [], //TODO >> is now an array of ids
			gender: -1, //* 0 male, 1 female, 2 both, -1 none
			languageIds: [],
			maxAge: "",
			minAge: "",
		},
		freelanceRequest: {
			startDate: null,
			endDate: null,
			experience: [], //TODO >> this is now an array of experience Lvl: "ENTRY_LEVEL", "MID_LEVEL", "SENIOR", etc...
			showBasedOnCalendar: false,
			workingLocations: [], //TODO >> is now an array >> ["OFFICE", "REMOTE", "HYBRID"]
		},
		jobRequest: {
			experience: [], //TODO >> this is now an array of experience Lvl: "ENTRY_LEVEL", "MID_LEVEL", "SENIOR", etc...
			longTermJobTypeId: [],
			workingLocations: [], //TODO >> is now an array >> ["OFFICE", "REMOTE", "HYBRID"]
		},
	},

	productsFilters: {
		gaawkCategories: [],
		ownerIds: [],
		latitude: 0,
		longitude: 0,
		q: "",
		productFilters: {
			colors: [],
			materials: [],
			serviceTags: [],
			minLength: "",
			maxLength: "",
			minWidth: "",
			maxWidth: "",
			minHeight: "",
			maxHeight: "",
			minWeight: "",
			maxWeight: "",
			widthUnit: "METER",
			heightUnit: "METER",
			lengthUnit: "METER",
			weightUnit: "GRAM",
			onlyUncategorizedProducts: false,
		},
	},

	//TODO >> can use the productsFilters as it's the same as servicesFilters ?
	servicesFilters: {
		// gaawkCategories: [],
		// friends: false,
		// inMyCircle: false,
		// inTheirCircle: false,
		// includeMedia: false,
		// includeProducts: false,
		gaawkCategories: [],
		ownerIds: [],
		latitude: 0,
		longitude: 0,
		q: "",
	},
};

const slice = createSlice({
	name: "filters",
	initialState,
	reducers: {
		updateAgencyFilters: (state, action) => {
			state.agencyFilters = action.payload;
		},
		resetAgencyFilters: (state) => {
			state.agencyFilters = initialState.agencyFilters;
		},
		updateTalentFilters: (state, action) => {
			state.talentFilters = { ...state.talentFilters, ...action.payload };
		},
		resetTalentFilters: (state) => {
			state.talentFilters = initialState.talentFilters;
		},
		updatePeopleFilters: (state, action) => {
			state.peopleFilters = { ...state.peopleFilters, ...action.payload };
		},
		resetPeopleFilters: (state) => {
			state.peopleFilters = initialState.peopleFilters;
		},
		updateCompaniesFilters: (state, action) => {
			state.companiesFilters = {
				...state.companiesFilters,
				...action.payload,
			};
		},
		resetCompaniesFilters: (state) => {
			state.companiesFilters = initialState.companiesFilters;
		},
		updatePostsFilters: (state, action) => {
			state.postsFilters = {
				...state.postsFilters,
				...action.payload,
			};
		},
		resetPostsFilters: (state) => {
			state.postsFilters = initialState.postsFilters;
		},
		updateJobsFilters: (state, action) => {
			state.jobsFilters = {
				...state.jobsFilters,
				...action.payload,
			};
		},
		resetJobsFilters: (state, action) => {
			state.jobsFilters = {
				...initialState.jobsFilters,
				jobType: action.payload || 0,
			};
		},
		updateProductsFilters: (state, action) => {
			state.productsFilters = {
				...state.productsFilters,
				...action.payload,
			};
		},
		resetProductsFilters: (state) => {
			state.productsFilters = initialState.productsFilters;
		},
		updateServicesFilters: (state, action) => {
			state.servicesFilters = {
				...state.servicesFilters,
				...action.payload,
			};
		},
		resetServicesFilters: (state) => {
			state.servicesFilters = initialState.servicesFilters;
		},
		resetAllFilters: () => initialState,
	},
});

export const {
	updateAgencyFilters,
	resetAgencyFilters,
	updateTalentFilters,
	resetTalentFilters,
	updatePeopleFilters,
	resetPeopleFilters,
	updateCompaniesFilters,
	resetCompaniesFilters,
	updatePostsFilters,
	resetPostsFilters,
	updateJobsFilters,
	resetJobsFilters,
	updateProductsFilters,
	resetProductsFilters,
	updateServicesFilters,
	resetServicesFilters,
	resetAllFilters,
} = slice.actions;

export default slice.reducer;
