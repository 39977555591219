import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import filterIcon from "images/filter-icon-white.svg";
import { useMemo } from "react";
import ProductItem from "../../Profile/ServicesTab/ServicesComponent/ProductItem";
import {
	// copyToClipboard,
	pluralize,
} from "../../Utils/General";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import styles from "./Products.module.scss";
// import { toast } from "react-toastify";
// import useDirectMessage from "../../../hooks/useDirectMessage";
// import productApi from "../../../api/product";
// import WarningModal from "../../Utils/GaawkModal/WarningModal";
// import useMutate from "../../../hooks/useMutate";
// import { useNavigate } from "react-router-dom";
// import useCurrentUser from "hooks/useCurrentUser";
// import ProductItemList from "../../Profile/ServicesTab/ServicesComponent/ProductItemList";
// import SortingButtons from "../../Utils/SubComs/SortingButtons/SortingButtons";

const Products = ({
	count,
	data,
	isPaginated = false,
	viewRef,
	isLoading,
	isMobile,
	onShowModal,
	isFetchingNextPage,
	onClick,
	tagsList,
	// onRefresh,
}) => {
	// const { handleDirectMessage } = useDirectMessage();
	// const navigate = useNavigate();

	// const { uuid: userId } = useCurrentUser();

	// const handleCopyLink = (productId) => {
	// 	copyToClipboard(`${window.location.origin}/products/${productId}`);
	// 	toast.success("Link copied !");
	// };

	// const handleEditProduct = (product) => {
	// 	const {
	// 		owner: { url },
	// 		uuid: productId,
	// 	} = product;

	// 	navigate(`/profile/${url}/product/${productId}`, {
	// 		state: { isEdit: true },
	// 	});
	// };

	// !===================================================================

	const itemsList = useMemo(
		() =>
			data?.map((product, index) => {
				const isLastItem = index === data.length - 1;

				return (
					<div key={product.uuid} ref={isLastItem && isPaginated ? viewRef : null}>
						<ProductItem
							// isOwner={userId === product.owner.uuid}
							item={product}
							optionsIcon={false}
							// onCopy={() => handleCopyLink(product.uuid)}
							// onMessage={() => handleDirectMessage(product.owner)}
							// onEdit={() => handleEditProduct(product)}
							// onDelete={() => handleRequestDelete(product.uuid)}
						/>
					</div>
				);
			}),
		[data, isPaginated, viewRef]
	);

	if (isLoading) return <LoadingSpinner />;

	if (!isLoading && count === 0)
		return (
			<NoResults
				visible={true}
				// isSearch={true}
				text={"No results"}
			/>
		);

	return (
		<>
			<div className={styles.title_wrapper}>
				<h4>{`${count} ${pluralize(count, "Product")} found`}</h4>
				<div className={styles.controls}>
					{/* //TODO >> DELETE COMPONENT ? (need to confirm with Sakshi) */}
					{/* {isPaginated && (
						<SortingButtons
							order={order}
							stateLayout={layout}
							onChangeOrder={onChangeOrder}
							onChangeLayout={onChangeLayout}
						/>
					)} */}
					{isMobile && isPaginated && (
						<button className={styles.filter_button} onClick={onShowModal}>
							<img src={filterIcon} alt="" />
						</button>
					)}
				</div>
			</div>

			{tagsList?.length > 0 && <div className={styles.active_filters}>{tagsList}</div>}

			<div
				className={styles.items_wrapper}
				// ${layout === "list" ? styles.list : undefined}
			>
				{itemsList}
			</div>

			{isFetchingNextPage && <LoadingSpinner />}

			{!isPaginated && (
				<div className={styles.button_container}>
					<SeeMore onClick={onClick} text="See more" />
				</div>
			)}

			{/* <WarningModal
				show={warningModal}
				headerText="Are you sure you want to delete this product?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DISCARD"}
				onSubmitButtonClicked={handleConfirmDelete}
			/> */}
		</>
	);
};

export default Products;
