import { useEffect, useState } from "react";
import styles from "./StepProgressBar.module.scss";

const StepProgressBar = ({ currentStep, totalSteps = 4 }) => {
	const [width, setWidth] = useState(0);

	useEffect(() => {
		const percentage = ((currentStep - 1) / (totalSteps - 1)) * 100;
		setWidth(percentage);
	}, [currentStep, totalSteps]);

	return (
		<div className={styles.progressContainer}>
			<div className={styles.backgroundBar} />
			<div
				className={styles.progressBar}
				style={{ width: `${width}%` }}
			/>
		</div>
	);
};

export default StepProgressBar;
