import styles from "./HeaderBar.module.scss";
import DynamicLogo from "../DynamicLogo/DynamicLogo";
import { useSelector } from "react-redux";
import { classNames } from "primereact/utils";
import ProfilePic from "components/Home/ProfilePic";
import { ReactComponent as Bell } from "images/navbar-icons/bell.svg";
import { ReactComponent as Add } from "images/navbar-icons/add.svg";
import { ReactComponent as Chat } from "images/navbar-icons/chat.svg";
import useCurrentUser from "hooks/useCurrentUser";
import { useRef, useState } from "react";
import Popover from "components/Utils/Popover/Popover";
import ProfileSwitch from "../BurgerMenu/ProfileSwitch";
import { useNavigate } from "react-router-dom";
import routes from "components/Routing/routing-keys";

const HeaderBar = ({ hideLeftSide, headerRightContent, hideSeparator = false }) => {
	const navigate = useNavigate();

	const isMobile = useSelector((state) => state.ui.isMobile);
	const currentUser = useCurrentUser();
	const thumbnail = currentUser?.profileImage?.framedImageURL
		? currentUser.profileImage.framedImageURL
		: currentUser?.profileImage?.originalImageURL
		? currentUser.profileImage.originalImageURL
		: undefined;

	const [menuOpen, setMenuOpen] = useState(false);
	const leftPopoverRef = useRef(null);
	// const popoverRef = useRef(null);

	// const [notificationsOpen, setNotificationsOpen] = useState(false);

	// const {
	// 	query: { data, isFetching },
	// 	viewRef,
	// 	markAllAsRed,
	// 	markAsRead,
	// 	deleteNotification,
	// } = useFetchNotifications(notificationsOpen);

	return (
		<>
			<header className={styles.header}>
				<div
					className={classNames(styles.left_header_wrapper, {
						[styles.show]: isMobile && !headerRightContent,
						[styles.hide]: hideLeftSide,
					})}
				>
					<div className={styles.logos_wrapper}>
						{isMobile && (
							<Popover
								allow={["bottom"]}
								onOpen={setMenuOpen}
								crossAxis={150}
								ref={leftPopoverRef}
								render={<ProfileSwitch isOpen={menuOpen} />}
							>
								<button type="button">
									<ProfilePic
										w={24}
										h={24}
										type={currentUser.type}
										thumbnail={thumbnail}
										noMarginRight
										name={currentUser.firstName || currentUser.name}
										enableName={false}
									/>
								</button>
							</Popover>
						)}

						<DynamicLogo />
					</div>

					<div className={styles.actions_wrapper}>
						<button
							onClick={() => alert("TODO")} //TODO
							type="button"
						>
							<Add />
						</button>

						<button onClick={() => navigate(routes.notifications)} type="button">
							{/* // TODO >> add the red dot for new notifications */}
							<Bell />
						</button>

						{/* <Popover
						allow={["bottom"]}
						ref={popoverRef}
						render={
							<NotificationsPopover
								data={data}
								ref={viewRef}
								isLoading={isFetching}
								onClose={popoverRef.current?.closePopover}
								onDelete={deleteNotification}
								onRead={markAsRead}
								onAllRead={markAllAsRed}
							/>
						}
					>
						<button
							onClick={() => setNotificationsOpen((prevState) => !prevState)}
							type="button"
							className={styles.small}
						>

							<Bell />
						</button>
					</Popover> */}

						<button onClick={() => navigate(routes.chat)} type="button">
							<Chat />
						</button>
					</div>
				</div>

				<div
					className={classNames(styles.right_header_wrapper, {
						[styles.show]: isMobile && headerRightContent,
						[styles.hide_separator]: hideSeparator,
						[styles.full_width]: hideLeftSide,
					})}
				>
					{headerRightContent && headerRightContent}
				</div>
			</header>
		</>
	);
};

export default HeaderBar;
