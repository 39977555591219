import styles from "./CollapsibleContainer.module.scss";
import { useCollapse } from "react-collapsed";
import { ReactComponent as ForwardIcon } from "images/arrow-icon.svg";
import { classNames } from "primereact/utils";
import RoundButton from "../Button/RoundButton";

const CollapsibleContainer = ({ defaultExpanded = true, title, children, onAdd }) => {
	const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
		defaultExpanded,
	});

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<button {...getToggleProps()}>
					<h3>{title}</h3>
					<ForwardIcon
						className={classNames(styles.forward_icon, {
							[styles.rotate]: isExpanded,
						})}
					/>
				</button>
				{onAdd && <RoundButton icon={"add"} onClick={onAdd} />}
			</div>
			<section {...getCollapseProps()}>{children}</section>
		</div>
	);
};

export default CollapsibleContainer;
