export const profileKeys = {
	all: ["profile"],
	myCompanies: () => [...profileKeys.all, "myCompanies"],
	pendingEmployees: (companyId) => [
		...profileKeys.all,
		companyId,
		"pendingEmployees",
	],
	employees: (companyId) => [...profileKeys.all, companyId, "employees"],
	employeesPaginated: (companyId, q = "") => [
		...profileKeys.all,
		companyId,
		"employeesPaginated",
		q,
	],
	myLocations: () => [...profileKeys.all, "profileLocationsList"],
	search: (query) => [...profileKeys.all, "search", query],
	searchTags: (query) => [...profileKeys.all, "searchTags", query],
	commonConnections: (requestedProfileId) => [
		...profileKeys.all,
		"commonConnections",
		requestedProfileId,
	],
	companySearch: (query) => [...profileKeys.all, "company", "search", query],
	profileByUrl: (url) => [...profileKeys.all, url],
	details: () => [...profileKeys.all, "details"],
	profileByUuid: (id, userType) => [...profileKeys.details(), id, userType],
	companyUsers: (companyId) => [...profileKeys.all, companyId],
	companyUsersType: (companyId, userType) => [
		...profileKeys.all,
		companyId,
		userType,
	],
	userCompanies: () => [...profileKeys.all, "myCompanies"],
	currentPositions: () => [...profileKeys.all, "currentPositions"],
	pendingTalents: (profileId) => [
		...profileKeys.all,
		profileId,
		"pendingTalents",
	],
	featuredProfiles: (userCoordinates) => [
		...profileKeys.all,
		"featuredProfiles",
		userCoordinates,
	],
};
