import { useParams } from "react-router-dom";
import styles from "./ServiceProducts.module.scss";
import SearchInput from "components/Utils/SubComs/Inputs/SearchInput/SearchInput";
import { useRef, useState } from "react";
import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
import NavBar from "components/Utils/SubComs/NavBar/NavBar";
import { servicesKeys } from "queryKeys/services-key-factory";
import { useCustomQuery } from "hooks/useCustomQuery";
import useApi from "hooks/useApi";
import servicesApi from "api/services";
import ServicePopover from "./ServicePopover";
import useVaultStorage from "hooks/useVaultStorage";
import ResourceSearchView from "components/Resources/ResourceSearchView/ResourceSearchView";
import ContextHeader from "components/Utils/SubComs/ContextHeader/ContextHeader";
import ProductEntry from "components/Profile/ProductsTab/ProductEntry/ProductEntry";
import ReportModal from "components/Utils/ReportModal/ReportModal";
import ServiceEntry from "../ServiceAdd/ServiceEntry";
import useFetchProfile from "hooks/useFetchProfile";

const ServiceProducts = ({ isUncategorised = false }) => {
	const { serviceTag } = useParams();
	const [searchInput, setSearchInput] = useState("");
	const reportRef = useRef();
	const resourceSearchViewRef = useRef();
	const { data: vaultData } = useVaultStorage();

	const {
		usedServicesCount,
		usedProductsCount,
		currentPlan: {
			services: servicesAdCount,
			products: productsAdCount,
		} = {},
	} = vaultData;

	const maxLimitServices = usedServicesCount >= servicesAdCount;
	const maxLimitProducts = usedProductsCount >= productsAdCount;

	// ! ====== GET SERVICE BY TAG ======

	const getServiceByTagApi = useApi(servicesApi.getServiceByTag, true, true);

	const fetchServiceDetails = async ({ queryKey }) => {
		const [_, __, serviceTag] = queryKey;
		const response = await getServiceByTagApi.request(serviceTag);

		return response.data;
	};

	const { data, fetchStatus } = useCustomQuery({
		queryKey: servicesKeys.detailByTag(serviceTag),
		queryFn: fetchServiceDetails,
		enabled: !!serviceTag,
	});
	const isFetchingServiceByTag = fetchStatus === "fetching";

	const { name, uuid: serviceId, minifiedProfile } = data || {};

	// ! ====== GET SERVICE OWNER ======

	const { profileData, isFetching } = useFetchProfile(isUncategorised);

	return (
		<DefaultPage
			headerRightContent={
				<ContextHeader
					isLoading={isFetchingServiceByTag || isFetching}
					title={!isUncategorised ? data?.name : "Un-categorised"}
					rightSideContent={
						!isUncategorised && (
							<ServicePopover
								service={data}
								maxLimitServices={maxLimitServices}
								maxLimitProducts={maxLimitProducts}
								isServiceProducts
								onReport={() =>
									reportRef.current.showReportModal(serviceId)
								}
							/>
						)
					}
				/>
			}
			leftSideChildren={<NavBar />}
			rightSideChildren={
				<>
					<div className={styles.container}>
						<SearchInput
							onChange={({ target }) => {
								const query = target.value;
								setSearchInput(query);
							}}
							value={searchInput}
							showIcons={true}
							onClearClicked={() => setSearchInput("")}
							border={false}
							placeholder={
								!isUncategorised
									? `Search resources in ${name}`
									: `Search uncategorised products`
							}
						/>

						<ResourceSearchView
							ref={resourceSearchViewRef}
							searchInput={searchInput}
							requestedProfileId={
								!isUncategorised
									? minifiedProfile?.uuid
									: profileData?.uuid
							}
							service={data}
							displayOptions={{
								services: !isUncategorised,
								products: true,
							}}
							isUncategorised={isUncategorised}
						/>
					</div>
					<ReportModal ref={reportRef} category="service" />
					<ServiceEntry />
					<ProductEntry
						onInvalidate={() => {
							resourceSearchViewRef.current.invalidate();
						}}
					/>
				</>
			}
		/>
	);
};

export default ServiceProducts;
