import { deleteToken, getMessaging } from "firebase/messaging";
import { useSelector } from "react-redux";
import useMutate from "./useMutate";
import profileApi from "api/profile";
import { logout } from "components/Utils/General";

export default function useLogout() {
	// const messaging = getMessaging(); //* firebase commented

	const { token } = useSelector((state) => state.fcmToken);
	const {
		action: { mutate: logoutUser },
	} = useMutate(profileApi.logout, async () => {
		// await deleteToken(messaging); //* firebase commented
		logout();
	});

	const handleLogout = () => {
		logoutUser(token || "");
	};

	return { handleLogout };
}
