import { useDispatch, useSelector } from "react-redux";
import styles from "../ServiceAdd.module.css";
import useCompanyId from "hooks/useCompanyId";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { firstStep } from "../schema";
import { setStep, updateStep1 } from "store/slices/service";
import infoIcon from "images/icon-info-gray.png";
import { RadioButton } from "primereact/radiobutton";
import ServicesTree from "../../ServicesComponent/ServicesTree";
import GaawkButton from "components/Utils/Button/GaawkButton";
import { classNames } from "primereact/utils";

const Stage1 = () => {
	const dispatch = useDispatch();

	const companyId = useCompanyId();

	const { step1 = {} } = useSelector((state) => state.service);

	const { hasServices } = useSelector((state) =>
		companyId ? state.company.companyInfo : state.user.profileInfo
	);

	const [accordionParentId, setAccordionParentId] = useState(
		step1?.accordionParentId || ""
	);

	const createDefaultValues = () => ({
		level: step1.level ? step1?.level : 1,
		category: step1.level
			? step1?.level === 1 || step1?.level === undefined
				? "NEW"
				: "SUB"
			: "NEW",
		subCategory: step1.level ? step1?.parentId : "",
	});

	const {
		// register,
		formState: { errors },
		handleSubmit,
		control,
		watch,
		setValue,
		clearErrors,
	} = useForm({
		resolver: joiResolver(firstStep),
		defaultValues: createDefaultValues(),
	});

	const category = watch("category");
	const subCategoryId = watch("subCategory");

	useEffect(() => {
		if (errors?.subCategory) clearErrors();
	}, [category]);

	const handleSave = (data) => {
		const { subCategory, level } = data;
		dispatch(
			updateStep1({
				level,
				parentId: subCategory,
				accordionParentId,
			})
		);
		dispatch(setStep(2));
	};

	const ServiceTitle = ({ onInfoClick }) => (
		<div className={styles.title_wrapper}>
			<h3 className={styles.title}>
				What are you creating?<span className="required">*</span>
			</h3>
			<button type="button" onClick={onInfoClick}>
				<img src={infoIcon} alt="" />
			</button>
		</div>
	);

	const ServiceOptions = () => (
		<Controller
			name={"category"}
			control={control}
			render={({ field: { onChange, value } }) => (
				<>
					<div className={styles.radio_wrapper}>
						<RadioButton
							checked={value === "NEW"}
							onChange={onChange}
							inputId={"NEW"}
							value={"NEW"}
						/>
						<label htmlFor={"NEW"}>
							<h4 className={styles.label_title}>New Category</h4>
							<p className={styles.label_text}>
								Your newly created service will be listed as a
								first level service.
							</p>
						</label>
					</div>

					<div
						className={classNames(styles.radio_wrapper, {
							[styles.disabled]: !hasServices,
						})}
					>
						<RadioButton
							checked={value === "SUB"}
							onChange={onChange}
							inputId={"SUB"}
							value={"SUB"}
							disabled={!hasServices}
						/>
						<label htmlFor={"SUB"}>
							<h4 className={styles.label_title}>Sub Category</h4>
							<p className={styles.label_text}>
								Your newly created service will be listed as a
								child to the category you select from the list
								below.
							</p>
						</label>
					</div>
				</>
			)}
		/>
	);

	return (
		<form
			className={styles.form}
			onSubmit={handleSubmit((data) => handleSave(data))}
			noValidate
		>
			<ServiceTitle /> {/* //TODO onInfoClick={} */}
			<ServiceOptions />
			{hasServices && category === "SUB" && (
				<div className={styles.services_accordion_wrapper}>
					<ServicesTree
						enabled={true}
						radioMode={true}
						accordionParentId={accordionParentId}
						onRadioSelect={(serviceId, level, parentId) => {
							setValue("subCategory", serviceId);
							setValue("level", level);
							setAccordionParentId(parentId);
						}}
						selected={subCategoryId}
					/>
				</div>
			)}
			{errors?.subCategory?.message && (
				<p className={styles.error_message}>
					{errors?.subCategory?.message}
				</p>
			)}
			<div className={styles.button_container}>
				<GaawkButton type={"submit"} text={"Next"} />
			</div>
		</form>
	);
};

export default Stage1;
