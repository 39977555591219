import styles from "./ConnectionModal.module.css";
import ProfilePic from "../../Home/ProfilePic";
import GaawkButton from "components/Utils/Button/GaawkButton";
import NewGaawkModal from "components/Utils/NewGaawkModal/NewGaawkModal";

const ConnectionModal = ({ show, action, onConfirm, onClose, name, profileImage }) => {
	let title = "";
	let text = "";
	let btnText = "";
	let btnText2 = "";
	let isWarning = false;

	switch (action) {
		case "ADD_WORK":
			title = "Add to Work Circle";
			btnText = "Add";
			text = (
				<div
					className={styles.text}
				>{`Are you sure you want to add ${name} to your Work Circle?`}</div>
			);
			break;
		case "REMOVE_WORK":
			title = "Remove from Work Circle";
			btnText = "Remove";
			isWarning = true;
			text = (
				<div
					className={styles.text}
				>{`Are you sure you want to remove ${name} to your Work Circle?`}</div>
			);
			break;
		case "ADD_FRIEND":
			title = "Add as friend";
			btnText = "Add";
			text = (
				<div
					className={styles.text}
				>{`Are you sure you want to add ${name} as a friend?`}</div>
			);
			break;
		case "REVOKE_FRIEND":
			title = "Revoke Friend request";
			btnText = "Revoke";
			text = (
				<div
					className={styles.text}
				>{`Are you sure you want to revoke your friend request to ${name}?`}</div>
			);
			break;
		case "ACCEPT_FRIEND":
			title = "Friend request";
			btnText = "Accept";
			btnText2 = "Decline";
			text = <div className={styles.text}>{`${name} sent you a friend request.`}</div>;
			break;
		case "REMOVE_FRIEND":
			title = "Remove Friend";
			btnText = "Remove";
			isWarning = true;
			text = (
				<div
					className={styles.text}
				>{`Are you sure you want to remove ${name} from your friends?`}</div>
			);
			break;
		case "FOLLOW":
			title = "Follow";
			btnText = "Follow";
			text = <div className={styles.text}>{`Are you sure you want to follow ${name}?`}</div>;
			break;
		case "UNFOLLOW":
			title = "Unfollow";
			btnText = "Unfollow";
			isWarning = true;
			text = (
				<div className={styles.text}>{`Are you sure you want to unfollow ${name}?`}</div>
			);
			break;
		case "BLOCK":
			title = "Block Connection";
			btnText = "Block";
			isWarning = true;
			text = (
				<div
					className={styles.text}
				>{`Are you sure you want to block ${name}? You will not receive messages or notifications, and you’ll not be connected.`}</div>
			);
			break;
		case "UNBLOCK":
			title = "Unblock Connection";
			btnText = "Unblock";
			text = (
				<div
					className={styles.text}
				>{`Are you sure you want to unblock ${name}? You will restart receiving messages and notifications.`}</div>
			);
			break;
		default:
			break;
	}

	return (
		<NewGaawkModal
			visible={show}
			onHide={onClose}
			title={title}
			children={
				<>
					<div className={styles.content_wrapper}>
						<ProfilePic
							type={"USER"}
							thumbnail={profileImage}
							w={"52px"}
							h={"52px"}
							noMarginRight={true}
							name={name}
							enableName={false}
						/>
						{text}
					</div>
					<div className={styles.btn_container}>
						{btnText2 ? (
							<GaawkButton
								severity={"tertiary"}
								text={btnText2}
								onClick={() => onConfirm("DECLINE_FRIEND")}
							/>
						) : (
							<GaawkButton severity={"tertiary"} text={"Cancel"} onClick={onClose} />
						)}
						<GaawkButton
							severity={isWarning ? "danger" : "primary"}
							text={btnText}
							onClick={() => onConfirm(action)}
						/>
					</div>
				</>
			}
		/>
	);
};

export default ConnectionModal;
