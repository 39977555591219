import Stage1 from "./stages/Stage1";
import Stage2 from "./stages/Stage2";
import Stage3 from "./stages/Stage3";
import { useDispatch, useSelector } from "react-redux";
import {
	setModal,
	setService,
	setStep,
	setSuggModal,
	setLocationModal,
	updateStep1,
	updateStep2,
} from "store/slices/service";
import { useEffect } from "react";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import NewGaawkModal from "components/Utils/NewGaawkModal/NewGaawkModal";
import StepProgressBar from "components/Utils/StepProgressBar/StepProgressBar";
import styles from "./ServiceAdd.module.css";

const ServiceEntry = () => {
	const { modal, step, suggModal, service, locationModal } = useSelector(
		(state) => state.service
	);

	const dispatch = useDispatch();

	useEffect(() => {
		if (modal) {
			if (suggModal) {
				trackEvent(eventsDictionary.SERVICE.SUGGEST_SERVICE);
			} else if (service) {
				if (step === 2) {
					trackEvent(eventsDictionary.SERVICE.EDIT_SERVICE_2);
				} else if (step === 3) {
					trackEvent(eventsDictionary.SERVICE.EDIT_SERVICE_3);
				}
			} else {
				if (step === 1) {
					trackEvent(eventsDictionary.SERVICE.ADD_SERVICE_1);
				} else if (step === 2) {
					trackEvent(eventsDictionary.SERVICE.ADD_SERVICE_2);
				} else {
					trackEvent(eventsDictionary.SERVICE.ADD_SERVICE_3);
				}
			}
		}
	}, [modal, step, service, suggModal]);

	const handleResetClose = () => {
		if (suggModal) {
			dispatch(setSuggModal(false));
			return;
		}
		if (locationModal) {
			dispatch(setLocationModal(false));
			return;
		}

		dispatch(setModal(false));
		dispatch(setService(undefined));
		dispatch(setStep(1));
		dispatch(updateStep1(undefined));
		dispatch(updateStep2(undefined));
	};

	const handleBack = () => {
		if (suggModal) {
			dispatch(setSuggModal(false));
			return;
		}

		if (locationModal) {
			dispatch(setLocationModal(false));
			return;
		}

		if (step === 1) {
			handleResetClose();
			return;
		}

		dispatch(setStep(step - 1));
	};

	return (
		<NewGaawkModal
			visible={modal}
			onHide={handleResetClose}
			onBack={
				step === 1 || (service && step === 2) ? undefined : handleBack
			}
			title={
				suggModal
					? "Suggest Category"
					: locationModal
					? "My locations"
					: service
					? "Edit Service"
					: "Add New Service"
			}
			children={
				<>
					<div className={styles.progress_wrapper}>
						<StepProgressBar currentStep={step + 1} />
					</div>
					{step === 1 ? (
						<Stage1 />
					) : step === 2 ? (
						<Stage2 />
					) : (
						<Stage3 /> //TODO >> to be deleted once finished refacto
					)}
				</>
			}
		/>
	);
};

export default ServiceEntry;
