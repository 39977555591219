import { useMemo } from "react";
import styles from "./BoardSection.module.scss";
// import BoardItem from "./BoardItem";
import LoadMoreButton from "../../Utils/Button/LoadMoreButton";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
// import { useInfiniteQuery } from "@tanstack/react-query";
import NoResults from "../../Utils/SubComs/NoResults/NoResults";
import { classNames } from "primereact/utils";
// import SearchInput from "../../Utils/SubComs/Inputs/SearchInput/SearchInput";
import { ReactComponent as BoardIcon } from "images/board.svg";
import BoardItem from "./BoardItem";
import { useNavigate } from "react-router-dom";
import routes from "components/Routing/routing-keys";
import GaawkButton from "components/Utils/Button/GaawkButton";

const BoardSection = ({
	title,
	infiniteData,
	searchInput,
	showEmpty = false,
	isSidebar = false,
	onNewBoard,
	showManageBoards = false,
}) => {
	const { data, isFetching, hasNextPage, fetchNextPage, isLoading } = infiniteData || {};

	const navigate = useNavigate();

	const noResults = data?.pages?.[0]?.length === 0;

	const formattedBoards = useMemo(
		() =>
			data?.pages.map((page) =>
				page.map((item) => <BoardItem key={item.uuid} data={item} isSidebar={isSidebar} />)
			),
		[data]
	);

	//won't display the section if no results
	if (!isFetching && noResults && !showEmpty) {
		return null;
	}

	return (
		<div className={styles.container}>
			<div
				className={classNames(styles.section_wrapper, {
					[styles.sidebar]: isSidebar,
				})}
			>
				<div className={styles.title_wrapper}>
					{title && <h3>{title}</h3>}
					{onNewBoard && !noResults && (
						<button type={"button"} onClick={onNewBoard}>
							+ Create board
						</button>
					)}
					{showManageBoards && (
						<button type={"button"} onClick={() => navigate(routes.manageBoards)}>
							Manage Your Boards
						</button>
					)}
				</div>

				{formattedBoards}

				{/* //TODO >> replace loading spinner with a custom board skeleton */}
				<LoadingSpinner visible={isLoading} />

				{showEmpty && !onNewBoard && (
					<NoResults
						text={searchInput ? "No results related to your search" : "No results"}
						title={searchInput ? "OOPS!" : "Nothing Yet!"}
						// noPadding={true}
						// isSearch={searchInput}
						visible={!isFetching && noResults}
					/>
				)}

				{onNewBoard && noResults && !isFetching && (
					<div className={styles.new_board_container}>
						<BoardIcon fill={"#000"} width={50} />
						<span>Feel free to create your first board here!</span>
						<GaawkButton
							severity={"secondary"}
							text={"+ Create Board"}
							onClick={onNewBoard}
						/>
					</div>
				)}

				{/* //TODO >> replace with inView infinite loading  */}
				<LoadMoreButton hasMore={hasNextPage} loading={isFetching} onLoad={fetchNextPage} />
			</div>
		</div>
	);
};

export default BoardSection;
