import { useState, useMemo } from "react";
import GroupMember from "../../Chat/GroupMember";
import SearchInput from "../../Utils/SubComs/Inputs/SearchInput/SearchInput";
import styles from "./TaggedModal.module.css";
import NewGaawkModal from "components/Utils/NewGaawkModal/NewGaawkModal";

const TaggedModal = ({ show, handleClose, title, profiles }) => {
	const [searchInput, setSearchInput] = useState("");

	const profileList = useMemo(
		() =>
			profiles
				?.filter((item) => item.name.toLowerCase().includes(searchInput))
				.map((profile) => {
					return (
						<div key={profile.uuid} className={styles.contact_item}>
							<div className={styles.contact_item_wrapper}>
								<GroupMember redirect={true} key={profile.uuid} data={profile} />
							</div>
						</div>
					);
				}),
		[searchInput, profiles]
	);

	const handleSearchInputChange = ({ target }) => {
		setSearchInput(target.value);
	};

	const handleClear = () => {
		setSearchInput("");
	};

	return (
		<NewGaawkModal
			visible={show}
			onHide={handleClose}
			title={title}
			children={
				<>
					<SearchInput
						onChange={handleSearchInputChange}
						value={searchInput}
						showIcons={true}
						onClearClicked={handleClear}
						border={false}
						placeholder={"Search..."}
					/>

					<div className={styles.container}>{profileList}</div>
				</>
			}
		/>
	);
};

export default TaggedModal;
