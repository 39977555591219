import React from "react";
import GaawkButton from "components/Utils/Button/GaawkButton";
import styles from "./DeleteAccount.module.scss";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import profileApi from "api/profile";
import useMutate from "hooks/useMutate";
import { useNavigate } from "react-router-dom";
import useLogout from "hooks/useLogout";
import { Controller, useForm } from "react-hook-form";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { passwordRegex } from "components/Utils/General";
import md5 from "md5";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import { Checkbox } from "primereact/checkbox";
import NewGaawkModal from "components/Utils/NewGaawkModal/NewGaawkModal";
import useCurrentUser from "hooks/useCurrentUser";
import routes from "components/Routing/routing-keys";
import useProfileSwitch from "hooks/useProfileSwitch";
import { useSelector } from "react-redux";

const DeleteAccount = () => {
	const { uuid: profileId } = useSelector((state) => {
		return state.user.profileInfo;
	});

	const { type, name: companyName, uuid: companyId } = useCurrentUser();
	const { handleLogout } = useLogout();
	const navigate = useNavigate();

	const schema = Joi.object({
		...(type === "USER"
			? {
					password: Joi.string()
						.pattern(passwordRegex)
						.required()
						.label("Password")
						.messages({
							"string.pattern.base": "Invalid password",
							"string.empty": "Password should not be empty",
						}),
			  }
			: {
					companyName: Joi.string()
						.valid(companyName)
						.required()
						.messages({
							"any.only": "Please enter the correct company name",
							"string.empty": "Company name is required",
						}),
			  }),
		acceptTerms: Joi.boolean().valid(true).required().messages({
			"any.required": "You must accept the terms and conditions",
			"any.only": "You must accept the terms and conditions",
		}),
	});

	const {
		register,
		formState: { errors, isValid },
		handleSubmit,
		control,
		watch,
		clearErrors,
	} = useForm({
		resolver: joiResolver(schema),
		mode: "onChange",
	});

	const { switchToProfile } = useProfileSwitch(false, () =>
		navigate(routes.root)
	);

	const {
		action: { isLoading },
		warningModal,
		setWarningModal,
		handleConfirmMutate,
	} = useMutate(
		type === "USER" ? profileApi.deleteAccount : profileApi.deleteCompany,
		() => {
			trackEvent(
				type === "USER"
					? eventsDictionary.SETTINGS.DELETE_USER
					: eventsDictionary.SETTINGS.DELETE_COMPANY
			);
			type === "USER" && handleLogout();
			type === "COMPANY" && switchToProfile(profileId);
		}
	);

	const handleConfirmDelete = () => {
		if (type === "USER") {
			const password = watch("password");
			handleConfirmMutate(md5(password));
		} else {
			handleConfirmMutate(companyId);
		}
	};

	return (
		<>
			<p>
				By deleting your account and <b>accepting all terms</b>, you
				acknowledge and agree to reset your account settings.
				<br />
				<br />
				This action will remove your account, accept all associated
				terms and conditions, and{" "}
				<b>may result in losing certain stored data</b>, such as
				preferences or unsaved progress. Make sure to <b>back up</b> any
				important information before proceeding, as{" "}
				<b>this action cannot be undone</b>.
				<br />
				<br />
				{type === "USER" && (
					<>
						You will not be able to delete your account if you own a
						company. If you have any company associated with your
						account, you must{" "}
						<b>
							either delete the company or transfer ownership to
							another user
						</b>{" "}
						before proceeding to delete your account.
						<br />
						<br />
					</>
				)}
				By entering your {type === "USER"
					? "password"
					: "company name"}{" "}
				and accepting all terms, you confirm your decision to delete
				your account.
			</p>

			<form
				onSubmit={handleSubmit(() => setWarningModal(true))}
				className={styles.form}
			>
				{type === "USER" ? (
					<InputWrapper
						label="Password"
						required={true}
						error={errors?.password}
						component={
							<TextInput
								{...register("password")}
								placeholder="Enter your password"
								cursor="text"
								isPassword={true}
							/>
						}
					/>
				) : (
					<InputWrapper
						label="Company Name"
						required={true}
						error={errors?.companyName}
						component={
							<TextInput
								{...register("companyName")}
								placeholder="Enter your company name"
								cursor="text"
							/>
						}
					/>
				)}

				<div className={styles.checkbox_container}>
					<Controller
						name={"acceptTerms"}
						control={control}
						render={({ field: { onChange, value } }) => (
							<>
								<Checkbox
									checked={value}
									onChange={(e) => {
										onChange(e.target.checked);
										clearErrors("acceptTerms");
									}}
									inputId={"acceptTerms"}
								/>
								<label htmlFor="acceptTerms">
									I accept the terms and conditions.
								</label>
							</>
						)}
					/>
				</div>
				{errors?.acceptTerms && (
					<span className={styles.error_message}>
						{errors.acceptTerms.message}
					</span>
				)}

				<div className={styles.button_container}>
					<GaawkButton
						type="submit"
						text="Delete Account"
						severity="danger"
						disabled={!isValid}
					/>
					<GaawkButton
						text="No, I changed my mind!"
						onClick={() => navigate(-1)}
						severity="tertiary"
					/>
				</div>
			</form>

			<NewGaawkModal
				title={`Confirm ${
					type === "USER" ? "Account" : "Company"
				} Deletion`}
				visible={warningModal}
				onHide={() => setWarningModal(false)}
				showCloseButton={true}
			>
				<div className={styles.confirmation_content}>
					<p>
						Are you absolutely sure you want to delete your{" "}
						{type === "USER" ? "account" : "company"}? This action
						cannot be undone.
					</p>
					<div className={styles.confirmation_buttons}>
						<GaawkButton
							text="Cancel"
							onClick={() => setWarningModal(false)}
							severity="tertiary"
						/>
						<GaawkButton
							text="Delete Account"
							onClick={handleConfirmDelete}
							severity="danger"
							loading={isLoading}
						/>
					</div>
				</div>
			</NewGaawkModal>
		</>
	);
};

export default DeleteAccount;
