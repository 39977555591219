import routes from "components/Routing/routing-keys";
import LoadingPage from "components/Utils/SubComs/CustomLoader/LoadingPage";
import { useNavigate } from "react-router-dom";
import useJobsLanding from "../EmployerLanding/hooks/useJobsLanding";
import CollapsibleProjectSection from "./CollapisbleSection/CollapsibleProjectSection";
import styles from "../EmployerLanding/EmployerLanding.module.scss";
import useCurrentUser from "hooks/useCurrentUser";
import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
import BackButton from "components/Utils/Button/BackButton";
import ProjectActions from "../JobsComponents/ProjectActions/ProjectActions";
import { useSelector } from "react-redux";
import useUrlParams from "hooks/useUrlParams";
import { classNames } from "primereact/utils";
import { ReactComponent as GaawkJobsLogo } from "images/gaawk-logos/gaawk-jobs.svg";
import ProjectDetails from "../ProjectDetails/ProjectDetails";
import useProject from "../EmployerLanding/hooks/useProject";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import { useEffect, useState } from "react";
import useJob from "hooks/useJob";
import JobDetails from "../JobDetails/JobDetails";
import JobActions from "../JobsComponents/JobActions/JobActions";

const ProjectAll = () => {
	const isMobile = useSelector((state) => state.ui.isMobile);

	// const [openDetails, setOpenDetails] = useState(false);
	const [selectedJob, setSelectedJob] = useState(undefined);

	// !---- url params handlers -----

	const paramConfigs = {
		tab: {
			validator: (tab) => ["description", "long_term", "freelance", "casting"].includes(tab),
			defaultValue: "",
		},
		projectId: {
			validator: (id) => typeof id === "string",
			defaultValue: "",
		},

		// jobId: {
		// 	validator: (id) => typeof id === "string",
		// 	defaultValue: "",
		// },
	};

	const { params, setParams } = useUrlParams(paramConfigs);

	const {
		tab: activeTab,
		projectId,
		// jobId
	} = params;

	// !------------------------------

	const { data: project, isFetching, isSuccess } = useProject(projectId);

	useEffect(() => {
		if (isSuccess) {
			trackEvent(eventsDictionary.JOB.PROJECT_DETAIL, { project });
		}
	}, [isSuccess, project]);

	const navigate = useNavigate();

	const { type } = useCurrentUser();
	const { data: jobsLanding, isLoading, fetchStatus } = useJobsLanding();

	const { activeProjects, pastProjects } = jobsLanding || {};

	const handleProjectClicked = (project) => {
		setParams({ projectId: project.uuid });
		setSelectedJob(undefined);
		// setOpenDetails(false);
	};

	const handleBackButton = () => {
		if (selectedJob) {
			// setOpenDetails(false);
			setSelectedJob(undefined);
		} else if (isMobile) {
			setParams({ projectId: "" });
		} else {
			navigate(-1);
		}
	};

	const {
		data: job,
		isFetching: isFetchingJob,
		// isSuccess: isSuccessJob,
	} = useJob(selectedJob?.uuid);

	if (type === "USER") navigate(-1); //TODO >> maybe a better way of restricting access ?

	if (isLoading && fetchStatus !== "idle") return <LoadingPage fullPage />;

	return (
		<DefaultPage
			headerRightContent={
				<>
					<div className={styles.header_bar}>
						<BackButton onBack={handleBackButton} />
						{selectedJob ? "Job" : "Projects"}
					</div>

					{selectedJob && job ? (
						<JobActions job={job} onDeleteJob={() => setSelectedJob(undefined)} />
					) : (
						!isFetching &&
						project && (
							<ProjectActions
								project={project}
								onDeleteProject={() => setParams({ projectId: "" })}
							/>
						)
					)}
				</>
			}
			hideLeftSide={isMobile && projectId}
			hideRightSide={isMobile && !projectId}
			enableCollapsedNavBar
			hybridNavigation
			// disableScrollbar
			leftSideChildren={
				<div className={styles.left_side}>
					<div className={styles.jobs_container}>
						<CollapsibleProjectSection
							onAdd={() => navigate(routes.addProject)}
							open
							count={activeProjects}
							onClick={handleProjectClicked}
							activeProjectId={projectId}
						/>
						<CollapsibleProjectSection
							open={false}
							count={pastProjects}
							onClick={handleProjectClicked}
							activeProjectId={projectId}
						/>
					</div>
				</div>
			}
			rightSideChildren={
				<div className={styles.container}>
					<div
						className={classNames(styles.right_side, {
							[styles.align_middle]: !projectId,
						})}
					>
						{selectedJob ? (
							<JobDetails job={job} isLoading={isFetchingJob} />
						) : projectId ? (
							<ProjectDetails
								activeTab={activeTab || "description"}
								project={project}
								isLoading={isFetching}
								onTabChange={(tab) => setParams({ tab })}
								onJobClick={(job) => {
									setSelectedJob(job);
									// setOpenDetails(true);
								}}
							/>
						) : (
							<GaawkJobsLogo />
						)}
					</div>
				</div>
			}
		/>
	);
};

export default ProjectAll;
