import routes from "components/Routing/routing-keys";
import useMutate from "hooks/useMutate";
import binIcon from "images/bin-icon.svg";
import editIcon from "images/rename-icon.svg";
import replyIcon from "images/reply-icon.svg";
import { ReactComponent as DotsIcon } from "images/dots.svg";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import postApi from "../../api/post";
import Popover from "../Utils/Popover/Popover";
import CommentReactionsCounter from "../Utils/Reactions/CommentReactionsCounter";
import FormatSpecialText from "../Utils/SubComs/FormatSpecialText/FormatSpecialText";
import ReadMore from "../Utils/SubComs/ReadMore/ReadMore";
import CommentInput from "./CommentInput";
import styles from "./CommentItem.module.css";
import ProfilePic from "./ProfilePic";
import Replies from "./Replies";
// import { getDiffIn } from "../Utils/General";
// import Comment from "images/comment-black.svg";
// import Reaction from "./Reaction";
// import CustomTextarea from "../Utils/SubComs/CustomTextarea/CustomTextarea";
// import Reactions from "../Utils/Reactions/Reactions";

const CommentItem = ({
	comment,
	postId,
	onCommentUpdate,
	onUpdateRepliesCount,
	profileImg,
	type,
	url,
	onDelete,
	isFullscreenPost,
	name,
}) => {
	const navigate = useNavigate();
	const didMountRef = useRef(false);
	const repliesRef = useRef(null);
	const popoverRef = useRef(null);

	const [showReplies, setShowReplies] = useState(false);

	// !======= REACTION HANDLERS ==============

	const {
		action: { mutate: addReaction },
	} = useMutate(postApi.addCommentReaction, (response) => onCommentUpdate(response.data));

	const {
		action: { mutate: removeReaction },
	} = useMutate(postApi.removeCommentReaction, (response) => onCommentUpdate(response.data));

	const handleAddReaction = (reactionType) => {
		if (comment.profileReactionType === reactionType) removeReaction(comment.uuid);
		else addReaction({ commentId: comment.uuid, reactionType });
	};

	const handleReactionBtnClicked = () => {
		if (comment.profileReactionType) removeReaction(comment.uuid);
		else addReaction({ commentId: comment.uuid, reactionType: "gaawk" });
	};

	// ! ========== comment edit item ==========

	const {
		action: { mutate: updateComment },
	} = useMutate(postApi.updateComment, (response) => {
		onCommentUpdate(response.data);
		setIsEditing(false);
	});

	const [commentText, setCommentText] = useState("");

	const [isEditing, setIsEditing] = useState(false);

	const handleEdit = () => {
		setIsEditing((prevState) => !prevState);
		setCommentText(comment.comment);
	};

	const handleConfirmComment = () => {
		updateComment({
			object: {
				comment: commentText,
				commentId: comment.uuid,
				postId,
			},
		});
	};

	const handleEmojiClick = (_, emojiObject) => {
		setCommentText((prevState) => prevState + emojiObject.emoji);
	};

	const handleCancelEdit = () => {
		setIsEditing(false);
		setCommentText("");
	};

	// ! ==== reply   handler =====

	const handleReply = () => {
		setShowReplies(true);
	};

	useEffect(() => {
		if (didMountRef.current) {
			repliesRef.current.replyComment(comment.profile.url);
		}
		didMountRef.current = true;
	}, [showReplies]);

	return (
		<>
			{!isEditing ? (
				<div className={styles.comment_item} key={comment.uuid}>
					<ProfilePic
						thumbnail={
							comment.profile.profileImage
								? comment.profile.profileImage.image
								: undefined
						}
						type={comment.profile.type}
						url={comment.profile.url}
						name={name}
						enableName={false}
					/>

					<div className={styles.comment_flex}>
						<div className={styles.comment}>
							<div className={styles.comment_header}>
								<label
									className={styles.owner}
									onClick={() => navigate(routes.profile(comment.profile.url))}
								>
									{comment.profile.name}
								</label>

								<Popover
									ref={popoverRef}
									render={
										<div className="popover_container">
											<button
												className="popover_item"
												onClick={() => {
													setShowReplies((prevState) => !prevState);
													popoverRef.current.closePopover();
												}}
											>
												<img src={replyIcon} alt="reply" />
												<span>Reply</span>
											</button>
											<button className="popover_item" onClick={handleEdit}>
												<img src={editIcon} alt="forward" />
												<span>Edit</span>
											</button>
											<button
												className="popover_item"
												onClick={() => onDelete(comment.uuid)}
											>
												<img src={binIcon} alt="delete" />
												<span>Delete</span>
											</button>
										</div>
									}
								>
									<button>
										<DotsIcon />
									</button>
								</Popover>
							</div>

							<ReadMore>
								<FormatSpecialText
									text={comment.comment}
									customStyle={styles.reply_text}
								/>
							</ReadMore>
						</div>

						<CommentReactionsCounter
							item={comment}
							onReply={handleReply}
							onClick={handleReactionBtnClicked}
							onReact={handleAddReaction}
							isFullscreenPost={isFullscreenPost}
						/>
					</div>

					{showReplies && (
						<Replies
							ref={repliesRef}
							commentId={comment.uuid}
							repliesCount={comment.repliesCount}
							ownerUrl={comment.profile.url}
							onUpdateRepliesCount={onUpdateRepliesCount}
						/>
					)}
				</div>
			) : (
				<CommentInput
					profileImg={profileImg}
					name={name}
					type={type}
					url={url}
					onTextChange={setCommentText}
					text={commentText}
					// onTextChange={onTextChange}
					// text={editedText}
					// onQuery={handleQuery}
					// onKeyPress={handleKeyPress}
					onEmojiClick={handleEmojiClick}
					onCancel={handleCancelEdit}
					isEditing={isEditing}
					onConfirm={handleConfirmComment}
				/>
			)}
		</>
	);
};

export default CommentItem;
