import ContentLoader from "react-content-loader";

const ContactLoader = (props) => (
	<ContentLoader
		speed={2}
		width={"100%"}
		height={58}
		// viewBox="0 0 100% 60"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		{...props}
	>
		<rect x="1" y="1" rx="12" ry="12" width="100%" height="58" />
	</ContentLoader>
);

export default ContactLoader;
