import routes from "components/Routing/routing-keys";
import useFetchRoles from "hooks/useFetchRoles";
import useMutate from "hooks/useMutate";
// import publicIcon from "images/public_icon.svg";
import { locationKeys } from "queryKeys/location-key-factory";
import { rolesKeys } from "queryKeys/roles-key-factory";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateProfileTab } from "store/slices/activeTab";
import workStatusApi from "../../../api/workStatus";
import useFetchLocation from "../../../hooks/useFetchLocation";
import {
	employementTypes,
	experienceLvlTypes,
	workLocation as workLocationTypes,
} from "../../Utils/General";
import NewMultiCheckbox from "components/Utils/NewMultiCheckBox/NewMultiCheckBox";
import MultiSelectInput from "../../Utils/SubComs/Inputs/MultiSelectInput/MultiSelectInput";
import {
	defaultFormatter,
	locationFormatter,
} from "../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import styles from "./UpdateWorkStatus.module.css";
// import { useCustomQuery } from "hooks/useCustomQuery";
// import useApi from "hooks/useApi";
// import { workStatusKeys } from "queryKeys/workStatus-key-factory";
// import useCurrentUser from "hooks/useCurrentUser";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";
import useWorkStatus from "hooks/useWorkStatus";
import LoadingPage from "components/Utils/SubComs/CustomLoader/LoadingPage";
import useCurrentUser from "hooks/useCurrentUser";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import Switcher from "components/Utils/SubComs/Switcher/Switcher";
import { classNames } from "primereact/utils";

const itemsLimit = 10;
const itemsPerPage = 20;

const UpdateWorkStatus = ({ onSubmitSuccess, isModal = false }) => {
	const navigate = useNavigate();

	const dispatch = useDispatch();

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.UPDATE_WORK_STATUS);
	}, []);

	const { uuid } = useCurrentUser();
	const { data, isLoading } = useWorkStatus(uuid);

	const [workStatus, setWorkStatus] = useState({});

	useEffect(() => {
		if (data) {
			setWorkStatus(data);
		}
	}, [data]);

	const {
		available = false,
		roles,
		types = [],
		locations = [],
		experienceLevels = [],
		// legalCountries = [],
		workplace_hybrid,
		workplace_office,
		workplace_remote,
	} = workStatus;
	console.log("🚀 ~ workStatus >>", workStatus);

	// ! ========== WORK AVAILABILITY ========

	const handleAvailability = (bool) => {
		setWorkStatus((prevState) => ({ ...prevState, available: bool }));
	};

	// ! ========== WORKPLACES ===============

	const selectedWorkplaceItems = [
		workplace_office && {
			label: "On Site",
			value: "OFFICE",
		},
		workplace_remote && {
			label: "Remote",
			value: "REMOTE",
		},
		workplace_hybrid && {
			label: "Hybrid",
			value: "HYBRID",
		},
	].filter(Boolean);

	const handleSelectWorkplace = (selectedWorkplaces) => {
		const workPlacesValues = selectedWorkplaces.map((item) => item.value);

		setWorkStatus((prevState) => ({
			...prevState,
			workplace_office: workPlacesValues.includes("OFFICE"),
			workplace_remote: workPlacesValues.includes("REMOTE"),
			workplace_hybrid: workPlacesValues.includes("HYBRID"),
		}));
	};

	// ! ========== EXPERIENCE LEVELS ================

	const handleSelectExperienceLvl = (selectedExperience) => {
		const formattedExpLvl = selectedExperience?.map((expLvl) => expLvl.value);

		setWorkStatus((prevState) => ({
			...prevState,
			experienceLevels: formattedExpLvl,
		}));
	};

	// ! ========== JOB TYPES ================
	const handleSelectJob = (jobTypes) => {
		const formattedJobTypes = jobTypes?.map((type) => type.value);
		setWorkStatus((prevState) => ({
			...prevState,
			types: formattedJobTypes,
		}));
	};

	// ! ========== ROLES ====================
	const handleSelectedRole = (role) => {
		setWorkStatus((prevState) => ({
			...prevState,
			roles: [...prevState.roles, role],
		}));
	};

	const handleRemoveRole = (roleIndex) => {
		setWorkStatus((prevState) => ({
			...prevState,
			roles: prevState.roles.filter((_, index) => index !== roleIndex),
		}));
	};

	// ! ========== CITIES ===================
	const handleCities = (city) => {
		setWorkStatus((prevState) => ({
			...prevState,
			locations: [city, ...prevState.locations],
		}));
	};

	const handleRemoveCity = (cityIndex) => {
		setWorkStatus((prevState) => ({
			...prevState,
			locations: prevState.locations.filter((_, index) => index !== cityIndex),
		}));
	};

	// ! ========== COUNTRIES ================
	// const handleCountries = (country) => {
	// 	setWorkStatus((prevState) => ({
	// 		...prevState,
	// 		legalCountries: [country, ...prevState.legalCountries],
	// 	}));
	// };

	// const handleRemoveCountry = (countryIndex) => {
	// 	setWorkStatus((prevState) => ({
	// 		...prevState,
	// 		legalCountries: prevState.legalCountries.filter((_, index) => index !== countryIndex),
	// 	}));
	// };

	// ! ========== SUBMIT HANDLER ===========

	const {
		action: { mutate: updateWorkStatus },
	} = useMutate(workStatusApi.updateWorkStatus, () => {
		trackEvent(eventsDictionary.PROFILE.UPDATE_WORK_STATUS_DONE);
		dispatch(updateProfileTab("work status"));
		onSubmitSuccess ? onSubmitSuccess() : navigate(routes.profile());
	});

	const handleSubmit = (e) => {
		e.preventDefault();

		const body = {
			available,
			locations: locations.map((location) => location.value),
			// legalCountries: legalCountries.map((country) => country.value),
			experienceLevels: experienceLevels,
			jobTypes: types,
			roleIds: roles.map((role) => role.value),
			workplace_office,
			workplace_hybrid,
			workplace_remote,
		};

		updateWorkStatus({ body });
	};

	const fetchLocation = useFetchLocation(true, itemsPerPage);
	// const fetchCountries = useFetchLocation(false, itemsPerPage);

	const fetchRoles = useFetchRoles();

	if (isLoading && isModal) return <LoadingSpinner customStyle={styles.modal_loading_spinner} />;
	if (isLoading && !isModal) return <LoadingPage />;

	return (
		<div
			className={classNames(styles.container, {
				[styles.isModal]: isModal,
			})}
		>
			<form onSubmit={handleSubmit}>
				<div className={styles.work_status_container}>
					<h4>Work Status</h4>
					<p>
						Based on your preferences, we'll suggest and notify jobs to you. Your work
						status is always public.
					</p>
				</div>

				<div className={styles.switch_container}>
					<label>
						<Switcher value={available} onChange={handleAvailability} />
						<span>
							{available ? "Open to work" : "Not looking for new opportunities"}
						</span>
					</label>
				</div>

				<MultiSelectInput
					creatable={true}
					queryName={rolesKeys.search}
					queryFn={fetchRoles}
					data={roles}
					itemsPerPage={itemsPerPage}
					formatter={defaultFormatter}
					label="Roles"
					limit={itemsLimit}
					onChange={handleSelectedRole}
					onRemoveItem={handleRemoveRole}
					infoText={`You can add up to ${itemsLimit} roles which outline your core
                    skill sets.`}
				/>

				<div className={styles.form_input_wrapper}>
					<NewMultiCheckbox
						title={"Experience Level"}
						customStyle={isModal ? styles.custom_multicheckbox : undefined}
						options={experienceLvlTypes}
						onSelect={handleSelectExperienceLvl}
						selected={experienceLevels.map((expLvl) =>
							experienceLvlTypes.find((lvlType) => lvlType.value === expLvl)
						)}
					/>
				</div>

				<div className={styles.form_input_wrapper}>
					<NewMultiCheckbox
						title={"Job Type"}
						customStyle={isModal ? styles.custom_multicheckbox : undefined}
						infoText={
							"What type of job you’re looking for? Full time, freelance, contract and more…"
						}
						options={employementTypes.flatMap((job) =>
							job.value !== "APPRENTICESHIP" &&
							job.value !== "SELF_EMPLOYED" &&
							job.value !== "FREELANCE" &&
							job.value !== "CASTING_CALLS"
								? job
								: []
						)}
						// canSelectAll={true}
						onSelect={handleSelectJob}
						selected={types.map((type) =>
							employementTypes.find((jobType) => jobType.value === type)
						)}
					/>
				</div>

				{/* <MultiSelectInput
					queryName={locationKeys.countries}
					queryFn={fetchCountries}
					data={legalCountries}
					itemsPerPage={itemsPerPage}
					formatter={locationFormatter}
					label="Legal to work in"
					limit={itemsLimit}
					onChange={handleCountries}
					onRemoveItem={handleRemoveCountry}
					infoText={`You can add up to ${itemsLimit} countries where you have / do not need a work permit to work in.`}
				/> */}

				<div className={styles.form_input_wrapper}>
					<NewMultiCheckbox
						title={"Workplace"}
						customStyle={isModal ? styles.custom_multicheckbox : undefined}
						infoText={
							"Workplace is your preference of working from home, office and / or hybrid."
						}
						options={workLocationTypes}
						onSelect={handleSelectWorkplace}
						selected={selectedWorkplaceItems}
					/>
				</div>

				<MultiSelectInput
					queryName={locationKeys.cities}
					queryFn={fetchLocation}
					data={locations}
					itemsPerPage={itemsPerPage}
					formatter={locationFormatter}
					label="Preferred Locations"
					limit={itemsLimit}
					onChange={handleCities}
					onRemoveItem={handleRemoveCity}
					infoText={`You can add up to ${itemsLimit} locations where you want to work.`}
				/>

				<div className={styles.button_wrapper}>
					<GaawkButton
						type="submit"
						// disabled={editAlbumApi.loading}
						text={"Save"}
						className={styles.button}
					/>
				</div>
			</form>
		</div>
	);
};

export default UpdateWorkStatus;
