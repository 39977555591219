import styles from "./NewServiceDetails.module.scss";
import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
import NavBar from "components/Utils/SubComs/NavBar/NavBar";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import useApi from "hooks/useApi";
import servicesApi from "api/services";
import { servicesKeys } from "queryKeys/services-key-factory";
import { useCustomQuery } from "hooks/useCustomQuery";
import { Skeleton } from "primereact/skeleton";
import LazyImage from "components/Utils/LazyImage/LazyImage";
import ProfilePic from "components/Home/ProfilePic";
import GaawkButton from "components/Utils/Button/GaawkButton";
import Tag from "components/Utils/SubComs/Tags/Tag";
import useDirectMessage from "hooks/useDirectMessage";
import ReadMore from "components/Utils/SubComs/ReadMore/ReadMore";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { Fragment, useEffect, useMemo, useRef } from "react";
import ServiceCard from "components/Resources/ServiceCard/ServiceCard";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import routes from "components/Routing/routing-keys";
import TabMenu from "components/Utils/SubComs/TabMenu/TabMenu";
import { useState } from "react";
import searchApi from "api/search";
import { useDispatch, useSelector } from "react-redux";
import postApi from "api/post";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import { ReactComponent as PlayIcon } from "images/play-button.svg";
import PostViewer from "components/Profile/GalleryTab/PostViewer";
import { useQueryClient } from "@tanstack/react-query";
import useCurrentUser from "hooks/useCurrentUser";
import useEditSubPost from "hooks/useEditSubPost";
import EditMediaModal from "components/Home/EditMediaModal";
import useMutate from "hooks/useMutate";
import WarningModal from "components/Utils/GaawkModal/WarningModal";
import FileSaver from "file-saver";
import CreatePost from "components/Home/CreatePost";
import { toast } from "react-toastify";
import useUserLocation from "hooks/useUserLocation";
import ServiceCardLoader from "components/Utils/SubComs/CustomLoader/ServiceCardLoader";
import ReportModal from "components/Utils/ReportModal/ReportModal";
import ServiceEntry from "../ServiceAdd/ServiceEntry";
import ServicePopover from "../ServicesComponent/ServicePopover";
import useVaultStorage from "hooks/useVaultStorage";
import { vaultKeys } from "queryKeys/vault-key-factory";
// import { profileKeys } from "queryKeys/profile-key-factory";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import useWindowSize from "hooks/useWindowSize";
import RoundButton from "components/Utils/Button/RoundButton";
import { setService, setModal, setStep } from "store/slices/service";
import ProductLoader from "components/Utils/SubComs/CustomLoader/ProductLoader";
import MediaLoader from "components/Utils/SubComs/CustomLoader/MediaLoader";
import NewProductItem from "components/Profile/ProductsTab/ProductsComponents/NewProductItem";
import ContextHeader from "components/Utils/SubComs/ContextHeader/ContextHeader";
import ProductEntry from "components/Profile/ProductsTab/ProductEntry/ProductEntry";

const PAGE_SIZE = 20;

const NewServiceDetails = () => {
	const dispatch = useDispatch();
	const { width } = useWindowSize();
	const location = useLocation();

	const queryClient = useQueryClient();
	const { uuid: userId, ...profile } = useCurrentUser();
	const navigate = useNavigate();
	const { serviceTag } = useParams();
	const { handleDirectMessage } = useDirectMessage();

	// Check if user is coming from resources route using URL query parameter
	const isFromResources = useMemo(() => {
		// Parse the query parameters from the URL
		const queryParams = new URLSearchParams(location.search);
		return queryParams.get("from") === "resources";
	}, [location.search]);

	// const popoverRef = useRef(null);
	const reportRef = useRef();
	const postRef = useRef(null);

	const [tab, setTab] = useState("products");

	const { data: vaultData } = useVaultStorage();

	const {
		usedServicesCount,
		usedProductsCount,
		currentPlan: {
			services: servicesAdCount,
			products: productsAdCount,
		} = {},
	} = vaultData;

	const maxLimitServices = usedServicesCount >= servicesAdCount;
	const maxLimitProducts = usedProductsCount >= productsAdCount;

	// ! ====== DELETE SERVICE  ======

	const {
		warningModal: warningModalDeleteService,
		setWarningModal: setWarningModalDeleteService,
		handleRequestMutate: handleRequestMutateDeleteService,
		handleConfirmMutate: handleConfirmMutateDeleteService,
		activeItem: activeItemDeleteService,
	} = useMutate(
		servicesApi.deleteService,
		() => {
			queryClient.invalidateQueries(vaultKeys.storage());
			trackEvent(eventsDictionary.SERVICE.DELETE_SERVICE);
			navigate(-1);
		},
		undefined,
		{
			meta: {
				successMessage: "Service deleted successfully",
			},
		}
	);

	// ! ====== GET SERVICE BY TAG ======

	const getServiceByTagApi = useApi(servicesApi.getServiceByTag, true, true);

	const fetchServiceDetails = async ({ queryKey }) => {
		const [_, __, serviceTag] = queryKey;
		const response = await getServiceByTagApi.request(serviceTag);

		return response.data;
	};

	const { data, isLoading, isSuccess } = useCustomQuery({
		queryKey: servicesKeys.detailByTag(serviceTag),
		queryFn: fetchServiceDetails,
	});

	const {
		locations = [],
		worldWide,
		name,
		image,
		minifiedProfile,
		description,
		tag,
		level,
		tagMedia,
		tagProducts,
		uuid: serviceId,
		productsCount,
	} = data || {};

	const isServiceOwner = minifiedProfile?.uuid === userId;

	// ! ====== EDIT SERVICE  ======

	const handleEditService = () => {
		dispatch(setService(data));
		dispatch(setStep(2));
		dispatch(setModal(true));
	};

	// ! ========= SUB SERVICES ==========.

	const getServicesApi = useApi(servicesApi.getServices, true, true);

	const fetchServicesList = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, userId, parentId] = queryKey;
		const response = await getServicesApi.request(
			userId,
			pageParam,
			PAGE_SIZE,
			0,
			parentId
		);
		return response.data;
	};

	const {
		items,
		isFetchingNextPage: isFetchingNextSubServicesPage,
		hasNextPage,
		loadMore,
	} = useInfiniteScroll({
		queryKey: servicesKeys.servicesList(minifiedProfile?.uuid, serviceId),
		queryFn: fetchServicesList,
		pageSize: PAGE_SIZE,
		enabled: !!data && level !== 3,
	});

	const subServicesList = useMemo(() => {
		return items?.map((service) => (
			<Fragment key={service.uuid}>
				<ServiceCard
					data={service}
					onClick={() => navigate(routes.serviceTag(service.tag))}
					className={styles.custom_card}
				/>
			</Fragment>
		));
	}, [items]);

	// ! ========= PRODUCTS ==========

	const productsFilters = useSelector(
		(state) => state.filters.productsFilters
	);

	const searchProductsApi = useApi(searchApi.searchProducts, true, true);

	const fetchProducts = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, ___, serviceId] = queryKey;
		const response = await searchProductsApi.request(pageParam, PAGE_SIZE, {
			...productsFilters,
			productFilters: {
				...productsFilters.productFilters,
				serviceTags: [serviceId],
			},
		});
		return response.data;
	};

	const {
		items: products,
		isLoading: isLoadingProducts,
		fetchStatus: productsFetchStatus,
		viewRef,
		isFetchingNextPage,
	} = useInfiniteScroll({
		queryKey: servicesKeys.products(serviceId),
		queryFn: fetchProducts,
		pageSize: PAGE_SIZE,
		queryOptions: {
			getNextPageParam: (lastPage, pages) => {
				const nextPage =
					lastPage.list?.length === PAGE_SIZE
						? pages.length
						: undefined;
				return nextPage;
			},
		},
		enabled:
			!!data && tagProducts && productsCount > 0 && tab === "products",
	});

	const isFetchingProducts =
		productsFetchStatus !== "idle" && isLoadingProducts;

	const productData = products?.flatMap((page) => page.list);

	const productsList = useMemo(() => {
		return productData?.map((product, index) => {
			const isLastItem = index === productData.length - 1;

			return (
				<Fragment key={product.uuid}>
					<NewProductItem
						ref={isLastItem ? viewRef : null}
						product={product}
					/>

					{isLastItem && isFetchingNextPage && <ProductLoader />}
				</Fragment>
			);
		});
	}, [productData, viewRef, isFetchingNextPage]);

	// !================ MEDIA ==================

	const getServiceMediaApi = useApi(postApi.getServiceMedia, true, true);

	const fetchMedia = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, ___, serviceId] = queryKey;
		const response = await getServiceMediaApi.request(
			serviceId,
			pageParam,
			PAGE_SIZE
		);
		return response.data;
	};

	const {
		items: medias,
		isLoading: isLoadingMedias,
		fetchStatus: mediaFetchStatus,
		viewRef: mediaViewRef,
		isFetchingNextPage: isFetchingNextMedia,
	} = useInfiniteScroll({
		queryKey: servicesKeys.media(serviceId),
		queryFn: fetchMedia,
		pageSize: PAGE_SIZE,
		enabled: !!data && tagMedia && tab === "media",
	});

	const isFetchingMedia = mediaFetchStatus !== "idle" && isLoadingMedias;

	const mediasList = useMemo(
		() =>
			medias?.map((media, index) => {
				const isLastItem = index === medias.length - 1;
				return (
					<Fragment key={media.uuid}>
						<button
							ref={isLastItem ? mediaViewRef : null}
							className={styles.service_media_card}
							onClick={() => handleShowSlider(media)}
						>
							{media?.file?.mediaType === "VIDEO" && (
								<span className={styles.circle_wrapper}>
									<PlayIcon className={styles.play_icon} />
								</span>
							)}

							<LazyImage
								image={
									<img
										src={
											media?.file?.mediaType === "VIDEO"
												? media?.file?.thumbnail
												: media?.file?.url
										}
										alt={media?.file?.originalName}
									/>
								}
								inlineStyle={styles.service_media_image}
							/>
						</button>

						{isLastItem && isFetchingNextMedia && <MediaLoader />}
					</Fragment>
				);
			}),
		[medias, mediaViewRef, isFetchingNextMedia]
	);

	// !========= MEDIA SLIDER =============

	const [showSlider, setShowSlider] = useState(false);
	const [initialSlideIndex, setInitialSlideIndex] = useState(0);
	const [activeSlideIndex, setActiveSlideIndex] = useState(0);

	const handleShowSlider = (media) => {
		const fileIndex = medias.findIndex((item) => item.uuid === media.uuid);
		setInitialSlideIndex(fileIndex);
		setActiveSlideIndex(fileIndex);
		setShowSlider(true);
		window.history.pushState(null, "", routes.post(media.uuid));
	};

	const handleCloseSlider = () => {
		setShowSlider(false);
		setActiveSlideIndex(0);
		window.history.replaceState(
			null,
			"",
			`${routes.serviceTag(tag)}${
				isFromResources ? "?from=resources" : ""
			}`
		);
	};

	const filesArray = useMemo(
		() => medias?.map((item) => item.file),
		[medias]
	);

	useEffect(() => {
		if (isSuccess) {
			if (showSlider && activeSlideIndex > -1) {
				window.history.replaceState(
					null,
					"",
					`/post/${medias[activeSlideIndex].uuid}`
				);
			} else {
				handleCloseSlider();
			}
		}
	}, [activeSlideIndex, medias, isSuccess]);

	const handlePostUpdate = (reaction) => {
		queryClient.setQueryData(servicesKeys.media(serviceId), (oldData) => {
			return {
				...oldData,
				pages: oldData.pages.map((page) =>
					page.map((post) =>
						post.uuid === reaction.postId
							? {
									...post,
									reactionStats: reaction.reactionStats,
									profileReactionType:
										reaction.profileReactionType,
							  }
							: post
					)
				),
			};
		});
	};

	const invalidateMedias = () => {
		queryClient.invalidateQueries(servicesKeys.media(serviceId));
	};

	const { handleEdit } = useEditSubPost(
		postApi.updateMedia,
		invalidateMedias
	);

	const [showEditMediaModal, setShowEditMediaModal] = useState(false);
	const [editItem, setEditItem] = useState("");

	const handleShowEditMediaModal = (media) => {
		setShowEditMediaModal(true);
		setEditItem(media);
	};

	const handleDeleteMediaSuccess = () => {
		invalidateMedias();
		if (showSlider && medias.length === activeSlideIndex + 1) {
			//* decrement activeSlideIndex by 1 when you're deleting the last subPost
			setActiveSlideIndex((prevState) => prevState - 1);
		}
	};

	const {
		warningModal,
		setWarningModal,
		handleRequestMutate,
		activeItem,
		handleConfirmMutate,
	} = useMutate(postApi.deletePost, handleDeleteMediaSuccess);

	const handleDownload = () => {
		const postMedia = filesArray[activeSlideIndex];
		FileSaver.saveAs(`${postMedia.url}`, `${postMedia.originalName}`);
	};

	// !========= SIMILAR SERVICES ==========

	const { userCoordinates } = useUserLocation();

	const getSuggestedServicesApi = useApi(
		servicesApi.getSuggestedServices,
		true,
		true
	);

	const fetchSuggestedServices = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, ___, userCoordinates] = queryKey;
		const response = await getSuggestedServicesApi.request(
			pageParam,
			PAGE_SIZE,
			userCoordinates.lat,
			userCoordinates.lng
		);
		return response.data;
	};

	const {
		items: suggestedServices,
		viewRef: suggestedServicesViewRef,
		isLoading: isLoadingSuggestedServices,
		fetchStatus: suggestedServicesFetchStatus,
		isFetchingNextPage: isFetchingNextSuggestedServicesPage,
	} = useInfiniteScroll({
		queryKey: servicesKeys.suggested({
			lat: userCoordinates.lat,
			lng: userCoordinates.lng,
		}),
		queryFn: fetchSuggestedServices,
		pageSize: PAGE_SIZE,
		enabled: !!data && isFromResources,
	});

	const isFetchingSuggestedServices =
		suggestedServicesFetchStatus !== "idle" && isLoadingSuggestedServices;

	const suggestedServicesList = useMemo(() => {
		return suggestedServices?.map((service, index) => {
			const isLastItem = index === suggestedServices.length - 1;
			return (
				<Fragment key={service.uuid}>
					<ServiceCard
						ref={isLastItem ? suggestedServicesViewRef : null}
						data={service}
						onClick={() =>
							navigate(
								`${routes.serviceTag(service.tag)}${
									isFromResources ? "?from=resources" : ""
								}`
							)
						}
						className={styles.custom_card}
					/>
					{isLastItem && isFetchingNextSuggestedServicesPage && (
						<>
							<ServiceCardLoader className={styles.custom_card} />
							<ServiceCardLoader className={styles.custom_card} />
						</>
					)}
				</Fragment>
			);
		});
	}, [
		suggestedServices,
		isFetchingNextSuggestedServicesPage,
		suggestedServicesViewRef,
		isFromResources,
	]);

	// !=========================================

	const ServiceDetailsLoadingView = () => {
		//TODO >> need to also make the skeleton adapts to mobile screen
		return (
			<div className={styles.skeleton_container}>
				<Skeleton
					pt={{
						root: {
							style: { flexShrink: "0" },
						},
					}}
					size="164px"
					borderRadius={24}
				></Skeleton>
				<div className={styles.skeleton_container_content}>
					<Skeleton width={150}></Skeleton>
					<div className={styles.user_skeleton}>
						<Skeleton shape="circle" size={25}></Skeleton>
						<Skeleton width={150}></Skeleton>
					</div>
					<Skeleton width={"100%"}></Skeleton>
					<Skeleton width={"90%"}></Skeleton>
					<Skeleton width={"80%"}></Skeleton>
					<Skeleton width={"85%"}></Skeleton>
					<Skeleton width={"70%"}></Skeleton>
				</div>
			</div>
		);
	};

	return (
		<DefaultPage
			headerRightContent={
				<ContextHeader
					isLoading={isLoading}
					title={data?.name}
					rightSideContent={
						<ServicePopover
							service={data}
							maxLimitServices={maxLimitServices}
							maxLimitProducts={maxLimitProducts}
							onReport={() =>
								reportRef.current.showReportModal(serviceId)
							}
							onDelete={() =>
								handleRequestMutateDeleteService(data)
							}
							onEdit={handleEditService}
						/>
					}
				/>
			}
			leftSideChildren={<NavBar />}
			rightSideChildren={
				<div className={styles.container}>
					{isLoading ? (
						<ServiceDetailsLoadingView />
					) : (
						<div className={styles.content}>
							<div className={styles.service_details_container}>
								<LazyImage
									image={
										<img
											src={image?.file?.customName}
											alt={name}
										/>
									}
									customWrapper={styles.image_wrapper}
									width={width < 974 ? "100%" : 164}
									height={width < 974 ? "100%" : 164}
									inlineStyle={styles.image_container}
								/>
								<div className={styles.service_details}>
									<div className={styles.service_header}>
										<div
											className={
												styles.service_header_left
											}
										>
											<ProfilePic
												thumbnail={
													minifiedProfile
														?.profileImage?.image
												}
												type={minifiedProfile?.type}
												name={minifiedProfile?.name}
												w={25}
												h={25}
												nameClassName={
													styles.owner_name
												}
											/>
											<h3>{name}</h3>
										</div>

										{isServiceOwner ? (
											<RoundButton
												icon="edit"
												onClick={handleEditService}
											/>
										) : (
											<GaawkButton
												size={width < 974 && "small"}
												text="Chat with us"
												onClick={() =>
													handleDirectMessage(
														minifiedProfile
													)
												}
											/>
										)}
									</div>

									<ReadMore
										customStyle={styles.description}
										lines={3}
									>
										{description}
									</ReadMore>

									<div
										className={
											styles.service_details_footer
										}
									>
										<Tag itemName={`+${tag}`} />

										<p>
											Available:{" "}
											{worldWide && "Worldwide"}
											{locations
												.map(
													(location) => location.name
												)
												.join(", ")}
										</p>
									</div>
								</div>
							</div>

							{level !== 3 && subServicesList.length > 0 && (
								<>
									<div className={styles.services_wrapper}>
										<h3>Sub-Categories</h3>
										<div
											className={
												styles.services_container
											}
										>
											{subServicesList}
											{isFetchingNextSubServicesPage && (
												<>
													<ServiceCardLoader />
													<ServiceCardLoader />
												</>
											)}
										</div>
										{hasNextPage && (
											<div
												className={
													styles.gradient_overlay
												}
											/>
										)}
									</div>

									{hasNextPage && (
										<SeeMore
											onClick={loadMore}
											horizontal={false}
											text="Show more categories"
										/>
									)}
								</>
							)}

							<TabMenu
								menuItems={{
									products: "Products",
									media: "Media Posts",
								}}
								selectedTab={tab}
								onSelectedTabChanged={setTab}
							/>

							{tab === "products" && (
								<div className={styles.products_wrapper}>
									<div className={styles.products_container}>
										{productsCount > 0 &&
											productData.length === 0 &&
											isFetchingProducts && (
												<>
													<ProductLoader />
													<ProductLoader />
												</>
											)}

										{productsCount > 0 &&
											tagProducts &&
											productsList}
									</div>
									<NoResults
										visible={
											productsCount === 0 &&
											!isFetchingProducts
										}
										title={"Nothing Here Yet!"}
										text={
											"There are no products to show here!"
										}
									/>
								</div>
							)}

							{tab === "media" && (
								<>
									<div className={styles.media_container}>
										{medias.length === 0 &&
											isFetchingMedia && (
												<>
													<MediaLoader />
													<MediaLoader />
												</>
											)}

										{medias.length > 0 &&
											tagMedia &&
											mediasList}
									</div>
									<NoResults
										visible={
											medias.length === 0 &&
											!isFetchingMedia
										}
										title={"Nothing Here Yet!"}
										text={"There is no media to show here!"}
									/>

									<PostViewer
										show={showSlider}
										onClose={handleCloseSlider}
										post={medias[activeSlideIndex]}
										files={filesArray}
										isServiceDetails={true}
										postName={name}
										initialSlideIndex={initialSlideIndex}
										onActiveSlide={(index) => {
											setActiveSlideIndex(index);
										}}
										onPostUpdate={handlePostUpdate}
										isOwner={
											userId ===
											medias[activeSlideIndex]?.owner.uuid
										}
										onEdit={(post) =>
											handleShowEditMediaModal(post)
										}
										onDelete={(post) =>
											handleRequestMutate(post)
										}
										onShare={(post) =>
											postRef.current.sharePost(post)
										}
										onDownload={handleDownload}
									/>

									<EditMediaModal
										show={showEditMediaModal}
										onClose={() =>
											setShowEditMediaModal(false)
										}
										onReset={() => setEditItem("")}
										item={editItem}
										onConfirm={handleEdit}
										onToggle={(bool) =>
											setShowEditMediaModal(bool)
										}
									/>

									<WarningModal
										show={warningModal}
										headerText="Are you sure you want to delete this post?"
										warningText={
											<>
												Deleting this post will also be
												deleted from its parent post.
												<br />
												<br />
												This action cannot be undone.
											</>
										}
										cancelButtonText={"Cancel"}
										onCancelButtonClicked={() =>
											setWarningModal(false)
										}
										submitButtonText={"DELETE"}
										onSubmitButtonClicked={() =>
											handleConfirmMutate(activeItem.uuid)
										}
									/>

									<CreatePost
										ref={postRef}
										name={
											profile.type === "COMPANY"
												? profile.name
												: `${profile.firstName} ${profile.lastName}`
										}
										profileImg={profile.profileImage}
										type={profile.type}
										onCreate={() =>
											toast.success(
												"Post successfully shared!"
											)
										}
									/>
								</>
							)}

							{/* Only show similar services when coming from resources */}
							{isFromResources && (
								<div className={styles.services_wrapper}>
									<h3>Similar Services</h3>

									<div className={styles.services_container}>
										{isFetchingSuggestedServices && (
											<>
												<ServiceCardLoader
													className={
														styles.custom_card
													}
												/>
												<ServiceCardLoader
													className={
														styles.custom_card
													}
												/>
											</>
										)}
										{suggestedServicesList}
									</div>
								</div>
							)}

							<WarningModal
								show={warningModalDeleteService}
								headerText="Are you sure you want to delete this service? All associated sub-services will be deleted as well."
								warningText="This action cannot be undone."
								cancelButtonText={"Cancel"}
								onCancelButtonClicked={() =>
									setWarningModalDeleteService(false)
								}
								submitButtonText={"DELETE"}
								onSubmitButtonClicked={() =>
									handleConfirmMutateDeleteService(
										activeItemDeleteService.uuid
									)
								}
							/>

							<ReportModal ref={reportRef} category="service" />
							<ServiceEntry />
							<ProductEntry
								onInvalidate={() => {
									queryClient.invalidateQueries(
										servicesKeys.detailByTag(tag)
									);
									queryClient.invalidateQueries(
										servicesKeys.products(serviceId)
									);
								}}
							/>
						</div>
					)}
				</div>
			}
		/>
	);
};

export default NewServiceDetails;
