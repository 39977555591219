import styles from "./ApplyModal.module.scss";
import { useMemo, useRef, useState } from "react";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";
import TextArea from "components/Utils/SubComs/Inputs/TextArea/TextArea";
import { ReactComponent as DeleteIcon } from "images/deleteInMenu.svg";
import { classNames } from "primereact/utils";
import useApi from "hooks/useApi";
import vaultApi from "api/vault";
import { vaultKeys } from "queryKeys/vault-key-factory";
import { useCustomQuery } from "hooks/useCustomQuery";
import { useQueryClient } from "@tanstack/react-query";
import useMutate from "hooks/useMutate";
import jobApi from "api/job";
import JobInfoItem from "../JobsComponents/JobInfoItem/JobInfoItem";
import Tag from "components/Utils/SubComs/Tags/Tag";
import WarningModal from "components/Utils/GaawkModal/WarningModal";
import { RadioButton } from "primereact/radiobutton";
import NewGaawkModal from "components/Utils/NewGaawkModal/NewGaawkModal";

const itemsPerPage = 5;

const VaultFile = ({ item, onClick, checked, onDelete }) => {
	return (
		<button className={styles.file_item} onClick={onClick}>
			<div className={styles.file_item_info}>
				<RadioButton checked={checked} readOnly={true} />
				<div>{item.name}</div>
			</div>
			<div className={styles.delete_button} role={"button"} tabIndex={0} onClick={onDelete}>
				<DeleteIcon fill={"#000"} />
			</div>
		</button>
	);
};

const ApplyModal = ({ onClose, jobId, onApply }) => {
	const [showReviewStep, setShowReviewStep] = useState(false);

	const [showSuccessStep, setShowSuccessStep] = useState(false);

	const [useLoadedFile, setUseLoadedFile] = useState(false);

	const [useLoadedCoverFile, setUseLoadedCoverFile] = useState(false);

	const [selectedPortfolio, setSelectedPortfolio] = useState();

	const [selectedCover, setSelectedCover] = useState();

	const [portfolioFile, setPortfolioFile] = useState();

	const [portfolioError, setPortfolioError] = useState(false);

	const [coverFile, setCoverFile] = useState();

	const [coverError, setCoverError] = useState(false);

	const [coverText, setCoverText] = useState("");

	// const [selectedProfile, setSelectedProfile] = useState();

	const portfolioInput = useRef(null);
	const coverLetterInput = useRef(null);

	const queryClient = useQueryClient();
	// !=========DELETE FILE===========

	const { handleRequestMutate, handleConfirmMutate, setWarningModal, warningModal, activeItem } =
		useMutate(vaultApi.deleteItems, () => {
			if (activeItem.type === "portfolio") {
				queryClient.invalidateQueries(vaultKeys.portfolio());
			} else {
				queryClient.invalidateQueries(vaultKeys.coverLetters());
			}
		});

	// ! get portfolio =====

	const getPortfolioApi = useApi(vaultApi.getPortfolio, true, true);

	const fetchPortfolio = async () => {
		const response = await getPortfolioApi.request(0, itemsPerPage);
		return response.data;
	};

	const { data: portfolios, isFetching: isFetchingPortfolio } = useCustomQuery({
		queryKey: vaultKeys.portfolio(),
		queryFn: fetchPortfolio,
	});

	const portfolioList = useMemo(
		() =>
			portfolios?.map((item) => (
				<VaultFile
					key={item.uuid}
					item={item}
					onClick={() => {
						setSelectedPortfolio({
							uuid: item.uuid,
							name: item.name,
						});
						setUseLoadedFile(false);
						// setPortfolioFile();
					}}
					onDelete={(e) => {
						e.stopPropagation();
						handleRequestMutate({
							file: item,
							type: "portfolio",
						});
					}}
					checked={selectedPortfolio?.uuid === item.uuid}
				/>
			)),
		[portfolios, selectedPortfolio?.uuid]
	);

	// ! get cover letters ====
	const getCoverLetterApi = useApi(vaultApi.getCoverLetter, true, true);

	const fetchCoverLetters = async () => {
		const response = await getCoverLetterApi.request(0, itemsPerPage);
		return response.data;
	};

	const { data: coverLetters, isFetching: isFetchingCoverLetters } = useCustomQuery({
		queryKey: vaultKeys.coverLetters(),
		queryFn: fetchCoverLetters,
	});

	const coverLettersList = useMemo(
		() =>
			coverLetters?.map((item) => (
				<VaultFile
					key={item.uuid}
					item={item}
					onClick={() => {
						setSelectedCover({ uuid: item.uuid, name: item.name });
						setCoverText("");
						setUseLoadedCoverFile(false);
						// setCoverFile();
					}}
					onDelete={(e) => {
						e.stopPropagation();
						handleRequestMutate({
							file: item,
							type: "cover",
						});
					}}
					checked={selectedCover?.uuid === item.uuid}
				/>
			)),
		[coverLetters, selectedCover?.uuid]
	);

	// !====================

	const fileSizeCheck = (fileSize) => {
		if ((fileSize / (1024 * 1024)).toFixed(2) < 2) return true;
		else return false;
	};

	const handlePortfolioChange = (val) => {
		setSelectedPortfolio();
		const file = val.target.files[0];
		if (file) {
			if (fileSizeCheck(file.size)) {
				setPortfolioFile(file);
				setPortfolioError(false);
				setUseLoadedFile(true);
			} else {
				setPortfolioFile();
				setPortfolioError(true);
				setUseLoadedFile(false);
			}
		}
		val.target.value = "";
	};

	const handleCoverChange = (val) => {
		setSelectedCover();
		setCoverText("");
		const file = val.target.files[0];
		if (file) {
			if (fileSizeCheck(file.size)) {
				setCoverFile(file);
				setCoverError(false);
				setUseLoadedCoverFile(true);
			} else {
				setCoverFile();
				setCoverError(true);
				setUseLoadedCoverFile(false);
			}
		}
		val.target.value = "";
	};

	const handleCoverText = ({ target }) => {
		setCoverError();
		setCoverFile();
		setSelectedCover();
		setCoverText(target.value);
		setUseLoadedCoverFile(false);
	};

	const {
		action: { mutate: apply, isLoading: isLoadingApply },
	} = useMutate(jobApi.apply, () => {
		trackEvent(eventsDictionary.JOB.APPLY_DONE, { jobId });
		setShowReviewStep(false);
		setShowSuccessStep(true);
	});

	const handleApply = () => {
		const formData = new FormData();

		if (coverFile && useLoadedCoverFile) formData.append("coverLetterFile", coverFile);
		if (coverText) formData.append("coverLetterText", coverText);
		if (selectedCover) formData.append("coverLetterVaultItemId", selectedCover.uuid);

		if (portfolioFile && useLoadedFile) formData.append("portfolioFile", portfolioFile);
		if (selectedPortfolio) formData.append("portfolioVaultItemId", selectedPortfolio.uuid);
		// if (selectedProfile) formData.append("specialProfileId", selectedProfile.uuid);

		apply({
			jobId,
			formData,
		});
	};

	const handleClose = () => {
		if (showSuccessStep) onApply();
		onClose();
	};

	return (
		<>
			<NewGaawkModal
				visible={true}
				onHide={handleClose}
				onBack={
					showReviewStep && !showSuccessStep ? () => setShowReviewStep(false) : undefined
				}
				title={
					!showReviewStep && !showSuccessStep
						? "Apply for Job"
						: showReviewStep && !showSuccessStep
						? "Review Application"
						: "Thank you!"
				}
				children={
					<div className={styles.container}>
						{!showReviewStep && !showSuccessStep && (
							<>
								<div className={styles.input_wrapper}>
									<label>Attach Portfolio</label>
									{portfolios?.length > 0 && (
										<div className={styles.files_wrapper}>{portfolioList}</div>
									)}

									{!portfolioError && portfolioFile && (
										<div className={styles.loaded_item}>
											<VaultFile
												item={portfolioFile}
												onClick={() => {
													setSelectedPortfolio(undefined);
													setUseLoadedFile(true);
												}}
												checked={useLoadedFile}
												onDelete={(e) => {
													e.stopPropagation();
													setUseLoadedFile(false);
													setPortfolioFile(undefined);
												}}
											/>
										</div>
									)}

									<div className={styles.custom_upload_button}>
										<input
											ref={portfolioInput}
											type="file"
											accept=".pdf"
											key={portfolioFile?.lastModified}
											onChange={handlePortfolioChange}
											tabIndex="-1"
											style={{ display: "none" }}
										/>

										<GaawkButton
											severity={"secondary"}
											onClick={() => {
												portfolioInput.current.click();
											}}
											text={"+ Upload New Portfolio"}
										/>
										<div className={styles.text_info}>
											You can upload PDF files only. Max size 2MB.
										</div>
										{portfolioError && !selectedPortfolio && (
											<div
												className={classNames(
													styles.text_info,
													styles.error
												)}
											>
												Your file is bigger than 2MB
											</div>
										)}
									</div>
								</div>

								<div className={styles.input_wrapper}>
									<label>Add Cover Letter</label>
									<div className={styles.text_info}>
										Select / Upload Cover letter <span>OR</span> paste it in the
										box below.
										<br />
										PDF and MS Word files only. Max size 2MB.
									</div>
									{coverLetters?.length > 0 && (
										<div className={styles.files_wrapper}>
											{coverLettersList}
										</div>
									)}

									{!coverError && coverFile && (
										<div className={styles.loaded_item}>
											<VaultFile
												item={coverFile}
												onClick={() => {
													setSelectedCover(undefined);
													setUseLoadedCoverFile(true);
												}}
												checked={useLoadedCoverFile}
												onDelete={(e) => {
													e.stopPropagation();
													setUseLoadedCoverFile(false);
													setCoverFile(undefined);
												}}
											/>
										</div>
									)}

									<div className={styles.custom_upload_button}>
										<input
											type="file"
											key={coverFile?.lastModified}
											accept=".pdf, .doc, .docx"
											onChange={handleCoverChange}
											tabIndex="-1"
											ref={coverLetterInput}
											style={{ display: "none" }}
										/>

										<GaawkButton
											severity={"secondary"}
											onClick={() => {
												coverLetterInput.current.click();
											}}
											text={"+ Upload Cover Letter"}
										/>
									</div>

									{coverError && !selectedCover && (
										<div className={classNames(styles.text_info, styles.error)}>
											Your file is bigger than 2MB
										</div>
									)}

									<TextArea
										className={styles.textarea_wrapper}
										placeholder="Enter your text"
										value={coverText}
										onChange={handleCoverText}
									/>
								</div>

								<div className={styles.button_container}>
									<GaawkButton
										text={"Review Your Application"}
										className={styles.review_btn}
										onClick={() => {
											setShowReviewStep(true);
											trackEvent(eventsDictionary.JOB.APPLY_REVIEW, {
												jobId,
											});
										}}
									/>
								</div>
							</>
						)}

						{showReviewStep && (
							<div className={styles.review_container}>
								<JobInfoItem
									title="Portfolio"
									content={
										selectedPortfolio || portfolioFile ? (
											<Tag
												itemName={
													selectedPortfolio
														? selectedPortfolio.name
														: portfolioFile.name
												}
												customStyle={styles.tag}
											/>
										) : (
											<i>No portfolio attached</i>
										)
									}
								/>

								<JobInfoItem
									title="Cover Letter"
									content={
										selectedCover || coverFile ? (
											<Tag
												itemName={
													selectedCover
														? selectedCover.name
														: coverFile.name
												}
												customStyle={styles.tag}
											/>
										) : coverText ? (
											<p>{coverText}</p>
										) : (
											<i>No cover letter attached</i>
										)
									}
								/>

								{/* <JobInfoItem
                                title="Special Profile"
                                content={
                                    selectedProfile ? (
                                        <ProfilePic
                                            type={"USER"}
                                            thumbnail={
                                                selectedProfile.profilePic.file.customName
                                            }
                                            name={selectedProfile.name}
                                        />
                                    ) : (
                                        <i>No special profile attached</i>
                                    )
                                }
                            /> */}
								<div className={styles.button_container}>
									<GaawkButton
										text={"Apply"}
										className={styles.review_btn}
										onClick={handleApply}
										isLoading={isLoadingApply}
									/>
								</div>
							</div>
						)}

						{showSuccessStep && (
							<>
								<p>Your application for the job has been submitted successfully.</p>
								<div className={styles.button_container}>
									<GaawkButton
										text={"Done"}
										className={styles.review_btn}
										onClick={handleClose}
									/>
								</div>
							</>
						)}
					</div>
				}
			/>

			<WarningModal
				show={warningModal}
				headerText={`${activeItem?.file?.name} will be deleted for you and everyone you shared it with. Are you sure?`}
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={(e) => {
					e.stopPropagation();
					setWarningModal(false);
				}}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={(e) => {
					e.stopPropagation();
					handleConfirmMutate([activeItem?.file]);
				}}
			/>
		</>
	);
};

export default ApplyModal;
