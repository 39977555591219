import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
import styles from "./JobsLanding.module.scss";
import useWorkStatus from "hooks/useWorkStatus";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import useApi from "hooks/useApi";
import jobApi from "api/job";
import { jobsKeys } from "queryKeys/jobs-key-factory";
import useUrlParams from "hooks/useUrlParams";
import TabMenu from "components/Utils/SubComs/TabMenu/TabMenu";
import { useMemo, Fragment, useState, useEffect, useRef } from "react";
import JobLoader from "components/Utils/SubComs/CustomLoader/JobLoader";
import JobCard from "../JobsComponents/JobCard/JobCard";
import useMutate from "hooks/useMutate";
import { useQueryClient } from "@tanstack/react-query";
import useJob from "hooks/useJob";
import JobDetails from "../JobDetails/JobDetails";
import { ReactComponent as GaawkJobsLogo } from "images/gaawk-logos/gaawk-jobs.svg";
import { classNames } from "primereact/utils";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "components/Utils/Button/BackButton";
import { useNavigate } from "react-router-dom";
import useProject from "../EmployerLanding/hooks/useProject";
import ProjectDetails from "../ProjectDetails/ProjectDetails";
// import ProjectActions from "../JobsComponents/ProjectActions/ProjectActions";
import JobActions from "../JobsComponents/JobActions/JobActions";
import { useCustomQuery } from "hooks/useCustomQuery";
import StepProgressBar from "components/Utils/StepProgressBar/StepProgressBar";
import GaawkButton from "components/Utils/Button/GaawkButton";
import useFetchRoles from "hooks/useFetchRoles";
import { ReactComponent as CongratsIcon } from "images/congrats.svg";
import { ReactComponent as FiltersIcon } from "images/filters.svg";
import { ReactComponent as BookmarkIcon } from "images/bookmark.svg";
import { ReactComponent as JobIcon } from "images/job.svg";
import { ReactComponent as EditIcon } from "images/edit.svg";
import { ReactComponent as DotsIcon } from "images/dots.svg";
import { rolesKeys } from "queryKeys/roles-key-factory";
import {
	defaultFormatter,
	jobTitleFormatter,
} from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import MultiSelectInput from "components/Utils/SubComs/Inputs/MultiSelectInput/MultiSelectInput";
import {
	employementTypes,
	experienceLvlTypes,
	formatFiltersValues,
} from "components/Utils/General";
import workStatusApi from "api/workStatus";
import { workStatusKeys } from "queryKeys/workStatus-key-factory";
import useCurrentUser from "hooks/useCurrentUser";
// import SearchInput from "components/Utils/SubComs/Inputs/SearchInput/SearchInput";
import useDebounce from "hooks/useDebounce";
import searchApi from "api/search";
import { searchKeys } from "queryKeys/search-key-factory";
import useUserLocation from "hooks/useUserLocation";
import CityModal from "components/Boards/BoardsComponents/CityModal";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import JobsFilter from "components/Search/FilterTabs/JobsFilter";
import { initialState, updateJobsFilters } from "store/slices/filters";
import SelectSearchInput from "components/Utils/SubComs/Inputs/SelectSearchInput/SelectSearchInput";
import Popover from "components/Utils/Popover/Popover";
import routes from "components/Routing/routing-keys";
import UpdateWorkStatus from "components/Profile/WorkStatusTab/UpdateWorkStatus";
import Tag from "components/Utils/SubComs/Tags/Tag";
import ScrollContainer from "react-indiana-drag-scroll";
import NewMultiCheckbox from "components/Utils/NewMultiCheckBox/NewMultiCheckBox";
import NewGaawkModal from "components/Utils/NewGaawkModal/NewGaawkModal";

const PAGE_SIZE = 20;
const itemsLimit = 10;

const JobsLanding = () => {
	const popoverRef = useRef(null);

	const navigate = useNavigate();
	const { uuid } = useCurrentUser();

	const dispatch = useDispatch();

	const isMobile = useSelector((state) => state.ui.isMobile);

	const { userCoordinates, handleSelectedCity } = useUserLocation();

	const hasSearchFetchedRef = useRef(false);

	// !---- url params handlers -----

	const paramConfigs = {
		tab: {
			validator: (tab) =>
				["long_term", "freelance", "casting"].includes(tab),
			defaultValue: "long_term",
		},
		projectTab: {
			validator: (tab) =>
				["description", "long_term", "freelance", "casting"].includes(
					tab
				),
			defaultValue: "",
		},
		projectId: {
			validator: (id) => typeof id === "string",
			defaultValue: "",
		},
		projectJobId: {
			validator: (id) => typeof id === "string",
			defaultValue: "",
		},
		jobId: {
			validator: (id) => typeof id === "string",
			defaultValue: "",
		},
		q: {
			validator: (query) => typeof query === "string",
			defaultValue: "",
		},
		activeSection: {
			validator: (section) =>
				["suggested", "recommended"].includes(section),
			defaultValue: "",
		},
	};

	const { params, setParams } = useUrlParams(paramConfigs);

	const {
		tab,
		jobId,
		projectTab,
		projectId,
		projectJobId,
		q,
		activeSection,
	} = params;

	const tabNumericValue =
		tab === "long_term" ? 1 : tab === "freelance" ? 2 : 3;

	const handleTabChange = (tab) => {
		setParams({ tab });
	};

	const handleBackButton = () => {
		if (projectJobId) {
			setParams({ projectJobId: "" });
		} else if (projectId) {
			setParams({ projectId: "" });
		} else if (isMobile && jobId) {
			setParams({ jobId: "", activeSection: "" });
		} else {
			navigate(-1);
		}
	};

	// !---- PREFERENCES MODALS  -----

	const [showPreferencesModal, setShowPreferencesModal] = useState(false);

	const handleUpdatePreferences = () => {
		setShowPreferencesModal(false);
		if (activeSection === "suggested" || activeSection === "recommended") {
			queryClient.invalidateQueries(
				jobsKeys.recommended(tabNumericValue, userCoordinates)
			);
			queryClient.invalidateQueries(jobsKeys.suggested(tabNumericValue));
		} else {
			queryClient.invalidateQueries(searchKeys.jobs(filtersToApply));
		}
	};

	// !---- FILTERS MODALS  -----

	const [showFiltersModal, setShowFiltersModal] = useState(false);

	const jobsFiltersObject = useSelector((state) => state.filters.jobsFilters);

	const storedJobsFilters = useMemo(
		() => ({
			...jobsFiltersObject,
			q: q || "",
			locationId: userCoordinates.id,
			jobType: tabNumericValue,
		}),
		[jobsFiltersObject, q, tabNumericValue, userCoordinates.id]
	);

	const initialStoredFilters = useRef(storedJobsFilters);

	// const [filtersToApply, setFiltersToApply] = useState(storedJobsFilters);
	const [filtersToApply, setFiltersToApply] = useState(() =>
		q ? storedJobsFilters : undefined
	);

	useEffect(() => {
		if (filtersToApply) {
			setFiltersToApply((prevState) => ({
				...prevState,
				jobType: tabNumericValue,
			}));
		}
	}, [tabNumericValue]);

	useEffect(() => {
		if (filtersToApply) {
			setFiltersToApply((prevState) => ({
				...prevState,
				locationId: userCoordinates.id,
			}));
		}
	}, [userCoordinates.id]);

	const tagsList = useMemo(() => {
		const valuesList = [];

		for (const [k, v] of Object.entries(filtersToApply || {})) {
			if (k === "skillIds" && Array.isArray(v) && v?.length > 0) {
				//* only for skillIds (the only array in the root of the jobsFilters object)
				v.map((item) =>
					valuesList.push(
						<Tag
							key={item.value}
							itemName={item.label}
							customStyle={styles.custom_tag}
							onRemove={() => {
								const updatedFilters = {
									...filtersToApply,
									skillIds: filtersToApply.skillIds.filter(
										(skill) => skill.value !== item.value
									),
								};
								setFiltersToApply(updatedFilters);
								dispatch(updateJobsFilters(updatedFilters));
							}}
						/>
					)
				);
			} else if (k === "castingCallRequest") {
				for (const [key, value] of Object.entries(v)) {
					// * only for ethnicityIds and languageIds (dynamic filters present in the castingCallRequest object)
					if (Array.isArray(value) && value?.length > 0) {
						value.map((item) =>
							valuesList.push(
								<Tag
									key={item.value}
									itemName={item.label}
									customStyle={styles.custom_tag}
									onRemove={() => {
										const updatedFilters = {
											...filtersToApply,
											castingCallRequest: {
												...filtersToApply.castingCallRequest,
												...(key === "ethnicityIds" && {
													ethnicityIds:
														filtersToApply.castingCallRequest.ethnicityIds.filter(
															(ethnicity) =>
																ethnicity.value !==
																item.value
														),
												}),
												...(key === "languageIds" && {
													languageIds:
														filtersToApply.castingCallRequest.languageIds.filter(
															(language) =>
																language.value !==
																item.value
														),
												}),
											},
										};
										setFiltersToApply(updatedFilters);
										dispatch(
											updateJobsFilters(updatedFilters)
										);
									}}
								/>
							)
						);
					}
				}
			}
		}
		return valuesList;
	}, [dispatch, filtersToApply]);

	// !------------- TOGGLE SAVE JOB -----------------
	const queryClient = useQueryClient();

	const {
		action: { mutate: toggleSave },
	} = useMutate(jobApi.toggleSaveJob, (response, variables) => {
		const { jobId, isSearch } = variables;

		if (isSearch) {
			queryClient.setQueryData(
				searchKeys.jobs(filtersToApply),
				(oldData) => {
					return {
						...oldData,
						pages: oldData.pages.map((page) => ({
							count: page.count,
							list: page.list.map((job) =>
								job.uuid === jobId
									? { ...job, savedJob: !job.savedJob }
									: job
							),
						})),
					};
				}
			);
		} else {
			queryClient.setQueryData(
				jobsKeys.recommended(tabNumericValue, userCoordinates),
				(oldData) => {
					return {
						...oldData,
						pages: oldData.pages.map((page) =>
							page.map((job) =>
								job.uuid === jobId
									? { ...job, savedJob: !job.savedJob }
									: job
							)
						),
					};
				}
			);
			queryClient.setQueryData(
				jobsKeys.suggested(tabNumericValue),
				(oldData) => {
					return {
						...oldData,
						pages: oldData.pages.map((page) =>
							page.map((job) =>
								job.uuid === jobId
									? { ...job, savedJob: !job.savedJob }
									: job
							)
						),
					};
				}
			);
		}
	});

	const handleApplyInvalidation = () => {
		if (activeSection === "suggested" || activeSection === "recommended") {
			queryClient.setQueryData(
				jobsKeys.recommended(tabNumericValue, userCoordinates),
				(oldData) => {
					return {
						...oldData,
						pages: oldData.pages.map((page) =>
							page.map((job) =>
								job.uuid === jobId
									? { ...job, appliedTime: true }
									: job
							)
						),
					};
				}
			);
			queryClient.setQueryData(
				jobsKeys.suggested(tabNumericValue),
				(oldData) => {
					return {
						...oldData,
						pages: oldData.pages.map((page) =>
							page.map((job) =>
								job.uuid === jobId
									? { ...job, appliedTime: true }
									: job
							)
						),
					};
				}
			);
		} else {
			queryClient.setQueryData(
				searchKeys.jobs(filtersToApply),
				(oldData) => {
					return {
						...oldData,
						pages: oldData.pages.map((page) => ({
							count: page.count,
							list: page.list.map((job) =>
								job.uuid === jobId
									? { ...job, appliedTime: true }
									: job
							),
						})),
					};
				}
			);
		}
	};

	// !===================== SELECTED JOB =========================

	const { data: job, isFetching: isFetchingJob } = useJob(jobId);

	const { data: projectJob, isFetching: isFetchingProjectJob } =
		useJob(projectJobId);

	// !===================== FETCH PROJECT DETAILS ================

	const {
		data: projectData,
		isFetching: isFetchingProject,
		// isLoading: isLoadingProject,
		// fetchStatus: projectFetchStatus,
	} = useProject(job?.project?.uuid);

	// !============================================================

	//*  A. GET THE WORK STATUS

	const {
		data: workStatusData,
		// isLoading: isLoadingWorkStatus,
		isSuccess: isSuccessWorkStatus,
	} = useWorkStatus(uuid);

	//! SUGGESTED JOBS ========================

	//~     1) if there are roles in the work status, hit the job/suggested + job/recommended endpoints

	const getSuggestedJobsApi = useApi(jobApi.getSuggestedJobs, true, true);

	const fetchSuggestedJobs = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, ___, jobTypeId] = queryKey;
		const response = await getSuggestedJobsApi.request(
			pageParam,
			PAGE_SIZE,
			jobTypeId
		);
		return response.data;
	};

	const {
		items: suggestedItems,
		viewRef,
		isFetchingNextPage,
		fetchStatus: fetchStatusSuggested,
		isLoading: isLoadingSuggested,
	} = useInfiniteScroll({
		queryKey: jobsKeys.suggested(tabNumericValue),
		queryFn: fetchSuggestedJobs,
		pageSize: PAGE_SIZE,
		enabled:
			isSuccessWorkStatus &&
			workStatusData.roles.length > 0 &&
			!q &&
			!hasSearchFetchedRef.current,
	});

	const suggestedJobsList = useMemo(
		() =>
			suggestedItems?.map((job, index) => {
				const isLastItem = index === suggestedItems.length - 1;
				const isActive =
					jobId === job.uuid && activeSection === "suggested";
				return (
					<Fragment key={job.uuid}>
						<JobCard
							ref={isLastItem ? viewRef : null}
							isActive={isActive}
							data={job}
							onClick={() => {
								setParams({
									jobId: job.uuid,
									activeSection: "suggested",
								});
							}}
							onToggleSave={() => {
								toggleSave({
									jobId: job.uuid,
									state: !job.savedJob,
								});
							}}
						/>

						{isLastItem && isFetchingNextPage && (
							<div className={styles.loading_item_container}>
								<JobLoader />
							</div>
						)}
					</Fragment>
				);
			}),
		[suggestedItems, viewRef, jobId, activeSection]
	);

	//! RECOMMENDED JOBS ========================

	const getRecommendedJobsApi = useApi(jobApi.getRecommendedJobs, true, true);

	const fetchRecommendedJobs = async ({ pageParam = 0, queryKey }) => {
		const [_, __, ___, jobTypeId, userCoordinates] = queryKey;
		const response = await getRecommendedJobsApi.request(
			pageParam,
			PAGE_SIZE,
			jobTypeId,
			userCoordinates.lat,
			userCoordinates.lng
		);
		return response.data;
	};

	const {
		items: recommendedItems,
		fetchStatus: fetchStatusRecommended,
		viewRef: viewRefRecommended,
		isFetchingNextPage: isFetchingNextPageRecommended,
		isLoading: isLoadingRecommended,
	} = useInfiniteScroll({
		queryKey: jobsKeys.recommended(tabNumericValue, userCoordinates),
		queryFn: fetchRecommendedJobs,
		pageSize: PAGE_SIZE,
		enabled:
			isSuccessWorkStatus &&
			workStatusData.roles.length > 0 &&
			!q &&
			!hasSearchFetchedRef.current,
	});

	const recoList = useMemo(
		() =>
			recommendedItems?.map((job, index) => {
				const isLastItem = index === recommendedItems.length - 1;
				const isActive =
					jobId === job.uuid && activeSection === "recommended";

				return (
					<Fragment key={job.uuid}>
						<JobCard
							ref={isLastItem ? viewRefRecommended : null}
							data={job}
							isActive={isActive}
							onClick={() => {
								setParams({
									jobId: job.uuid,
									activeSection: "recommended",
								});
							}}
							onToggleSave={() =>
								toggleSave({
									jobId: job.uuid,
									state: !job.savedJob,
									isReco: true,
								})
							}
						/>
						{isLastItem && isFetchingNextPageRecommended && (
							<div className={styles.loading_item_container}>
								<JobLoader />
							</div>
						)}
					</Fragment>
				);
			}),
		[recommendedItems, viewRefRecommended, jobId, activeSection]
	);

	// ! ============ fetch last search filters ============

	//~     2) if no roles in workStatus, hit the job/last-search-filter endpoint. If there's a query `q` with a value, go to the jobs landing and hit the jobs/search with the last-searched filters object
	const getLastSearchFiltersApi = useApi(
		jobApi.getLastSearchFilters,
		true,
		true
	);

	const fetchLastFilters = async () => {
		const response = await getLastSearchFiltersApi.request();
		return response.data;
	};

	const {
		data: lastFilters,
		// isFetching: isFetchingLastFilters,
		isSuccess: isSuccessLastFilters,
	} = useCustomQuery({
		queryKey: jobsKeys.lastSearchFilters(),
		queryFn: fetchLastFilters,
		enabled: isSuccessWorkStatus && workStatusData.roles.length === 0,
	});

	//! ============ onboarding modal ============

	const fetchRoles = useFetchRoles();

	const [onboardingModal, setOnboardingModal] = useState(false);
	const [onboardingStep, setOnboardingStep] = useState(1);
	const [onBoardingSettings, setOnBoardingSettings] = useState({
		roles: [],
		experienceLvl: [],
		jobType: [],
	});

	useEffect(() => {
		if (isSuccessLastFilters && lastFilters.q === "") {
			//~     3) a) if no query `q`, show the onboarding modal
			setOnboardingModal(true);
		} else if (isSuccessLastFilters && lastFilters.q !== "") {
			//~     3) b) IF THERE'S A QUERY Q, THEN PUT IT IN THE URL (SHOULD TRIGGER THE JOB SEARCH ENDPOINT)
			setParams({ q: lastFilters.q });
			setFiltersToApply(lastFilters);
		}
	}, [lastFilters, isSuccessLastFilters]);

	const handleSelectedRole = (role) => {
		setOnBoardingSettings((prevState) => ({
			...prevState,
			roles: [...prevState.roles, role],
		}));
	};

	const handleRemoveRole = (roleIndex) => {
		setOnBoardingSettings((prevState) => ({
			...prevState,
			roles: prevState.roles.filter((_, index) => index !== roleIndex),
		}));
	};

	const handleSelectExperienceLvl = (selectedExperience) => {
		setOnBoardingSettings((prevState) => ({
			...prevState,
			experienceLvl: selectedExperience,
		}));
	};

	const handleSelectJobType = (jobTypes) => {
		setOnBoardingSettings((prevState) => ({
			...prevState,
			jobType: jobTypes,
		}));
	};

	const {
		action: { mutate: updateWorkStatus },
	} = useMutate(workStatusApi.updateWorkStatus, () =>
		queryClient.invalidateQueries(workStatusKeys.getWorkStatus(uuid))
	);

	const handleStep3 = () => {
		updateWorkStatus({
			body: {
				roleIds: onBoardingSettings.roles.map((role) => role.value),
				jobTypes: onBoardingSettings.jobType.map((type) => type.value),
				experienceLevels: onBoardingSettings.experienceLvl.map(
					(level) => level.value
				),
			},
		});
		setOnboardingStep(4);
	};

	//! ================ SEARCH JOB =====================

	const [searchQuery, setSearchQuery] = useState("");

	const debouncedQuery = useDebounce(searchQuery);

	const searchJobTitlesApi = useApi(searchApi.searchJobTitles, true, true);

	const searchJobTitles = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, q] = queryKey;
		const response = await searchJobTitlesApi.request(
			pageParam,
			PAGE_SIZE,
			q
		);
		return response.data;
	};

	const {
		items: jobTitles,
		loadMore,
		isFetching,
		// viewRef,
		// isFetchingNextPage,
		// isLoading
	} = useInfiniteScroll({
		queryKey: searchKeys.jobTitles(debouncedQuery),
		queryFn: searchJobTitles,
		pageSize: PAGE_SIZE,
	});

	const [showLocationModal, setShowLocationModal] = useState(false);

	const searchJobsApi = useApi(jobApi.search, true, true);

	const fetchSearchedJobs = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, filters] = queryKey;
		const response = await searchJobsApi.request(
			pageParam,
			PAGE_SIZE,
			formatFiltersValues(filters)
		);
		return response.data;
	};

	const {
		// data: dataJobs,
		items: searchedItems,
		// isFetching,
		// isSuccess: isSuccessSearch,
		isFetchingNextPage: isFetchingNextPageSearch,
		viewRef: viewRefSearch,
		isLoading: isLoadingSearch,
		fetchStatus: fetchStatusSearch,
		isSuccess: isSearchSuccess,
		// errorComponent,
	} = useInfiniteScroll({
		queryKey: searchKeys.jobs(filtersToApply),
		queryFn: fetchSearchedJobs,
		pageSize: PAGE_SIZE,
		queryOptions: {
			getNextPageParam: (lastPage, pages) => {
				const nextPage =
					lastPage.list?.length === PAGE_SIZE
						? pages.length
						: undefined;
				return nextPage;
			},
		},
		enabled: !!filtersToApply,
	});

	useEffect(() => {
		if (isSearchSuccess) {
			hasSearchFetchedRef.current = true;
			setParams({ activeSection: "" });
		}
	}, [isSearchSuccess]);

	const searchedJobs = searchedItems?.flatMap((page) => page.list);

	const searchedJobsList = useMemo(
		() =>
			searchedJobs?.map((job, index) => {
				const isLastItem = index === searchedJobs.length - 1;
				const isActive = jobId === job.uuid;

				return (
					<Fragment key={job.uuid}>
						<JobCard
							ref={isLastItem ? viewRefSearch : null}
							data={job}
							onToggleSave={() =>
								toggleSave({
									jobId: job.uuid,
									state: !job.savedJob,
									isSearch: true,
								})
							}
							isActive={isActive}
							onClick={() => setParams({ jobId: job.uuid })}
						/>
						{isLastItem && isFetchingNextPageSearch && (
							<div className={styles.loading_item_container}>
								<JobLoader />
							</div>
						)}
					</Fragment>
				);
			}),
		[searchedJobs, viewRefSearch, jobId]
	);

	//! =====================================
	return (
		<>
			<DefaultPage
				headerRightContent={
					<>
						<div className={styles.header_bar}>
							<BackButton onBack={handleBackButton} />
							{projectId && !projectJobId ? "Project" : "Jobs"}
						</div>

						<div className={styles.header_right_side}>
							<button onClick={() => setShowLocationModal(true)}>
								<img
									src={userCoordinates?.countryDTO?.flagUrl}
									alt=""
								/>
							</button>
							{!isFetchingJob && job && !projectId && (
								<JobActions job={job} />
							)}
						</div>
					</>
				}
				hideLeftSide={isMobile && jobId}
				hideRightSide={isMobile && !jobId}
				enableCollapsedNavBar
				hybridNavigation
				// disableScrollbar
				leftSideChildren={
					<div className={styles.left_side}>
						<div className={styles.title_wrapper}>
							<h3>Find Your Dream Job 🗞️</h3>
							<Popover
								ref={popoverRef}
								render={
									<div className="popover_container">
										<button
											className={`popover_item`}
											onClick={() => {
												setShowPreferencesModal(true);
												popoverRef.current.closePopover();
											}}
										>
											<EditIcon width={20} />

											<span>Preferences</span>
										</button>
										<button
											className={`popover_item`}
											onClick={() => {
												navigate(routes.savedJobs);
												popoverRef.current.closePopover();
											}}
										>
											<BookmarkIcon width={20} />
											<span>Saved Jobs</span>
										</button>

										<button
											className={`popover_item`}
											onClick={() => {
												navigate(routes.appliedJobs);
												popoverRef.current.closePopover();
											}}
										>
											<JobIcon width={20} fill={"#000"} />
											<span>Jobs you applied to</span>
										</button>
									</div>
								}
							>
								<button>
									<DotsIcon />
								</button>
							</Popover>
						</div>
						<div className={styles.header}>
							<SelectSearchInput
								value={q ? { value: q, label: q } : null}
								onChange={(option) => {
									setParams({
										q: option ? option.value : "",
									});
									setFiltersToApply({
										...storedJobsFilters,
										q: option ? option.value : "",
									});
								}}
								isLoading={isFetching}
								onInputChange={setSearchQuery}
								onMenuScrollToBottom={loadMore}
								options={jobTitles.map(jobTitleFormatter)}
							/>

							{/* <CustomSelect
								className={styles.search_input}
								value={q ? { value: q, label: q } : null}
								onChange={(option) => {
									setParams({ q: option ? option.value : "" });
									setFiltersToApply({
										...storedJobsFilters,
										q: option ? option.value : "",
									});
								}}
								onInputChange={setSearchQuery}
								onMenuScrollToBottom={loadMore}
								isClearable
								isLoading={isFetching}
								options={jobTitles.map(jobTitleFormatter)}
								placeholder={"Search"}
								components={{
									DropdownIndicator: () => null,
									IndicatorSeparator: () => null,
									Option: (props) => {
										const searchItem = props.data;
										return (
											<components.Option {...props}>
												<div className={styles.search_item}>
													{searchItem.label}
												</div>
											</components.Option>
										);
									},
								}}
							/> */}

							<button
								className={styles.filter_btn}
								type={"button"}
								onClick={() => setShowFiltersModal(true)}
							>
								<FiltersIcon />
							</button>
						</div>

						{tagsList?.length > 0 && (
							<ScrollContainer
								className={styles.scroll_container}
							>
								{tagsList}
							</ScrollContainer>
						)}

						<div className={styles.menu}>
							<TabMenu
								menuItems={{
									long_term: "Job Openings",
									freelance: "Freelancing",
									casting: "Casting Calls",
								}}
								selectedTab={tab}
								onSelectedTabChanged={handleTabChange}
							/>
						</div>

						{/* //* IF THERE'S NO QUERY and THERE ARE ROLES IN THE WORKSTATUS, SHOW THE SUGGESTED JOBS */}
						{!filtersToApply && (
							<>
								<div className={styles.jobs_container}>
									<h3>Jobs based on your preferences</h3>

									{fetchStatusSuggested !== "idle" &&
										isLoadingSuggested && (
											<div
												className={
													styles.loading_item_container
												}
											>
												<JobLoader />
												<JobLoader />
											</div>
										)}

									{suggestedItems.length > 0 &&
										!isLoadingSuggested &&
										suggestedJobsList}

									<NoResults
										visible={
											!isLoadingSuggested &&
											suggestedItems.length === 0
										}
										title={"Nothing Found!"}
									/>
								</div>

								<div className={styles.jobs_container}>
									<h3>Recommended Jobs</h3>

									{fetchStatusRecommended !== "idle" &&
										isLoadingRecommended && (
											<div
												className={
													styles.loading_item_container
												}
											>
												<JobLoader />
												<JobLoader />
											</div>
										)}

									{recommendedItems.length > 0 &&
										!isLoadingRecommended &&
										recoList}

									<NoResults
										visible={
											!isLoadingRecommended &&
											recommendedItems.length === 0
										}
										title={"Nothing Found!"}
									/>
								</div>
							</>
						)}
						{/* //* IF THERE'S A QUERY Q, SHOW THE SEARCHED JOBS */}
						{filtersToApply && (
							<div className={styles.jobs_container}>
								<h3>Search Results</h3>

								{fetchStatusSearch !== "idle" &&
									isLoadingSearch && (
										<div
											className={
												styles.loading_item_container
											}
										>
											<JobLoader />
											<JobLoader />
										</div>
									)}

								{searchedJobs.length > 0 &&
									!isLoadingSearch &&
									searchedJobsList}

								<NoResults
									visible={
										!isLoadingSearch &&
										searchedJobs.length === 0
									}
									title={"Nothing Found!"}
									text={
										"Try refining your search of browse different categories for more opportunities"
									}
								/>

								{/* {searchedJobs.length > 0 ? (
									searchedJobsList
								) : (
									<NoResults
										visible
										title={"Nothing Found!"}
										text={
											"Try refining your search of browse different categories for more opportunities"
										}
									/>
								)} */}
							</div>
						)}
					</div>
				}
				rightSideChildren={
					<div className={styles.container}>
						<div
							className={classNames(styles.right_side, {
								[styles.align_middle]: !jobId,
							})}
						>
							{jobId ? (
								projectId ? (
									projectJobId ? (
										<JobDetails
											job={projectJob}
											isLoading={isFetchingProjectJob}
											onProjectClick={() =>
												setParams({
													projectId:
														job?.project?.uuid,
													projectJobId: "",
												})
											}
										/>
									) : (
										<ProjectDetails
											project={projectData}
											isLoading={
												isFetchingJob ||
												isFetchingProject
											}
											activeTab={
												projectTab || "description"
											}
											onTabChange={(projectTab) =>
												setParams({ projectTab })
											}
											onJobClick={(job) => {
												setParams({
													projectJobId: job.uuid,
													projectTab: "",
												});
											}}
										/>
									)
								) : (
									<JobDetails
										job={job}
										isLoading={isFetchingJob}
										onProjectClick={() =>
											setParams({
												projectId: job?.project?.uuid,
											})
										}
										onApply={handleApplyInvalidation}
									/>
								)
							) : (
								<GaawkJobsLogo />
							)}
						</div>
					</div>
				}
			/>

			{/* //* ONBOARDING MODAL FLOW */}

			<NewGaawkModal
				visible={onboardingModal}
				title={"Find Your Dream Job 🗞️"}
				showCloseButton={false}
				closeOnEscape={false}
				children={
					<div
						className={classNames(styles.modal_container, {
							[styles.step_one]: onboardingStep === 1,
						})}
					>
						<h3>Three Steps to Kickstart Your Job Search: </h3>
						<StepProgressBar currentStep={onboardingStep} />

						{onboardingStep === 1 && (
							<MultiSelectInput
								label={
									"1. What job titles are you searching for?"
								}
								creatable={true}
								queryName={rolesKeys.search}
								queryFn={fetchRoles}
								itemsPerPage={PAGE_SIZE}
								formatter={defaultFormatter}
								data={onBoardingSettings.roles}
								limit={itemsLimit}
								onChange={handleSelectedRole}
								onRemoveItem={handleRemoveRole}
								infoText={`You can add up to ${itemsLimit} roles.`}
								enableTagContainerLabel={false}
								customStyle={styles.multi_select_input_wrapper}
								wrapperClassName={
									styles.multi_select_input_wrapper
								}
								borderTagContainer={false}
							/>
						)}
						{onboardingStep === 2 && (
							<NewMultiCheckbox
								title={
									"2. Experience level and desired seniority."
								}
								options={experienceLvlTypes}
								perRow={"2, auto"}
								onSelect={handleSelectExperienceLvl}
								selected={onBoardingSettings.experienceLvl}
							/>
						)}
						{onboardingStep === 3 && (
							<NewMultiCheckbox
								title={
									"3. What type of job you’re looking for?"
								}
								perRow={"2, auto"}
								options={employementTypes.flatMap((job) =>
									job.value !== "APPRENTICESHIP" &&
									job.value !== "SELF_EMPLOYED" &&
									job.value !== "FREELANCE" &&
									job.value !== "CASTING_CALLS"
										? job
										: []
								)}
								onSelect={handleSelectJobType}
								selected={onBoardingSettings.jobType.map(
									(type) =>
										employementTypes.find(
											(jobType) =>
												jobType.value === type.value
										)
								)}
							/>
						)}
						{onboardingStep === 4 && (
							<div className={styles.last_step_container}>
								<CongratsIcon />
								<h3>Wish You Luck</h3>
								<p>
									Congrats. Your preferences will also be
									updated in your profile - work status.
								</p>
							</div>
						)}

						<div className={styles.modal_buttons}>
							{onboardingStep > 1 && onboardingStep < 4 && (
								<GaawkButton
									text={"Previous"}
									severity={"tertiary"}
									onClick={() =>
										setOnboardingStep(
											(prevState) => prevState - 1
										)
									}
								/>
							)}
							<GaawkButton
								text={
									onboardingStep === 4 ? "Done" : "Next Step"
								}
								onClick={() =>
									onboardingStep === 4
										? setOnboardingModal(false)
										: onboardingStep === 3
										? handleStep3()
										: setOnboardingStep(
												(prevState) => prevState + 1
										  )
								}
								disabled={
									(onboardingStep === 1 &&
										onBoardingSettings.roles.length ===
											0) ||
									(onboardingStep === 2 &&
										onBoardingSettings.experienceLvl
											.length === 0) ||
									(onboardingStep === 3 &&
										onBoardingSettings.jobType.length === 0)
								}
							/>
						</div>
					</div>
				}
			/>

			{/* //* FILTERS MODAL */}

			<NewGaawkModal
				visible={showFiltersModal}
				onHide={() => {
					setShowFiltersModal(false);
					dispatch(
						updateJobsFilters(
							filtersToApply ?? initialStoredFilters.current
						)
					);
				}}
				title={"Filter Jobs"}
				children={
					<JobsFilter
						// ref={jobFiltersRef} //TODO !
						userCoordinates={userCoordinates}
						onApplyFilters={() => {
							setFiltersToApply(storedJobsFilters);
							setShowFiltersModal(false);
						}}
						onClearFilters={() =>
							setFiltersToApply({
								...initialState.jobsFilters,
								q: q || "",
								jobType: tabNumericValue,
								locationId: userCoordinates.id,
							})
						}
					/>
				}
			/>

			<NewGaawkModal
				visible={showPreferencesModal}
				onHide={() => setShowPreferencesModal(false)}
				title={"Preferences / Work Status"}
				children={
					<UpdateWorkStatus
						onSubmitSuccess={handleUpdatePreferences}
						isModal
					/>
				}
			/>

			{showLocationModal && (
				<CityModal
					show={showLocationModal}
					onClose={() => setShowLocationModal(false)}
					onSelectCity={handleSelectedCity}
				/>
			)}
		</>
	);
};

export default JobsLanding;
