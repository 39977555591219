import { useQuery } from "@tanstack/react-query";
import jobApi from "api/job";
import useApi from "hooks/useApi";
import { jobsKeys } from "queryKeys/jobs-key-factory";

const useJob = (jobId) => {
	const getJobByIdApi = useApi(jobApi.getJobById, true, true);

	const fetchJobById = async ({ queryKey }) => {
		const [_, __, jobId] = queryKey;
		const response = await getJobByIdApi.request(jobId);
		return response.data;
	};

	const query = useQuery({
		queryKey: jobsKeys.detail(jobId),
		queryFn: fetchJobById,
		enabled: !!jobId,
	});

	return query;
};

export default useJob;
